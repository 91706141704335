import {
  IconButtonV2,
  StatusV2,
  customMedia,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  DELETE_EVENT_SCHEDULE_PARTICIPANT_V2,
  DeleteEventScheduleParticipantV2Request,
  DeleteEventScheduleParticipantV2Response,
  EventScheduleParticipantId,
  EventScheduleParticipantV2,
  EventScheduleV2,
  SAVE_EVENT_SCHEDULE_PARTICIPANTS_V2,
  SaveEventScheduleParticipantsV2Request,
  SaveEventScheduleParticipantsV2Response,
  User,
  isBaseFunctionToggleEnabled
} from '@atomica.co/irori';
import { Count } from '@atomica.co/types';
import { Language, ZERO, builder, isNull, isUndefined, isZero, toDateTimeDurationStr, uuid } from '@atomica.co/utils';
import StarOutlineRoundedIcon from '@material-ui/icons/StarOutlineRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import mojaco from '../../../assets/mojaco/mojaco_break.png';
import CommonRequest from '../../../requests/common-request';

interface P {
  base: BaseDto;
  user: User;
  eventSchedule: EventScheduleV2;
  onClick: () => void;
}

const AccountEventCard: React.FC<P> = React.memo(props => {
  const { base, user, eventSchedule, onClick } = props;

  const unmountRef = useUnmountRef();
  const [isThumbnailExisting, setIsThumbnailExisting] = useSafeState<boolean>(unmountRef, true);
  const [participantId, setParticipantId] = useSafeState<EventScheduleParticipantId>(unmountRef);
  const [isInterested, setIsInterested] = useSafeState<boolean>(unmountRef, false);
  const [isApplied, setIsApplied] = useSafeState<boolean>(unmountRef, false);
  const [isAnswered, setIsAnswered] = useSafeState<boolean>(unmountRef, false);
  const [isInitialized, setIsInitialized] = useSafeState<boolean>(unmountRef, false);
  const [isSaving, setIsSaving] = useSafeState<boolean>(unmountRef, false);

  const isHeld = useMemo<boolean>(() => eventSchedule.endAtV2 < new Date(), [eventSchedule]);
  const countOfparticipants = useMemo<Count>(
    () => eventSchedule.participants?.filter(participant => participant.isApplied).length || ZERO,
    [eventSchedule]
  );
  const hasQuestionnaire = useMemo<boolean>(
    () => (isHeld ? !!eventSchedule.postQuestionnaireURL : !!eventSchedule.preQuestionnaireURL),
    [isHeld, eventSchedule]
  );

  const saveParticipantForInterest = useSafeCallback(async (): Promise<void> => {
    setIsSaving(true);
    setIsInterested(true);
    const participantToSave = builder<EventScheduleParticipantV2>()
      .eventScheduleParticipantId(uuid())
      .isInterested(true)
      .isApplied(false)
      .eventSchedule(eventSchedule)
      .user(user)
      .build();

    const request = builder<SaveEventScheduleParticipantsV2Request>()
      .eventScheduleParticipantsV2([participantToSave])
      .build();

    const response = await CommonRequest.call<
      SaveEventScheduleParticipantsV2Request,
      SaveEventScheduleParticipantsV2Response
    >(SAVE_EVENT_SCHEDULE_PARTICIPANTS_V2, request);

    if (response.eventScheduleParticipantIds) {
      setParticipantId(response.eventScheduleParticipantIds[ZERO]);
    }
    setIsSaving(false);
  }, [eventSchedule, user, setIsInterested, setIsSaving, setParticipantId]);

  const deleteParticipant = useSafeCallback(async (): Promise<void> => {
    setIsSaving(true);
    setIsInterested(false);

    const request = builder<DeleteEventScheduleParticipantV2Request>()
      .eventScheduleParticipantId(participantId!)
      .build();
    await CommonRequest.call<DeleteEventScheduleParticipantV2Request, DeleteEventScheduleParticipantV2Response>(
      DELETE_EVENT_SCHEDULE_PARTICIPANT_V2,
      request
    );

    setIsSaving(false);
  }, [participantId, setIsInterested, setIsSaving]);

  useEffect(() => {
    if (!user || !eventSchedule.participants) {
      setIsInitialized(true);
      return;
    }
    const existingParticipant = eventSchedule.participants.find(
      participant => participant.user?.userId === user.userId
    );
    if (existingParticipant) {
      setParticipantId(existingParticipant.eventScheduleParticipantId);
      setIsInterested(existingParticipant.isInterested);
      setIsApplied(existingParticipant.isApplied);
      setIsAnswered(
        isHeld ? existingParticipant.isAnsweredPostQuestionnaire : existingParticipant.isAnsweredPreQuestionnaire
      );
    }
    setIsInitialized(true);
  }, [eventSchedule, isHeld, user, setIsAnswered, setIsApplied, setIsInitialized, setIsInterested, setParticipantId]);

  return (
    <Container onClick={onClick}>
      <ThumbnailWrapper>
        <Thumbnail
          src={isThumbnailExisting && eventSchedule.thumbnailURL ? eventSchedule.thumbnailURL : mojaco}
          onError={() => setIsThumbnailExisting(false)}
        />
      </ThumbnailWrapper>
      <Content>
        <TopContentWrapper>
          <TitleWrapper>
            <Title>{eventSchedule.eventV2!.name}</Title>
            {isInitialized && !isApplied && user && (
              <IconButtonV2
                disabled={isSaving}
                icon={isInterested ? <StyledStarRoundedIcon /> : <StarOutlineRoundedIcon />}
                onClick={isInterested ? deleteParticipant : saveParticipantForInterest}
              />
            )}
          </TitleWrapper>
          <SubTitleWrapper>
            <SubTitle>{eventSchedule.name}</SubTitle>
          </SubTitleWrapper>
        </TopContentWrapper>
        <Datetime>{toDateTimeDurationStr(eventSchedule.startAtV2, eventSchedule.endAtV2, Language.JAPANESE)}</Datetime>
        <BottomContentWrapper>
          <Participants>
            {(isNull(eventSchedule.capacity) || isUndefined(eventSchedule.capacity)) && <NoBold>定員なし</NoBold>}
            {!isNull(eventSchedule.capacity) && countOfparticipants >= eventSchedule.capacity! && (
              <NoBold>定員に達しました</NoBold>
            )}
            {!isNull(eventSchedule.capacity) && countOfparticipants < eventSchedule.capacity! && (
              <>
                <NoBold>残り </NoBold>
                {`${countOfparticipants}/${eventSchedule.capacity}枠`}
              </>
            )}

            {isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USER_POINT) &&
              (isNull(eventSchedule.capacity) || countOfparticipants < eventSchedule.capacity!) && (
                <NoBold>
                  {isZero(eventSchedule.points) ? '・参加無料' : `・${eventSchedule.points.toLocaleString()} ポイント`}
                </NoBold>
              )}
          </Participants>
          <StatusWrapper>
            {user && isApplied && !isHeld && <StyledStatus status='success' label='参加予定' />}
            {user && isApplied && hasQuestionnaire && (
              <StyledStatus
                status={isAnswered ? 'success' : 'warning'}
                label={isAnswered ? 'アンケート回答済み' : 'アンケート未回答'}
                variant='contained'
              />
            )}
          </StatusWrapper>
        </BottomContentWrapper>
      </Content>
    </Container>
  );
});

AccountEventCard.displayName = 'AccountEventCard';
export default AccountEventCard;

const Container = styled.div`
  width: 100%;
  height: auto;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 12px;
  margin-bottom: ${themeV2.mixins.v2.spacing * 2}px;
  cursor: pointer;
  ${customMedia.greaterThan('small')`
    height: 161px;
    display: flex;
    align-items: center;
  `}
`;

const ThumbnailWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 12px 12px 0 0;
  ${customMedia.greaterThan('small')`
    width: 194px;
    height: 100%;
  `}
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 12px 12px 0 0;
  object-fit: cover;
  aspect-ratio: 16 / 9;
  ${customMedia.greaterThan('small')`
    border-radius: 12px 0 0 12px;
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  gap: 8px;
`;

const TopContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing / 2}px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  ${themeV2.mixins.v2.typography.title.large};
  word-break: break-all;
  line-height: 110%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const SubTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  word-break: break-all;
  line-height: 110%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  ${customMedia.greaterThan('small')`
    -webkit-line-clamp: 1;
  `}
`;

const SubTitle = styled.div`
  ${themeV2.mixins.v2.typography.title.medium};
  font-weight: 400;
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const Datetime = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
`;

const BottomContentWrapper = styled.div`
  ${media.greaterThan('small')`
    display: flex;
    align-items: center;
    gap: ${themeV2.mixins.v2.spacing}px;  
  `}
`;

const NoBold = styled.span`
  font-weight: 400;
`;

const Participants = styled.span`
  ${themeV2.mixins.v2.typography.label.medium};
`;

const StatusWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledStarRoundedIcon = styled(StarRoundedIcon)`
  color: ${themeV2.mixins.v2.color.font.pink};
`;

const StyledStatus = styled(StatusV2)`
  height: ${themeV2.mixins.v2.spacing * 3}px;
`;
