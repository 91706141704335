import {
  ButtonV2,
  Component,
  Modal,
  RadioBox,
  ScreenLoaderV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseDto, Space, SpaceId, SpaceReservationId, SpaceUsagesForTime, User } from '@atomica.co/irori';
import { Message, Name, Option, Remarks } from '@atomica.co/types';
import {
  EMPTY,
  Language,
  MILLI_SECONDS_OF_ONE_SECOND,
  SECONDS_OF_ONE_MINUTE,
  ZERO,
  calcTimeDiff,
  formatTime,
  setTimeToDate
} from '@atomica.co/utils';
import { TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SubjectOutlinedIcon from '@material-ui/icons/SubjectOutlined';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { convertToDisabledOption, convertToLabel, convertToOption } from '../../../converters/space-converter';
import { ReservationModalMode } from '../../../enums/space-enum';
import { Labels } from '../../../models/common-model';
import { reservationTimeToStr } from '../../../utils/space-reservation-util';
import ConfirmationModal from './ConfirmationModal';

const constructErrorMessage = (selectedSpace: Space, startAt: Date, endAt: Date): Message => {
  const reservationMinutes = Math.floor(
    calcTimeDiff(startAt, endAt) / (SECONDS_OF_ONE_MINUTE * MILLI_SECONDS_OF_ONE_SECOND)
  );

  if (
    selectedSpace.minimumReservation > reservationMinutes ||
    reservationMinutes % selectedSpace.reservationUnit !== ZERO
  ) {
    return `予約可能時間は${selectedSpace.minimumReservation}分から${selectedSpace.reservationUnit}分刻みのみとなりますので、再度時間の選択をお願いいたします。`;
  }

  if (selectedSpace.lastStartAt) {
    const lastStartAt = setTimeToDate(new Date(startAt), selectedSpace.lastStartAt);
    return !!lastStartAt && lastStartAt < startAt
      ? `利用開始時刻は${formatTime(selectedSpace.lastStartAt, Language.JAPANESE)}よりも前を選択してください`
      : EMPTY;
  }

  return EMPTY;
};

interface P {
  isOpen: boolean;
  mode: ReservationModalMode;
  reservationName: Name;
  selectedDate: Date | undefined;
  startAt: Date | undefined;
  endAt: Date | undefined;
  remarks: Remarks;
  spaces: Space[];
  spaceUsages: SpaceUsagesForTime | undefined;
  spaceIdToReserve: SpaceId | undefined;
  spaceReservationIdToUpdate: SpaceReservationId | undefined;
  user: User | undefined;
  base: BaseDto;
  setReservationName(reservationName: Name): void;
  setSpaceIdToReserve(spaceId: SpaceId | undefined): void;
  setRemarks(remarks: Remarks): void;
  saveSpaceReservation(): Promise<SpaceReservationId | undefined>;
  deleteSpaceReservation(): void;
  onClose(): void;
}

const ReservationModal: React.FC<P> = React.memo(props => {
  const {
    isOpen,
    mode,
    selectedDate,
    startAt,
    endAt,
    remarks,
    spaces,
    spaceIdToReserve,
    spaceReservationIdToUpdate,
    spaceUsages,
    user,
    base,
    setSpaceIdToReserve,
    setRemarks,
    saveSpaceReservation,
    deleteSpaceReservation,
    onClose
  } = props;

  const unmountRef = useUnmountRef();
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [disabledButtons, setDisabledButtons] = useSafeState<boolean>(unmountRef, true);
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);

  const labels = useMemo<Labels>(() => convertToLabel(spaces), [spaces]);
  const options = useMemo<string[]>(() => convertToOption(spaces), [spaces]);
  const disabledOptions = useMemo<Option[]>(
    () =>
      mode !== ReservationModalMode.EDIT
        ? convertToDisabledOption(
            spaces,
            spaceUsages,
            selectedDate,
            startAt,
            endAt,
            spaceReservationIdToUpdate,
            base.timezone
          )
        : spaces.filter(space => space.spaceId !== spaceIdToReserve).map(space => space.spaceId),
    [
      mode,
      spaces,
      spaceUsages,
      selectedDate,
      startAt,
      endAt,
      spaceReservationIdToUpdate,
      base.timezone,
      spaceIdToReserve
    ]
  );
  const selectedSpace = useMemo<Space | undefined>(
    () => spaces.find(space => space.spaceId === spaceIdToReserve),
    [spaces, spaceIdToReserve]
  );

  const handleSaveClicked = useSafeCallback(async (): Promise<void> => {
    setSaving(true);
    setDisabledButtons(true);
    await saveSpaceReservation();
    setSaving(false);
    setDisabledButtons(false);
  }, [setSaving, setDisabledButtons, saveSpaceReservation]);

  const handleDeleteClicked = useSafeCallback(async (): Promise<void> => {
    setSaving(true);
    setDisabledButtons(true);
    setIsModalOpen(false);
    await deleteSpaceReservation();
    setSaving(false);
    setDisabledButtons(false);
  }, [setSaving, setDisabledButtons, setIsModalOpen, deleteSpaceReservation]);

  const initialize = useSafeCallback((): void => {
    if (!startAt || !endAt || !selectedSpace) return;
    const errorMessage = constructErrorMessage(selectedSpace, startAt, endAt);
    setErrorMessage(errorMessage);
    setDisabledButtons(!!errorMessage);
  }, [startAt, endAt, selectedSpace, setErrorMessage, setDisabledButtons]);

  useEffect(() => {
    if (!isOpen) return;
    initialize();
  }, [isOpen, initialize]);

  return (
    <Component className='reservation-modal'>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Container onClick={onClose}>
          <Frame onClick={e => e.stopPropagation()}>
            <CloseButtonV2Wrapper>
              <CustomCloseIcon onClick={onClose} />
            </CloseButtonV2Wrapper>

            <Content>
              <TitleWrapper>
                <Title>予約内容</Title>
              </TitleWrapper>

              <ItemWrapper>
                <ItemName>
                  <CustomScheduleIcon />
                  <Label>{reservationTimeToStr(selectedDate, startAt, endAt)}</Label>
                </ItemName>
              </ItemWrapper>

              <ItemWrapper>
                <ItemName>
                  <CustomRoomOutlinedIcon />
                  <Label>会議室</Label>
                </ItemName>
                <RadioWrapper>
                  <CustomRadio
                    disabledOptions={disabledOptions}
                    options={options}
                    labels={labels}
                    value={spaceIdToReserve}
                    onChange={setSpaceIdToReserve}
                  />
                </RadioWrapper>
              </ItemWrapper>

              <ItemWrapper>
                <ItemRemark>
                  <CustomSubjectOutlinedIcon />
                  <CustomTextField
                    minRows={5}
                    multiline
                    placeholder='備考（質問や要望など）'
                    value={remarks}
                    onChange={event => setRemarks(event.target.value)}
                  />
                </ItemRemark>
              </ItemWrapper>

              <ItemWrapper>{!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}</ItemWrapper>

              <ButtonV2Wrapper>
                {mode !== ReservationModalMode.READONLY &&
                  (mode === ReservationModalMode.EDIT ? (
                    <>
                      <LeftButtonV2Wrapper>
                        <ButtonV2
                          type='primary'
                          disabled={disabledButtons}
                          onClick={handleSaveClicked}
                          label='備考を修正する'
                        />
                      </LeftButtonV2Wrapper>
                      <ButtonV2 type='default' onClick={() => setIsModalOpen(true)} label='予約を取り消す' />
                    </>
                  ) : (
                    <ButtonV2
                      type='primary'
                      disabled={disabledButtons}
                      onClick={handleSaveClicked}
                      label={user ? '予約する' : 'ログインして予約する'}
                    />
                  ))}
              </ButtonV2Wrapper>
            </Content>
          </Frame>
        </Container>
      </Modal>

      <ConfirmationModal isOpen={isModalOpen} onClick={handleDeleteClicked} onClose={() => setIsModalOpen(false)} />
      <ScreenLoaderV2 loading={saving} />
    </Component>
  );
});

ReservationModal.displayName = 'ReservationModal';
export default ReservationModal;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Frame = styled.div`
  width: 480px;
  height: auto;
  background: ${themeV2.mixins.v2.color.background.lightGray};
  border-radius: 12px;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  display: flex;
  flex-direction: column;

  ${media.lessThan('small')`
    width: 95vw;
    max-height: 80vh;
    overflow-y: auto;

    @supports (height: 1dvh) {
      max-height: 80dvh;
    }
  `}
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;

  ${media.lessThan('small')`
    gap: ${themeV2.mixins.v2.spacing / 2}px;
  `}
`;

const CloseButtonV2Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const CustomCloseIcon = styled(CloseIcon)`
  color: ${themeV2.mixins.v2.color.font.gray};
  font-size: 14px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${themeV2.mixins.v2.spacing}px;
`;

const Title = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.large};
  border-left: 4px solid ${themeV2.mixins.v2.color.border.gray};
  padding-left: ${themeV2.mixins.v2.spacing * 2}px;

  ${media.lessThan('small')`
    ${themeV2.mixins.v2.typography.title.medium};
    padding: ${themeV2.mixins.v2.spacing}px;
  `}
`;

const ItemWrapper = styled.div``;

const ItemName = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const ItemRemark = styled.div`
  display: flex;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const CustomScheduleIcon = styled(ScheduleIcon)`
  color: ${themeV2.mixins.v2.color.font.lightGray};
  font-size: 24px;
`;

const CustomRoomOutlinedIcon = styled(RoomOutlinedIcon)`
  color: ${themeV2.mixins.v2.color.font.lightGray};
  font-size: 24px;
`;

const CustomSubjectOutlinedIcon = styled(SubjectOutlinedIcon)`
  color: ${themeV2.mixins.v2.color.font.lightGray};
  font-size: 24px;
  margin-top: ${themeV2.mixins.v2.spacing / 4}px;
`;

const Label = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.large};
  font-weight: bold;
`;

const RadioWrapper = styled.div`
  width: 100%;
  height: auto;
`;

const CustomRadio = styled(RadioBox)`
  background: ${themeV2.mixins.v2.color.background.lightGray};
`;

const ErrorMessage = styled(Typography)`
  color: ${themeV2.mixins.v2.color.font.pink};
  ${themeV2.mixins.v2.typography.body.large};
  font-weight: 700;
`;

const ButtonV2Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const LeftButtonV2Wrapper = styled.div`
  margin-right: ${themeV2.mixins.v2.spacing}px;
`;

const CustomTextField = styled(TextField)`
  color: ${themeV2.mixins.v2.color.font.gray};
  ${themeV2.mixins.v2.typography.body.large};
  font-size: 24px;
  width: 100%;
  padding: 0 !important;
`;
