import { Scrollable, themeV2, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  BaseDto,
  FETCH_CONTRACT_V2_INVITATION,
  FetchContractV2InvitationRequest,
  FetchContractV2InvitationResponse,
  InvitationStatus,
  InvitationType,
  User,
  ValidContractV2Invitation
} from '@atomica.co/irori';
import { Id, Label, Message } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import React, { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import { auth } from '../../firebase';
import useCachedEmail from '../../redux/hooks/useCachedEmail';
import useCachedInvitationEmail from '../../redux/hooks/useCachedInvitationEmail';
import useCachedJoinURL from '../../redux/hooks/useCachedJoinURL';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import useLogin from '../../redux/hooks/useLoginService';
import usePath from '../../redux/hooks/usePath';
import { persistor } from '../../redux/store';
import { Path } from '../../router/Routes';
import {
  differntEmailMessage,
  InvitationTypeLabel,
  joinedMessage,
  joinedSubMessage,
  validInvitationMessage
} from '../../texts/join-text';
import { getBaseConsumerHomePathAndName } from '../../utils/path-util';
import atomica from './../../assets/atomica.png';
import DifferntEmail from './content/DifferentEmail';
import InvalidInvitation from './content/InvalidInvitation';
import Joined from './content/Joined';
import ValidInvitation from './content/ValidInvitation';

const enum DisplayContent {
  INVALID_INVITATION = 'invalid-invitation',
  VALID_INVITATION = 'valid-invitation',
  JOINED = 'joined',
  DIFFERENT_EMAIL = 'different-email'
}

interface P extends RouteComponentProps {
  base: BaseDto;
  user: User | undefined;
}
const JoinScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { generateBasePathWithQueryParams, openBasePathWithQueryParams, openBasePath, queryParams } = usePath();
  const { cachedEmail, clearCachedEmail } = useCachedEmail();
  const { saveCachedInvitationEmail, clearCachedInvitationEmail } = useCachedInvitationEmail();
  const { commonRequest } = useCommonRequest();
  const {
    usableLoginServiceTypes,
    loadLoginServices,
    openGoogleLoginPage,
    openLineLoginPage,
    openSakuraLoginPage,
    handleSignInWithRedirectResult,
    errorMessage
  } = useLogin({ base, user });
  const { saveCachedJoinURL: hookSaveCachedJoinURL, clearCachedJoinURL } = useCachedJoinURL();
  const unmountref = useUnmountRef();
  const [initialized, setInitialized] = useSafeState<boolean>(unmountref, false);
  const [invitation, setInvitation] = useSafeState<ValidContractV2Invitation>(unmountref);
  const [displayContent, setDisplayContent] = useSafeState<DisplayContent>(
    unmountref,
    DisplayContent.INVALID_INVITATION
  );
  const [messageText, setMessageText] = useSafeState<Message>(unmountref);
  const [messageSubText, setMessageSubText] = useSafeState<Message>(unmountref);

  const { invitationId, invitationType, invitationTypeLabel } = useMemo<{
    invitationId: Id | undefined;
    invitationType: InvitationType | undefined;
    invitationTypeLabel: Label;
  }>(() => {
    const { invitationId, invitationType } = queryParams;
    const invitationTypeLabel = invitationType ? InvitationTypeLabel[invitationType] : EMPTY;
    return { invitationId, invitationType, invitationTypeLabel };
  }, [queryParams]);

  const saveCachedJoinURL = useSafeCallback((): void => {
    const { invitationId, invitationType } = queryParams;
    if (!invitationId || !invitationType) {
      clearCachedJoinURL();
      return;
    }
    const urlToCache = generateBasePathWithQueryParams(Path.JOIN, { invitationType, invitationId });
    hookSaveCachedJoinURL(urlToCache);
  }, [clearCachedJoinURL, hookSaveCachedJoinURL, queryParams, generateBasePathWithQueryParams]);

  const openHome = useSafeCallback((): void => {
    const [path] = getBaseConsumerHomePathAndName(base);
    openBasePath(path);
  }, [base, openBasePath]);

  const openSignIn = useSafeCallback((): void => {
    saveCachedInvitationEmail(invitation?.email);
    saveCachedJoinURL();
    openBasePath(Path.SIGN_IN);
  }, [invitation, openBasePath, saveCachedJoinURL, saveCachedInvitationEmail]);

  const openSignOut = useSafeCallback(async (): Promise<void> => {
    clearCachedJoinURL();
    await auth.signOut();
    await persistor.purge();
    window.location.reload();
  }, [clearCachedJoinURL]);

  const backToValidInvitation = useCallback((): void => {
    clearCachedEmail();
    setMessageText(validInvitationMessage(base.baseName, invitationTypeLabel));
    setDisplayContent(DisplayContent.VALID_INVITATION);
  }, [base.baseName, invitationTypeLabel, clearCachedEmail, setDisplayContent, setMessageText]);

  const openRegisterAccountScreenWithEmail = useSafeCallback((): void => {
    openBasePathWithQueryParams(Path.REGISTER_ACCOUNT, { email: invitation?.email });
  }, [openBasePathWithQueryParams, invitation?.email]);

  const checkContractInvitation = useSafeCallback(async (): Promise<void> => {
    if (!invitationId || !invitationType) return;
    const response = await commonRequest<FetchContractV2InvitationRequest, FetchContractV2InvitationResponse>(
      FETCH_CONTRACT_V2_INVITATION,
      { baseId: base.baseId, contractUserId: invitationId }
    );

    if (!response.result) {
      setDisplayContent(DisplayContent.INVALID_INVITATION);
      return;
    }

    const invitation = response.invitation;
    setInvitation(invitation);

    if (user) {
      // ログイン済、かつステータス：参加済み、かつ招待メールアドレスと一致している場合は参加完了画面
      if (invitation.invitationStatus === InvitationStatus.REGISTERED && user?.email === invitation.email) {
        setDisplayContent(DisplayContent.JOINED);
        setMessageText(joinedMessage(base.baseName));
        setMessageSubText(joinedSubMessage(invitationTypeLabel));
        clearCachedInvitationEmail();
        return;
      }
      // ログイン済、かつステータス：招待中、かつ招待メールアドレスと一致しない場合はメール不一致画面
      if (invitation.invitationStatus === InvitationStatus.INVITED && user?.email !== invitation.email) {
        setDisplayContent(DisplayContent.DIFFERENT_EMAIL);
        setMessageText(differntEmailMessage());
        return;
      }

      // ログイン済、かつステータス：招待中、かつ招待メールアドレスと一致する場合にどれにも該当しないことでINVALID_INVITATIONが表示されちゃうのやだなぁ
      // このパターンは招待画面から外部サービスアカウント系でログインした後、アカウント登録せずに離脱して戻ってきた場合に起きる
    } else {
      if (invitation.invitationStatus === InvitationStatus.INVITED) {
        // 未ログイン、かつステータス：招待中、かつ認証メールアドレスと不一致の場合は招待画面
        if (!!cachedEmail && cachedEmail !== invitation.email) {
          saveCachedJoinURL();
          setMessageText(differntEmailMessage());
          setDisplayContent(DisplayContent.DIFFERENT_EMAIL);
          return;
        }
        // 未ログイン、かつステータス：招待中、かつ認証メールアドレスが空の場合は招待画面
        if (!cachedEmail) {
          saveCachedJoinURL();
          saveCachedInvitationEmail(invitation.email);
          setMessageText(validInvitationMessage(base.baseName, invitationTypeLabel));
          setDisplayContent(DisplayContent.VALID_INVITATION);
          return;
        }
      }
    }
    clearCachedEmail();
    setDisplayContent(DisplayContent.INVALID_INVITATION);
  }, [
    cachedEmail,
    base.baseId,
    commonRequest,
    invitationId,
    invitationType,
    clearCachedEmail,
    setInvitation,
    setMessageText,
    setMessageSubText,
    setDisplayContent,
    saveCachedJoinURL,
    saveCachedInvitationEmail,
    user,
    base.baseName,
    invitationTypeLabel,
    clearCachedInvitationEmail
  ]);

  const initialize = useSafeCallback(async () => {
    if (!invitationType || !invitationId || !Object.values(InvitationType).includes(invitationType)) {
      setDisplayContent(DisplayContent.INVALID_INVITATION);
      return;
    }

    const promises: Promise<void>[] = [];
    promises.push(loadLoginServices());

    switch (invitationType) {
      case InvitationType.CONTRACT:
        promises.push(checkContractInvitation());
        break;
    }

    await Promise.all(promises);
  }, [invitationType, invitationId, loadLoginServices, setDisplayContent, checkContractInvitation]);

  useEffect(() => {
    if (initialized) return;
    handleSignInWithRedirectResult().finally(() => initialize().finally(() => setInitialized(true)));
  }, [handleSignInWithRedirectResult, initialize, initialized, setInitialized]);

  return (
    <Screen className='join-screen' loading={!initialized}>
      <Container>
        <Scrollable>
          <Content>
            <LogoWrapper>
              <Logo src={base.topImageURL || atomica} />
            </LogoWrapper>
            {displayContent === DisplayContent.DIFFERENT_EMAIL && (
              <DifferntEmail
                openHome={openHome}
                openSignOut={openSignOut}
                backToValidInvitation={backToValidInvitation}
                invitationEmail={invitation?.email}
                userEmail={user?.email || cachedEmail}
                isSigned={!!user}
              />
            )}
            {displayContent === DisplayContent.INVALID_INVITATION && (
              <InvalidInvitation openSignIn={openSignIn} openHome={openHome} isSigned={!!user} />
            )}
            {displayContent === DisplayContent.VALID_INVITATION && (
              <ValidInvitation
                invitation={invitation}
                messageText={messageText ?? EMPTY}
                messageSubText={messageSubText}
                openSignIn={openSignIn}
                usableLoginServiceTypes={usableLoginServiceTypes}
                openRegisterAccountScreenWithEmail={openRegisterAccountScreenWithEmail}
                openGoogleLoginPage={openGoogleLoginPage}
                openLineLoginPage={openLineLoginPage}
                openSakuraLoginPage={openSakuraLoginPage}
                errorMessage={errorMessage}
              />
            )}
            {displayContent === DisplayContent.JOINED && (
              <Joined messageText={messageText ?? EMPTY} messageSubText={messageSubText} openHome={openHome} />
            )}
          </Content>
        </Scrollable>
      </Container>
    </Screen>
  );
});

JoinScreen.displayName = 'JoinScreen';
export default JoinScreen;

const Container = styled.div`
  width: 100vw;
  height: 100vh;

  @supports (height: 1dvh) {
    height: 100dvh;
  }
`;

const Content = styled.div`
  max-width: 428px;
  margin-inline: auto;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 12}px;
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${themeV2.mixins.v2.spacing * 2}px;
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  min-height: 160px;
  object-fit: contain;
`;
