import {
  ButtonV2,
  CardWithLabelV2,
  themeV2,
  themeV3,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  CardBrand,
  ContractV2,
  CreditCard,
  PaymentMethod,
  SEND_CREDIT_CARD_REGISTER_FORM_FOR_ADMIN,
  SendCreditCardRegisterFormForAdminRequest,
  SendCreditCardRegisterFormForAdminResponse
} from '@atomica.co/irori';
import { builder } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import amex from '../../../../assets/credit-card/amex.png';
import diner from '../../../../assets/credit-card/diner.png';
import jcb from '../../../../assets/credit-card/jcb.png';
import master from '../../../../assets/credit-card/master.png';
import visa from '../../../../assets/credit-card/visa.png';
import DefaultModal from '../../../../components/modal/DefaultModal';
import { SUCCESS } from '../../../../constants/snackbar-const';
import { useSnackbarV2 } from '../../../../provider/SnackbarProviderV2';
import useCommonRequest from '../../../../redux/hooks/useCommonRequest';
import { PAYMENT_METHOD_LABELS } from '../../../../texts/contract-v2-text';
import { SEND_STRIPE_REGISTER_CREDIT_CARD_FROM_SUCCESS } from '../../../../texts/snackbar-text';

interface P {
  base: BaseDto;
  contract: ContractV2 | undefined;
  creditCard: CreditCard | undefined;
}

const ContractDetailsPayment: React.FC<P> = React.memo(props => {
  const { base, contract, creditCard } = props;

  const unmountRef = useUnmountRef();
  const { openSnackbar } = useSnackbarV2();
  const [isResendMailModalOpen, setIsResendMailModalOpen] = useSafeState<boolean>(unmountRef, false);
  const { commonRequest } = useCommonRequest();

  const [isSending, setIsSending] = useSafeState(unmountRef, false);
  const isCreditCardExpired = useSafeCallback((creditCard: CreditCard) => {
    const now = new Date();
    const thisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    const expirationDate = new Date(creditCard.expYear, creditCard.expMonth, 1);
    return thisMonth > expirationDate;
  }, []);

  const creditCardBrandImage = useSafeCallback((cardBrand: CardBrand): string => {
    switch (cardBrand) {
      case CardBrand.AMEX:
        return amex;
      case CardBrand.JCB:
        return jcb;
      case CardBrand.DINERS:
        return diner;
      case CardBrand.MASTER:
        return master;
      case CardBrand.VISA:
        return visa;
      default:
        return '';
    }
  }, []);

  const modalTitle = useSafeCallback((): string => {
    return creditCard ? 'クレジットカードの変更' : 'クレジットカードの登録案内を再送';
  }, [creditCard]);

  const modalContent = useSafeCallback((): React.ReactNode => {
    return creditCard
      ? `契約先担当者のメールアドレス\n（${contract?.contractorEmail}）宛に、クレジットカードの変更を案内するメールを送付します。`
      : `契約先担当者のメールアドレス\n（${contract?.contractorEmail}）宛に、クレジットカードの登録を案内するメールを再度送付します。`;
  }, [creditCard, contract]);

  const modalButtonLabel = useSafeCallback((): string => {
    return creditCard ? '送信' : '再送';
  }, [creditCard, contract]);

  const sendCreditCardRegisterForm = useSafeCallback(async (): Promise<void> => {
    setIsSending(true);
    const sendCreditCardRegisterRequest = builder<SendCreditCardRegisterFormForAdminRequest>()
      .baseId(base.baseId)
      .contractId(contract!.contractId)
      .isChange(true)
      .build();
    await commonRequest<SendCreditCardRegisterFormForAdminRequest, SendCreditCardRegisterFormForAdminResponse>(
      SEND_CREDIT_CARD_REGISTER_FORM_FOR_ADMIN,
      sendCreditCardRegisterRequest
    );
    setIsSending(false);
    openSnackbar(SEND_STRIPE_REGISTER_CREDIT_CARD_FROM_SUCCESS, SUCCESS, 4000);
    setIsResendMailModalOpen(false);
  }, [base.baseId, commonRequest, contract, openSnackbar, setIsResendMailModalOpen, setIsSending]);

  const renderCreditCard = useMemo(() => {
    if (contract?.paymentMethod === PaymentMethod.CREDIT_CARD) {
      if (creditCard) {
        return (
          <>
            <CardWithLabelV2
              src={creditCardBrandImage(creditCard.brand)}
              shape='rect'
              label='登録済みのクレジットカード'
              text={creditCard.maskedNumber.replace(/(.{4})/g, '$1 ').trim()}
              subText={`有効期限: ${creditCard.expMonth}/${creditCard.expYear.toString().slice(-2)}`}
              subTextColor={isCreditCardExpired(creditCard) ? 'error' : 'default'}
              component={<ButtonV2 type='default' label={'変更'} onClick={() => setIsResendMailModalOpen(true)} />}
            />
            {isCreditCardExpired(creditCard) && (
              <ErrorContainer>
                <StyledErrorOutlined>
                  <WarningRounded />
                </StyledErrorOutlined>
                <ErrorMessage>{'クレジットカードの有効期限が切れています。'}</ErrorMessage>
              </ErrorContainer>
            )}
          </>
        );
      } else {
        return (
          <CardWithLabelV2
            label='登録済みのクレジットカード'
            text={'未登録'}
            cardStyles={{ color: '#A30A04' }}
            component={
              <ButtonV2 type='default' label='登録案内を再送' onClick={() => setIsResendMailModalOpen(true)} />
            }
          />
        );
      }
    }
  }, [contract, creditCard, creditCardBrandImage, isCreditCardExpired, setIsResendMailModalOpen]);

  return (
    <PaymentContent>
      <ContentTitle>支払方法</ContentTitle>
      <Rows>
        <CardWithLabelV2 label='支払い方法' text={PAYMENT_METHOD_LABELS[contract?.paymentMethod ?? 'credit_card']} />
        {renderCreditCard}
      </Rows>
      <DefaultModal
        isOpen={isResendMailModalOpen}
        headerLabel={modalTitle()}
        rightButtonProps={{ label: modalButtonLabel(), onClick: sendCreditCardRegisterForm, disabled: isSending }}
        onClose={() => setIsResendMailModalOpen(false)}
      >
        <ModalContent>{modalContent()}</ModalContent>
      </DefaultModal>
    </PaymentContent>
  );
});

ContractDetailsPayment.displayName = 'ContractDetailsPayment';
export default ContractDetailsPayment;

const Content = styled.div`
  background-color: ${themeV2.mixins.v2.color.background.white};
  border-radius: 8px;
  padding: ${`${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px`};
`;

const PaymentContent = styled(Content)`
  grid-area: payment;
`;

const ContentTitle = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.black};
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
  padding: ${themeV2.mixins.v2.spacing}px 0;
`;
const Rows = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 3}px 0;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const ErrorMessage = styled.div`
  color: ${themeV3.mixins.v3.color.container.action.errorDark};
  ${themeV2.mixins.v2.typography.body.medium};
  line-height: 150%;
  letter-spacing: 0.25px;
  padding-bottom: 0;
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledErrorOutlined = styled(WarningRounded)`
  color: ${themeV3.mixins.v3.color.container.action.errorDark} !important;
`;

const ModalContent = styled.div`
  white-space: pre-wrap;
`;
