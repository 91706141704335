import { Component, Scrollable, theme } from '@atomica.co/components';
import { BaseDto, ErrorCode, User } from '@atomica.co/irori';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../constants/common-const';
import { Path } from '../../router/Routes';
import { isAdmin, isPIC } from '../../utils/user-util';
import BillingDetailsScreenV2 from '../billing-v2/BillingDetailsScreen';
import BillingListScreenV2 from '../billing-v2/BillingListScreen';
import RegisterBillingScreenV2 from '../billing-v2/RegisterBillingScreen';
import ContractDetailsScreenV2 from '../contract-v2/ContractDetailsScreen';
import ContractListScreenV2 from '../contract-v2/ContractListScreen';
import RegisterContractScreenV2 from '../contract-v2/RegisterContractScreen';
import ContractDetailsScreen from '../contract/ContractDetailsScreen';
import ContractListScreen from '../contract/ContractListScreen';
import RegisterContractScreen from '../contract/RegisterContractScreen';
import EventScheduleV2DetailsScreen from '../event-v2/EventScheduleV2DetailsScreen';
import EventV2DetailsScreen from '../event-v2/EventV2DetailsScreen';
import EventV2ListScreen from '../event-v2/EventV2ListScreen';
import RegisterEventScheduleV2Screen from '../event-v2/RegisterEventScheduleV2Screen';
import RegisterEventV2Screen from '../event-v2/RegisterEventV2Screen';
import ExportScreen from '../export/ExportScreen';
import RegisterResourceMasterScreen from '../master/resource/RegisterResourceMasterScreen';
import ResourceMasterListScreen from '../master/resource/ResourceMasterListScreen';
import ShotMasterScreen from '../master/shot/ShotMasterScreen';
import QuestionnaireDetailScreen from '../questionnaire/QuestionnaireDetailScreen';
import QuestionnaireListScreen from '../questionnaire/QuestionnaireListScreen';
import RegisterQuestionnaireScreen from '../questionnaire/RegisterQuestionnaireScreen';
import ReservationDetailScreen from '../reservation/ReservationDetailScreen';
import ReservationListScreen from '../reservation/ReservationListScreen';
import UserV2BulkUpdateScreen from '../user-v2/UserV2BulkUpdateScreen';
import UserV2DetailsScreen from '../user-v2/UserV2DetailsScreen';
import UserV2ListScreen from '../user-v2/UserV2ListScreen';
import UserSearchScreen from '../users/UserSearchScreen';
import VisitorsScreen from '../users/VisitorsScreen';
import CreateWishScreen from '../wish/CreateWishScreen';
import WishDetailsScreen from '../wish/WishDetailsScreen';
import WishSearchScreen from '../wish/WishListScreen';
import { DRAWER_WIDTH } from './ConsoleScreen';

interface P {
  isDrawerOpen: boolean;
  path: Path | undefined;
  base: BaseDto;
  user: User;
  onChange(path: Path): void;
}

const ConsoleSwitcher: React.FC<P> = React.memo(props => {
  const { isDrawerOpen, base, user, path, onChange } = props;

  useEffect(() => {
    if (!isPIC(user, base.baseCode) || !isAdmin(user, base.baseCode)) throw new Error(ErrorCode.PERMISSION_DENIED);
  }, [base, user]);

  const screen = useMemo<React.ReactElement | undefined>(() => {
    switch (path) {
      case Path.VISITORS:
        return <VisitorsScreen base={base} />;

      case Path.USER_SEARCH:
        return <UserSearchScreen base={base} user={user} />;

      case Path.USER_SEARCH_V2:
        return <UserV2ListScreen base={base} user={user} />;

      case Path.USER_OVERVIEW:
      case Path.USER_WISH:
      case Path.USER_DIALOGUE_LOG:
      case Path.USER_EVENT_LOG:
      case Path.USER_SPACE_USAGE_LOG:
      case Path.USER_CHECK_IN_HISTORY:
      case Path.USER_POINT_USAGE_LOG:
      case Path.USER_ROOM_ENTRY_EXIT_HISTORY:
        return <UserV2DetailsScreen isDrawerOpen={false} base={base} user={user} />;

      case Path.USER_BULK_UPDATE:
        return <UserV2BulkUpdateScreen base={base} user={user} />;

      case Path.CONTRACT_LIST:
        return <ContractListScreen base={base} user={user} onChange={onChange} />;

      case Path.REGISTER_CONTRACT:
        return <RegisterContractScreen base={base} user={user} onChange={onChange} />;

      case Path.CONTRACT_DETAILS:
        return <ContractDetailsScreen base={base} user={user} onChange={onChange} />;

      case Path.CONTRACT_LIST_V2:
        return <ContractListScreenV2 base={base} user={user} />;

      case Path.REGISTER_CONTRACT_V2:
      case Path.EDIT_CONTRACT_V2:
        return <RegisterContractScreenV2 isDrawerOpen={false} base={base} user={user} />;

      case Path.CONTRACT_DETAILS_V2_DETAIL:
      case Path.CONTRACT_DETAILS_V2_BILLING:
      case Path.CONTRACT_DETAILS_V2_MEMBER:
      case Path.CONTRACT_DETAILS_V2_LOGS:
        return <ContractDetailsScreenV2 isDrawerOpen={false} base={base} user={user} />;

      case Path.BILLING_LIST_V2:
        return <BillingListScreenV2 base={base} user={user} />;

      case Path.REGISTER_BILLING_V2:
      case Path.EDIT_BILLING_V2:
        return <RegisterBillingScreenV2 isDrawerOpen={false} base={base} user={user} />;

      case Path.BILLING_DETAILS_V2_DETAIL:
      case Path.BILLING_DETAILS_V2_SPACE_USAGES:
      case Path.BILLING_DETAILS_V2_ACCESSES:
      case Path.BILLING_DETAILS_V2_LOGS:
        return <BillingDetailsScreenV2 base={base} user={user} />;

      case Path.WISH_LIST:
        return <WishSearchScreen base={base} />;

      case Path.REGISTER_WISH:
        return <CreateWishScreen base={base} user={user} />;

      case Path.EDIT_WISH:
        return <CreateWishScreen base={base} user={user} />;

      case Path.WISH_DETAILS:
        return <WishDetailsScreen base={base} />;

      case Path.EVENT_V2_LIST:
        return <EventV2ListScreen base={base} user={user} onChange={onChange} />;

      case Path.REGISTER_EVENT_V2:
      case Path.EDIT_EVENT_V2:
        return <RegisterEventV2Screen isDrawerOpen={false} base={base} user={user} onChange={onChange} />;

      case Path.EVENT_V2_DETAILS:
        return <EventV2DetailsScreen isDrawerOpen={false} base={base} user={user} onChange={onChange} />;

      case Path.EDIT_EVENT_V2_DETAILS:
        return <RegisterEventV2Screen isDrawerOpen={false} base={base} user={user} onChange={onChange} />;

      case Path.REGISTER_EVENT_SCHEDULE_V2:
      case Path.EDIT_EVENT_SCHEDULE_V2:
        return <RegisterEventScheduleV2Screen isDrawerOpen={false} base={base} user={user} onChange={onChange} />;

      case Path.EVENT_SCHEDULE_V2_DETAILS:
      case Path.EVENT_SCHEDULE_PARTICIPANTS_V2:
      case Path.EVENT_SCHEDULE_GROUPS_V2:
      case Path.ENTRY_FORM_MESSAGES:
      case Path.REMIND_MESSAGES:
        return <EventScheduleV2DetailsScreen isDrawerOpen={false} base={base} user={user} />;

      case Path.QUESTIONNAIRE_LIST:
        return <QuestionnaireListScreen base={base} />;

      case Path.REGISTER_QUESTIONNAIRE:
      case Path.EDIT_QUESTIONNAIRE:
        return <RegisterQuestionnaireScreen base={base} user={user} />;

      case Path.QUESTIONNAIRE_DETAILS:
        return <QuestionnaireDetailScreen base={base} />;

      case Path.RESERVATION_LIST:
        return <ReservationListScreen base={base} user={user} />;

      case Path.RESERVATION_DETAIL:
        return <ReservationDetailScreen base={base} user={user} />;

      case Path.EXPORT:
        return <ExportScreen base={base} user={user} />;

      case Path.SHOT_MASTER:
        return <ShotMasterScreen base={base} />;

      case Path.RESOURCE_MASTER:
        return <ResourceMasterListScreen base={base} />;

      case Path.EDIT_RESOURCE_MASTER:
        return <RegisterResourceMasterScreen base={base} />;
    }
  }, [path, base, user, onChange]);

  return (
    <Component style={{ width: '100%', height: '100%' }} className='console-switcher'>
      <Container open={isDrawerOpen}>
        <Screens>
          <Scrollable>{screen}</Scrollable>
        </Screens>
      </Container>
    </Component>
  );
});

ConsoleSwitcher.displayName = 'ConsoleSwitcher';
export default ConsoleSwitcher;

const Container = styled.main<{ open: boolean }>`
  ${props =>
    props.open
      ? `
    width: 100%;
    transition: ${theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })};
    margin-left: 0px;
  `
      : `
    flex-grow: 1;
    padding: ${theme.spacing(3)}px;
    transition: ${theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })};
    margin-left: -${DRAWER_WIDTH}px;
  `}
`;

const Screens = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);

  @supports (height: 1dvh) {
    height: calc(100dvh - ${HEADER_HEIGHT}px);
  }
`;
