import {
  BackButtonV2,
  ButtonV2,
  CheckBoxV2,
  Component,
  IconButtonV2,
  PageHeaderV2,
  StatusV2,
  styleForFullExpansion,
  themeV2,
  themeV3,
  useMobile,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  DELETE_USER_WISH,
  DeleteUserWishRequest,
  DeleteUserWishResponse,
  DONE_USER_WISH,
  DoneUserWishRequest,
  DoneUserWishResponse,
  User,
  UserWishData,
  UserWishId,
  UserWishStatus,
  UserWishStatusWithoutDiscarded
} from '@atomica.co/irori';
import { builder, embedIdInPath } from '@atomica.co/utils';
import { Add } from '@material-ui/icons';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import DefaultModal from '../../../components/modal/DefaultModal';
import { createCustomization } from '../../../customization/customizationFactory';
import { useSnackbarV2 } from '../../../provider/SnackbarProviderV2';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import usePath from '../../../redux/hooks/usePath';
import useUserWish from '../../../redux/hooks/useUserWish';
import { Path, PATH_IDS } from '../../../router/Routes';
import { USER_WISH_STATUS_LABELS } from '../../../texts/user-wish-text';
import { toStatus } from '../../../utils/user-wish-util';
import { USER_ID } from '@atomica.co/types';

const TARGET_USER_WISH_STATUSES: UserWishStatusWithoutDiscarded[] = [UserWishStatus.DONE, UserWishStatus.IN_PROGRESS];

interface P {
  base: BaseDto;
  user: User;
}

const Card = (
  data: UserWishData,
  onClickForEditButton: (userWishId: UserWishId) => void,
  onClickForDeleteButton: (userWishId: UserWishId) => void,
  onClickForDoneButton: (userWishId: UserWishId) => void,
  isSelf: boolean
) => {
  return (
    <WishCardContent key={`card-${data.userWishId}`}>
      <StatusV2 status={toStatus(data.status)} label={USER_WISH_STATUS_LABELS[data.status]} />
      <WishCardText>{data.content}</WishCardText>
      <ButtonsWrapper>
        {data.status === UserWishStatus.IN_PROGRESS && isSelf && (
          <ButtonV2 label='実現した' type='tertiary' onClick={() => onClickForDoneButton(data.userWishId)}></ButtonV2>
        )}
        <Spacer />
        {isSelf && <IconButtonV2
          icon={<EditOutlinedIcon data-testid='edit-button' />}
          onClick={() => onClickForEditButton(data.userWishId)}
        />}
        {isSelf && <IconButtonV2
          icon={<DeleteOutlineRoundedIcon data-testid='delete-button' />}
          onClick={() => onClickForDeleteButton(data.userWishId)}
        />}
      </ButtonsWrapper>
    </WishCardContent>
  );
};

const AccountWishListScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;

  const { params } = usePath();
  const selectedUserId = params[USER_ID];

  const customization = createCustomization(base.baseCode);
  const replacedWishWord = useMemo(() => {
    return customization.replaceWithMapping('Wish');
  }, [customization]);

  const { openPath, openBasePath } = usePath();
  const { commonRequest } = useCommonRequest();
  const { openSnackbar } = useSnackbarV2();
  const { userWishes, loadUserWish } = useUserWish();
  const isMobile = useMobile();

  const unmountRef = useUnmountRef();
  const [isLoading, setIsLoading] = useSafeState<boolean>(unmountRef, true);
  const [isDeleteModalOepn, setIsDeleteModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [isDoneModalOepn, setIsDoneModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [selectedUserWishId, setSelectedUserWishId] = useSafeState<UserWishId | undefined>(unmountRef, undefined);
  const [isDoneWishShown, setIsDoneWishShown] = useSafeState<boolean>(unmountRef, false);

  const wishesInProgress = useMemo<UserWishData[]>(
    () => userWishes.filter(wish => wish.status === UserWishStatus.IN_PROGRESS),
    [userWishes]
  );
  const wishesDone = useMemo<UserWishData[]>(
    () => userWishes.filter(wish => wish.status === UserWishStatus.DONE),
    [userWishes]
  );
  const isRegisterButtonShown = useMemo<boolean>(() => wishesInProgress.length < 3, [wishesInProgress.length]);

  const openEditWishScreen = useSafeCallback(
    (userWishId: UserWishId): void => {
      openPath(embedIdInPath(Path.EDIT_ACCOUNT_WISH, PATH_IDS, [base.baseCode, userWishId]));
    },
    [base.baseCode, openPath]
  );

  const isSelf = useMemo(() => {
    return user.userId === selectedUserId;
  },[user.userId, selectedUserId])

  const handleBackButtonClicked = useCallback((): void => {
    if (isSelf) {
      openBasePath(Path.ACCOUNT_PROFILE_V2);
    } else {
      openPath(embedIdInPath(Path.MEMBER_PROFILE, PATH_IDS, [base.baseCode, selectedUserId]));
    }
  }, [base.baseCode, isSelf, openBasePath, openPath, selectedUserId]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    setIsLoading(true);
    await loadUserWish(selectedUserId, base.baseId, TARGET_USER_WISH_STATUSES);
    setIsLoading(false);
  }, [base.baseId, loadUserWish, user.userId, setIsLoading]);

  const deleteUserWish = useSafeCallback(async (): Promise<void> => {
    if (!selectedUserWishId) return;

    setIsDeleteModalOpen(false);
    setIsLoading(true);

    const request = builder<DeleteUserWishRequest>().userWishId(selectedUserWishId).build();
    const response = await commonRequest<DeleteUserWishRequest, DeleteUserWishResponse>(DELETE_USER_WISH, request);
    if (!response.result) {
      openSnackbar(`${replacedWishWord}の破棄に失敗しました 時間をおいてもう一度お試しください`, 'error', 4000);
      return;
    }

    openSnackbar(`${replacedWishWord}を破棄しました！`, 'success', 4000);

    await initialize();
  }, [commonRequest, initialize, openSnackbar, selectedUserWishId, setIsDeleteModalOpen, setIsLoading]);

  const doneUserWish = useSafeCallback(async (): Promise<void> => {
    if (!selectedUserWishId) return;

    setIsDoneModalOpen(false);
    setIsLoading(true);

    const request = builder<DoneUserWishRequest>().baseId(base.baseId).userWishId(selectedUserWishId).build();
    const response = await commonRequest<DoneUserWishRequest, DoneUserWishResponse>(DONE_USER_WISH, request);
    if (!response.result) {
      openSnackbar(`${replacedWishWord}の更新に失敗しました 時間をおいてもう一度お試しください`, 'error', 4000);
      return;
    }

    openSnackbar(`${replacedWishWord}を実現済みにしました！`, 'success', 4000);

    await initialize();
  }, [base.baseId, commonRequest, initialize, selectedUserWishId, setIsDoneModalOpen, setIsLoading]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleDoneWishShownCheckboxChanged = useSafeCallback((): void => {
    setIsDoneWishShown(current => !current);
  }, [setIsDoneWishShown]);

  return (
    <Component
      style={styleForFullExpansion}
      className='account-wish-list-screen'
      title={`${replacedWishWord}リスト(β版)`}
      loading={isLoading}
    >
      <Container data-testid='account-wish-list-screen'>
        <BackButtonV2 label='戻る' onClick={handleBackButtonClicked} />
        <Content>
          <PageHeaderV2 title={`${replacedWishWord}リスト(β版)`} titleSize={isMobile ? 'small' : 'default'} />
          <CheckBoxV2 checked={isDoneWishShown} onChange={handleDoneWishShownCheckboxChanged}>
            <CheckBoxText>{`実現済みの${replacedWishWord}を表示する`}</CheckBoxText>
          </CheckBoxV2>
          {wishesInProgress.map(userWish =>
            Card(
              userWish,
              openEditWishScreen,
              userWishId => {
                setIsDeleteModalOpen(true);
                setSelectedUserWishId(userWishId);
              },
              userWishId => {
                setIsDoneModalOpen(true);
                setSelectedUserWishId(userWishId);
              },
              isSelf
            )
          )}
          {isRegisterButtonShown && isSelf && (
            <ButtonV2
              type='secondary'
              isFullWidth
              size='large'
              startIcon={<Add />}
              label='新規作成'
              onClick={() => openBasePath(Path.REGISTER_ACCOUNT_WISH)}
            />
          )}
          {isDoneWishShown && (
            <>
              <PageHeaderV2 title={`実現済みの${replacedWishWord}`} titleSize={isMobile ? 'small' : 'default'} />
              {wishesDone.map(userWish =>
                Card(
                  userWish,
                  openEditWishScreen,
                  userWishId => {
                    setIsDeleteModalOpen(true);
                    setSelectedUserWishId(userWishId);
                  },
                  userWishId => {
                    setIsDoneModalOpen(true);
                    setSelectedUserWishId(userWishId);
                  },
                  isSelf
                )
              )}
            </>
          )}
        </Content>
        <DefaultModal
          headerLabel={`${replacedWishWord}の破棄`}
          rightButtonProps={{ label: '破棄', onClick: deleteUserWish }}
          isOpen={isDeleteModalOepn}
          onClose={() => setIsDeleteModalOpen(false)}
        >
          破棄してもよろしいですか？
        </DefaultModal>
        <DefaultModal
          headerLabel={`${replacedWishWord}の実現`}
          rightButtonProps={{ label: 'はい', onClick: doneUserWish }}
          isOpen={isDoneModalOepn}
          onClose={() => setIsDoneModalOpen(false)}
        >
          {`${replacedWishWord}のステータスを実現済みにします。よろしいですか？`}
        </DefaultModal>
      </Container>
    </Component>
  );
});
AccountWishListScreen.displayName = 'AccountWishListScreen';
export default AccountWishListScreen;

const Container = styled.div`
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  padding-inline: ${themeV2.mixins.v2.spacing * 2}px;
  padding-bottom: ${themeV2.mixins.v2.spacing * 3}px;
`;

const CheckBoxText = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  cursor: pointer;
`;

const WishCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  border-radius: 12px;
  background: ${themeV3.mixins.v3.color.container.neutral.white};
  word-break: break-all;
  white-space: pre-wrap;
`;

const WishCardText = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${themeV2.mixins.v2.color.background.gray};
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 2}px 0;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;

const Spacer = styled.div`
  flex-grow: 1;
`;
