import {
  ContractDetailV2,
  ContractLogV2,
  ContractUserV2,
  ContractV2,
  TaxDiv,
  TaxV2,
  UnitV2,
  User
} from '@atomica.co/irori';
import { Key, Name, Operation, Text } from '@atomica.co/types';
import { builder, EMPTY, hasLength, isNumber, ONE, uuid, ZERO } from '@atomica.co/utils';
import { format, isDate, isEqual } from 'date-fns';
import {
  CONTRACTOR_TYPE_LABELS,
  CONTRACT_CREATED,
  CONTRACT_DELETED,
  CONTRACT_DETAIL_ADDED,
  CONTRACT_DETAIL_DELETED,
  CONTRACT_INFLOW_SOURCE_LABELS,
  CONTRACT_MODIFIED_DEFAULT,
  CONTRACT_MODIFIED_DETAIL,
  PAYMENT_METHOD_LABELS
} from '../texts/contract-v2-text';

enum LogTypeEnum {
  DEFAULT = 'default',
  DETAIL = 'detail'
}

type ConfigCompareColumn = {
  columnName: Name | Name[];
  columnLabel: Name;
  logType: LogTypeEnum;
  displayItemName?: Name;
  getLabel?: (key: Key) => Text;
};

const configCompareContract: ConfigCompareColumn[] = [
  { columnName: 'startDate', columnLabel: '契約開始日', logType: LogTypeEnum.DETAIL },
  { columnName: 'endDate', columnLabel: '契約終了日', logType: LogTypeEnum.DETAIL },
  { columnName: 'contractPlanId', columnLabel: '契約プラン', logType: LogTypeEnum.DEFAULT },
  {
    columnName: 'contractorType',
    columnLabel: '契約先種別',
    logType: LogTypeEnum.DETAIL,
    getLabel: key => CONTRACTOR_TYPE_LABELS[key]
  },
  { columnName: 'entityName', columnLabel: '法人名', logType: LogTypeEnum.DETAIL },
  { columnName: 'representativeName', columnLabel: '代表者名', logType: LogTypeEnum.DETAIL },
  { columnName: 'contractorName', columnLabel: '契約者名', logType: LogTypeEnum.DETAIL },
  { columnName: 'contractorEmail', columnLabel: '契約者メールアドレス', logType: LogTypeEnum.DETAIL },
  { columnName: 'contractorPost', columnLabel: '契約者郵便番号', logType: LogTypeEnum.DEFAULT },
  { columnName: 'contractorAddress', columnLabel: '契約者住所', logType: LogTypeEnum.DEFAULT },
  { columnName: 'paymentCount', columnLabel: '支払回数', logType: LogTypeEnum.DETAIL },
  {
    columnName: 'paymentMethod',
    columnLabel: '支払方法',
    logType: LogTypeEnum.DETAIL,
    getLabel: key => PAYMENT_METHOD_LABELS[key]
  },
  {
    columnName: 'inflowSource',
    columnLabel: '流入経路',
    logType: LogTypeEnum.DETAIL,
    getLabel: key => CONTRACT_INFLOW_SOURCE_LABELS[key]
  },
  { columnName: 'contractUserCount', columnLabel: '契約人数', logType: LogTypeEnum.DETAIL },
  { columnName: 'contractorPhone', columnLabel: '契約者電話番号', logType: LogTypeEnum.DEFAULT },
  { columnName: 'billingPost', columnLabel: '請求先郵便番号', logType: LogTypeEnum.DEFAULT },
  { columnName: 'billingAddress', columnLabel: '請求先住所', logType: LogTypeEnum.DEFAULT },
  { columnName: 'remarks', columnLabel: '備考', logType: LogTypeEnum.DEFAULT }
];

const configCompareContractDetail = (units: UnitV2[], taxes: TaxV2[]): ConfigCompareColumn[] => [
  {
    columnName: 'isDisabled',
    columnLabel: '有効／無効',
    logType: LogTypeEnum.DETAIL,
    getLabel: key => (key ? '無効' : '有効'),
    displayItemName: 'itemName'
  },
  { columnName: 'itemName', columnLabel: '項目名', logType: LogTypeEnum.DETAIL, displayItemName: 'itemName' },
  {
    columnName: 'recurrenceCount',
    columnLabel: '繰り返し回数',
    logType: LogTypeEnum.DETAIL,
    displayItemName: 'itemName'
  },
  {
    columnName: 'taxDiv',
    columnLabel: '税種別',
    logType: LogTypeEnum.DETAIL,
    getLabel: key => TaxDiv[key.toUpperCase()],
    displayItemName: 'itemName'
  },
  {
    columnName: ['tax', 'taxId'],
    columnLabel: '税率',
    logType: LogTypeEnum.DETAIL,
    displayItemName: 'itemName',
    getLabel: key => taxes.filter(tax => tax.taxId === key)[ZERO].taxName
  },
  {
    columnName: ['unit', 'unitId'],
    columnLabel: '単位',
    logType: LogTypeEnum.DETAIL,
    displayItemName: 'itemName',
    getLabel: key => units.filter(unit => unit.unitId === key)[ZERO].unitName
  },
  { columnName: 'unitQuantity', columnLabel: '数量', logType: LogTypeEnum.DETAIL, displayItemName: 'itemName' },
  { columnName: 'unitPrice', columnLabel: '単価', logType: LogTypeEnum.DETAIL, displayItemName: 'itemName' }
];

type ContractV2Dtos = ContractV2 | ContractDetailV2 | ContractUserV2;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toRegisterText = (value: any, getLabel: ((key: Key) => Text) | undefined, isDateColumn: boolean): Text => {
  if (value === null || value === undefined) return value;
  if (getLabel) {
    return getLabel(value);
  }
  if (isDateColumn) {
    return format(value, 'yyyy-MM-dd');
  }
  return value.toString();
};

const createContractLogs = (contract: ContractV2, operation: Operation, user: User): ContractLogV2 => {
  return builder<ContractLogV2>()
    .contractLogId(uuid())
    .contract(contract)
    .operation(operation)
    .createdUser(user)
    .build();
};

const getValue = (dto: ContractV2Dtos, columnName: Name | Name[]) =>
  typeof columnName === 'string'
    ? dto[columnName]
    : dto[columnName[ZERO]]
    ? dto[columnName[ZERO]][columnName[ONE]]
    : EMPTY;

const compare = (
  contract: ContractV2,
  prev: ContractV2Dtos,
  curr: ContractV2Dtos,
  configs: ConfigCompareColumn[],
  user: User
): ContractLogV2[] => {
  if (!prev || !curr) return [];

  return configs.reduce((contractLogs: ContractLogV2[], config: ConfigCompareColumn): ContractLogV2[] => {
    const { columnName, columnLabel, displayItemName, logType, getLabel } = config;
    const prevValue = getValue(prev, columnName);
    const currValue = getValue(curr, columnName);
    const isNumberColumn = isNumber(prevValue) || isNumber(currValue);
    const isDateColumn = isDate(prevValue) || isDate(currValue);

    const isDifferent = isDateColumn
      ? !isEqual(prevValue, currValue)
      : isNumberColumn
      ? parseFloat(prevValue) !== parseFloat(currValue)
      : prevValue !== currValue;
    if (isDifferent) {
      const prevText = toRegisterText(prevValue, getLabel, isDateColumn);
      const currText = toRegisterText(currValue, getLabel, isDateColumn);
      const itemName = displayItemName && curr[displayItemName];
      contractLogs.push(
        createContractLogs(
          contract,
          logType === LogTypeEnum.DETAIL
            ? CONTRACT_MODIFIED_DETAIL(columnLabel, prevText, currText, itemName)
            : CONTRACT_MODIFIED_DEFAULT(columnLabel, itemName),
          user
        )
      );
    }
    return contractLogs;
  }, []);
};

export const createContractLogsFromContract = async (
  prevContracts: ContractV2 | undefined,
  currContracts: ContractV2 | undefined,
  user: User | undefined
): Promise<ContractLogV2[]> => {
  if (!user) return [];
  if (!prevContracts && !currContracts) return [];

  // 新規契約登録
  if (!prevContracts && currContracts) {
    return [createContractLogs(currContracts, CONTRACT_CREATED, user)];
  }

  // 契約削除
  if (prevContracts && !currContracts) {
    return [createContractLogs(prevContracts, CONTRACT_DELETED, user)];
  }

  // 差分取得
  return compare(
    currContracts as ContractV2,
    prevContracts as ContractV2Dtos,
    currContracts as ContractV2,
    configCompareContract, user
  );
};

export const createContractLogsFromContractDetail = async (
  contract: ContractV2,
  prevContractDetails: ContractDetailV2[],
  currContractDetails: ContractDetailV2[],
  user: User | undefined,
  units: UnitV2[],
  taxes: TaxV2[]
): Promise<ContractLogV2[]> => {
  if (!user) return [];
  if (!hasLength(prevContractDetails) && !hasLength(currContractDetails)) return [];

  // 追加された明細のログ
  const addedContractLogs = currContractDetails.reduce(
    (logs: ContractLogV2[], currDetail: ContractDetailV2): ContractLogV2[] => {
      if (!prevContractDetails.some(prevDetail => prevDetail.contractDetailId === currDetail.contractDetailId)) {
        logs.push(createContractLogs(contract, CONTRACT_DETAIL_ADDED(currDetail.itemName), user));
      }
      return logs;
    },
    []
  );

  // 削除された明細のログ
  const deletedContractLogs = prevContractDetails.reduce(
    (logs: ContractLogV2[], prevDetail: ContractDetailV2): ContractLogV2[] => {
      if (!currContractDetails.some(currDetail => currDetail.contractDetailId === prevDetail.contractDetailId)) {
        logs.push(createContractLogs(contract, CONTRACT_DETAIL_DELETED(prevDetail.itemName), user));
      }
      return logs;
    },
    []
  );

  // 共通明細のログ
  const comparedContractLogs = currContractDetails.reduce(
    (logs: ContractLogV2[], currContractDetail: ContractDetailV2): ContractLogV2[] => {
      const prevContractDetail = prevContractDetails.find(
        prevDetail => prevDetail.contractDetailId === currContractDetail.contractDetailId
      );
      if (prevContractDetail) {
        const comparedContractLogs = compare(
          contract,
          prevContractDetail,
          currContractDetail,
          configCompareContractDetail(units, taxes),
          user
        );
        return logs.concat(comparedContractLogs);
      }
      return logs;
    },
    []
  );
  return addedContractLogs.concat(deletedContractLogs).concat(comparedContractLogs);
};
