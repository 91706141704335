import { CardWithLabelV2, LabelV2, Tag, themeV2, useMobile } from '@atomica.co/components';
import {
  AuthorityDefCategory,
  BaseDto,
  BaseFunctionToggleCode,
  isBaseFunctionToggleEnabled,
  User
} from '@atomica.co/irori';
import { Name, URL } from '@atomica.co/types';
import { EMPTY, FIRST_INDEX, hasLength, PREFECTURE_NAMES } from '@atomica.co/utils';
import { format } from 'date-fns';
import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { USER_GENDER_LABELS } from '../../../../texts/user-text';
import { getBasePICV2 } from '../../../../utils/user-util';
import mojaco from './../../../../assets/mojaco/mojaco_break.png';

const MASKED_PASSWORD = '*'.repeat(8);

interface Props {
  base: BaseDto;
  user: User;
  facePhotoDownloadURL?: URL;
  useFaceAccess: boolean;
}

const AccountProfileCardView: React.FC<Props> = memo(props => {
  const { base, user, facePhotoDownloadURL, useFaceAccess } = props;
  const isMobile = useMobile();
  const membershipName = useMemo<Name | undefined>(() => {
    const memberships = getBasePICV2(user, base, AuthorityDefCategory.RANK);

    if (hasLength(memberships)) {
      return memberships[FIRST_INDEX].authority?.authorityName;
    }

    return undefined;
  }, [base, user]);

  const { hasCompany, hasOther, otherValue } = useMemo((): {
    hasCompany: boolean;
    hasOther: boolean;
    otherValue?: string;
  } => {
    if (user.userDiv?.userDivDef?.hasOther) {
      return { hasCompany: true, hasOther: true, otherValue: user.userDivOther };
    }

    if (user.userStudentDiv?.userStudentDivDef?.hasOther) {
      return { hasCompany: false, hasOther: true, otherValue: user.userStudentDivOther };
    }

    return { hasCompany: !user.userDiv?.userDivDef?.isStudent, hasOther: false };
  }, [user]);

  const userInfrowSourceName = useMemo((): Name | undefined => {
    const name = user.userInflowSource?.userInflowSourceName;
    const hasOther = user.userInflowSource?.userInflowSourceDef?.hasOther;
    return hasOther ? `その他(${user.userInflowSourceOther})` : name;
  }, [user]);

  const isEnabledProfileV3 = useMemo((): boolean => {
    return isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_PROFILE_V3);
  }, [base]);

  // const userUsagePurposeName = useMemo((): Name | undefined => {
  //   const name = user.userUsagePurpose?.userUsagePurposeName;
  //   const hasOther = user.userUsagePurpose?.userUsagePurposeDef?.hasOther;
  //   return hasOther ? `その他(${user.userUsagePurposeOther})` : name;
  // }, [user]);

  // const familyMemberNamesStr = useMemo((): Name => {
  //   return getFamilyMemberNamesStr(user.userFamilyMemberMappings);
  // }, [user]);

  return (
    <Card isColumn={isEnabledProfileV3 ? true : isMobile}>
      {!isEnabledProfileV3 && (
        <ImageWrapper>
          <Image src={user.photoURL || mojaco} />
        </ImageWrapper>
      )}

      {isEnabledProfileV3 && (
        <InformationRowWrapper isMobile={isMobile}>
          <ImageItem>
            <LabelV2 text='プロフィール画像' />
            <Image src={user.photoURL || mojaco} />
          </ImageItem>
        </InformationRowWrapper>
      )}

      <InformationWrapper isMobile={isMobile}>
        {!isEnabledProfileV3 && membershipName && (
          <InformationRowWrapper isMobile={isMobile}>
            <MembershipWrapper>
              <Tag label={membershipName} status='secondary' />
            </MembershipWrapper>
          </InformationRowWrapper>
        )}

        <InformationRowWrapper isMobile={isMobile}>
          <CardWithLabelV2 label={'姓'} text={user.familyName ?? EMPTY} />
          <CardWithLabelV2 label={'名'} text={user.firstName ?? EMPTY} />
        </InformationRowWrapper>
        <InformationRowWrapper isHalf={!isMobile} isMobile={isMobile}>
          <CardWithLabelV2 label={'メールアドレス'} text={user.email ?? EMPTY} />
        </InformationRowWrapper>
        <InformationRowWrapper isHalf={!isMobile} isMobile={isMobile}>
          <CardWithLabelV2 label={'パスワード'} text={MASKED_PASSWORD} />
        </InformationRowWrapper>
        <InformationRowWrapper data-testid='gender' isHalf={!isMobile} isMobile={isMobile}>
          <CardWithLabelV2 label={'性別'} text={user.gender ? USER_GENDER_LABELS[user.gender] : EMPTY} />
        </InformationRowWrapper>
        <InformationRowWrapper isHalf={!isMobile} isMobile={isMobile}>
          <CardWithLabelV2
            label={'生年月日'}
            text={user.dateOfBirthV2 ? format(new Date(user.dateOfBirthV2), 'yyyy/MM/dd') : EMPTY}
          />
        </InformationRowWrapper>
        <InformationRowWrapper isHalf={!isMobile} isMobile={isMobile}>
          <CardWithLabelV2 label={'電話番号'} text={user.phone ?? EMPTY} />
        </InformationRowWrapper>
        <InformationRowWrapper isMobile={isMobile}>
          <CardWithLabelV2 label={'都道府県'} text={user.prefecture ? PREFECTURE_NAMES[user.prefecture] : EMPTY} />
          <CardWithLabelV2 label={'市区町村'} text={user.city ?? EMPTY} />
        </InformationRowWrapper>
        <InformationRowWrapper isHalf={!isMobile && !hasCompany} isMobile={isMobile}>
          <CardWithLabelV2 label={'職業'} text={user.userDiv?.userDivName ?? EMPTY} />
          {hasCompany && <CardWithLabelV2 label={'お勤め先'} text={user.companyName ?? EMPTY} />}
        </InformationRowWrapper>
        {user.userDiv?.userDivDef?.isStudent && (
          <InformationRowWrapper isHalf={!isMobile} isMobile={isMobile}>
            <CardWithLabelV2 label={'学年'} text={user.userStudentDiv?.userStudentDivName ?? EMPTY} />
          </InformationRowWrapper>
        )}
        {user.userStudentDiv?.userStudentDivDef?.hasSchoolName && (
          <InformationRowWrapper isHalf={!isMobile} isMobile={isMobile}>
            <CardWithLabelV2 label={'学校'} text={user.schoolName ?? EMPTY} />
          </InformationRowWrapper>
        )}
        {user.userStudentDiv?.userStudentDivDef?.hasSchoolName && (
          <InformationRowWrapper isHalf={!isMobile} isMobile={isMobile}>
            <CardWithLabelV2 label={'学部'} text={user.schoolDepartmentName ?? EMPTY} />
          </InformationRowWrapper>
        )}
        {hasOther && (
          <InformationRowWrapper isMobile={isMobile}>
            <CardWithLabelV2 label={'その他'} text={otherValue ?? EMPTY} />
          </InformationRowWrapper>
        )}
        <InformationRowWrapper isHalf={!isMobile} isMobile={isMobile}>
          <CardWithLabelV2 label={'ご利用のきっかけ'} text={userInfrowSourceName ?? EMPTY} />
        </InformationRowWrapper>
        {/* <InformationRowWrapper isHalf={!isMobile} isMobile={isMobile}>
            <CardWithLabelV2 label={'ご利用の目的'} text={userUsagePurposeName ?? EMPTY} />
          </InformationRowWrapper>
          <InformationRowWrapper isHalf={!isMobile} isMobile={isMobile}>
            <CardWithLabelV2 label={'同居家族'} text={familyMemberNamesStr ?? EMPTY} />
          </InformationRowWrapper> */}

        {useFaceAccess && (
          <InformationRowWrapper isHalf={!isMobile} isMobile={isMobile}>
            <FacePhotoAndTitleWrapper>
              <FacePhotoTitleWrapper>
                <FacePhotoTitle>顔認証用写真</FacePhotoTitle>
              </FacePhotoTitleWrapper>
              {facePhotoDownloadURL ? (
                <FacePhotoImage src={facePhotoDownloadURL} />
              ) : (
                <FacePhotoImageUndefined>顔写真未登録</FacePhotoImageUndefined>
              )}
            </FacePhotoAndTitleWrapper>
          </InformationRowWrapper>
        )}
      </InformationWrapper>
    </Card>
  );
});
AccountProfileCardView.displayName = 'AccountProfileCardView';
export default AccountProfileCardView;

const Card = styled.div<{ isColumn: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')};
  align-items: flex-start;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const ImageWrapper = styled.div`
  width: 120px;
  height: 120px;
  text-align: center;
  margin: 0 auto;
`;

const InformationWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ isMobile }) => (isMobile ? EMPTY : 'flex-grow: 1')};
  ${({ isMobile }) => (isMobile ? 'width:100%' : EMPTY)};
  align-items: flex-start;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  width: 100%;
`;

const InformationRowWrapper = styled.div<{ isHalf?: boolean; isMobile: boolean }>`
  display: flex;
  align-items: flex-start;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  width: ${({ isHalf }) => (isHalf ? 'calc(50% - ' + Math.floor((themeV2.mixins.v2.spacing * 3) / 2) + 'px)' : '100%')};
`;

const MembershipWrapper = styled.div`
  border-radius: 24px;
  background: var(--background-light-gray, #f0efed);
`;

const ImageItem = styled.div`
  width: 100%;
  min-height: 24px;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing / 2}px;
`;

const Image = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border: 1px solid ${themeV2.mixins.v2.color.border.gray};
  border-radius: 60px;
`;

const FacePhotoAndTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FacePhotoTitleWrapper = styled.div`
  width: 100%;
  min-height: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FacePhotoTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  font-family: 'Noto Sans JP';
  letter-spacing: 0.5px;
  color: #666666;
`;

const FacePhotoImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
`;

const FacePhotoImageUndefined = styled.div`
  width: 120px;
  height: 120px;
  line-height: 120px;
  border-radius: 8px;
  background-color: ${themeV2.mixins.v2.color.border.gray};
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Noto Sans JP';
  letter-spacing: 0.5px;
  color: #666666;
`;
