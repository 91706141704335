import {
  ButtonV2,
  CardWithLabelV2,
  DialogV2,
  InputWithLabelV2,
  LabelV2,
  RadioBoxV2,
  ScreenLoaderV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  BaseIdentification,
  Identification,
  IdentificationDefCode,
  SAVE_USER_IDENTIFICATION_FOR_ADMIN,
  SaveUserIdentificationForAdminRequest,
  SaveUserIdentificationForAdminResponse,
  User,
  UserIdentification,
  toFullName
} from '@atomica.co/irori';
import { Code, Label, NoStr, Option, Text } from '@atomica.co/types';
import { EMPTY, builder, hasLength, uuid } from '@atomica.co/utils';
import { format } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import mojaco from '../../../assets/mojaco/mojaco_break.png';
import { Labels } from '../../../models/common-model';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import useUser from '../../../redux/hooks/useUser';

interface P {
  isOpen: boolean;
  base: BaseDto;
  selectedUser: User;
  isEditable: boolean;
  onClose: () => void;
}

const RegisterUserIdentificationModal: React.FC<P> = React.memo(props => {
  const { isOpen, base, selectedUser, onClose, isEditable } = props;
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const { user: signInUser } = useUser();
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [identificationCode, setIdentificationCode] = useSafeState<Code>(unmountRef, EMPTY);
  const [otherDocument, setOtherDocument] = useSafeState<Text>(unmountRef, EMPTY);
  const [identificationNumber, setIdentificationNumber] = useSafeState<NoStr>(unmountRef, EMPTY);

  const otherDocumentLabel = useMemo<Label>(
    () => (identificationCode === IdentificationDefCode.OTHER ? '書類の名称' : '追加で確認した書類'),
    [identificationCode]
  );

  const prevUserIdentification = useMemo<UserIdentification | undefined>(() => {
    if (!hasLength(selectedUser.userIdentifications)) return;
    return selectedUser.userIdentifications!.find(
      identification => identification.baseIdentification?.base?.baseId === base.baseId
    );
  }, [base, selectedUser]);

  const baseIdentifications = useMemo<BaseIdentification[]>(() => base.baseIdentifications || [], [base]);

  const baseIdentificationOptions = useMemo<Option[]>(() => {
    const options = prevUserIdentification ? ([Identification.UNCONFIRMED] as string[]) : [];
    return options.concat(baseIdentifications?.map(id => id.identificationDef!.identificationDefCode));
  }, [baseIdentifications, prevUserIdentification]);

  const baseIdentificationLabels = useMemo<Labels>(() => {
    return baseIdentifications.reduce((prev, current) => {
      prev[current.identificationDef!.identificationDefCode] = current.baseIdentificationName;
      return prev;
    }, {});
  }, [baseIdentifications]);

  const initialize = useSafeCallback((): void => {
    if (prevUserIdentification) baseIdentificationLabels[Identification.UNCONFIRMED] = '未確認';
    setIdentificationCode(
      prevUserIdentification?.baseIdentification?.identificationDef?.identificationDefCode || EMPTY
    );
    setOtherDocument(prevUserIdentification?.otherUserIdentificationDocument || EMPTY);
    setIdentificationNumber(prevUserIdentification?.userIdentificationNo || EMPTY);
  }, [
    baseIdentificationLabels,
    prevUserIdentification,
    setOtherDocument,
    setIdentificationCode,
    setIdentificationNumber
  ]);

  useEffect(() => {
    if (!isOpen) return;
    initialize();
  }, [isOpen, initialize]);

  const saveUserIdentification = useSafeCallback(async (): Promise<void> => {
    if (!signInUser) return;
    setSaving(true);

    const selectedBaseIdentification = baseIdentifications.find(
      id => id.identificationDef?.identificationDefCode === identificationCode
    );

    const userIdentificationToSave = builder<UserIdentification>()
      .userIdentificationId(prevUserIdentification?.userIdentificationId || uuid())
      .otherUserIdentificationDocument(otherDocument)
      .userIdentificationNo(identificationNumber)
      .baseIdentification(selectedBaseIdentification || new BaseIdentification())
      .user(selectedUser)
      .createdUser(signInUser)
      .build();

    const request = builder<SaveUserIdentificationForAdminRequest>()
      .baseId(base.baseId)
      .userIdentification(userIdentificationToSave)
      .build();

    await commonRequest<SaveUserIdentificationForAdminRequest, SaveUserIdentificationForAdminResponse>(
      SAVE_USER_IDENTIFICATION_FOR_ADMIN,
      request
    );

    setSaving(false);
    onClose();
  }, [
    base,
    commonRequest,
    identificationCode,
    identificationNumber,
    initialize,
    onClose,
    otherDocument,
    selectedUser,
    setSaving,
    signInUser
  ]);

  const footerButtons = useMemo<JSX.Element[]>(() => {
    const buttons: JSX.Element[] = [];
    buttons.push(<ButtonV2 disabled={saving} key='cancel' label='キャンセル' onClick={onClose} />);
    if (isEditable)
      buttons.push(
        <ButtonV2 disabled={saving} key='save' type='primary' label='保存' onClick={saveUserIdentification} />
      );
    return buttons;
  }, [isEditable, saving, onClose, saveUserIdentification]);

  return (
    <DialogV2
      width={1120}
      isOpen={isOpen}
      headerLabel={`本人確認状況の${isEditable ? '変更' : '確認'}`}
      buttonsOnTheRight={footerButtons}
      onClose={onClose}
    >
      <Content>
        <CardWithLabelV2
          cardStyles={{ background: themeV2.mixins.v2.color.background.white }}
          label='ユーザー'
          src={selectedUser.photoURL || mojaco}
          text={toFullName(selectedUser)}
          subText={`${selectedUser.companyName && `${selectedUser.companyName}のメンバー`}${
            selectedUser.companyName && selectedUser.email && '・'
          }${selectedUser.email}`}
        />
        {!isEditable &&
          prevUserIdentification &&
          prevUserIdentification.createdUser &&
          prevUserIdentification.updatedAt && (
            <CardWithLabelV2
              cardStyles={{ background: themeV2.mixins.v2.color.background.white }}
              label='確認者'
              src={prevUserIdentification.createdUser.photoURL || mojaco}
              text={toFullName(prevUserIdentification.createdUser)}
              subText={`${format(prevUserIdentification.updatedAt, 'yyyy/MM/dd')} に確認済み`}
            />
          )}
        {isEditable && (
          <RadioWrapper>
            <LabelV2 text='確認した書類' />
            <RadioBoxV2
              options={baseIdentificationOptions}
              labels={baseIdentificationLabels}
              value={identificationCode}
              onChange={setIdentificationCode}
              gap={16}
            />
          </RadioWrapper>
        )}
        {!isEditable && (
          <CardWithLabelV2
            cardStyles={{ background: themeV2.mixins.v2.color.background.white }}
            label='確認した書類'
            text={baseIdentificationLabels[identificationCode]}
          />
        )}

        {(identificationCode === IdentificationDefCode.INSURANCE_CARD ||
          identificationCode === IdentificationDefCode.OTHER) &&
          (isEditable ? (
            <InputWithLabelV2 text={otherDocumentLabel} value={otherDocument} onChange={setOtherDocument} />
          ) : (
            <CardWithLabelV2
              cardStyles={{ background: themeV2.mixins.v2.color.background.white }}
              label={otherDocumentLabel}
              text={otherDocument}
            />
          ))}

        {!(
          identificationCode === IdentificationDefCode.MY_NUMBER_CARD ||
          identificationCode === Identification.UNCONFIRMED
        ) &&
          (isEditable ? (
            <InputWithLabelV2
              text='確認した書類の番号'
              value={identificationNumber}
              onChange={setIdentificationNumber}
            />
          ) : (
            <CardWithLabelV2
              cardStyles={{ background: themeV2.mixins.v2.color.background.white }}
              label='確認した書類の番号'
              text={identificationNumber}
            />
          ))}
      </Content>
      <ScreenLoaderV2 loading={saving} />
    </DialogV2>
  );
});

RegisterUserIdentificationModal.displayName = 'RegisterUserIdentificationModal';
export default RegisterUserIdentificationModal;

const Content = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  display: flex;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  background-color: ${themeV2.mixins.v2.color.background.offWhite};
  flex-direction: column;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing / 2}px;
`;
