import {
  Button,
  Component,
  DateBox,
  PageHeaderV2,
  RadioBox,
  theme,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  Access,
  BaseDto,
  ContractUsageV2,
  ContractV2,
  EventParticipant,
  FETCH_ACCESSES_BY_DATES_FOR_ADMIN,
  FETCH_CONTRACTS_BY_DATES_FOR_ADMIN,
  FETCH_CONTRACT_USAGES_BY_DATES_FOR_ADMIN,
  FETCH_SHOT_USAGES_BY_DATES_FOR_ADMIN,
  FETCH_SPACE_RESERVATIONS_BY_DATES_FOR_ADMIN,
  FETCH_USERS_BY_DATES_FOR_ADMIN,
  FetchAccessesByDatesForAdminRequest,
  FetchAccessesByDatesForAdminResponse,
  FetchContractUsagesByDatesForAdminRequest,
  FetchContractUsagesByDatesForAdminResponse,
  FetchContractsByDatesForAdminRequest,
  FetchContractsByDatesForAdminResponse,
  FetchShotUsagesByDatesForAdminRequest,
  FetchShotUsagesByDatesForAdminResponse,
  FetchSpaceReservationsByDatesForAdminRequest,
  FetchSpaceReservationsByDatesForAdminResponse,
  FetchUsersByDatesForAdminRequest,
  FetchUsersByDatesForAdminResponse,
  Shot,
  SpaceReservation,
  User
} from '@atomica.co/irori';
import { builder, hasLength, toBeginningOfDay, toEndOfDay } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import {
  UserCSV,
  convertToAccessesCSV,
  convertToContractUsagesCSV,
  convertToContractsCSV,
  convertToShotCSV,
  convertToSpaceReservationsCSV,
  convertToUserCSV
} from '../../converters/export-converter';
import { ExportTarget } from '../../enums/export-enum';
import { CSVTemplate } from '../../models/common-model';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import {
  ACCESS_HEADERS,
  CONTRACT_HEADERS,
  CONTRACT_USAGE_HEADERS,
  EXPORT_LABELS,
  SHOT_HEADERS,
  SPACE_RESERVATION_PAYMENTS_HEADERS,
  SPACE_RESERVATION_WITH_IS_CONTRACT_USER_HEADERS,
  USERS_HEADERS
} from '../../texts/export-text';

type EXPORT_TARGETS = SpaceReservation | Shot | ContractV2 | ContractUsageV2 | Access | EventParticipant | UserCSV;

interface P {
  base: BaseDto;
  user: User;
}

const ExportScreen: React.FC<P> = React.memo(props => {
  const { base } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const csvInstance = useRef<any>();

  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [disabledExportButton, setDisabledExportButton] = useSafeState<boolean>(unmountRef, true);
  const [exportTarget, setExportTarget] = useSafeState<ExportTarget>(unmountRef, ExportTarget.SHOT_USAGE);
  const [startDate, setStartDate] = useSafeState<Date | undefined>(unmountRef, toBeginningOfDay(new Date()));
  const [endDate, setEndDate] = useSafeState<Date | undefined>(unmountRef, toEndOfDay(new Date()));
  const [csvHeaders, setCsvHeaders] = useSafeState<CSVTemplate[]>(unmountRef, []);
  const [csvContent, setCsvContent] = useSafeState<
    Shot[] | ContractV2[] | ContractUsageV2[] | Access[] | EventParticipant[] | SpaceReservation[] | UserCSV[]
  >(unmountRef, []);

  useEffect(() => {
    setDisabledExportButton(!startDate || !endDate);
  }, [setDisabledExportButton, startDate, endDate]);

  const exportShotUsagesCSV = useSafeCallback(async (): Promise<void> => {
    const usageRequest = builder<FetchShotUsagesByDatesForAdminRequest>()
      .baseId(base.baseId)
      .start(startDate!)
      .end(endDate!)
      .build();
    const usageResponse = await commonRequest<
      FetchShotUsagesByDatesForAdminRequest,
      FetchShotUsagesByDatesForAdminResponse
    >(FETCH_SHOT_USAGES_BY_DATES_FOR_ADMIN, usageRequest);

    setCsvHeaders(SHOT_HEADERS);
    setCsvContent(convertToShotCSV(usageResponse.shots, base));
  }, [base, commonRequest, endDate, setCsvContent, setCsvHeaders, startDate]);

  const exportConstactsCSV = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchContractsByDatesForAdminRequest>()
      .baseId(base.baseId)
      .start(startDate!)
      .end(endDate!)
      .build();
    const response = await commonRequest<FetchContractsByDatesForAdminRequest, FetchContractsByDatesForAdminResponse>(
      FETCH_CONTRACTS_BY_DATES_FOR_ADMIN,
      request
    );
    setCsvHeaders(CONTRACT_HEADERS);
    setCsvContent(convertToContractsCSV(response.contracts, base));
  }, [base, commonRequest, endDate, setCsvContent, setCsvHeaders, startDate]);

  const exportConstactUsagesCSV = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchContractUsagesByDatesForAdminRequest>()
      .baseId(base.baseId)
      .start(startDate!)
      .end(endDate!)
      .build();
    const response = await commonRequest<
      FetchContractUsagesByDatesForAdminRequest,
      FetchContractUsagesByDatesForAdminResponse
    >(FETCH_CONTRACT_USAGES_BY_DATES_FOR_ADMIN, request);
    setCsvHeaders(CONTRACT_USAGE_HEADERS);
    setCsvContent(convertToContractUsagesCSV(response.usages, base));
  }, [base, commonRequest, endDate, setCsvContent, setCsvHeaders, startDate]);

  const exportAccessesCSV = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchAccessesByDatesForAdminRequest>()
      .baseId(base.baseId)
      .start(startDate!)
      .end(endDate!)
      .build();
    const response = await commonRequest<FetchAccessesByDatesForAdminRequest, FetchAccessesByDatesForAdminResponse>(
      FETCH_ACCESSES_BY_DATES_FOR_ADMIN,
      request
    );
    setCsvHeaders(ACCESS_HEADERS);
    setCsvContent(convertToAccessesCSV(response.accesses, base));
  }, [base, commonRequest, startDate, endDate, setCsvHeaders, setCsvContent]);

  const exportSpaceReservationsCSV = useSafeCallback(
    async (isPaymentIncluded: boolean): Promise<void> => {
      const request = builder<FetchSpaceReservationsByDatesForAdminRequest>()
        .baseId(base.baseId)
        .start(startDate!)
        .end(endDate!)
        .isPaymentIncluded(isPaymentIncluded)
        .build();

      const spaceReservationResponse = await commonRequest<
        FetchSpaceReservationsByDatesForAdminRequest,
        FetchSpaceReservationsByDatesForAdminResponse
      >(FETCH_SPACE_RESERVATIONS_BY_DATES_FOR_ADMIN, request);
      isPaymentIncluded
        ? setCsvHeaders(SPACE_RESERVATION_PAYMENTS_HEADERS)
        : setCsvHeaders(SPACE_RESERVATION_WITH_IS_CONTRACT_USER_HEADERS);
      const spaceReservations = spaceReservationResponse.spaceReservations;
      setCsvContent(convertToSpaceReservationsCSV(spaceReservations, isPaymentIncluded));
    },
    [base.baseId, commonRequest, endDate, setCsvContent, setCsvHeaders, startDate]
  );

  const exportUsersCSV = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchUsersByDatesForAdminRequest>()
      .baseId(base.baseId)
      .start(startDate!)
      .end(endDate!)
      .build();
    const response = await commonRequest<FetchUsersByDatesForAdminRequest, FetchUsersByDatesForAdminResponse>(
      FETCH_USERS_BY_DATES_FOR_ADMIN,
      request
    );
    setCsvHeaders(USERS_HEADERS);
    setCsvContent(convertToUserCSV(response.users, base));
  }, [base, commonRequest, endDate, setCsvContent, setCsvHeaders, startDate]);

  const exportCSV = useSafeCallback(async (): Promise<void> => {
    setDisabledExportButton(true);

    switch (exportTarget) {
      case ExportTarget.SHOT_USAGE:
        await exportShotUsagesCSV();
        break;

      case ExportTarget.CONTRACT:
        await exportConstactsCSV();
        break;

      case ExportTarget.CONTRACT_USAGE:
        await exportConstactUsagesCSV();
        break;

      case ExportTarget.ACCESS:
        await exportAccessesCSV();
        break;

      case ExportTarget.SPACE_RESERVATION:
        await exportSpaceReservationsCSV(false);
        break;

      case ExportTarget.USER:
        await exportUsersCSV();
        break;

      case ExportTarget.SPACE_RESERVATION_PAYMENT:
        await exportSpaceReservationsCSV(true);
        break;

      default:
        throw new Error(`${exportTarget} is out of target.`);
    }

    setDisabledExportButton(false);
  }, [
    setDisabledExportButton,
    exportTarget,
    exportShotUsagesCSV,
    exportConstactsCSV,
    exportConstactUsagesCSV,
    exportAccessesCSV,
    exportSpaceReservationsCSV,
    exportUsersCSV
  ]);

  useEffect(() => {
    const isReadyToExport = !!csvContent && !!csvInstance && !!csvInstance.current && !!csvInstance.current.link;
    if (!isReadyToExport) return;

    setTimeout(() => {
      csvInstance.current.link.click();
      setCsvContent([]);
    });
  }, [csvContent, setCsvContent]);

  const handleStartDateChanged = useSafeCallback(
    (date: Date) => {
      if (!date) return;
      setStartDate(toBeginningOfDay(date));
    },
    [setStartDate]
  );

  const handleEndDateChanged = useSafeCallback(
    (date: Date) => {
      if (!date) return;
      setEndDate(toEndOfDay(date)!);
    },
    [setEndDate]
  );

  return (
    <Component className='export-screen'>
      <Container>
        <Content>
          <PageHeaderWrapper>
            <PageHeaderV2 title='データ出力' />
          </PageHeaderWrapper>

          <RadioWrapper>
            <RadioBox
              title='対象'
              options={Object.values(ExportTarget)}
              labels={EXPORT_LABELS}
              value={exportTarget}
              onChange={setExportTarget}
            />
          </RadioWrapper>

          <DateWrapper>
            <PartialDateWrapper>
              <DateBox label='開始日' value={startDate ?? null} onChange={handleStartDateChanged} />
            </PartialDateWrapper>

            <PartialDateWrapper>
              <DateBox label='終了日' value={endDate ?? null} onChange={handleEndDateChanged} />
            </PartialDateWrapper>
          </DateWrapper>

          <ButtonWrapper>
            <Button type='primary' disabled={disabledExportButton} onClick={exportCSV}>
              <Label>CSV出力</Label>
            </Button>

            {hasLength<EXPORT_TARGETS>(csvContent) && (
              <CSVLink ref={csvInstance} headers={csvHeaders} data={csvContent} />
            )}
          </ButtonWrapper>
        </Content>
      </Container>
    </Component>
  );
});

ExportScreen.displayName = 'ExportScreen';
export default ExportScreen;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 768px;
  height: auto;
`;

const PageHeaderWrapper = styled.div`
  margin: ${themeV2.mixins.v2.spacing * 2}px;
`;

const RadioWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const DateWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const PartialDateWrapper = styled.div`
  width: calc(50% - ${theme.mixins.spacing / 2}px);
  height: auto;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;

const Label = styled(Typography)`
  width: 160px;
  height: auto;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
`;
