import {
  ButtonV2,
  CircularLoader,
  ColWidth,
  Header,
  MoreHorizMenu,
  SearchListV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  DELETE_EVENT_REGULAR_PARTICIPANTS_V2_FOR_ADMIN,
  DeleteEventRegularParticipantsV2ForAdminRequest,
  DeleteEventRegularParticipantsV2ForAdminResponse,
  EventRegularParticipantId,
  EventRegularParticipantV2,
  EventV2,
  SEARCH_EVENT_REGULAR_PARTICIPANTS_V2_FOR_ADMIN,
  SearchEventRegularParticipantsV2ForAdminRequest,
  SearchEventRegularParticipantsV2ForAdminResponse,
  User,
  toFullName
} from '@atomica.co/irori';
import { Count, Email, Id, Index, Name, Offset, Word } from '@atomica.co/types';
import { EMPTY, ONE, ZERO, builder, embedIdInPath, hasLength, isGreaterThanZero } from '@atomica.co/utils';
import AddIcon from '@material-ui/icons/Add';
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import DefaultUserIcon from '../../../assets/default_user_icon.png';
import { EventScheduleSearchOption } from '../../../enums/event-v2-enum';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import usePath from '../../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../../router/Routes';
import RegisterRegularParticipantModal from '../modal/RegisterRegularParticipantsModal';

const COLUMN_WIDTH: ColWidth = { icon: 56, name: 'auto', email: 'auto' };

const HEADER: Header = { icon: { label: EMPTY }, name: { label: '氏名' }, email: { label: 'メールアドレス' } };

interface RegularParticipantRow {
  id: Id;
  icon: JSX.Element;
  name: Name;
  email: Email;
}

interface P {
  base: BaseDto;
  event: EventV2;
}

const iconComponent = (user: User): JSX.Element => {
  return (
    <IconWrapper>
      <Icon src={user.photoURL || DefaultUserIcon} />
    </IconWrapper>
  );
};

const RegularParticipantList: React.FC<P> = React.memo(props => {
  const { base, event } = props;
  const { openPath } = usePath();
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, EMPTY);
  const [participants, setParticipants] = useSafeState<EventRegularParticipantV2[]>(unmountRef, []);
  const [rows, setRows] = useSafeState<RegularParticipantRow[]>(unmountRef, []);
  const [offset, setOffset] = useSafeState<Offset>(unmountRef, EventScheduleSearchOption.OFFSET);
  const [totalCount, setTotalCount] = useSafeState<Count>(unmountRef, ZERO);
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [selectedRowIndex, setselectedRowIndex] = useSafeState<Index>(unmountRef, ZERO);
  const [hasParticipants, setHasParticipants] = useSafeState<boolean>(unmountRef, false);

  const searchEventRegularParticipants = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);

    const participantRequest = builder<SearchEventRegularParticipantsV2ForAdminRequest>()
      .baseId(base.baseId)
      .eventIdV2(event.eventId)
      .offset(offset)
      .limit(EventScheduleSearchOption.LIMIT)
      .word(searchingWord)
      .build();

    const { eventRegularParticipantsV2, totalCount } = await commonRequest<
      SearchEventRegularParticipantsV2ForAdminRequest,
      SearchEventRegularParticipantsV2ForAdminResponse
    >(SEARCH_EVENT_REGULAR_PARTICIPANTS_V2_FOR_ADMIN, participantRequest);

    const rows = eventRegularParticipantsV2.map(participant =>
      builder<RegularParticipantRow>()
        .id(participant.eventRegularParticipantId)
        .icon(iconComponent(participant.user!))
        .name(toFullName(participant.user))
        .email(participant.user!.email)
        .build()
    );

    if (!hasLength(rows) && isGreaterThanZero(offset)) setOffset(offset - ONE);
    if (hasLength(rows)) setHasParticipants(true);
    setParticipants(eventRegularParticipantsV2);
    setRows(rows);
    setTotalCount(totalCount);
    setIsLoaderShown(false);
  }, [
    base,
    commonRequest,
    event,
    offset,
    searchingWord,
    setHasParticipants,
    setOffset,
    setParticipants,
    setRows,
    setTotalCount,
    setIsLoaderShown
  ]);

  useEffect(() => {
    searchEventRegularParticipants();
  }, [searchEventRegularParticipants]);

  const handleSearchingWordChanged = useSafeCallback(
    (word: Word): void => {
      setSearchingWord(word);
      setOffset(ZERO);
    },
    [setSearchingWord, setOffset]
  );

  const handleModalClosed = useSafeCallback(
    async (isUpdated: boolean): Promise<void> => {
      setIsModalOpen(false);
      if (isUpdated) await searchEventRegularParticipants();
    },
    [setIsModalOpen, searchEventRegularParticipants]
  );

  const deleteParticipant = useSafeCallback(
    async (eventRegularParticipantId: EventRegularParticipantId): Promise<void> => {
      setIsLoaderShown(true);
      setRows([]);

      const request = builder<DeleteEventRegularParticipantsV2ForAdminRequest>()
        .baseId(base.baseId)
        .eventRegularParticipantIds([eventRegularParticipantId])
        .build();

      await commonRequest<
        DeleteEventRegularParticipantsV2ForAdminRequest,
        DeleteEventRegularParticipantsV2ForAdminResponse
      >(DELETE_EVENT_REGULAR_PARTICIPANTS_V2_FOR_ADMIN, request);

      await searchEventRegularParticipants();
    },
    [searchEventRegularParticipants, setIsLoaderShown, setRows]
  );

  const openUserOverviewScreen = useSafeCallback(
    (index: Index): void => {
      const user = participants[index].user;
      openPath(embedIdInPath(Path.USER_OVERVIEW, PATH_IDS, [base.baseCode, user!.userId]));
    },
    [base, openPath, participants]
  );

  const moreHorizMenusForSearchList = useMemo<MoreHorizMenu[]>(
    () => [
      {
        color: themeV2.mixins.v2.color.font.red,
        label: '参加者を削除',
        onClick: async () => await deleteParticipant(rows[selectedRowIndex].id)
      }
    ],
    [rows, selectedRowIndex, deleteParticipant]
  );

  return (
    <Container>
      <Message>定期参加者は、このイベントのスケジュールに対して毎回自動的に「参加者」として登録されます。</Message>

      {!hasParticipants && (
        <NoParticipantCard>
          {isLoaderShown ? (
            <CircularLoader />
          ) : (
            <>
              <StyledPersonOutlineRoundedIcon />
              <NoParticipantMessage>定期参加者は登録されていません。</NoParticipantMessage>
              <ButtonV2
                type='primary'
                startIcon={<AddIcon />}
                label='定期参加者を登録'
                onClick={() => setIsModalOpen(true)}
              />
            </>
          )}
        </NoParticipantCard>
      )}

      {hasParticipants && (
        <SearchListV2
          name='定期参加者'
          title={`${totalCount}件の定期参加者`}
          colWidth={COLUMN_WIDTH}
          header={HEADER}
          rows={rows}
          offset={offset}
          limit={EventScheduleSearchOption.LIMIT}
          totalCount={totalCount}
          placeholder='参加者の氏名・メールアドレス'
          searchingWord={searchingWord}
          isLoaderShown={isLoaderShown}
          moreHorizMenusForTable={moreHorizMenusForSearchList}
          headerRightComponent={<ButtonV2 size='large' label='追加' onClick={() => setIsModalOpen(true)} />}
          onChange={handleSearchingWordChanged}
          onClickRow={index => openUserOverviewScreen(index)}
          onClickMoreHoriz={setselectedRowIndex}
          setOffset={setOffset}
        />
      )}

      <RegisterRegularParticipantModal base={base} event={event} isOpen={isModalOpen} onClose={handleModalClosed} />
    </Container>
  );
});

RegularParticipantList.displayName = 'RegularParticipantList';
export default RegularParticipantList;

const Container = styled.div`
  width: 100%;
`;

const Message = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  margin-bottom: ${themeV2.mixins.v2.spacing * 2}px;
`;

const NoParticipantCard = styled.div`
  width: auto;
  height: 188px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  gap: 16px;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 12px;
`;

const StyledPersonOutlineRoundedIcon = styled(PersonOutlineRoundedIcon)`
  width: 48px;
  height: 48px;
  color: ${themeV2.mixins.v2.color.font.lightGray};
`;

const NoParticipantMessage = styled.div`
  ${themeV2.mixins.v2.typography.title.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
`;
