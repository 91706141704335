import {
  ButtonV2,
  ColWidth,
  Component,
  Header,
  PAGINATE_HEIGHT,
  PageHeaderV2,
  PullDownOption,
  PullDownV2,
  SearchListV2,
  StatusV2,
  styleForFullExpansion,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  QuestionnaireId,
  QuestionnaireSortColumn,
  QuestionnaireStatus,
  QuestionnaireStatusForSearching,
  SEARCH_QUESTIONNAIRE_FOR_ADMIN,
  SearchQuestionnaireForAdminRequest,
  SearchQuestionnaireForAdminResponse
} from '@atomica.co/irori';
import { Count, DateStr, Index, Name, Offset, Sort, Word } from '@atomica.co/types';
import { EMPTY, builder, embedIdInPath } from '@atomica.co/utils';
import AddIcon from '@material-ui/icons/Add';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { SearchOption } from '../../enums/event-v2-enum';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../router/Routes';
import { QUESTIONNAIRE_STATUS_LABEL } from '../../texts/questionnaire-text';
import { getQuestionnaireStatusLabel, toStatus } from '../../utils/questionnaire-util';

const LIMIT = 50;

const COLUMN_WIDTH: ColWidth = { status: 120, name: 'auto', answerDueAt: 120, updatedAt: 120, releasedAt: 120 };

const HEADER: Header = {
  status: { label: 'ステータス' },
  name: { label: 'アンケート名' },
  answerDueAt: { label: '回答期限' },
  updatedAt: { label: '最終更新日' },
  releasedAt: { label: '公開日' }
};

const SORT_COLUMNS = {
  updatedAt: QuestionnaireSortColumn.UPDATED_AT,
  releasedAt: QuestionnaireSortColumn.RELEASED_AT,
  answerDueAt: QuestionnaireSortColumn.ANSWER_DUE_AT
};

interface QuestionnaireRow {
  id: QuestionnaireId;
  status: JSX.Element;
  name: Name;
  answerDueAt: DateStr | JSX.Element;
  updatedAt: DateStr | JSX.Element;
  releasedAt: DateStr | JSX.Element;
}

interface P {
  base: BaseDto;
}

const pullDownOptions = Object.entries(QUESTIONNAIRE_STATUS_LABEL).map(([key, value]) =>
  builder<PullDownOption>().id(key).label(value).build()
);

const toDateColumn = (date: Date | undefined): DateStr | JSX.Element => {
  return date ? format(date, 'yyyy/MM/dd') : <UndecidedSpan>-</UndecidedSpan>;
};

const getPath = (status: QuestionnaireStatus): Path => {
  switch (status) {
    case QuestionnaireStatus.DRAFT:
      return Path.EDIT_QUESTIONNAIRE;
    case QuestionnaireStatus.RELEASED:
      return Path.QUESTIONNAIRE_DETAILS;
    default:
      return Path.QUESTIONNAIRE_DETAILS;
  }
};

const QuestionnaireListScreen: React.FC<P> = React.memo(props => {
  const { base } = props;

  const { openPathInNewTab, openBasePath } = usePath();
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, EMPTY);
  const [rows, setRows] = useSafeState<QuestionnaireRow[]>(unmountRef, []);
  const [offset, setOffset] = useSafeState<Offset>(unmountRef, SearchOption.OFFSET);
  const [totalCount, setTotalCount] = useSafeState<Count>(unmountRef, 0);
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [selectedStatus, setSelectedStatus] = useSafeState<QuestionnaireStatusForSearching>(
    unmountRef,
    QuestionnaireStatusForSearching.ALL
  );
  const [sortKey, setSortKey] = useSafeState<QuestionnaireSortColumn>(unmountRef, QuestionnaireSortColumn.UPDATED_AT);
  const [sort, setSort] = useSafeState<Sort>(unmountRef, Sort.DESC);

  const [statuses, setStatuses] = useSafeState<QuestionnaireStatus[]>(unmountRef, []);

  const handleSortColClicked = useSafeCallback(
    (sortKey: QuestionnaireSortColumn, sort: Sort): void => {
      setSortKey(sortKey);
      setSort(sort);
    },
    [setSortKey, setSort, rows]
  );

  const initialize = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const request = builder<SearchQuestionnaireForAdminRequest>()
      .baseId(base.baseId)
      .limit(LIMIT)
      .offset(offset)
      .word(searchingWord)
      .sortCol(sortKey)
      .sort(sort)
      .status(selectedStatus)
      .build();

    const { questionnaires, totalCount } = await commonRequest<
      SearchQuestionnaireForAdminRequest,
      SearchQuestionnaireForAdminResponse
    >(SEARCH_QUESTIONNAIRE_FOR_ADMIN, request);
    const statuses = questionnaires.map(questionnaire => questionnaire.status);
    const values = questionnaires.map(questionnaire =>
      builder<QuestionnaireRow>()
        .id(questionnaire.questionnaireId)
        .status(
          <StatusV2 width={74} status={toStatus(questionnaire)} label={getQuestionnaireStatusLabel(questionnaire)} />
        )
        .name(questionnaire.name)
        .answerDueAt(toDateColumn(questionnaire.answerDueAt))
        .updatedAt(toDateColumn(questionnaire.updatedAt))
        .releasedAt(toDateColumn(questionnaire.releasedAt))
        .build()
    );

    setStatuses(statuses);
    setRows(values);
    setTotalCount(totalCount);
    setIsLoaderShown(false);
  }, [
    base,
    searchingWord,
    offset,
    selectedStatus,
    setTotalCount,
    setRows,
    setIsLoaderShown,
    setStatuses,
    sortKey,
    sort
  ]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleSearchingWordChanged = useSafeCallback(
    (word: Word): void => {
      setSearchingWord(word);
      setOffset(0);
    },
    [setSearchingWord, setOffset]
  );

  const openQuestionnaireDetailsScreen = useSafeCallback(
    (index: Index): void => {
      const selectedQuestionnaire = rows[index];
      const status = statuses[index];
      const path = getPath(status);
      openPathInNewTab(embedIdInPath(path, PATH_IDS, [base.baseCode, selectedQuestionnaire.id]));
    },
    [base, openPathInNewTab, rows, statuses]
  );

  const openRegisterQuestionnaireScreen = useSafeCallback((): void => {
    openBasePath(Path.REGISTER_QUESTIONNAIRE);
  }, [openBasePath]);

  return (
    <Component style={styleForFullExpansion} className='questionnaire-list-screen'>
      <Container>
        <Content>
          <PageHeaderV2
            title='アンケート'
            rightComponent={
              <ButtonV2
                type='primary'
                startIcon={<AddIcon />}
                label='新規作成'
                onClick={openRegisterQuestionnaireScreen}
              />
            }
          />

          <SearchListV2
            title={`${totalCount}件のアンケート`}
            name='アンケート'
            colWidth={COLUMN_WIDTH}
            header={HEADER}
            rows={rows}
            sortKey={sortKey}
            sortCol={SORT_COLUMNS}
            offset={offset}
            limit={LIMIT}
            totalCount={totalCount}
            placeholder='アンケート名で検索'
            searchingWord={searchingWord}
            isLoaderShown={isLoaderShown}
            onChange={handleSearchingWordChanged}
            onClickRow={openQuestionnaireDetailsScreen}
            onClickSortCol={handleSortColClicked}
            setOffset={setOffset}
            headerBottomComponent={
              <PullDownWrapper>
                <PullDownV2
                  id={selectedStatus}
                  placeholder='選択'
                  options={pullDownOptions}
                  onClick={setSelectedStatus}
                />
              </PullDownWrapper>
            }
          />
        </Content>
      </Container>
    </Component>
  );
});

QuestionnaireListScreen.displayName = 'QuestionnaireListScreen';
export default QuestionnaireListScreen;

const Container = styled.div`
  width: 100%;
  height: calc(100% - ${PAGINATE_HEIGHT}px);
  display: flex;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const UndecidedSpan = styled.span`
  color: ${themeV2.mixins.v2.color.font.lightGray};
`;

const PullDownWrapper = styled.div`
  width: 179px;
  margin: ${themeV2.mixins.v2.spacing}px 0px ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 3}px;
`;
