import {
  CheckBoxV2,
  FormGroupSelect,
  InputWithLabelV2,
  LabelV2,
  RadioBoxV2,
  themeV2,
  useSafeCallback
} from '@atomica.co/components';
import {
  ContractV2,
  InvoiceDeliveryMethod,
  PaymentMethod,
  PaymentOption,
  SpacePaymentFrequency
} from '@atomica.co/irori';
import { Address, City, Count, Post } from '@atomica.co/types';
import { PREFECTURE_NAMES, Prefecture } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  INVOICE_DELIVERY_METHOD_LABELS,
  PAYMENT_METHOD_LABELS,
  SPACE_PAYMENT_FREQUENCY_LABELS
} from '../../../texts/contract-v2-text';

export interface PaymentInputValues {
  billingPost: Post;
  billingPrefecture: Prefecture | undefined;
  billingCity: City;
  billingAddress: Address;
  paymentMethod: PaymentMethod;
  isSameBillingAddressAndContractorAddress: boolean;
  paymentOption: PaymentOption;
  paymentCount: Count;
  bankAccount: string;
  spacePaymentFrequency: SpacePaymentFrequency;
  invoiceDeliveryMethod: InvoiceDeliveryMethod;
}

export interface PaymentInputOnChanges {
  setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>;
  setPaymentOption: React.Dispatch<React.SetStateAction<PaymentOption>>;
  setIsSameBillingAddressAndContractorAddress: React.Dispatch<React.SetStateAction<boolean>>;
  setPaymentCount: React.Dispatch<React.SetStateAction<Count>>;
  setBankAccount: React.Dispatch<React.SetStateAction<string>>;
  setBillingPost: React.Dispatch<React.SetStateAction<Post>>;
  setBillingPrefecture: React.Dispatch<React.SetStateAction<Prefecture | undefined>>;
  setBillingCity: React.Dispatch<React.SetStateAction<City>>;
  setBillingAddress: React.Dispatch<React.SetStateAction<Address>>;
  setSpacePaymentFrequency: React.Dispatch<React.SetStateAction<SpacePaymentFrequency>>;
  setInvoiceDeliveryMethod: React.Dispatch<React.SetStateAction<InvoiceDeliveryMethod>>;
}

interface P {
  contract: ContractV2 | undefined;
  contractorPost: Post;
  contractorPrefecture: Prefecture | undefined;
  contractorCity: City;
  contractorAddress: Address;
  values: PaymentInputValues;
  onChanges: PaymentInputOnChanges;
}

const PaymentInputV2: React.FC<P> = React.memo(props => {
  const { contractorPost, contractorPrefecture, contractorCity, contractorAddress, values, onChanges } = props;
  const {
    billingPost,
    billingPrefecture,
    billingCity,
    billingAddress,
    paymentMethod,
    isSameBillingAddressAndContractorAddress,
    invoiceDeliveryMethod,
    spacePaymentFrequency
  } = values;
  const {
    setPaymentMethod,
    setIsSameBillingAddressAndContractorAddress,
    setBillingPost,
    setBillingPrefecture,
    setBillingCity,
    setBillingAddress,
    setInvoiceDeliveryMethod,
    setSpacePaymentFrequency
  } = onChanges;

  const handleCheckboxChanged = useSafeCallback(() => {
    setIsSameBillingAddressAndContractorAddress(current => {
      const nextChecked = !current;
      if (nextChecked) {
        setBillingPost(contractorPost);
        setBillingPrefecture(contractorPrefecture);
        setBillingCity(contractorCity);
        setBillingAddress(contractorAddress);
      }
      return nextChecked;
    });
  }, [
    contractorAddress,
    contractorCity,
    contractorPost,
    contractorPrefecture,
    setBillingAddress,
    setBillingCity,
    setBillingPost,
    setBillingPrefecture,
    setIsSameBillingAddressAndContractorAddress
  ]);

  const RenderInvoiceDeliveryMethod = useSafeCallback(
    (props: { options: InvoiceDeliveryMethod[] }): JSX.Element => {
      return (
        <>
          <LabelV2 required text='請求書送付方法' />
          <RadioWrapper>
            <RadioBoxV2
              options={props.options}
              labels={INVOICE_DELIVERY_METHOD_LABELS}
              onChange={setInvoiceDeliveryMethod}
              value={invoiceDeliveryMethod}
              gap={40}
            />
          </RadioWrapper>
        </>
      );
    },
    [setInvoiceDeliveryMethod, invoiceDeliveryMethod]
  );

  const billingAddressInputs = useMemo((): JSX.Element => {
    return (
      <CustomRow gap={16}>
        <LabelV2 text='請求先住所' />
        <CheckBoxV2 checked={isSameBillingAddressAndContractorAddress} onChange={handleCheckboxChanged}>
          契約者住所と同じ
        </CheckBoxV2>
        <Row>
          <Cell>
            <InputWithLabelV2
              required
              text='郵便番号'
              value={billingPost}
              onChange={setBillingPost}
              disabled={isSameBillingAddressAndContractorAddress}
              maxLength={8}
            />
          </Cell>
          <Cell />
        </Row>
        <Row>
          <Cell>
            <FormGroupSelect
              required
              placeholder='都道府県を選択'
              title='都道府県'
              options={Object.values(Prefecture)}
              labels={PREFECTURE_NAMES}
              value={billingPrefecture}
              onChange={setBillingPrefecture}
              disabled={isSameBillingAddressAndContractorAddress}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <InputWithLabelV2
              required
              text='市区町村'
              value={billingCity}
              onChange={setBillingCity}
              disabled={isSameBillingAddressAndContractorAddress}
              maxLength={255}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <InputWithLabelV2
              required
              text='番地・建物名等'
              value={billingAddress}
              onChange={setBillingAddress}
              disabled={isSameBillingAddressAndContractorAddress}
              maxLength={255}
            />
          </Cell>
        </Row>
      </CustomRow>
    );
  }, [
    isSameBillingAddressAndContractorAddress,
    handleCheckboxChanged,
    billingPost,
    setBillingPost,
    billingPrefecture,
    setBillingPrefecture,
    billingCity,
    setBillingCity,
    billingAddress,
    setBillingAddress
  ]);

  const getInitInvoiceDeliveryMethod = useSafeCallback((paymentMethod: PaymentMethod): InvoiceDeliveryMethod => {
    switch (paymentMethod) {
      case PaymentMethod.CREDIT_CARD:
      case PaymentMethod.CASH:
      case PaymentMethod.BANK_TRANSFER:
        return InvoiceDeliveryMethod.NONE;
      case PaymentMethod.INVOICE:
        return InvoiceDeliveryMethod.EMAIL;
      default:
        throw new Error(`${paymentMethod} is out of target.`);
    }
  }, []);

  const handlePaymentMethodChanged = useSafeCallback(
    (paymentMethod: PaymentMethod): void => {
      setPaymentMethod(paymentMethod);
      setInvoiceDeliveryMethod(getInitInvoiceDeliveryMethod(paymentMethod));
    },
    [setPaymentMethod, setInvoiceDeliveryMethod, getInitInvoiceDeliveryMethod]
  );

  const allPaymentMethods = [
    PaymentMethod.CREDIT_CARD,
    PaymentMethod.BANK_TRANSFER,
    PaymentMethod.INVOICE,
    PaymentMethod.CASH
  ];

  return (
    <PaymentContent data-testid="payment-method">
      <ContentTitle>支払い方法</ContentTitle>
      <Rows>
        <Row direction='column'>
          <LabelV2 required text='支払い方法' />
          <RadioWrapper>
            <RadioBoxV2
              options={allPaymentMethods}
              labels={PAYMENT_METHOD_LABELS}
              onChange={handlePaymentMethodChanged}
              value={paymentMethod}
              gap={40}
            />
          </RadioWrapper>
          {paymentMethod === PaymentMethod.CREDIT_CARD && (
            <PaymentMethodText>
              契約の作成後、契約先担当者のメールアドレス宛にクレジットカード登録用のURLが送付されます。
            </PaymentMethodText>
          )}
        </Row>
        {paymentMethod === PaymentMethod.CREDIT_CARD && (
          <Row direction='column'>
            <LabelV2 required text='会議室利用料金' />
            <RadioWrapper>
              <RadioBoxV2
                options={[SpacePaymentFrequency.MONTHLY_PAYMENT]}
                labels={SPACE_PAYMENT_FREQUENCY_LABELS}
                onChange={setSpacePaymentFrequency}
                value={spacePaymentFrequency}
                gap={40}
              />
            </RadioWrapper>
          </Row>
        )}
        {paymentMethod === PaymentMethod.BANK_TRANSFER && (
          <Row direction='column'>
            <RenderInvoiceDeliveryMethod
              options={[InvoiceDeliveryMethod.NONE, InvoiceDeliveryMethod.EMAIL, InvoiceDeliveryMethod.POST]}
            />
            {billingAddressInputs}
          </Row>
        )}
        {paymentMethod === PaymentMethod.INVOICE && (
          <>
            <Row direction='column'>
              <RenderInvoiceDeliveryMethod options={[InvoiceDeliveryMethod.EMAIL, InvoiceDeliveryMethod.POST]} />
            </Row>
            {billingAddressInputs}
          </>
        )}
      </Rows>
    </PaymentContent>
  );
});

PaymentInputV2.displayName = 'PlanInputV2';
export default PaymentInputV2;

const Content = styled.div`
  background-color: ${themeV2.mixins.v2.color.background.white};
  border-radius: 6px;
  padding: ${`${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px`};
`;
const Rows = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 3}px 0;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  width: 100%;
`;
const Row = styled.div<{ direction?: 'row' | 'column'; gap?: number }>`
  display: flex;
  min-height: 24px;
  width: 100%;
  flex-direction: ${({ direction = 'row' }) => direction};
  gap: ${({ direction, gap = direction === 'row' ? themeV2.mixins.v2.spacing * 3 : themeV2.mixins.v2.spacing / 2 }) =>
    gap}px;
`;
const Cell = styled.div`
  width: 100%;
  display: flex;
`;

const PaymentMethodText = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
`;

const PaymentContent = styled(Content)``;

const ContentTitle = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.black};
  padding: ${themeV2.mixins.v2.spacing}px 0;
  width: 100%;
`;

const RadioWrapper = styled.div`
  margin-left: ${themeV2.mixins.v2.spacing * 2}px;
`;

const CustomRow = styled.div<{ gap?: number }>`
  width: 100%;
  min-height: 24px;
  display: flex;
  flex-direction: column;
  gap: ${({ gap = themeV2.mixins.v2.spacing / 2 }) => gap}px;
`;
