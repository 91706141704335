import {
  BackButtonV2,
  ButtonV2,
  Component,
  PageHeaderV2,
  ScreenLoaderV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  FETCH_WISH_FOR_ADMIN,
  FetchWishForAdminRequest,
  FetchWishForAdminResponse,
  LogLevel,
  RECORD_LOGS,
  RecordLogsRequest,
  RecordLogsResponse,
  SAVE_USER_WISH_TAGS_FOR_ADMIN,
  SAVE_WISH_FOR_ADMIN,
  SAVE_WISH_USER_FOR_ADMIN,
  SaveUserWishTagsForAdminRequest,
  SaveUserWishTagsForAdminResponse,
  SaveWishForAdminRequest,
  SaveWishForAdminResponse,
  SaveWishUserForAdminRequest,
  SaveWishUserForAdminResponse,
  User,
  UserWishTag,
  WISH_ID,
  Wish,
  WishId,
  WishScope,
  WishStatus,
  WishTag,
  WishType
} from '@atomica.co/irori';
import { Name, Text } from '@atomica.co/types';
import { EMPTY, builder, embedIdInPath, isEmpty, stringify, uuid } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useSnackbarV2 } from '../../provider/SnackbarProviderV2';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { Path } from '../../router/Routes';
import { FAILED_TO_REGISTER_WISH } from '../../texts/wish-text';
import RegisterWishUserModal from '../user-v2/modal/RegisterWishUserModal';
import WishInput from './input/WishInput';
import WishScopeModal from './modal/WishScopeModal';

const WISH_DETAILS_CONTENT_MAX_WIDTH = 1280;
const WISH_DETAILS_CONTENT_MIN_WIDTH = 756;

interface P {
  base: BaseDto;
  user: User;
}

const CreateWishScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { path, params } = usePath();
  const { commonRequest } = useCommonRequest();
  const { openBasePath } = usePath();
  const { openSnackbar } = useSnackbarV2();

  const unmountRef = useUnmountRef();

  const wishId = useMemo<WishId>(() => (path === Path.EDIT_WISH ? params[WISH_ID] : uuid()), [path, params]);
  const isEdit = useMemo<boolean>(() => path === Path.EDIT_WISH, [path]);

  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, path === Path.REGISTER_WISH);
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [isUserModalOpen, setIsUserModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [userModalInitName, setUserModalInitName] = useSafeState<Name>(unmountRef, EMPTY);
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [userSaving, setUserSaving] = useSafeState<boolean>(unmountRef, false);
  const [wish, setWish] = useSafeState<Wish | undefined>(unmountRef);
  const [fromUser, setFromUser] = useSafeState<User | null>(unmountRef, null);
  const [toUser, setToUser] = useSafeState<User | null>(unmountRef, user);
  const [content, setContent] = useSafeState<Text>(unmountRef, EMPTY);
  const [remarks, setRemarks] = useSafeState<Text>(unmountRef, EMPTY);
  const [wishTagsToSave, setWishTagsToSave] = useSafeState<WishTag[]>(unmountRef, []);
  const [status, setStatus] = useSafeState<WishStatus>(unmountRef, WishStatus.TODO);
  const [scope, setScope] = useSafeState<WishScope>(unmountRef, WishScope.PUBLIC);
  const [linkSlack] = useSafeState<boolean>(unmountRef, true);
  const [type, setType] = useSafeState<WishType>(unmountRef, WishType.FAVOR);

  const isDisabledSaveButton = useMemo<boolean>(
    () => isEmpty(content) || isEmpty(remarks) || !fromUser || !toUser,
    [content, remarks, fromUser, toUser]
  );

  const cancelHandler = useSafeCallback((): void => {
    if (!wish) {
      openBasePath(Path.WISH_LIST);
      return;
    }

    openBasePath(embedIdInPath(Path.WISH_DETAILS, [WISH_ID], [wish.wishId]));
  }, [wish, openBasePath]);

  const saveNewWish = useSafeCallback(async (): Promise<void> => {
    if (!fromUser || !toUser) return;

    setSaving(true);
    const wishToSave = builder<Wish>()
      .wishId(wishId)
      .type(type)
      .status(status)
      .linkSlack(linkSlack)
      .scope(scope)
      .content(content)
      .remarks(remarks)
      .base(base)
      .toUser(toUser)
      .fromUser(fromUser);

    const userWishTagToSave = wishTagsToSave.map(wishTag => {
      const userWishTag = builder<UserWishTag>().userWishTagId(uuid()).wish(wishToSave.build()).wishTag(wishTag);
      if (fromUser) userWishTag.user(fromUser);
      return userWishTag.build();
    });

    const wishRequest = builder<SaveWishForAdminRequest>().baseId(base.baseId).wish(wishToSave.build()).build();

    const userWishTagsRequest = builder<SaveUserWishTagsForAdminRequest>()
      .baseId(base.baseId)
      .userWishTags(userWishTagToSave)
      .userWishTagsToDelete(wish?.userWishTags ?? [])
      .build();

    try {
      const response = await commonRequest<SaveWishForAdminRequest, SaveWishForAdminResponse>(
        SAVE_WISH_FOR_ADMIN,
        wishRequest
      );
      await commonRequest<SaveUserWishTagsForAdminRequest, SaveUserWishTagsForAdminResponse>(
        SAVE_USER_WISH_TAGS_FOR_ADMIN,
        userWishTagsRequest
      );

      openBasePath(embedIdInPath(Path.WISH_DETAILS, [WISH_ID], [response.wishId!]));
    } catch (e) {
      openSnackbar(FAILED_TO_REGISTER_WISH, 'error', 5000);

      commonRequest<RecordLogsRequest, RecordLogsResponse>(
        RECORD_LOGS,
        builder<RecordLogsRequest>()
          .level(LogLevel.ERROR)
          .jsonToRecord(stringify(e as Error))
          .build()
      );
    }

    setSaving(false);
  }, [
    base,
    commonRequest,
    wishId,
    type,
    status,
    scope,
    content,
    remarks,
    linkSlack,
    base,
    fromUser,
    toUser,
    wish,
    wishTagsToSave,
    setSaving,
    openSnackbar
  ]);

  const initWish = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchWishForAdminRequest>().baseId(base.baseId).wishId(wishId).build();
    const { wish } = await commonRequest<FetchWishForAdminRequest, FetchWishForAdminResponse>(
      FETCH_WISH_FOR_ADMIN,
      request
    );
    setWish(wish);
    if (wish) setStatus(wish.status);
    setLoaded(true);
  }, [base.baseId, setWish, wishId, setLoaded]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    await initWish();
  }, [initWish]);

  const openNewUserModal = useSafeCallback(
    (name: Name): void => {
      setUserModalInitName(name);
      setIsUserModalOpen(true);
    },
    [setUserModalInitName, setIsUserModalOpen]
  );

  const saveNewUser = useSafeCallback(
    async (newUser: User): Promise<void> => {
      setUserSaving(true);
      setIsUserModalOpen(false);
      const request = builder<SaveWishUserForAdminRequest>().baseId(base.baseId).user(newUser).build();

      const response = await commonRequest<SaveWishUserForAdminRequest, SaveWishUserForAdminResponse>(
        SAVE_WISH_USER_FOR_ADMIN,
        request
      );
      setUserSaving(false);
      if (response.user) setFromUser(response.user);
    },
    [setIsUserModalOpen, setFromUser]
  );

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Component style={{ width: '100%', height: '100%' }} loading={!loaded} className='create-wish-screen'>
      <Container>
        <BackButtonV2 label='戻る' onClick={cancelHandler} />
        <PageHeaderWrapper>
          <PageHeaderV2 title={`Wishの${isEdit ? '編集' : '新規作成'}`} />
        </PageHeaderWrapper>
        <WishInputWrapper>
          <WishInput
            base={base}
            initWish={wish}
            fromUser={fromUser}
            toUser={toUser}
            content={content}
            remarks={remarks}
            wishTags={wishTagsToSave}
            status={status}
            scope={scope}
            type={type}
            setFromUser={setFromUser}
            setToUser={setToUser}
            setContent={setContent}
            setRemarks={setRemarks}
            setWishTags={setWishTagsToSave}
            setStatus={setStatus}
            setScope={setScope}
            setType={setType}
            addNewUserAction={openNewUserModal}
          />
        </WishInputWrapper>
        <WishScopeModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        <RegisterWishUserModal
          isOpen={isUserModalOpen}
          initName={userModalInitName}
          onClose={() => setIsUserModalOpen(false)}
          saveNewUser={saveNewUser}
        />
        <Footer>
          <ButtonV2 type='secondary' disabled={saving} label='キャンセル' onClick={cancelHandler} />
          <ButtonV2
            type='primary'
            disabled={saving || isDisabledSaveButton}
            label={isEdit ? '保存' : '作成'}
            onClick={saveNewWish}
          />
        </Footer>
        <ScreenLoaderV2 loading={saving || userSaving} />
      </Container>
    </Component>
  );
});

CreateWishScreen.displayName = 'CreateWishScreen';
export default CreateWishScreen;

const Container = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  width: 100%;
  margin: 0 auto ${themeV2.mixins.v2.spacing * 5}px;
  max-width: ${WISH_DETAILS_CONTENT_MAX_WIDTH}px;
  min-width: ${WISH_DETAILS_CONTENT_MIN_WIDTH}px;
`;

const PageHeaderWrapper = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 2}px 0;
`;

const WishInputWrapper = styled.div`
  margin-bottom: 55px;
`;

const Footer = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
  background-color: ${themeV2.mixins.v2.color.font.white};
  box-shadow:
    0px -4px 8px 3px rgba(0, 0, 0, 0.15),
    0px -1px 3px rgba(0, 0, 0, 0.3);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`;
