import {
  BackButtonV2,
  ButtonV2,
  Component,
  PageHeaderV2,
  ScreenLoaderV2,
  styleForFullExpansion,
  themeV2,
  themeV3,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { AuthorityDefCategory, AuthorityDefCodeEnum, BaseDto, Gender, User } from '@atomica.co/irori';
import { City, Code, URL } from '@atomica.co/types';
import { builder, EMPTY, FIRST_INDEX, hasLength, Prefecture, uuid } from '@atomica.co/utils';
import { DeleteOutlineRounded } from '@material-ui/icons';
import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { ImageCategory } from '../../../enums/common-enum';
import { storage } from '../../../firebase';
import { useSnackbarV2 } from '../../../provider/SnackbarProviderV2';
import useCity from '../../../redux/hooks/useCity';
import usePath from '../../../redux/hooks/usePath';
import { screenContext } from '../../../redux/hooks/useScreenContext';
import useUser from '../../../redux/hooks/useUser';
import useUserDiv from '../../../redux/hooks/useUserDiv';
import useUserInflowSource from '../../../redux/hooks/useUserInflowSource';
import useUserStudentDiv from '../../../redux/hooks/useUserStudentDiv';
import { ImageService } from '../../../services/image-service';
import { openAccountDeletion } from '../../../utils/account-util';
import { findSpecificCities } from '../../../utils/prefecture-city-utils';
import { getBasePICV2 } from '../../../utils/user-util';
import AccountProfileCardEdit from '../account-profile/user-information-v2/AccountProfileCardEdit';
import AccountProfileCardView from '../account-profile/user-information-v2/AccountProfileCardView';
import { SaveUserBody } from '../../../__generated/model';
import { getBsUser } from '../../../__generated/user/bs-user/bs-user';

interface P {
  base: BaseDto;
  user: User;
}

const AccountSettingScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;

  const { goBack } = usePath();
  const { openSnackbar } = useSnackbarV2();
  const { userDivs, loadUserDivs } = useUserDiv();
  const { userStudentDivs, loadUserStudentDivs } = useUserStudentDiv();
  const { userInflowSources, loadUserInflowSources } = useUserInflowSource();
  const { prefectureWithCities, loadPrefectureWithCities } = useCity();
  const { fetchUser } = useUser({ noInitialize: true });
  const { setUserAndPropagate } = useContext(screenContext);

  const unmountRef = useUnmountRef();
  const [isInitialized, setIsInitialized] = useSafeState<boolean>(unmountRef, false);
  const [isEdit, setIsEdit] = useSafeState<boolean>(unmountRef, false);
  const [isSaving, setIsSaving] = useSafeState<boolean>(unmountRef, false);
  const [editableUser, setEditableUser] = useSafeState(unmountRef, user);
  const [facePhotoDownloadURL, setFacePhotoDownloadURL] = useSafeState<URL>(unmountRef, EMPTY);
  const [userPhotoFileToSave, setUserPhotoFileToSave] = useSafeState<File>(unmountRef);
  const [facePhotoFileToSave, setFacePhotoFileToSave] = useSafeState<File>(unmountRef);
  const [saveButtonDisabled, setSaveButtonDisabled] = useSafeState<boolean>(unmountRef, false);

  const topRef = useRef<HTMLDivElement>(null);

  const cities = useMemo<City[]>(
    () => findSpecificCities(prefectureWithCities, editableUser.prefecture),
    [editableUser.prefecture, prefectureWithCities]
  );

  const authorityDefCode = useMemo<Code | undefined>(() => {
    const pics = getBasePICV2(user, base, AuthorityDefCategory.USER_AUTHORITY);
    return hasLength(pics) ? pics[FIRST_INDEX].authority?.authorityDef?.authorityDefCode : undefined;
  }, [base, user]);

  const useFaceAccess = useMemo<boolean>(() => {
    return authorityDefCode === AuthorityDefCodeEnum.FACE_ACCESS;
  }, [authorityDefCode]);

  const getFacePhotoDownloadURL = useSafeCallback(async (): Promise<void> => {
    if (!user.faceRecognitionPhotoURL) return;

    const isUrl = user.faceRecognitionPhotoURL.startsWith('http');
    if (isUrl) {
      setFacePhotoDownloadURL(user.faceRecognitionPhotoURL);
      return;
    }

    const ref = storage.ref(user.faceRecognitionPhotoURL);
    const downloadURL = await ref.getDownloadURL();
    setFacePhotoDownloadURL(downloadURL);
  }, [user.faceRecognitionPhotoURL, setFacePhotoDownloadURL]);

  const switchViewMode = useSafeCallback((): void => {
    setIsEdit(false);
    setEditableUser(user);
  }, [setEditableUser, setIsEdit, user]);

  const handlerSaveButtonClicked = useSafeCallback(async (): Promise<void> => {
    setIsSaving(true);

    const [photoURL, faceRecognitionPhotoURL] = await Promise.all([
      userPhotoFileToSave
        ? ImageService.uploadImageToFirebase(userPhotoFileToSave, ImageCategory.USER_PROFILE, uuid())
        : EMPTY,
      facePhotoFileToSave
        ? ImageService.uploadImageToFirebase(facePhotoFileToSave, ImageCategory.USER_FACE, uuid())
        : EMPTY
    ]);

    const userRequest = builder<SaveUserBody>()
      .familyName(editableUser.familyName ?? EMPTY)
      .firstName(editableUser.firstName ?? EMPTY)
      .email(user.email) // FIXME: メールアドレスはここで変更すると不整合になりそう。対応要検討
      .gender(editableUser.gender ?? Gender.OTHER)
      .dateOfBirthV2(editableUser.dateOfBirthV2 ?? EMPTY)
      .prefecture(editableUser.prefecture ?? Prefecture.TOKYO)
      .city(editableUser.city ?? EMPTY)
      .userDiv(editableUser.userDiv)
      .userDivOther(editableUser.userDivOther ?? EMPTY)
      .campanyName(editableUser.companyName ?? EMPTY)
      .userStudentDiv(editableUser.userStudentDiv)
      .userStudentDivOther(editableUser.userStudentDivOther ?? EMPTY)
      .schoolName(editableUser.schoolName ?? EMPTY)
      .schoolDepartmentName(editableUser.schoolDepartmentName ?? EMPTY)
      .phone(editableUser.phone ?? EMPTY)
      .photoURL((photoURL || editableUser.photoURL) ?? EMPTY)
      .faceRecognitionPhotoURL((faceRecognitionPhotoURL || editableUser.faceRecognitionPhotoURL) ?? EMPTY)
      .faceFeatureStr(editableUser.faceFeatureStr ?? EMPTY)
      .userInflowSource(editableUser.userInflowSource)
      .userInflowSourceOther(editableUser.userInflowSourceOther ?? EMPTY)
      .userUsagePurpose(editableUser.userUsagePurpose)
      .userUsagePurposeOther(editableUser.userUsagePurposeOther ?? EMPTY)
      .userFamilyMembers(editableUser.userFamilyMemberMappings?.map(mapping => mapping.userFamilyMember!) ?? [])
      .userFamilyMemberOther(
        editableUser.userFamilyMemberMappings?.find(mapping => mapping.userFamilyMember?.userFamilyMemberDef?.hasOther)
          ?.other ?? EMPTY
      )
      .dateOfAgreement(editableUser.dateOfAgreement ?? new Date())
      .providers([])
      .build();

    const { data: response } = await getBsUser().saveUser(userRequest);

    if (!response.userId) {
      setIsSaving(false);
      openSnackbar('プロフィールの保存に失敗しました 時間をおいてもう一度お試しください', 'error', 4000);
      return;
    }

    const updatedUser = await fetchUser();
    if (updatedUser) {
      setEditableUser(updatedUser);
      setUserAndPropagate(updatedUser);
    }

    openSnackbar('プロフィールの保存に成功しました', 'success', 4000);
    setIsSaving(false);
    setIsEdit(false);
    topRef.current?.scrollIntoView();
  }, [
    setIsSaving,
    userPhotoFileToSave,
    facePhotoFileToSave,
    editableUser.familyName,
    editableUser.firstName,
    editableUser.gender,
    editableUser.dateOfBirthV2,
    editableUser.prefecture,
    editableUser.city,
    editableUser.userDiv,
    editableUser.userDivOther,
    editableUser.companyName,
    editableUser.userStudentDiv,
    editableUser.userStudentDivOther,
    editableUser.schoolName,
    editableUser.schoolDepartmentName,
    editableUser.phone,
    editableUser.photoURL,
    editableUser.faceRecognitionPhotoURL,
    editableUser.faceFeatureStr,
    editableUser.userInflowSource,
    editableUser.userInflowSourceOther,
    editableUser.userUsagePurpose,
    editableUser.userUsagePurposeOther,
    editableUser.userFamilyMemberMappings,
    editableUser.dateOfAgreement,
    user.email,
    fetchUser,
    openSnackbar,
    setIsEdit,
    setEditableUser,
    setUserAndPropagate
  ]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (isInitialized) return;
    await Promise.all([
      getFacePhotoDownloadURL(),
      loadUserDivs(base.baseCode),
      loadUserStudentDivs(base.baseCode),
      loadUserInflowSources(base.baseCode),
      loadPrefectureWithCities()
    ]);
    setIsInitialized(true);
  }, [
    base.baseCode,
    getFacePhotoDownloadURL,
    isInitialized,
    loadPrefectureWithCities,
    loadUserDivs,
    loadUserInflowSources,
    loadUserStudentDivs,
    setIsInitialized
  ]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (!isInitialized) return;
    getFacePhotoDownloadURL();
  }, [getFacePhotoDownloadURL, isInitialized, user.faceRecognitionPhotoURL]);

  useLayoutEffect(() => {
    topRef.current?.scrollIntoView();
  }, []);

  return (
    <Component style={styleForFullExpansion} className='account-setting-screen'>
      <Container ref={topRef} data-testid='account-setting-screen'>
        <BackButtonV2 label='戻る' onClick={isEdit ? switchViewMode : goBack} />
        <Content>
          <PageHeaderV2
            title='アカウント設定'
            rightComponent={
              <ButtonWrapper>
                {!isEdit && <ButtonV2 type='primary' label='編集' onClick={() => setIsEdit(true)} />}
              </ButtonWrapper>
            }
          />
          <CardWrapper>
            {!isEdit && (
              <AccountProfileCardView
                base={base}
                user={user}
                facePhotoDownloadURL={facePhotoDownloadURL}
                useFaceAccess={useFaceAccess}
              />
            )}
            {isEdit && (
              <AccountProfileCardEdit
                base={base}
                user={editableUser}
                facePhotoDownloadURL={facePhotoDownloadURL}
                useFaceAccess={useFaceAccess}
                userDivs={userDivs}
                userStudentDivs={userStudentDivs}
                userInflowSources={userInflowSources}
                cities={cities}
                setUser={setEditableUser}
                setUserPhotoFileToSave={setUserPhotoFileToSave}
                setFacePhotoFileToSave={setFacePhotoFileToSave}
                setSaveButtonDisabled={setSaveButtonDisabled}
              />
            )}
          </CardWrapper>
          {!isEdit && (
            <DangerButtonWrapper>
              <DangerButton startIcon={<DeleteOutlineRounded />} label='アカウント削除' onClick={openAccountDeletion} />
            </DangerButtonWrapper>
          )}
        </Content>
      </Container>
      {isEdit && (
        <Footer>
          <StyledButton
            disabled={isSaving || saveButtonDisabled}
            type='primary'
            label='アカウント設定を保存'
            onClick={handlerSaveButtonClicked}
          />
          <StyledButton label='キャンセル' onClick={switchViewMode} />
        </Footer>
      )}
      <ScreenLoaderV2 loading={isSaving} />
    </Component>
  );
});

AccountSettingScreen.displayName = 'AccountSettingScreen';
export default AccountSettingScreen;

const Container = styled.div`
  width: 100%;
  max-width: 968px;
  margin-inline: auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  padding: 0 ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 2}px;
`;

const StyledButton = styled(ButtonV2).attrs({ size: 'large', isFullWidth: true })`
  max-width: 343px;
`;

const CardWrapper = styled.div`
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 12px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  background: ${themeV3.mixins.v3.color.object.white};
`;

const DangerButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${themeV2.mixins.v2.spacing * 2}px 0 ${themeV2.mixins.v2.spacing * 8}px;
  border-top: 1px solid ${themeV3.mixins.v3.color.border.gray};
`;

const DangerButton = styled(ButtonV2)`
  color: ${themeV2.mixins.v2.color.font.pink} !important;
`;

const ButtonWrapper = styled.div`
  height: 36px;
`;
