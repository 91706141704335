import {
  ButtonV2,
  ColWidth,
  DialogV2,
  Header,
  PullDownOption,
  PullDownV2,
  ScreenLoaderV2,
  TableV3,
  themeV2,
  themeV3,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseId,
  BaseResourceCategory,
  BaseResourceCategoryId,
  SAVE_SPACE_RESERVATION_FOR_ADMIN,
  SEARCH_RESERVABLE_SPACES_FOR_ADMIN,
  SaveSpaceReservationForAdminRequest,
  SaveSpaceReservationForAdminResponse,
  SearchReservableSpacesForAdminRequest,
  SearchReservableSpacesForAdminResponse,
  Space,
  SpaceId,
  SpaceReservation
} from '@atomica.co/irori';
import { Index, Name, UserId } from '@atomica.co/types';
import { EMPTY, builder } from '@atomica.co/utils';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { format } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useSnackbarV2 } from '../../../provider/SnackbarProviderV2';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import { partialBuilder } from '../../../utils/common-util';

const COLUMN_WIDTH: ColWidth<ReservableSpaceRow> = { component: 56, category: 120, name: 'auto' };
const HEADER: Header<ReservableSpaceRow> = {
  component: { label: EMPTY },
  category: { label: '設備カテゴリ' },
  name: { label: '設備名' }
};

interface ReservableSpaceRow {
  id: SpaceId;
  component: JSX.Element;
  category: Name;
  name: Name;
}

interface P {
  baseId: BaseId;
  userId: UserId;
  reservation: SpaceReservation;
  baseResourceCategories: BaseResourceCategory[];
  isOpen: boolean;
  onClose: () => void;
  onSave: () => Promise<void>;
}

const ChangeSpaceModal: React.FC<P> = React.memo(props => {
  const { baseId, userId, reservation, baseResourceCategories, isOpen, onClose, onSave } = props;

  const { commonRequest } = useCommonRequest();
  const { openSnackbar } = useSnackbarV2();

  const unmountRef = useUnmountRef();
  const [spaces, setSpaces] = useSafeState<Space[]>(unmountRef, []);
  const [selectedSpaceId, setSelectedSpaceId] = useSafeState<SpaceId>(unmountRef, EMPTY);
  const [selectedBaseResourceCategoryId, setSelectedBaseResourceCategoryId] = useSafeState<
    BaseResourceCategoryId | undefined
  >(unmountRef, reservation.space?.baseResourceCategory?.baseResourceCategoryId);
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [isSaving, setIsSaving] = useSafeState<boolean>(unmountRef, false);

  const rows = useMemo<ReservableSpaceRow[]>(() => {
    return spaces.map((space, index) =>
      builder<ReservableSpaceRow>()
        .id(space.spaceId)
        .component(
          <RadioGroup key={`radio-group-${index}`} name='change-space' value={selectedSpaceId}>
            <StyledFormControlLabel control={<Radio />} label={EMPTY} value={space.spaceId} />
          </RadioGroup>
        )
        .category(space.baseResourceCategory?.categoryLabel ?? EMPTY)
        .name(space.spaceName)
        .build()
    );
  }, [selectedSpaceId, spaces]);

  const handleRowClicked = useSafeCallback(
    (index: Index) => {
      setSelectedSpaceId(rows[index].id);
    },
    [rows, setSelectedSpaceId]
  );

  const handleClose = useSafeCallback(() => {
    setSelectedSpaceId(EMPTY);
    onClose();
  }, [onClose, setSelectedSpaceId]);

  const searchReservableSpaces = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const request = builder<SearchReservableSpacesForAdminRequest>()
      .baseId(baseId)
      .resourceCategoryId(selectedBaseResourceCategoryId!)
      .startAt(reservation.startAt!)
      .endAt(reservation.endAt!)
      .build();
    const response = await commonRequest<SearchReservableSpacesForAdminRequest, SearchReservableSpacesForAdminResponse>(
      SEARCH_RESERVABLE_SPACES_FOR_ADMIN,
      request
    );

    setSpaces(response.spaces);
    setIsLoaderShown(false);
  }, [
    baseId,
    commonRequest,
    reservation.endAt,
    reservation.startAt,
    selectedBaseResourceCategoryId,
    setIsLoaderShown,
    setSpaces
  ]);

  const saveSpaceReservation = useSafeCallback(async (): Promise<void> => {
    setIsSaving(true);
    const space = spaces.find(space => space.spaceId === selectedSpaceId);
    const reservationToSave = partialBuilder(reservation, { space });
    const request = builder<SaveSpaceReservationForAdminRequest>()
      .baseId(baseId)
      .spaceReservation(reservationToSave)
      .spaceParticipants(reservationToSave.participants ?? [])
      .userId(userId)
      .build();
    const response = await commonRequest<SaveSpaceReservationForAdminRequest, SaveSpaceReservationForAdminResponse>(
      SAVE_SPACE_RESERVATION_FOR_ADMIN,
      request
    );
    await onSave();
    setIsSaving(false);
    const message = response.spaceReservationId ? '設備の変更が完了しました' : '設備の変更に失敗しました';
    const status = response.spaceReservationId ? 'success' : 'error';
    openSnackbar(message, status, 3000);
  }, [baseId, commonRequest, onSave, openSnackbar, reservation, selectedSpaceId, setIsSaving, spaces, userId]);

  useEffect(() => {
    searchReservableSpaces();
  }, [searchReservableSpaces]);

  const footerButtons = useMemo<React.ReactNode[]>(() => {
    return [
      <ButtonV2 key='cancel' label='キャンセル' onClick={handleClose} />,
      <ButtonV2 key='confirm' disabled={!selectedSpaceId} type='primary' label='確定' onClick={saveSpaceReservation} />
    ];
  }, [handleClose, saveSpaceReservation, selectedSpaceId]);

  const pullDownOptions = useMemo<PullDownOption[]>(() => {
    return baseResourceCategories.map(category =>
      builder<PullDownOption>().id(category.baseResourceCategoryId).label(category.categoryLabel).build()
    );
  }, [baseResourceCategories]);

  return (
    <DialogV2
      width={763}
      height={600}
      isOpen={isOpen}
      headerLabel='設備の変更'
      buttonsOnTheRight={footerButtons}
      onClose={onClose}
    >
      <Content>
        <Text>
          {reservation.startAt ? format(reservation.startAt, 'yyyy/MM/dd HH:mm') : EMPTY} ~{' '}
          {reservation.endAt ? format(reservation.endAt, 'yyyy/MM/dd HH:mm') : EMPTY}
          に予約可能な設備を表示しています。
        </Text>
        <PullDownRow>
          <PullDownWrapper>
            <PullDownV2
              onModal
              id={selectedBaseResourceCategoryId!}
              placeholder='選択'
              options={pullDownOptions}
              onClick={setSelectedBaseResourceCategoryId}
            />
          </PullDownWrapper>
        </PullDownRow>
        <TableWrapper>
          <TableV3
            isLoaderShown={isLoaderShown}
            colWidth={COLUMN_WIDTH}
            header={HEADER}
            rows={rows}
            onClickRow={handleRowClicked}
          />
        </TableWrapper>
      </Content>

      <ScreenLoaderV2 loading={isSaving} />
    </DialogV2>
  );
});

ChangeSpaceModal.displayName = 'ChangeSpaceModal';
export default ChangeSpaceModal;
const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const Text = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
`;

const PullDownRow = styled.div`
  background: ${themeV3.mixins.v3.color.container.neutral.middle};
  padding: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing}px
    ${themeV2.mixins.v2.spacing * 3}px;
`;

const PullDownWrapper = styled.div`
  width: 216px;
`;

const TableWrapper = styled.div`
  overflow-y: scroll;
  ${themeV2.mixins.v2.scrollbarInvisible};
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: 0;
`;
