import {
  ButtonV2,
  ColWidth,
  DialogV2,
  Header,
  LabelV2,
  TableV3,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  AuthorityCategoryDef,
  AuthorityDefCategory,
  AuthorityId,
  BaseAuthority,
  BaseDto,
  LogLevel,
  PersonInCharge,
  RECORD_LOGS,
  RecordLogsRequest,
  RecordLogsResponse,
  SAVE_PERSON_IN_CHARGE_FOR_ADMIN,
  SavePersonInChargeForAdminRequest,
  SavePersonInChargeForAdminResponse,
  User,
  toFullName
} from '@atomica.co/irori';
import { Index, Name } from '@atomica.co/types';
import { EMPTY, FIRST_INDEX, ONE, builder, hasLength, stringify, uuid } from '@atomica.co/utils';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import mojaco from '../../../assets/mojaco/mojaco_break.png';
import { useSnackbarV2 } from '../../../provider/SnackbarProviderV2';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import { FAILED_TO_REGISTER_WISH } from '../../../texts/wish-text';
import { getBasePICV2 } from '../../../utils/user-util';

const RADIO_COLUMN_WIDTH: ColWidth = { component: 56, name: 'auto' };
const CHECKBOX_COLUMN_WIDTH: ColWidth = { name: 'auto' };
const MAX_DISPLAY_USER_COUNT = 10;

interface baseAuthorityRow {
  id: AuthorityId;
  component?: JSX.Element;
  name: Name;
}

interface P {
  isOpen: boolean;
  base: BaseDto;
  baseAuthorities: BaseAuthority[];
  selectedUsers: User[];
  category: AuthorityCategoryDef;
  initialAuthorityIds?: AuthorityId[];
  onClose: (authorityIds?: AuthorityId[]) => void;
}

const getDefaultRadioId = (baseAuthorities: BaseAuthority[], initialAuthorityIds?: AuthorityId[]): AuthorityId => {
  return hasLength(initialAuthorityIds) ? initialAuthorityIds[FIRST_INDEX] : baseAuthorities[FIRST_INDEX].authorityId;
};

const RegisterPersonInChargeModal: React.FC<P> = React.memo(props => {
  const { isOpen, base, baseAuthorities, selectedUsers, category, initialAuthorityIds, onClose } = props;
  const { categoryName } = category;
  const { commonRequest } = useCommonRequest();
  const unmountRef = useUnmountRef();
  const { openSnackbar } = useSnackbarV2();

  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [selectedAuthortyIdForRadio, setSelectedAuthortyIdForRadio] = useSafeState<AuthorityId>(
    unmountRef,
    getDefaultRadioId(baseAuthorities, initialAuthorityIds)
  );
  const [selectedAuthortyIds, setSelectedAuthortyIds] = useSafeState<AuthorityId[]>(
    unmountRef,
    initialAuthorityIds || []
  );
  const [updating, setUpdating] = useSafeState<boolean>(unmountRef, false);

  const selectedAuthority = useMemo<BaseAuthority | undefined>(() => {
    if (!hasLength(baseAuthorities)) return;
    return baseAuthorities.find(auth => auth.authorityId === selectedAuthortyIdForRadio);
  }, [baseAuthorities, selectedAuthortyIdForRadio]);

  const selectedAuthorities = useMemo<BaseAuthority[]>(() => {
    if (!hasLength(baseAuthorities)) return [];
    return baseAuthorities.filter(auth => selectedAuthortyIds.includes(auth.authorityId));
  }, [baseAuthorities, selectedAuthortyIds]);

  const radioHeader = useMemo<Header>(() => {
    return { component: { label: EMPTY }, name: { label: categoryName } };
  }, [categoryName]);

  const checkboxHeader = useMemo<Header>(() => {
    return { name: { label: categoryName } };
  }, [categoryName]);

  const handleModalClosed = useSafeCallback(
    (savedAuthorityIds?: AuthorityId[]): void => {
      if (category.isMultiple) setSelectedAuthortyIds([]);
      if (!category.isMultiple && !savedAuthorityIds)
        setSelectedAuthortyIdForRadio(getDefaultRadioId(baseAuthorities, initialAuthorityIds));
      setIsModalOpen(false);
      onClose(savedAuthorityIds);
    },
    [baseAuthorities, category, onClose, setIsModalOpen, setSelectedAuthortyIdForRadio, setSelectedAuthortyIds]
  );

  const toPIC = useSafeCallback(
    (authority: BaseAuthority, user: User): PersonInCharge => {
      const pics = getBasePICV2(user, base, category.categoryCode as AuthorityDefCategory);
      const existingPic = pics.find(pic => pic.authority?.authorityId === authority.authorityId);
      return builder<PersonInCharge>()
        .personInChargeId(existingPic?.personInChargeId || uuid())
        .authority(authority)
        .user(user)
        .build();
    },
    [base, category]
  );

  const updatePersonInCharges = useSafeCallback(async (): Promise<void> => {
    setUpdating(true);

    const personInChargesToSave: PersonInCharge[] = [];
    const personInChargesToDelete: PersonInCharge[] = [];

    for (const user of selectedUsers) {
      if (category.isMultiple) {
        const personInCharges = selectedAuthorities.map(authority => toPIC(authority, user));
        personInChargesToSave.push(...personInCharges);
      } else {
        if (!selectedAuthority) return;
        personInChargesToSave.push(toPIC(selectedAuthority, user));
      }

      personInChargesToDelete.push(
        ...personInChargesToSave,
        ...getBasePICV2(user, base, category.categoryCode as AuthorityDefCategory)
      );
    }

    try {
      const request = builder<SavePersonInChargeForAdminRequest>()
        .baseId(base.baseId)
        .personInCharges(personInChargesToSave)
        .personInChargesToDelete(personInChargesToDelete)
        .build();
      await commonRequest<SavePersonInChargeForAdminRequest, SavePersonInChargeForAdminResponse>(
        SAVE_PERSON_IN_CHARGE_FOR_ADMIN,
        request
      );

      const authorityIds = category.isMultiple
        ? selectedAuthorities.map(authority => authority.authorityId)
        : selectedAuthority
          ? [selectedAuthority.authorityId]
          : [];
      handleModalClosed(authorityIds);
    } catch (e) {
      openSnackbar(FAILED_TO_REGISTER_WISH, 'error', 5000);

      commonRequest<RecordLogsRequest, RecordLogsResponse>(
        RECORD_LOGS,
        builder<RecordLogsRequest>()
          .level(LogLevel.ERROR)
          .jsonToRecord(stringify(e as Error))
          .build()
      );
    }

    setUpdating(false);
  }, [
    setUpdating,
    base,
    category,
    commonRequest,
    handleModalClosed,
    selectedAuthorities,
    selectedAuthority,
    selectedUsers,
    toPIC,
    openSnackbar
  ]);

  const footerButtons = useMemo<React.ReactNode[]>(() => {
    return [
      <ButtonV2 key='cancelButton' label='キャンセル' onClick={() => handleModalClosed()} />,
      <StyledButton key='saveButton' label='更新' onClick={() => setIsModalOpen(true)} />
    ];
  }, [handleModalClosed, setIsModalOpen]);

  const rows = useMemo<baseAuthorityRow[]>(
    () =>
      baseAuthorities.map((baseAuthority: BaseAuthority, index: Index) => {
        return category?.isMultiple
          ? builder<baseAuthorityRow>().id(baseAuthority.authorityId).name(baseAuthority.authorityName).build()
          : builder<baseAuthorityRow>()
              .id(baseAuthority.authorityId)
              .component(
                <RadioGroup
                  key={`radio-group-${index}`}
                  name='update-person-in-charge'
                  value={selectedAuthortyIdForRadio}
                >
                  <StyledFormControlLabel control={<Radio />} label={EMPTY} value={baseAuthority.authorityId} />
                </RadioGroup>
              )
              .name(baseAuthority.authorityName)
              .build();
      }),
    [baseAuthorities, category, selectedAuthortyIdForRadio]
  );

  const handleRadioClicked = useSafeCallback(
    (index: Index) => {
      setSelectedAuthortyIdForRadio(rows[index].id);
    },
    [rows, setSelectedAuthortyIdForRadio]
  );

  const handleCheckboxClicked = useSafeCallback(
    (index: Index): void => {
      if (!rows) return;
      setSelectedAuthortyIds(selectedIds => {
        const rowId = rows[index].id;
        const newSelectedIds = selectedIds.concat();
        newSelectedIds.includes(rowId)
          ? newSelectedIds.splice(newSelectedIds.indexOf(rowId), ONE)
          : newSelectedIds.push(rowId);
        return newSelectedIds;
      });
    },
    [rows, setSelectedAuthortyIds]
  );

  useEffect(() => {
    if (!category.isMultiple) setSelectedAuthortyIdForRadio(getDefaultRadioId(baseAuthorities, initialAuthorityIds));
  }, [baseAuthorities, category, initialAuthorityIds, setSelectedAuthortyIdForRadio]);

  useEffect(() => {
    if (initialAuthorityIds) setSelectedAuthortyIds(initialAuthorityIds);
  }, [initialAuthorityIds, setSelectedAuthortyIds]);

  return (
    <DialogV2
      width={600}
      isOpen={isOpen}
      headerLabel={`${categoryName}の更新`}
      buttonsOnTheRight={footerButtons}
      onClose={() => handleModalClosed()}
    >
      <Content>
        <LabelV2 text='選択したユーザー' />
        <UsersWrapper>
          {selectedUsers.slice(FIRST_INDEX, MAX_DISPLAY_USER_COUNT).map((user: User, index: Index) => (
            <UserWrapper key={`user-${index}`}>
              <Icon src={user.photoURL || mojaco} />
              <UserName>{toFullName(user)}</UserName>
            </UserWrapper>
          ))}
          {selectedUsers.length > MAX_DISPLAY_USER_COUNT && (
            <OtherUserCount>その他 {selectedUsers.length - MAX_DISPLAY_USER_COUNT}名</OtherUserCount>
          )}
        </UsersWrapper>
      </Content>
      <TableWrapper>
        <TableV3
          colWidth={category.isMultiple ? CHECKBOX_COLUMN_WIDTH : RADIO_COLUMN_WIDTH}
          header={category.isMultiple ? checkboxHeader : radioHeader}
          rows={rows}
          onClickRow={category.isMultiple ? handleCheckboxClicked : handleRadioClicked}
          selectedIds={category.isMultiple ? selectedAuthortyIds : undefined}
          setSelectedIds={category.isMultiple ? setSelectedAuthortyIds : undefined}
        />
      </TableWrapper>
      <DialogV2
        width={400}
        isOpen={isModalOpen}
        headerLabel={`${categoryName}の更新しますか？`}
        buttonsOnTheRight={[
          <ButtonV2 key='cancel' label='いいえ' onClick={() => setIsModalOpen(false)} />,
          <StyledButton key='update' label='更新' disabled={updating} onClick={updatePersonInCharges} />
        ]}
        onClose={() => setIsModalOpen(false)}
      >
        <Content>
          {selectedUsers.length}名の{categoryName}を変更します
        </Content>
      </DialogV2>
    </DialogV2>
  );
});

RegisterPersonInChargeModal.displayName = 'RegisterPersonInChargeModal';
export default RegisterPersonInChargeModal;

const Content = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  display: flex;
  flex-direction: column;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  gap: ${themeV2.mixins.v2.spacing}px;
  background: ${themeV2.mixins.v2.color.background.offWhite};
`;

const UsersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const OtherUserCount = styled.div`
  color: ${themeV2.mixins.v2.color.font.gray};
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 20px;
  border: 1px solid ${themeV2.mixins.v2.color.border.gray};
`;

const UserName = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
`;

const TableWrapper = styled.div`
  max-height: calc(100vh - 240px);
  overflow-y: scroll;
  ${themeV2.mixins.v2.scrollbarInvisible};
  position: relative;
  tr:last-child {
    td {
      border-bottom: none;
    }
  }

  @supports (height: 1dvh) {
    max-height: calc(100dvh - 240px);
  }
`;

const StyledButton = styled(ButtonV2).attrs(() => ({ type: 'primary', width: 105 }))``;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: 0;
`;
