import {
  BillingDetailCategory,
  BillingDetailV2,
  BillingStatus,
  BillingV2,
  HonorificType,
  ItemId,
  ItemV2,
  PaymentMethod,
  PriceUtils,
  TaxDiv,
  TaxId,
  TaxV2,
  UnitId,
  UnitV2
} from '@atomica.co/irori';
import {
  Account,
  Address,
  Code,
  Description,
  Hour,
  Id,
  Name,
  NoStr,
  Post,
  Price,
  Quantity,
  Rate,
  Remarks,
  Text
} from '@atomica.co/types';
import {
  EMPTY,
  NOT_FOUND_INDEX,
  ZERO,
  builder,
  hasLength,
  isArray,
  isGreaterThanZero,
  parseFloat,
  toTaxExcluded,
  toTaxIncluded
} from '@atomica.co/utils';
import { calcTaxIncludedAmountPrice } from '../utils/tax-util';

export interface BillingDetail {
  billingDetailId?: Id;
  disabled: boolean;
  itemId?: ItemId;
  itemName: Name;
  description?: Description;
  itemCode?: Code;
  quantity: Quantity;
  taxDiv: TaxDiv;
  tax: TaxV2;
  unit: UnitV2;
  unitPrice: Price;
}

export interface PriceDescription {
  price: Price;
  tax: Price;
  taxRate: Rate;
}

interface BillingPriceDescription {
  [taxCode: Code]: PriceDescription;
}

/*
 * 請求詳細画面
 */

export interface DetailsForBillingDetailScreen {
  invoice?: InvoiceHeader;
  invoiceDetails?: InvoiceDetail[];
  invoicePrice?: InvoicePriceSummary;
  relation?: BillingRelation;
}

export interface InvoiceHeader {
  bankAccount: Account;
  billingAddress: Address;
  billingDate: Date;
  billingName: Name;
  billingNo: NoStr;
  billingPost: Post;
  billingStatus: BillingStatus;
  contractorInfo: Text;
  contractorName: Name;
  cutoffDate: Date;
  honorificType: HonorificType;
  paymentDueDate: Date | undefined;
  paymentMethod: PaymentMethod;
  payableAccount: Account;
  remarks: Remarks;
  taxIncludedTotalPrice: Price;
  taxExcludedTotalPrice: Price;
}

export interface InvoiceDetail {
  item: ItemV2;
  itemName: Name;
  quantity: Quantity;
  tax: TaxV2;
  taxDiv: TaxDiv;
  unit: UnitV2;
  unitPrice: Price;
}

export interface InvoicePriceSummary {
  [TaxDiv.INCLUDED]: BillingPriceDescription;
  [TaxDiv.EXCLUDED]: BillingPriceDescription;
  [TaxDiv.EXEMPT]: PriceDescription;
}

export interface BillingRelation {
  contractPlanmName: Name;
  freeTier: Hour | undefined;
  discountRate: Rate | undefined;
}

const INITIAL_INVOICE_PRICE: InvoicePriceSummary = {
  [TaxDiv.INCLUDED]: {},
  [TaxDiv.EXCLUDED]: {},
  [TaxDiv.EXEMPT]: { price: ZERO, tax: ZERO, taxRate: ZERO }
};

export const toInitialBillingDetails = (details: BillingDetailV2[] | undefined): BillingDetail[] => {
  if (!isArray(details)) return [];

  return details.map(detail => {
    return builder<BillingDetail>()
      .billingDetailId(detail.billingDetailId)
      .disabled(detail.isDisabled)
      .itemId(detail.item ? detail.item.itemId : EMPTY)
      .itemName(detail.itemName)
      .description(detail.description)
      .quantity(parseFloat(detail.unitQuantity.toString()))
      .taxDiv(detail.taxDiv)
      .tax(detail.tax!)
      .unit(detail.unit!)
      .unitPrice(detail.unitPrice)
      .build();
  });
};

export const toInvoiceHeader = (billing: BillingV2): InvoiceHeader | undefined => {
  if (!billing) return;

  return {
    bankAccount: billing.bankAccount ?? EMPTY,
    billingAddress: billing.billingAddress ?? EMPTY,
    billingName: billing.billingName,
    billingNo: billing.billingNo,
    billingPost: billing.billingPost,
    billingDate: billing.billingDate,
    billingStatus: billing.billingStatus,
    contractorInfo: billing.contractorInfo,
    contractorName: billing.contractorName,
    cutoffDate: billing.cutoffDate,
    honorificType: billing.honorificType,
    paymentDueDate: billing.paymentDueDate,
    paymentMethod: billing.paymentMethod,
    payableAccount: billing.payableAccount ?? EMPTY,
    remarks: billing.remarks ?? EMPTY,
    taxExcludedTotalPrice: billing.taxExcludedTotalPrice ?? 0,
    taxIncludedTotalPrice: billing.taxIncludedTotalPrice ?? 0
  };
};

export const toInvoiceDetails = (billing: BillingV2): InvoiceDetail[] => {
  if (!billing || !billing.billingDetails) return [];
  const billingDetails = billing.billingDetails;
  return billingDetails
    .filter(
      (billingDetail: BillingDetailV2) =>
        billingDetail.isDisabled === false && isGreaterThanZero(billingDetail.unitQuantity)
    )
    .map((billingDetail: BillingDetailV2) => {
      const registeredItem = builder<ItemV2>()
        .unitPrice(billingDetail.unitPrice)
        .tax(billingDetail.tax)
        .taxDiv(billingDetail.taxDiv)
        .build();
      return {
        item: registeredItem,
        itemName: billingDetail.itemName,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        quantity: parseFloat(billingDetail.unitQuantity as any),
        tax: billingDetail.tax!,
        taxDiv: billingDetail.taxDiv,
        unit: billingDetail.unit!,
        unitPrice: billingDetail.unitPrice
      };
    });
};

export const toInvoicePriceSummary = (billing: BillingV2): InvoicePriceSummary | undefined => {
  if (!billing) return;
  const billingDetails = billing.billingDetails;
  if (!billingDetails) return INITIAL_INVOICE_PRICE;
  return billingDetails
    .filter(detail => !detail.isDisabled)
    .reduce(
      (pre: InvoicePriceSummary, curr: BillingDetailV2): InvoicePriceSummary => {
        const taxDiv = curr.taxDiv;
        const totalPrice = PriceUtils.applyAmountPrice(curr.unitPrice, curr.unitQuantity);

        switch (taxDiv) {
          case TaxDiv.EXCLUDED:
          case TaxDiv.INCLUDED: {
            const taxIncludedPrice =
              taxDiv === TaxDiv.EXCLUDED ? toTaxIncluded(totalPrice, curr.tax!.taxRate) : totalPrice;
            const taxExculedPrice =
              taxDiv === TaxDiv.EXCLUDED ? totalPrice : toTaxExcluded(totalPrice, curr.tax!.taxRate);
            if (!pre[taxDiv][curr.tax!.taxCode])
              pre[taxDiv][curr.tax!.taxCode] = { price: ZERO, tax: ZERO, taxRate: ZERO };
            pre[taxDiv][curr.tax!.taxCode].price += taxIncludedPrice;
            pre[taxDiv][curr.tax!.taxCode].tax += taxIncludedPrice - taxExculedPrice;
            pre[taxDiv][curr.tax!.taxCode].taxRate = curr.tax!.taxRate;
            return pre;
          }
          case TaxDiv.EXEMPT:
            pre[TaxDiv.EXEMPT].price += totalPrice;
            return pre;
          default:
            return pre;
        }
      },
      JSON.parse(JSON.stringify(INITIAL_INVOICE_PRICE))
    );
};

export const toBillingRelation = (billing: BillingV2): BillingRelation | undefined => {
  if (!billing || !billing.contract || !billing.contract.contractPlan) return;
  return {
    contractPlanmName: billing.contract.contractPlan!.contractPlanName,
    freeTier: billing.contract.contractPlan?.freeTier,
    discountRate: billing.contract.contractPlan?.discountRate
  };
};

/*
 * 請求登録画面
 */

export interface DetailForBillingRegisterScreen {
  billingDetailId: Id;
  category: BillingDetailCategory;
  disabled: boolean;
  isDeletable: boolean;
  item?: ItemV2;
  itemName: Name;
  itemCode?: Code;
  quantity: Quantity;
  taxCode?: Code;
  taxDiv?: TaxDiv;
  taxId?: TaxId;
  taxRate?: Rate;
  unitId?: UnitId;
  unitName?: Name;
  unitPrice: Price;
}

export const toBillingDetailsFromExistingBillingDetails = (
  details: BillingDetailV2[]
): DetailForBillingRegisterScreen[] => {
  if (!hasLength(details)) return [];

  return details.map((detail: BillingDetailV2) => {
    const item = detail.item;
    const detailToSave = builder<DetailForBillingRegisterScreen>()
      .billingDetailId(detail.billingDetailId)
      .category(detail.category || BillingDetailCategory.MANUAL)
      .disabled(detail.isDisabled)
      .isDeletable(detail.isDeletable)
      .itemName(detail.itemName)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .quantity(parseFloat(detail.unitQuantity as any))
      .unitPrice(detail.unitPrice);
    if (item) detailToSave.item(item);
    if (item) detailToSave.itemCode(item.itemCode || EMPTY);
    if (detail.taxDiv) detailToSave.taxDiv(detail.taxDiv);
    if (detail.tax) {
      detailToSave.taxId(detail.tax.taxId);
      detailToSave.taxCode(detail.tax.taxCode);
      detailToSave.taxRate(detail.tax.taxRate);
    }
    if (detail.unit) detailToSave.unitId(detail.unit.unitId);
    return detailToSave.build();
  });
};

/*
 * 請求予定金額計算
 */
export interface BillingSummaryDetail {
  itemName: Name;
  quantity?: Quantity;
  taxExcludedSubtotalPrice?: Price;
  taxIncludedSubtotalPrice?: Price;
  taxDiv?: TaxDiv;
  taxRate?: Rate;
  unitPrice: Price;
}

export interface SubtotalPriceByTaxDiv {
  taxDiv: TaxDiv;
  taxId: TaxId;
  taxRate: Rate;
  taxIncludedPrice: Price;
  taxExcludedPrice: Price;
}

export interface BillingSummaryHeader {
  taxIncludedTotalPrice: Price;
  taxExcludedTotalPrice: Price;
  subtotalPricesByTaxDiv: SubtotalPriceByTaxDiv[];
  details: BillingSummaryDetail[];
}

const createBillingSubtotalPricesByTax = (
  subtotalPricesByTaxDiv: SubtotalPriceByTaxDiv[],
  taxId: TaxId,
  taxDiv: TaxDiv,
  taxRate: Rate,
  taxIncludedPrice: Price,
  taxExcludedPrice: Price
): SubtotalPriceByTaxDiv[] => {
  const index = subtotalPricesByTaxDiv.findIndex(price => {
    return price.taxDiv === taxDiv && price.taxId === taxId;
  });

  if (index === NOT_FOUND_INDEX) {
    const price = builder<SubtotalPriceByTaxDiv>()
      .taxDiv(taxDiv)
      .taxId(taxId)
      .taxRate(taxRate)
      .taxIncludedPrice(taxIncludedPrice)
      .taxExcludedPrice(taxExcludedPrice)
      .build();

    subtotalPricesByTaxDiv.push(price);
    return subtotalPricesByTaxDiv;
  }

  const prevPrice = subtotalPricesByTaxDiv[index];
  const priceToUpdate = builder<SubtotalPriceByTaxDiv>()
    .taxDiv(taxDiv)
    .taxId(taxId)
    .taxRate(taxRate)
    .taxIncludedPrice(prevPrice.taxIncludedPrice + taxIncludedPrice)
    .taxExcludedPrice(prevPrice.taxExcludedPrice + taxExcludedPrice)
    .build();

  subtotalPricesByTaxDiv[index] = priceToUpdate;
  return subtotalPricesByTaxDiv;
};

export const toBillingSummary = (details: DetailForBillingRegisterScreen[]): BillingSummaryHeader => {
  let taxIncludedTotalPrice = 0;
  let taxExcludedTotalPrice = 0;
  const subtotalPricesByTaxDiv: SubtotalPriceByTaxDiv[] = [];
  const billingSummaryDetails: BillingSummaryDetail[] = [];

  for (const detail of details) {
    const { disabled, itemName, quantity, taxId, taxDiv, taxRate, unitPrice } = detail;
    if (disabled || !itemName || !quantity || !taxId || !taxDiv || taxRate == null || !unitPrice || isNaN(unitPrice))
      continue;
    const includedPrice = calcTaxIncludedAmountPrice(unitPrice, quantity, taxDiv, taxRate);
    const excludedPrice = toTaxExcluded(includedPrice, taxRate);
    createBillingSubtotalPricesByTax(subtotalPricesByTaxDiv, taxId, taxDiv, taxRate, includedPrice, excludedPrice);

    taxIncludedTotalPrice += includedPrice;
    taxExcludedTotalPrice += excludedPrice;

    const displayBillingSummaryDetail = builder<BillingSummaryDetail>()
      .itemName(itemName)
      .quantity(quantity)
      .taxDiv(taxDiv)
      .taxExcludedSubtotalPrice(excludedPrice)
      .taxIncludedSubtotalPrice(includedPrice)
      .taxRate(taxRate)
      .unitPrice(unitPrice)
      .build();

    billingSummaryDetails.push(displayBillingSummaryDetail);
  }

  return {
    taxIncludedTotalPrice,
    taxExcludedTotalPrice,
    subtotalPricesByTaxDiv,
    details: billingSummaryDetails
  };
};
