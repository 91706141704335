import {
  BackButtonV2,
  CommentBox,
  MOBILE_MAX_WIDTH,
  MOBILE_MIN_WIDTH,
  theme,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  ACCESS_ACCESS_TYPE,
  ACCESS_ID,
  AccessV2,
  BaseDto,
  ContractUsageV2,
  ContractV2,
  ContractVersion,
  Direction,
  FETCH_ACCESSES,
  FETCH_CONTRACT_USAGE,
  FETCH_CONTRACT_V2_BY_USER,
  FetchAccessesRequest,
  FetchAccessesResponse,
  FetchContractUsageRequest,
  FetchContractUsageResponse,
  FetchContractV2ByUserRequest,
  FetchContractV2ByUserResponse,
  SAVE_ACCESS,
  SaveAccessRequest,
  SaveAccessResponse,
  User,
  toLatestUserPath
} from '@atomica.co/irori';
import { Count, Label, Message, Offset } from '@atomica.co/types';
import {
  EMPTY,
  EQUAL,
  FIRST_INDEX,
  Language,
  QUESTION_MARK,
  ZERO,
  builder,
  embedIdInPath,
  hasLength,
  isGreaterThanZero,
  toDateTimeStr,
  uuid
} from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import ListV2, { ListRow } from '../../components/list/List';
import Screen from '../../components/screen/Screen';
import { toContractPlanLabel } from '../../constants/base-const';
import { database } from '../../firebase';
import usePath from '../../redux/hooks/usePath';
import CommonRequest from '../../requests/common-request';
import { PATH_IDS, Path } from '../../router/Routes';
import { DIRECTION_LABELS } from '../../texts/access-v2-text';
import { MEMBER_ONLY } from '../../texts/error-message-text';
import { getContractVersion } from '../../utils/contract-v2-util';
import { toTimeStr } from '../../utils/date-util';
import { getBaseConsumerHomePathAndName } from '../../utils/path-util';
import mojaco_greeting from './../../assets/mojaco/mojaco_greeting.png';

const LIMIT = 5;

interface P extends RouteComponentProps {
  base: BaseDto;
  user: User;
}

const RecordAccessScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { openBasePath, queryParams } = usePath();
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [accessesLoaded, setAccessesLoaded] = useSafeState<boolean>(unmountRef, false);
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef);
  const [contract, setContract] = useSafeState<ContractV2 | undefined>(unmountRef);
  const [usageSummary, setUsageSummary] = useSafeState<ContractUsageV2 | undefined>(unmountRef);
  const [currentAccess, setCurrentAccess] = useSafeState<AccessV2>(unmountRef);
  const [pastAccesses, setPastAccesses] = useSafeState<AccessV2[]>(unmountRef, []);
  const [version, setVersion] = useSafeState<ContractVersion>(unmountRef);
  const [offset, setOffset] = useSafeState<Offset>(unmountRef, ZERO);
  const [totalCount, setTotalCount] = useSafeState<Count>(unmountRef, ZERO);

  const contractPlanLabel = useMemo<Label>(() => {
    switch (version) {
      case ContractVersion.V1:
        return toContractPlanLabel(base.baseCode, user.contract?.contractPlan);
      case ContractVersion.V2:
        return contract?.contractPlan?.contractPlanName || EMPTY;
      default:
        return EMPTY;
    }
  }, [version, base, user, contract]);

  const getContract = useSafeCallback(async (): Promise<ContractV2 | undefined> => {
    const request = builder<FetchContractV2ByUserRequest>().baseId(base.baseId).userId(user.userId).build();
    const { contract } = await CommonRequest.call<FetchContractV2ByUserRequest, FetchContractV2ByUserResponse>(
      FETCH_CONTRACT_V2_BY_USER,
      request
    );
    return contract;
  }, [base, user]);

  const saveAccess = useSafeCallback(
    async (version: ContractVersion): Promise<void> => {
      const prevAccessId = queryParams[ACCESS_ID];

      if (prevAccessId) return;
      const request = builder<SaveAccessRequest>()
        .accessId(uuid())
        .baseId(base.baseId)
        .userId(user.userId)
        .accessType(queryParams[ACCESS_ACCESS_TYPE])
        .version(version)
        .build();
      const response = await CommonRequest.call<SaveAccessRequest, SaveAccessResponse>(SAVE_ACCESS, request);

      if (response && response.accessId) {
        const savedAccessId = response.accessId;
        database.ref(toLatestUserPath(base.baseCode)).set({ accessId: savedAccessId });
        window.location.replace(
          `${embedIdInPath(Path.RECORD_ACCESS, PATH_IDS, [
            base.baseCode
          ])}${QUESTION_MARK}${ACCESS_ID}${EQUAL}${savedAccessId}` as Path
        );
      }
    },
    [base, queryParams, user]
  );

  const initContractUsage = useSafeCallback(
    async (version: ContractVersion): Promise<void> => {
      const request = builder<FetchContractUsageRequest>()
        .baseId(base.baseId)
        .userId(user.userId)
        .version(version)
        .build();
      const response = await CommonRequest.call<FetchContractUsageRequest, FetchContractUsageResponse>(
        FETCH_CONTRACT_USAGE,
        request
      );
      setUsageSummary(response.usage);
    },
    [base, user, setUsageSummary]
  );

  const initAccesses = useSafeCallback(
    async (offset: Offset, version: ContractVersion | undefined): Promise<void> => {
      setAccessesLoaded(false);
      const request = builder<FetchAccessesRequest>()
        .baseCode(base.baseCode)
        .userId(user.userId)
        .limit(LIMIT)
        .offset(offset * LIMIT)
        .version(version)
        .build();
      const response = await CommonRequest.call<FetchAccessesRequest, FetchAccessesResponse>(FETCH_ACCESSES, request);
      const { accesses, totalCount } = response;

      setCurrentAccess(accesses[FIRST_INDEX]);
      setPastAccesses(accesses);
      setTotalCount(totalCount);
      setAccessesLoaded(true);
    },
    [setAccessesLoaded, base, user, setCurrentAccess, setPastAccesses, setTotalCount]
  );

  const initialize = useSafeCallback(async (): Promise<void> => {
    const contract = await getContract();
    const version = getContractVersion(user, contract);

    if (!version) {
      setErrorMessage(MEMBER_ONLY);
      setLoaded(true);
      return;
    }

    setContract(contract);
    setVersion(version);

    await saveAccess(version);
    await Promise.all([initContractUsage(version), initAccesses(ZERO, version)]);

    setLoaded(true);
  }, [
    getContract,
    user,
    setErrorMessage,
    setLoaded,
    setContract,
    setVersion,
    saveAccess,
    initContractUsage,
    initAccesses
  ]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handlePagenateClicked = useSafeCallback(
    async (offset: Offset): Promise<void> => {
      await initAccesses(offset, version);
      setOffset(offset);
    },
    [initAccesses, version, setOffset]
  );

  const handleBackButtonClicked = useSafeCallback((): void => {
    const [path] = getBaseConsumerHomePathAndName(base);
    openBasePath(path);
  }, [openBasePath]);

  return (
    <Screen errorMsg={errorMessage} loading={!loaded} className='record-access-screen'>
      <Container>
        <Content>
          <HeaderWrapper>
            <BackButtonV2 label='ホーム' onClick={handleBackButtonClicked} />
          </HeaderWrapper>
          <MojacoWrapper>
            <CommentBox animation photoURL={mojaco_greeting}>
              <Greeting>
                {currentAccess?.direction ? `${DIRECTION_LABELS[currentAccess.direction]}の時間を記録したよ！` : EMPTY}
              </Greeting>
            </CommentBox>
          </MojacoWrapper>

          {contractPlanLabel && (
            <Wrapper>
              <Title>ご利用状況</Title>
              <Card>
                <CardLabel>ご契約プラン</CardLabel>
                <CardText>{contractPlanLabel}</CardText>
              </Card>
            </Wrapper>
          )}
          {usageSummary && (
            <Wrapper>
              <Card>
                <CardLabel>今月のご利用状況</CardLabel>
                <CardText>{toTimeStr(usageSummary.usageMsec, Language.JAPANESE)}</CardText>
              </Card>
              <Card>
                <CardLabel>今月の残り時間</CardLabel>
                <CardText>
                  {toTimeStr(
                    isGreaterThanZero(usageSummary.remainingMsec) ? usageSummary!.remainingMsec : ZERO,
                    Language.JAPANESE
                  )}
                </CardText>
              </Card>
            </Wrapper>
          )}

          {hasLength(pastAccesses) && (
            <Wrapper>
              <Title>エントランス入退履歴</Title>
              <ListV2
                loaded={accessesLoaded}
                rows={pastAccesses.map(access =>
                  builder<ListRow>()
                    .text(toDateTimeStr(access.operationDatetime, Language.JAPANESE))
                    .secondaryAction(
                      access.direction === Direction.ENTER ? <EnterText>入室</EnterText> : <Text>退室</Text>
                    )
                    .build()
                )}
                paginateProps={{ offset, limit: LIMIT, totalCount, setOffset: handlePagenateClicked }}
              />
            </Wrapper>
          )}
        </Content>
      </Container>
    </Screen>
  );
});

RecordAccessScreen.displayName = 'RecordAccessScreen';
export default RecordAccessScreen;

const Container = styled.div`
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: 100vh;
  padding-top: ${themeV2.mixins.v2.spacing * 2}px;
  padding-bottom: ${themeV2.mixins.v2.spacing * 15}px;
  overflow: scroll;
  ${themeV2.mixins.v2.scrollbarInvisible};

  @supports (height: 1dvh) {
    height: 100dvh;
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const MojacoWrapper = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 4}px ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing}px;
`;

const Wrapper = styled.div`
  padding: 0 ${themeV2.mixins.v2.spacing * 2}px;
`;

const Greeting = styled(Typography)`
  ${themeV2.mixins.v2.typography.label.large};
  width: calc(100% - ${themeV2.mixins.v2.spacing * 3}px);
  height: auto;
  color: ${themeV2.mixins.v2.color.font.gray};
  margin: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing}px
    ${themeV2.mixins.v2.spacing * 2}px;
  ${theme.mixins.underline};
`;

const Title = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.large};
  color: ${themeV2.mixins.v2.color.font.black};
  margin-top: ${themeV2.mixins.v2.spacing * 3}px;
`;

const Card = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 12px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  margin-top: ${themeV2.mixins.v2.spacing}px;
`;

const CardLabel = styled.div`
  ${themeV2.mixins.v2.typography.title.small};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const Text = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.large};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const CardText = styled(Text)``;

const EnterText = styled(Text)`
  color: ${themeV2.mixins.v2.color.font.pink};
`;
