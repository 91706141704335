import {
  BackButtonV2,
  Component,
  HEADER_HEIGHT,
  MappedTabProperty,
  MappedTabs,
  MoreHorizMenu,
  MoreHorizMenuButton,
  PageHeaderV2,
  styleForFullExpansion,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  FETCH_USER_FOR_ADMIN,
  FetchUserForAdminRequest,
  FetchUserForAdminResponse,
  User,
  isBaseFunctionToggleEnabled
} from '@atomica.co/irori';
import { USER_ID, UserId } from '@atomica.co/types';
import { builder, embedIdInPath } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { USER_DETAIL_FEATURE_PATH } from '../../constants/user-v2-const';
import { USER_DETAIL_FEATURES, UserDetailFeature } from '../../enums/users-enum';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../router/Routes';
import { USER_DETAIL_FEATURE_LABELS_V2 } from '../../texts/user-text';
import { openAccountDeletion } from '../../utils/account-util';
import { toFullName } from '../../utils/user-util';
import UserOverviewV2 from './user-overview/UserOverviewV2';
import UserPointUsageLog from './user-point-usage-log/UserPointUsageLog';
import UserRoomEntryExitHistory from './user-room-entry-exit-history/UserRoomEntryExitHistory';

const USER_CONTENT_MAX_WIDTH = 1280;
const USER_CONTENT_MIN_WIDTH = 756;

interface P {
  isDrawerOpen: boolean;
  base: BaseDto;
  user: User;
}

const subMenus: MoreHorizMenu[] = [{ label: '削除', onClick: openAccountDeletion }];

const shouldDisplayTab = (featureKey: UserDetailFeature, base: BaseDto): boolean => {
  switch (featureKey) {
    case USER_DETAIL_FEATURES.POINT_USAGE_LOG:
      return isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USER_POINT);
    /** NOTE: 入退室履歴の機能を全拠点展開する場合はSER_DETAIL_FEATURES.ROOM_ENTRY_EXIT_HISTORYは不要 */
    case USER_DETAIL_FEATURES.ROOM_ENTRY_EXIT_HISTORY:
      return isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_ROOM_ENTRY_EXIT_HISTORY_IN_USER_DETAIL);
    default:
      return true;
  }
};

const RENDER_COMPONENT = {
  [USER_DETAIL_FEATURES.OVERVIEW]: props => <UserOverviewV2 {...props} />,
  [USER_DETAIL_FEATURES.POINT_USAGE_LOG]: props => <UserPointUsageLog {...props} />,
  [USER_DETAIL_FEATURES.ROOM_ENTRY_EXIT_HISTORY]: props => <UserRoomEntryExitHistory {...props} />
};

const UserV2DetailsScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const unmountRef = useUnmountRef();
  const { params, openPath, openBasePath } = usePath();
  const { commonRequest } = useCommonRequest();

  /** use-state */
  const [signInUser, setSignInUser] = useSafeState<User>(unmountRef);
  const [selectedUser, setSelectedUser] = useSafeState<User>(unmountRef);

  const [selectedFeatureKey, setSelectedFeatureKey] = useSafeState<UserDetailFeature>(
    unmountRef,
    USER_DETAIL_FEATURES.OVERVIEW
  );

  const TabDetailContent = RENDER_COMPONENT[selectedFeatureKey];

  /** use-memo */
  const tabProperties = useMemo<Map<UserDetailFeature, MappedTabProperty>>(() => {
    const featureTabs = new Map<UserDetailFeature, MappedTabProperty>();

    Object.keys(USER_DETAIL_FEATURE_LABELS_V2).forEach(key => {
      const featureKey = key as UserDetailFeature;
      if (shouldDisplayTab(featureKey, base)) {
        featureTabs.set(featureKey, {
          label: USER_DETAIL_FEATURE_LABELS_V2[featureKey]
        });
      }
    });
    return featureTabs;
  }, [base]);

  const userDetailPaths = useMemo<Map<UserDetailFeature, Path>>(() => {
    return new Map(
      Array.from(USER_DETAIL_FEATURE_PATH.entries()).filter(([featureKey]) => shouldDisplayTab(featureKey, base))
    );
  }, [base]);

  const selectedUserId = useMemo<UserId>(() => params[USER_ID], [params]);

  /** use-callback */
  const handleButtonClicked = useSafeCallback((): void => {
    openBasePath(Path.USER_SEARCH_V2);
  }, [openBasePath]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchUserForAdminRequest>().baseId(base.baseId).userId(selectedUserId).build();
    const response = await commonRequest<FetchUserForAdminRequest, FetchUserForAdminResponse>(
      FETCH_USER_FOR_ADMIN,
      request
    );
    if (user) setSignInUser(user);
    if (response.user) setSelectedUser(response.user);
  }, [base, commonRequest, selectedUserId, setSelectedUser, setSignInUser, user]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const openPathForUserDetail = useSafeCallback(
    (path: Path, userId: UserId): void => {
      openPath(embedIdInPath(path, PATH_IDS, [base.baseCode, userId]));
    },
    [base, openPath]
  );

  const selectPath = useSafeCallback(
    (selectedFeatureKey: UserDetailFeature): void => {
      const path = userDetailPaths.get(selectedFeatureKey);
      if (path) {
        openPathForUserDetail(path, selectedUserId);
      } else {
        openPathForUserDetail(Path.USER_OVERVIEW, selectedUserId);
      }
    },
    [userDetailPaths, selectedUserId, openPathForUserDetail]
  );

  useEffect(() => {
    selectPath(selectedFeatureKey);
  }, [selectedFeatureKey, selectPath]);

  return (
    <Component className='user-v2-details-screen' style={styleForFullExpansion}>
      <Container>
        {selectedUser && (
          <Content>
            <HeaderWrapper>
              <BackButtonV2 label='ユーザー一覧へ' onClick={handleButtonClicked} />

              <PageHeaderV2
                title={toFullName(selectedUser)}
                rightComponent={<MoreHorizMenuButton menuButtons={subMenus} />}
              />
            </HeaderWrapper>
            <TabsWrapper>
              <MappedTabs<UserDetailFeature>
                tabs={tabProperties}
                selectedTabKey={selectedFeatureKey}
                onChange={setSelectedFeatureKey}
              />
              <TabContent>
                <TabDetailContent
                  base={base}
                  selectedUser={selectedUser}
                  signInUser={signInUser}
                  selectedUserId={selectedUserId}
                  initialize={initialize}
                />
              </TabContent>
            </TabsWrapper>
          </Content>
        )}
      </Container>
    </Component>
  );
});

UserV2DetailsScreen.displayName = 'UserV2DetailsScreen';
export default UserV2DetailsScreen;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  ${themeV2.mixins.v2.scrollbarInvisible};

  @supports (height: 1dvh) {
    height: calc(100dvh - ${HEADER_HEIGHT}px);
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  max-width: ${USER_CONTENT_MAX_WIDTH}px;
  min-width: ${USER_CONTENT_MIN_WIDTH}px;
  margin: ${themeV2.mixins.v2.spacing * 2}px auto;
  padding: 0 ${themeV2.mixins.v2.spacing * 3}px;
`;

const TabsWrapper = styled.div`
  width: 100%;
  flex: 1;
  max-width: ${USER_CONTENT_MAX_WIDTH}px;
  min-width: ${USER_CONTENT_MIN_WIDTH}px;
  margin: 0 auto;
  padding: 0 ${themeV2.mixins.v2.spacing * 3}px 0;
  display: flex;
  flex-direction: column;
`;

const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
