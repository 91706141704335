import {
  ButtonV2,
  DialogV2,
  InputDateWithLabelV2,
  InputWithLabelV2,
  LabelV2,
  ScreenLoaderV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  SAVE_USER_POINTS_FOR_ADMIN,
  SaveUserPointsForAdminRequest,
  SaveUserPointsForAdminResponse,
  User,
  UserPoint
} from '@atomica.co/irori';
import { Comment, Message, Point } from '@atomica.co/types';
import {
  EMPTY,
  ZERO,
  builder,
  isEmpty,
  isGreaterThan,
  isGreaterThanZero,
  noop,
  toEndOfDay,
  toLastDayOfMonth
} from '@atomica.co/utils';
import { format, isAfter } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { PointAction } from '../../../enums/point-enum';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';

interface P {
  isOpen: boolean;
  base: BaseDto;
  action: PointAction;
  selectedUser: User | undefined;
  signInUser: User;
  userPoint?: UserPoint;
  onSave?(): void;
  onClose(): void;
}

const PointModal: React.FC<P> = React.memo(props => {
  const { action, base, isOpen, selectedUser, signInUser, userPoint, onSave = noop, onClose } = props;

  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [inputPoint, setInputPoint] = useSafeState<Point>(unmountRef, ZERO);
  const [inputDate, setInputDate] = useSafeState<Date>(unmountRef, toLastDayOfMonth(new Date(), 0));
  const [inputComment, setInputComment] = useSafeState<Comment>(unmountRef, EMPTY);
  const [isValidPoint, setIsValidPoint] = useSafeState<boolean>(unmountRef, true);
  const [isValidDate, setIsValidDate] = useSafeState<boolean>(unmountRef, true);
  const [isValidComment, setIsValidComment] = useSafeState<boolean>(unmountRef, true);
  const [errorMessageForPoint, setErrorMessageForPoint] = useSafeState<Message>(unmountRef);
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);

  const isValid = useMemo<boolean>(
    () => isValidComment && isValidDate && isValidPoint,
    [isValidComment, isValidDate, isValidPoint]
  );
  const actionName = action === PointAction.GRANT ? '追加' : '削除';

  useEffect(() => setIsValidPoint(true), [inputPoint, setIsValidPoint]);
  useEffect(() => setIsValidDate(true), [inputDate, setIsValidDate]);
  useEffect(() => setIsValidComment(true), [inputComment, setIsValidComment]);

  const validate = useSafeCallback((): boolean => {
    const isCountingNumber = isGreaterThanZero(inputPoint);
    const isLessThenMax = !userPoint || isGreaterThan(userPoint.point, inputPoint - 1);
    const isValidDate = isAfter(inputDate, new Date());
    const isValidComment = !isEmpty(inputComment);
    if (!isCountingNumber) setErrorMessageForPoint('0以上の数字を入力してください');
    if (!isLessThenMax) setErrorMessageForPoint('利用可能ポイントを上回っています。');
    setIsValidPoint(isCountingNumber && isLessThenMax);
    setIsValidDate(isValidDate);
    setIsValidComment(isValidComment);
    return isCountingNumber && isLessThenMax && isValidDate && isValidComment;
  }, [
    inputPoint,
    inputDate,
    inputComment,
    setErrorMessageForPoint,
    setIsValidComment,
    setIsValidDate,
    setIsValidPoint
  ]);

  const initializeForm = useSafeCallback((): void => {
    setInputComment(EMPTY);
    setInputDate(toLastDayOfMonth(new Date(), 0));
    setInputPoint(ZERO);
    setIsValidPoint(true);
    setIsValidDate(true);
    setIsValidComment(true);
  }, [setInputComment, setInputDate, setInputPoint, setIsValidComment, setIsValidDate, setIsValidPoint, userPoint]);

  const handleSaveButtonClicked = useSafeCallback(async (): Promise<void> => {
    if (!validate()) return;
    setSaving(true);

    const point = action === PointAction.GRANT ? inputPoint : inputPoint * -1;

    const request = builder<SaveUserPointsForAdminRequest>()
      .baseId(base.baseId)
      .point(point)
      .expirationDate(userPoint && userPoint.expirationDate ? userPoint.expirationDate : toEndOfDay(inputDate)!)
      .comment(inputComment)
      .userId(selectedUser!.userId)
      .createdUserId(signInUser.userId)
      .build();
    await commonRequest<SaveUserPointsForAdminRequest, SaveUserPointsForAdminResponse>(
      SAVE_USER_POINTS_FOR_ADMIN,
      request
    );

    initializeForm();
    onSave();
    setSaving(false);
  }, [
    action,
    base,
    commonRequest,
    initializeForm,
    inputComment,
    inputDate,
    inputPoint,
    onSave,
    selectedUser,
    setSaving,
    signInUser,
    validate
  ]);

  const handleModalClosed = useSafeCallback((): void => {
    initializeForm();
    onClose();
  }, [onClose, initializeForm]);

  return (
    <DialogV2
      width={540}
      isOpen={isOpen}
      headerLabel={`ポイントの${actionName}`}
      buttonsOnTheRight={[
        <ButtonV2 disabled={saving} key='cancel' label='キャンセル' onClick={handleModalClosed} />,
        <StyledButtonV2
          action={action}
          disabled={saving || !isValid}
          key='save'
          type='primary'
          label={actionName}
          onClick={handleSaveButtonClicked}
        />
      ]}
      onClose={handleModalClosed}
    >
      <DialogContent>
        {action !== PointAction.GRANT && userPoint && (
          <UsablePointWrapper>
            <LabelWrapper>
              <LabelV2 text='利用可能ポイント' />
              <StyledLabel fontSize='medium' text={`（${format(userPoint.expirationDate!, 'yyyy/MM/dd')}まで）`} />
            </LabelWrapper>
            <UsablePoint>{userPoint.point!.toLocaleString()} ポイント</UsablePoint>
          </UsablePointWrapper>
        )}
        <InputWithLabelV2
          required
          align='right'
          inputWidth={100}
          text={`${actionName}するポイント数`}
          value={inputPoint || EMPTY}
          onChange={setInputPoint}
          inputType='number'
          unit='ポイント'
          errorMessage={!isValidPoint ? errorMessageForPoint : EMPTY}
        />
        {action === PointAction.GRANT && (
          <InputDateWithLabelV2
            required
            error={!isValidDate}
            errorMessage={!isValidDate ? '過去の日付が入力されています' : EMPTY}
            text='ポイントの有効期限'
            remarks='指定日の23:59まで有効'
            value={inputDate}
            onChange={setInputDate}
          />
        )}
        <InputWithLabelV2
          required
          multiline
          text={`${actionName}の理由`}
          value={inputComment}
          onChange={setInputComment}
          errorMessage={!isValidComment ? `${actionName}の理由を入力してください。` : EMPTY}
        />
      </DialogContent>
      <ScreenLoaderV2 loading={saving} />
    </DialogV2>
  );
});

PointModal.displayName = 'PointModal';
export default PointModal;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  background: ${themeV2.mixins.v2.color.background.offWhite};
`;
const LabelWrapper = styled.div`
  display: flex;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const StyledLabel = styled(LabelV2)`
  ${themeV2.mixins.v2.typography.body.small};
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const UsablePointWrapper = styled.div``;
const UsablePoint = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
`;

const StyledButtonV2 = styled(ButtonV2)<{ action: PointAction }>`
  ${({ action }) =>
    action === PointAction.SUBTRACT &&
    `
  background-color: ${themeV2.mixins.v2.color.status.error} !important;
  border: 2px solid ${themeV2.mixins.v2.color.status.error} !important;
  `}
`;
