import { MasterSearchOption, themeV3 } from '@atomica.co/components';
import {
  Space,
  SpaceAvailability,
  SpaceId,
  SpaceReservation,
  SpaceReservationId,
  SpaceUsages,
  SpaceUsagesForTime
} from '@atomica.co/irori';
import { Count, Option, Time, TimeZone } from '@atomica.co/types';
import { EMPTY, builder, hasLength, isZero, toBeginningOfDay } from '@atomica.co/utils';
import { getWeekOfMonth, isTomorrow } from 'date-fns';
import {
  DAYS_OF_WEEK_IDX,
  DAYS_OF_WEEK_LABELS,
  DEFAULT_DATE_TIME,
  DEFAULT_START_UNIT,
  END_OF_DAY,
  THIRTY_MINUTES,
  ZERO_MINUTES
} from '../constants/space-const';
import { AvailableTime, Labels } from '../models/common-model';
import { isNotFoundIndex } from '../utils/common-util';
import { toZonedDate, toZonedTimeStr } from '../utils/date-util';
import { getSpaceAvailabilityAtOfTargetDate } from '../utils/space-utils';

const DISABLED_BUSINESS_HOUR: BusinessHour = {
  daysOfWeek: DAYS_OF_WEEK_IDX,
  startTime: DEFAULT_DATE_TIME,
  endTime: DEFAULT_DATE_TIME
};

interface AvailableTimeRow {
  time: string;
  weekdayIdx: number[];
}

interface BusinessHour {
  daysOfWeek: Array<number>;
  startTime: string | undefined;
  endTime: string | undefined;
}

export interface SpaceResourceRow {
  id: SpaceId;
  order: number;
  title: string;
  businessHours: BusinessHour[];
}

export interface TimelineEventRow {
  resourceId: SpaceId;
  id: SpaceReservationId;
  title: string;
  color: string;
  textColor?: string;
  start: Date | undefined;
  end: Date | undefined;
}

export interface SpaceReservationDates {
  availableSpaceDates: SpaceUsages;
  unavailableSpaceDates: SpaceUsages;
}

const isAfter = (baseTime: Date | string | undefined, targetTime: Date | string | undefined): boolean => {
  if (!baseTime || !targetTime) return false;
  const baseHour = typeof baseTime === 'string' ? Number(baseTime.substring(0, 2)) : baseTime.getHours();
  const baseMinutes = typeof baseTime === 'string' ? Number(baseTime.substring(3, 4)) : baseTime.getMinutes();
  const targetHour = typeof targetTime === 'string' ? Number(targetTime.substring(0, 2)) : targetTime.getHours();
  const targetMinutes = typeof targetTime === 'string' ? Number(targetTime.substring(3, 4)) : targetTime.getMinutes();

  return baseHour < targetHour || (baseHour === targetHour && baseMinutes <= targetMinutes);
};

export const convertToAvailableTimes = (
  availabilities: { startAt: Time; endAt: Time; dayOfWeek: Count }[] | undefined,
  timezone: TimeZone
): AvailableTime[] => {
  if (!availabilities) return [];
  const businessDay = availabilities.reduce((prev: AvailableTimeRow[], current) => {
    const zonedStartAt = toZonedTimeStr(current.startAt, timezone);
    const zonedEndAt = toZonedTimeStr(current.endAt, timezone);
    const startAt = `${Number(zonedStartAt.slice(0, 2))}${zonedStartAt.slice(2, 5)}`;
    let endAtHours = Number(zonedEndAt.slice(0, 2));
    const endAtMinutes = zonedEndAt.slice(2, 5);
    if (endAtHours === 0 && endAtMinutes === ':00') {
      endAtHours = 24;
    }
    const endAt = `${endAtHours}${endAtMinutes}`;

    const businessTime = `${startAt} - ${endAt}`;
    const dayOfWeek = current.dayOfWeek;

    if (isZero(prev.length)) {
      prev.push({ time: businessTime, weekdayIdx: [dayOfWeek] });
      return prev;
    }

    const timeExistIdx = prev.findIndex(row => businessTime.includes(row.time));
    if (timeExistIdx >= 0) {
      prev[timeExistIdx].weekdayIdx.push(dayOfWeek);
    } else {
      prev.push({ time: businessTime, weekdayIdx: [dayOfWeek] });
    }

    prev.forEach(row => row.weekdayIdx.sort((a, b) => a - b));

    return prev;
  }, []);

  const rows = businessDay
    .sort((a, b) => a.weekdayIdx[0] - b.weekdayIdx[0])
    .map((row: AvailableTimeRow) => {
      let aggregateFlag = false;
      return {
        time: row.time,
        weekday: row.weekdayIdx.reduce((prev: string, curr: number, idx: number, base: number[]) => {
          const weekday = DAYS_OF_WEEK_LABELS[curr];
          if (!prev) {
            prev = weekday;
            aggregateFlag = false;
            return prev;
          }

          if (curr === base[idx - 1] + 1) {
            prev = aggregateFlag ? `${prev.slice(0, -1)}${weekday}` : `${prev}～${weekday}`;
            aggregateFlag = true;
          } else {
            prev += ` ${weekday}`;
            aggregateFlag = false;
          }

          return prev;
        }, EMPTY)
      };
    });

  return rows;
};

export const convertToTimelineResources = (
  spaces: Space[],
  selectedSpaceIds: SpaceId[],
  selectedDate: Date,
  timezone: TimeZone
): SpaceResourceRow[] => {
  if (!spaces || !selectedDate) return [];
  const newSelectedDate = new Date(selectedDate);
  const targetWeekday = newSelectedDate.getDay();
  const targetWeek = Math.floor((newSelectedDate.getDate() - newSelectedDate.getDay() + 12) / 7).toString();

  const resources = spaces.reduce((prev: SpaceResourceRow[], current: Space) => {
    if (!selectedSpaceIds?.includes(current.spaceId)) return prev;
    const availabilities =
      current.availabilities?.filter((availability: SpaceAvailability) => {
        return (
          (isZero(availability.weeks) || !isNotFoundIndex(availability.weeks.toString().indexOf(targetWeek))) &&
          availability.dayOfWeek === targetWeekday
        );
      }) ?? [];

    if (!hasLength(availabilities)) {
      prev.push({
        id: current.spaceId,
        order: current.order,
        title: current.spaceName,
        businessHours: [DISABLED_BUSINESS_HOUR]
      });
      return prev;
    }

    const today = toBeginningOfDay(new Date()) as Date;
    const now = new Date();

    const isPast = newSelectedDate < today;
    const isFuture = today < newSelectedDate;

    const businessHours: BusinessHour[] = [];

    for (const availability of availabilities) {
      const startAt = !isPast ? toZonedTimeStr(availability.startAt, timezone) : DEFAULT_DATE_TIME;
      const endAt = !isPast ? toZonedTimeStr(availability.endAt, timezone) : DEFAULT_DATE_TIME;
      const isAllDays = availability.startAt === availability.endAt;
      const isReservableMultipleDays = isAfter(endAt, startAt) && isTomorrow(toZonedDate(availability.endAt, timezone));
      const startTime =
        !isFuture && isAfter(startAt, now)
          ? `${now.getHours()}:${now.getMinutes() > DEFAULT_START_UNIT ? THIRTY_MINUTES : ZERO_MINUTES}`
          : startAt;

      businessHours.push({
        daysOfWeek: DAYS_OF_WEEK_IDX,
        startTime: isAllDays ? DEFAULT_DATE_TIME : startTime,
        endTime: isAllDays || isReservableMultipleDays ? END_OF_DAY : endAt
      });
    }

    prev.push({ id: current.spaceId, order: current.order, title: current.spaceName, businessHours });

    return prev;
  }, []);

  return resources;
};
export const convertToTimelineEvents = (dates: SpaceReservationDates): TimelineEventRow[] => {
  const availableEvents = Object.entries(dates.availableSpaceDates)
    .map(([spaceId, usage]) => {
      return usage.map(u => {
        return builder<TimelineEventRow>()
          .resourceId(spaceId)
          .id(u.spaceReservationId)
          .title('自分の予定')
          .color(themeV3.mixins.v3.color.container.primary.default)
          .start(u.start)
          .end(u.end)
          .build();
      });
    })
    .flat();

  const unavailableEvents = Object.entries(dates.unavailableSpaceDates)
    .map(([spaceId, usage]) => {
      return usage.map(u => {
        return builder<TimelineEventRow>()
          .resourceId(spaceId)
          .id(u.spaceReservationId)
          .title('予約できません')
          .color(themeV3.mixins.v3.color.container.neutral.high)
          .start(u.start)
          .end(u.end)
          .build();
      });
    })
    .flat();

  return [...availableEvents, ...unavailableEvents];
};

export const convertToOption = (spaces: Space[]): Option[] => {
  if (!hasLength(spaces)) return [];
  return spaces.map(space => space.spaceId);
};

export const convertToDisabledOption = (
  spaces: Space[],
  spaceUsages: SpaceUsagesForTime | undefined,
  selectedDate: Date | undefined,
  reservationStartAt: Date | undefined,
  reservationEndAt: Date | undefined,
  spaceReservationIdToUpdate: SpaceReservationId | undefined,
  timezone: TimeZone
): Option[] => {
  if (!hasLength(spaces) || !selectedDate || !reservationStartAt || !reservationEndAt) return [];

  return spaces.reduce((prev: Option[], current: Space) => {
    const reserveMinutes = Math.floor((reservationEndAt.getTime() - reservationStartAt.getTime()) / (1000 * 60));
    if (current.minimumReservation > reserveMinutes) {
      prev.push(current.spaceId);
      return prev;
    }

    const { spaceStartAt, spaceEndAt } = getSpaceAvailabilityAtOfTargetDate(
      selectedDate,
      timezone,
      current.availabilities
    );
    if (!spaceStartAt || !spaceEndAt) {
      prev.push(current.spaceId);
      return prev;
    }

    if (!isAfter(spaceStartAt, reservationStartAt)) {
      prev.push(current.spaceId);
      return prev;
    }

    if (!isAfter(reservationEndAt, spaceEndAt)) {
      prev.push(current.spaceId);
      return prev;
    }

    const isKeyExisting = Object.keys(spaceUsages!).includes(current.spaceId);
    const reservations = isKeyExisting
      ? spaceUsages?.[current.spaceId].map(usage =>
          builder<SpaceReservation>()
            .startAt(usage.start)
            .spaceReservationId(usage.spaceReservationId)
            .endAt(usage.end)
            .build()
        )
      : [];

    const hasAlreadyReserved = reservations?.find(reservation => {
      const reservedStartAt = reservation.startAt;
      const reservedEndAt = reservation.endAt;
      return spaceReservationIdToUpdate === reservation.spaceReservationId
        ? false
        : reservedStartAt &&
            reservedEndAt &&
            ((reservedStartAt <= reservationStartAt && reservationStartAt < reservedEndAt) ||
              (reservedStartAt < reservationEndAt && reservationEndAt <= reservedEndAt) ||
              (reservedStartAt < reservationStartAt && reservationEndAt < reservedEndAt));
    });

    if (hasAlreadyReserved) {
      prev.push(current.spaceId);
      return prev;
    }

    return prev;
  }, []);
};

export const convertToLabel = (spaces: Space[]): Labels => {
  if (!hasLength(spaces)) return {};
  return spaces.reduce((prev, current) => {
    prev[current.spaceId] = current.spaceName;
    return prev;
  }, {});
};

const toMasterSearchOption = (space: Space): MasterSearchOption => {
  return { value: space.spaceId, label: space.spaceName };
};

export const toMasterSearchOptions = (spaces: Space[]): MasterSearchOption[] => {
  return spaces.map(toMasterSearchOption);
};

export type AvailabilityTimes = {
  startTime: Time;
  endTime: Time;
};

export const getStartAndEndTimesForDay = (
  timezone: TimeZone,
  date: Date,
  availabilities: SpaceAvailability[]
): AvailabilityTimes | undefined => {
  const dayOfWeek = date.getDay();
  const availability = availabilities.find(
    availability =>
      availability.dayOfWeek === dayOfWeek && (availability.weeks === 0 || availability.weeks === getWeekOfMonth(date))
  );
  if (!availability) return;

  let endTime = toZonedTimeStr(availability.endAt, timezone, 'HH:mm');
  if (endTime === '00:00') {
    endTime = '24:00';
  }

  return {
    startTime: toZonedTimeStr(availability.startAt, timezone, 'HH:mm'),
    endTime
  };
};
