import {
  HeaderV2,
  LeftPanelV2,
  ParentMenu,
  Screen,
  customMedia,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseDto, BaseMenu, MenuType, User } from '@atomica.co/irori';
import { Code, Label, URL } from '@atomica.co/types';
import { EMPTY, hasLength } from '@atomica.co/utils';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { AccountTab } from '../../enums/account-enum';
import usePath from '../../redux/hooks/usePath';
import { Path } from '../../router/Routes';
import logo from './../../assets/logo/logo_knot_place.png';
import ConsumerSwitcher from './ConsumerSwitcher';

import { BottomNavigation, BottomNavigationAction, SvgIcon, Typography } from '@material-ui/core';
import { BOTTOM_NAVIGATOR_HEIGHT } from '../../constants/account-const';
import { HEADER_HEIGHT } from '../../constants/common-const';
import { toIcon } from '../../converters/icon-converter';
import { fromAccountTab } from '../../converters/path-converter';
import { getBaseConsumerHomePathAndName } from '../../utils/path-util';

interface NavigationMenuItem {
  id: Code;
  icon?: React.ReactElement;
  label?: Label;
}

interface P {
  isDrawerOpen?: boolean;
  base: BaseDto;
  user: User | undefined;
}

const ConsumerScreen: React.FC<P> = React.memo(props => {
  const { isDrawerOpen = false, base, user } = props;
  const unmountRef = useUnmountRef();
  const { openBasePath, path } = usePath();

  const [menus, selectedMenuDefCode] = useMemo<[BaseMenu[], Code]>(() => {
    const consumerMenus = base.menus?.filter(menu => menu.menuType === MenuType.CONSUMER) ?? [];
    const selectedMenu = consumerMenus.find(
      menu => menu.menuDef?.menuDefCode && fromAccountTab(menu.menuDef.menuDefCode) === path
    );
    return [consumerMenus, selectedMenu?.menuDef?.menuDefCode ?? EMPTY];
  }, [base, path]);

  const [selectedTab, setSelectedTab] = useSafeState<Code>(unmountRef, selectedMenuDefCode);

  const bottomNavigationMenus = useMemo<NavigationMenuItem[]>(() => {
    if (!hasLength(menus)) return [];
    return menus.map(menu => ({
      icon: toIcon(menu.menuDef!.iconCode),
      id: menu.menuDef!.menuDefCode,
      label: menu.menuLabel || menu.menuDef?.menuDefName
    }));
  }, [menus]);

  const handleNavigationMenuClick = useSafeCallback(
    (path: Path): void => {
      openBasePath(path);
    },
    [openBasePath]
  );
  const handleNavigationMenuClickForExternalUrl = useSafeCallback((externalUrl: URL): void => {
    window.open(externalUrl, '_blank');
  }, []);

  const handleBottomNavigatorClicked = useSafeCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_: React.ChangeEvent<any>, menuDefCode: Code): void => {
      const menu = menus.find(menu => menu.menuDef?.menuDefCode === menuDefCode);
      if (!menu) return;
      if (menu.externalUrl) {
        handleNavigationMenuClickForExternalUrl(menu.externalUrl);
        return;
      }
      setSelectedTab(menuDefCode);
      openBasePath(fromAccountTab(menuDefCode));
    },
    [menus, setSelectedTab, openBasePath, handleNavigationMenuClickForExternalUrl]
  );

  const leftNavigationMenus = useMemo<ParentMenu[]>(() => {
    if (!hasLength(menus)) return [];
    return menus.map(menu => {
      const menuPath = fromAccountTab(menu.menuDef!.menuDefCode);
      const externalUrl = menu.externalUrl;

      return {
        icon: toIcon(menu.menuDef!.iconCode),
        mainItem: {
          label: menu.menuLabel || menu.menuDef?.menuDefName || EMPTY,
          isSelected: menuPath === path,
          onClick: externalUrl
            ? () => handleNavigationMenuClickForExternalUrl(externalUrl)
            : () => handleNavigationMenuClick(menuPath)
        }
      };
    });
  }, [handleNavigationMenuClick, handleNavigationMenuClickForExternalUrl, menus, path]);

  const openHome = useSafeCallback((): void => {
    const [path] = getBaseConsumerHomePathAndName(base);
    openBasePath(path);
  }, [openBasePath]);

  const openAccountProfileScreen = useSafeCallback((): void => {
    setSelectedTab(AccountTab.MY_PAGE);
    openBasePath(Path.ACCOUNT_PROFILE_V2);
  }, [openBasePath, setSelectedTab]);

  useEffect(() => {
    switch (path) {
      case Path.ACCOUNT_HOME:
        setSelectedTab(AccountTab.HOME);
        return;
      case Path.ACCOUNT_EVENTS:
        setSelectedTab(AccountTab.EVENTS);
        return;
      case Path.RESERVE_SPACE:
        setSelectedTab(AccountTab.RESERVE);
        return;
      case Path.ACCOUNT_SHOT:
        setSelectedTab(AccountTab.SHOT);
        return;
      case Path.MEMBERS:
        setSelectedTab(AccountTab.MEMBERS);
    }
  }, [path, setSelectedTab]);

  return (
    <Screen className='consumer-screen'>
      <Container>
        <CssBaseline />
        <HeaderV2
          logo={base.logoURL || logo}
          icon={user?.photoURL}
          onClickLogo={openHome}
          onClickIcon={openAccountProfileScreen}
        />
        <Content>
          {isDrawerOpen && (
            <LeftPanelWrapper>
              <LeftPanelV2 menus={leftNavigationMenus} />
            </LeftPanelWrapper>
          )}

          {!!base && !!path && (
            <ConsumerSwitcherWrapper isDrawerOpen={isDrawerOpen}>
              <ConsumerSwitcher base={base} path={path} user={user} />
            </ConsumerSwitcherWrapper>
          )}
        </Content>
        {isDrawerOpen && (
          <WrapperBottomNavigation>
            <CustomBottomNavigation value={selectedTab} onChange={handleBottomNavigatorClicked}>
              {bottomNavigationMenus.map((tab, idx) => (
                <CustomBottomNavigationAction
                  key={idx}
                  value={tab.id}
                  icon={<SvgIcon>{tab.icon}</SvgIcon>}
                  label={<TabLabel>{tab.label}</TabLabel>}
                  showLabel={true}
                />
              ))}
            </CustomBottomNavigation>
          </WrapperBottomNavigation>
        )}
      </Container>
    </Screen>
  );
});

ConsumerScreen.displayName = 'ConsumerScreen';
export default ConsumerScreen;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;

  @supports (height: 1dvh) {
    min-height: 100dvh;
  }
`;

const Content = styled.div`
  width: 100%;
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  display: flex;
`;

const ConsumerSwitcherWrapper = styled.div<{ isDrawerOpen: boolean }>`
  flex: 1;
  max-width: 100%;
  height: calc(
    100vh - ${HEADER_HEIGHT}px - ${({ isDrawerOpen }) => (isDrawerOpen ? BOTTOM_NAVIGATOR_HEIGHT - 8 : 0)}px
  );

  @supports (height: 1dvh) {
    height: calc(
      100dvh - ${HEADER_HEIGHT}px - ${({ isDrawerOpen }) => (isDrawerOpen ? BOTTOM_NAVIGATOR_HEIGHT - 8 : 0)}px
    );
  }

  ${customMedia.greaterThan('tablet')`
    height: calc(100vh - ${HEADER_HEIGHT}px);

    @supports (height: 1dvh) {
      height: calc(100dvh - ${HEADER_HEIGHT}px);
    }
  `};
`;

const LeftPanelWrapper = styled.div`
  height: calc(100vh - ${HEADER_HEIGHT}px);
  position: relative;
  display: none;

  @supports (height: 1dvh) {
    height: calc(100dvh - ${HEADER_HEIGHT}px);
  }

  ${customMedia.greaterThan('tablet')`
    display: block;
  `};
`;

const WrapperBottomNavigation = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 100;
  box-shadow: ${themeV2.mixins.v2.shadow.elevation5};
`;

const CustomBottomNavigation = styled(BottomNavigation)`
  height: ${BOTTOM_NAVIGATOR_HEIGHT}px;
  ${customMedia.greaterThan('tablet')`
    display: none;
  `}
  ${customMedia.lessThan('tiny')`
    height: ${BOTTOM_NAVIGATOR_HEIGHT - themeV2.mixins.v2.spacing}px
  `}
`;

const CustomBottomNavigationAction = styled(BottomNavigationAction)`
  border-radius: 50vh;
  padding-top: 6px !important;

  @supports (height: 1dvh) {
    border-radius: 50dvh;
  }
`;

const TabLabel = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.small};
  font-weight: bold;
`;
