import {
  ButtonV2,
  ScreenLoaderV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { Code, Email, Id, Index, Key, Password } from '@atomica.co/types';
import { EMPTY, builder, isEmpty } from '@atomica.co/utils';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { RegisterAccountStepEnum } from '../../../enums/account-enum';
import {
  ACTIVATE_WORKHUB_ACCOUNT,
  ActivateWorkhubAccountRequest,
  ActivateWorkhubAccountResponse,
  BaseDto
} from '@atomica.co/irori';
import CommonRequest from '../../../requests/common-request';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

const REGEX_FOR_NUMBER_OR_NULL = /^(?:\d+(?:\.\d*)?|\.\d+)?$/;

interface P {
  base: BaseDto;
  email: Email;
  password: Password;
  signupSessionId: Id;
  onChange: (index: Index) => void;
}

const handleInputKeyDown = (
  key: Key,
  setCode: React.Dispatch<React.SetStateAction<Code>>,
  setIsFocused: (focused: boolean) => void
): void => {
  if (key !== 'Backspace') return;
  setCode(prevCode => {
    if (isEmpty(prevCode)) setIsFocused(true);
    return EMPTY;
  });
};

const RegisterAccountAuthorization: React.FC<P> = React.memo(props => {
  const { base, email, password, signupSessionId, onChange } = props;

  const unmountRef = useUnmountRef();
  const [firstCode, setFirstCode] = useSafeState<Code>(unmountRef, EMPTY);
  const [secondCode, setSecondCode] = useSafeState<Code>(unmountRef, EMPTY);
  const [thirdCode, setThirdCode] = useSafeState<Code>(unmountRef, EMPTY);
  const [fourthCode, setFourthCode] = useSafeState<Code>(unmountRef, EMPTY);

  const [isFirstCodeFocused, setIsFirstCodeFocused] = useSafeState<boolean>(unmountRef, true);
  const [isSecondCodeFocused, setIsSecondCodeFocused] = useSafeState<boolean>(unmountRef, false);
  const [isThirdCodeFocused, setIsThirdCodeFocused] = useSafeState<boolean>(unmountRef, false);
  const [isFourthCodeFocused, setIsFourthCodeFocused] = useSafeState<boolean>(unmountRef, false);

  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [isError, setIsError] = useSafeState<boolean>(unmountRef, false);

  const firstCodeRef = useRef<HTMLInputElement>(null);
  const secondCodeRef = useRef<HTMLInputElement>(null);
  const thirdCodeRef = useRef<HTMLInputElement>(null);
  const fourthCodeRef = useRef<HTMLInputElement>(null);

  const handleCodeChanged = useSafeCallback(
    (
      code: Code,
      setCode: (code: Code) => void,
      setIsCurrentFocused: (focused: boolean) => void,
      setIsNextFocused?: (focused: boolean) => void
    ): void => {
      if (!REGEX_FOR_NUMBER_OR_NULL.test(code) || code.length > 1) return;
      setCode(code);
      setIsError(false);
      if (isEmpty(code)) return;
      setIsCurrentFocused(false);
      if (setIsNextFocused) setIsNextFocused(true);
    },
    [isError]
  );

  const activateAccount = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const request = builder<ActivateWorkhubAccountRequest>()
      .base(base)
      .email(email)
      .authorizationCode(firstCode + secondCode + thirdCode + fourthCode)
      .password(password)
      .signupSessionId(signupSessionId)
      .build();
    const response = await CommonRequest.call<ActivateWorkhubAccountRequest, ActivateWorkhubAccountResponse>(
      ACTIVATE_WORKHUB_ACCOUNT,
      request
    );

    if (!response) {
      setIsError(true);
      setIsLoaderShown(false);
      return;
    }
    setIsLoaderShown(false);
    onChange(RegisterAccountStepEnum.COMPLETION);
  }, [
    base,
    email,
    firstCode,
    fourthCode,
    onChange,
    password,
    secondCode,
    setIsError,
    setIsLoaderShown,
    signupSessionId,
    thirdCode
  ]);

  useEffect(() => {
    if (isEmpty(firstCode) || isEmpty(secondCode) || isEmpty(thirdCode) || isEmpty(fourthCode)) return;
    activateAccount();
  }, [activateAccount, firstCode, fourthCode, secondCode, thirdCode]);

  useEffect(() => {
    if (!firstCodeRef.current) return;
    if (isFirstCodeFocused) firstCodeRef.current.focus();
  }, [isFirstCodeFocused]);

  useEffect(() => {
    if (!secondCodeRef.current) return;
    if (isSecondCodeFocused) secondCodeRef.current.focus();
  }, [isSecondCodeFocused]);

  useEffect(() => {
    if (!thirdCodeRef.current) return;
    if (isThirdCodeFocused) thirdCodeRef.current.focus();
  }, [isThirdCodeFocused]);

  useEffect(() => {
    if (!fourthCodeRef.current) return;
    if (isFourthCodeFocused) fourthCodeRef.current.focus();
  }, [isFourthCodeFocused]);

  return (
    <Container>
      <Content>
        <Message>{email} 宛に送信した4桁の認証コードを入力してください。</Message>
        {isError && (
          <ErrorMessageCard>
            <WarningRoundedIcon />
            認証コードが間違っています。
          </ErrorMessageCard>
        )}
        <TextFieldWrapper>
          <Input
            ref={firstCodeRef}
            type='number'
            inputMode='numeric'
            value={firstCode}
            focused={isFirstCodeFocused}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleCodeChanged(e.target.value, setFirstCode, setIsFirstCodeFocused, setIsSecondCodeFocused)
            }
            onFocus={() => setIsFirstCodeFocused(true)}
            onBlur={() => setIsFirstCodeFocused(false)}
          />

          <Input
            ref={secondCodeRef}
            type='number'
            inputMode='numeric'
            value={secondCode}
            focused={isSecondCodeFocused}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleCodeChanged(e.target.value, setSecondCode, setIsSecondCodeFocused, setIsThirdCodeFocused)
            }
            onFocus={() => setIsSecondCodeFocused(true)}
            onBlur={() => setIsSecondCodeFocused(false)}
            onKeyDown={(e: React.KeyboardEvent<HTMLElement>) =>
              handleInputKeyDown(e.key, setSecondCode, setIsFirstCodeFocused)
            }
          />
          <Input
            ref={thirdCodeRef}
            type='number'
            inputMode='numeric'
            value={thirdCode}
            focused={isThirdCodeFocused}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleCodeChanged(e.target.value, setThirdCode, setIsThirdCodeFocused, setIsFourthCodeFocused)
            }
            onFocus={() => setIsThirdCodeFocused(true)}
            onBlur={() => setIsThirdCodeFocused(false)}
            onKeyDown={(e: React.KeyboardEvent<HTMLElement>) =>
              handleInputKeyDown(e.key, setThirdCode, setIsSecondCodeFocused)
            }
          />
          <Input
            ref={fourthCodeRef}
            type='number'
            inputMode='numeric'
            value={fourthCode}
            focused={isFourthCodeFocused}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleCodeChanged(e.target.value, setFourthCode, setIsFourthCodeFocused)
            }
            onFocus={() => setIsFourthCodeFocused(true)}
            onBlur={() => setIsFourthCodeFocused(false)}
            onKeyDown={(e: React.KeyboardEvent<HTMLElement>) =>
              handleInputKeyDown(e.key, setFourthCode, setIsThirdCodeFocused)
            }
          />
        </TextFieldWrapper>
        <ButtonV2
          isFullWidth
          size='large'
          label='アカウント情報の入力に戻る'
          onClick={() => onChange(RegisterAccountStepEnum.INPUT)}
        />
        <ScreenLoaderV2 loading={isLoaderShown} />
      </Content>
    </Container>
  );
});

RegisterAccountAuthorization.displayName = 'RegisterAccountAuthorization';
export default RegisterAccountAuthorization;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const Message = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  color: ${themeV2.mixins.v2.color.font.black};
  padding-bottom: ${themeV2.mixins.v2.spacing}px;
`;

const ErrorMessageCard = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing / 2}px;
  padding: ${themeV2.mixins.v2.spacing * 1.5}px;
  margin-bottom: ${themeV2.mixins.v2.spacing}px;
  border-radius: 8px;
  color: ${themeV2.mixins.v2.color.status.error};
  background: ${themeV2.mixins.v2.color.background.pinkPale};
`;

const TextFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const Input = styled.input<{ focused: boolean }>`
  ${themeV2.mixins.v2.typography.title.large};
  width: 48px;
  height: 48px;
  text-align: center;
  outline: none;
  border-radius: 8px;
  ${({ focused }) =>
    `border: 2px solid ${
      focused ? themeV2.mixins.v2.color.border.pink : themeV2.mixins.v2.color.border.gray
    } !important;`}

  &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;
