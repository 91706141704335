import {
  ButtonV2,
  CircularLoader,
  ColWidth,
  Header,
  MoreHorizMenuForTable,
  SearchListV2,
  StatusV2,
  TabProperty,
  TabsV3,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  DELETE_EVENT_SCHEDULE_V2_FOR_ADMIN,
  DeleteEventScheduleV2ForAdminRequest,
  DeleteEventScheduleV2ForAdminResponse,
  EVENT_ID_V2,
  EventIdV2,
  EventScheduleV2,
  MessageType,
  RecurrenceTarget,
  RecurrenceTypeEnum,
  SEARCH_EVENT_SCHEDULES_V2_FOR_ADMIN,
  SearchEventSchedulesV2ForAdminRequest,
  SearchEventSchedulesV2ForAdminResponse
} from '@atomica.co/irori';
import { Count, DateStr, Index, Name, Offset, Word } from '@atomica.co/types';
import {
  EMPTY,
  Language,
  ONE,
  ZERO,
  builder,
  embedIdInPath,
  hasLength,
  isGreaterThanZero,
  toDateTimeDurationStr
} from '@atomica.co/utils';

import AddIcon from '@material-ui/icons/Add';
import DateRangeIcon from '@material-ui/icons/DateRange';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { messageToLabel } from '../../../converters/message-v2-converter';
import { EventScheduleSearchOption, EventScheduleTabEnum } from '../../../enums/event-v2-enum';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import usePath from '../../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../../router/Routes';
import { MESSAGE_SENDING_STATUS } from '../../../statuses/message-status';
import { EVENT_SCHEDULE_LIST_TAB_LABELS } from '../../../texts/event-text';
import DeleteEventScheduleModal from '../modal/EditOrDeleteEventScheduleModal';

const COLUMN_WIDTH: ColWidth = { name: 'auto', date: 'auto', entryForm: 150, remind: 120 };

const HEADER: Header = {
  name: { label: 'スケジュール名' },
  date: { label: '日時' },
  entryForm: { label: '参加申し込みフォーム', align: 'center' },
  remind: { label: 'リマインド', align: 'center' }
};

interface EventScheduleRow {
  id: EventIdV2;
  name: Name;
  date: DateStr;
  entryForm: JSX.Element;
  remind: JSX.Element;
}

interface P {
  base: BaseDto;
  eventId: EventIdV2;
}

const statusComponent = (eventScheduleV2: EventScheduleV2, messageType: MessageType): JSX.Element => {
  const message = eventScheduleV2.messages?.find(message => message.messageType === messageType);
  return (
    <StatusWrapper>
      <StatusV2
        label={messageToLabel(message, messageType)}
        variant='normal'
        status={message ? MESSAGE_SENDING_STATUS[message.status] : 'default'}
      />
    </StatusWrapper>
  );
};

const tabProperties = Object.values(EVENT_SCHEDULE_LIST_TAB_LABELS).map<TabProperty>(label => ({
  label,
  disabled: false
}));

const EventScheduleList: React.FC<P> = React.memo(props => {
  const { base, eventId } = props;
  const { params, openPath } = usePath();
  const unmountRef = useUnmountRef();
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, EMPTY);
  const [eventSchedules, setEventSchedules] = useSafeState<EventScheduleV2[]>(unmountRef, []);
  const [rows, setRows] = useSafeState<EventScheduleRow[]>(unmountRef, []);
  const [offset, setOffset] = useSafeState<Offset>(unmountRef, EventScheduleSearchOption.OFFSET);
  const [totalCount, setTotalCount] = useSafeState<Count>(unmountRef, ZERO);
  const [selectedTabIdx, setSelectedTabIdx] = useSafeState<EventScheduleTabEnum>(
    unmountRef,
    EventScheduleTabEnum.SCHEDULED
  );
  const [isDeleteEventScheduleModalOpen, setIsDeleteEventScheduleModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [selectedDeleteEventSchedule, setSelectedDeleteEventSchedule] = useSafeState<EventScheduleV2>(unmountRef);
  const [hasSchedules, setHasSchedules] = useSafeState<boolean>(unmountRef, false);
  const [isInitialized, setIsInitialized] = useSafeState<boolean>(unmountRef, false);
  const { commonRequest } = useCommonRequest();

  const isHeldTab = useMemo<boolean>(() => selectedTabIdx === EventScheduleTabEnum.HELD, [selectedTabIdx]);

  const searchAllSchedules = useSafeCallback(async () => {
    const allScheduleRequest = builder<SearchEventSchedulesV2ForAdminRequest>()
      .baseId(base.baseId)
      .eventIdV2(eventId)
      .limit(EventScheduleSearchOption.LIMIT)
      .offset(offset)
      .word(EMPTY)
      .build();
    const { totalCount: allScheduleTotalCount } = await commonRequest<
      SearchEventSchedulesV2ForAdminRequest,
      SearchEventSchedulesV2ForAdminResponse
    >(SEARCH_EVENT_SCHEDULES_V2_FOR_ADMIN, allScheduleRequest);
    setHasSchedules(isGreaterThanZero(allScheduleTotalCount));
  }, [base, setHasSchedules, setIsInitialized]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    if (!isInitialized) {
      await searchAllSchedules();
      setIsInitialized(true);
    }
    const request = builder<SearchEventSchedulesV2ForAdminRequest>()
      .baseId(base.baseId)
      .eventIdV2(eventId)
      .limit(EventScheduleSearchOption.LIMIT)
      .offset(offset)
      .word(searchingWord)
      .isHeld(selectedTabIdx === EventScheduleTabEnum.HELD)
      .build();
    const { eventScheduleV2, totalCount } = await commonRequest<
      SearchEventSchedulesV2ForAdminRequest,
      SearchEventSchedulesV2ForAdminResponse
    >(SEARCH_EVENT_SCHEDULES_V2_FOR_ADMIN, request);
    const rows = eventScheduleV2.map(schedule =>
      builder<EventScheduleRow>()
        .id(schedule.eventScheduleId)
        .name(schedule.name)
        .date(toDateTimeDurationStr(schedule.startAtV2, schedule.endAtV2, Language.JAPANESE))
        .entryForm(statusComponent(schedule, MessageType.ENTRY))
        .remind(statusComponent(schedule, MessageType.REMIND))
        .build()
    );

    if (!hasLength(rows) && isGreaterThanZero(offset)) setOffset(offset - ONE);

    setEventSchedules(eventScheduleV2);
    setRows(rows);
    setTotalCount(totalCount);
    setIsLoaderShown(false);
  }, [
    base,
    eventId,
    isInitialized,
    searchingWord,
    selectedTabIdx,
    offset,
    setEventSchedules,
    setIsInitialized,
    setOffset,
    setRows,
    setTotalCount,
    setIsLoaderShown
  ]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleSearchingWordChanged = useSafeCallback(
    (word: Word): void => {
      setSearchingWord(word);
      setOffset(ZERO);
    },
    [setSearchingWord, setOffset]
  );

  const openRegisterEventScheduleV2Screen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.REGISTER_EVENT_SCHEDULE_V2, PATH_IDS, [base.baseCode, eventId]));
  }, [base, eventId, openPath]);

  const openEventScheduleDetailsV2Screen = useSafeCallback(
    (index: Index): void => {
      openPath(
        embedIdInPath(Path.EVENT_SCHEDULE_V2_DETAILS, PATH_IDS, [base.baseCode, params[EVENT_ID_V2], rows[index].id])
      );
    },
    [params, base, rows, openPath]
  );

  const handleEventScheduleDelete = useSafeCallback(
    async (rowIndex: Index) => {
      const eventSchedule = eventSchedules[rowIndex];
      if (eventSchedule.recurrenceType !== RecurrenceTypeEnum.NOT_RECURRING) {
        setSelectedDeleteEventSchedule(eventSchedules[rowIndex]);
        setIsDeleteEventScheduleModalOpen(true);
        return;
      }
      await deleteEventSchedule(RecurrenceTarget.THIS_ONE, eventSchedule);
    },
    [setIsDeleteEventScheduleModalOpen, setSelectedDeleteEventSchedule, eventSchedules]
  );

  const moreHorizMenusForTable = useMemo<MoreHorizMenuForTable[]>(
    () => [
      {
        label: 'スケジュールを削除',
        color: themeV2.mixins.v2.color.font.gray,
        onClick: rowIndex => handleEventScheduleDelete(rowIndex)
      }
    ],
    [handleEventScheduleDelete]
  );

  const deleteEventSchedule = useSafeCallback(
    async (recurrenceTarget: RecurrenceTarget, eventSchedule: EventScheduleV2): Promise<void> => {
      const request = builder<DeleteEventScheduleV2ForAdminRequest>()
        .baseId(base.baseId)
        .eventScheduleId(eventSchedule.eventScheduleId)
        .recurrenceTarget(recurrenceTarget)
        .build();
      await commonRequest<DeleteEventScheduleV2ForAdminRequest, DeleteEventScheduleV2ForAdminResponse>(
        DELETE_EVENT_SCHEDULE_V2_FOR_ADMIN,
        request
      );
      await searchAllSchedules();
      await initialize();
    },
    [base.baseId, initialize, searchAllSchedules]
  );

  return (
    <Container>
      {!hasSchedules && (
        <NoScheduleWrapper>
          {isLoaderShown ? (
            <CircularLoader />
          ) : (
            <NoScheduleCard>
              <StyledDateRangeIcon />
              <NoScheduleMessage>スケジュールは登録されていません。</NoScheduleMessage>
              <ButtonV2
                type='primary'
                startIcon={<AddIcon />}
                label='スケジュールを作成'
                onClick={openRegisterEventScheduleV2Screen}
              />
            </NoScheduleCard>
          )}
        </NoScheduleWrapper>
      )}

      {hasSchedules && (
        <>
          <TabWrapper>
            <TabsV3
              tabs={tabProperties}
              variant='outline'
              selectedTabIdx={selectedTabIdx}
              onChange={setSelectedTabIdx}
            />
          </TabWrapper>
          <SearchListV2
            name={`${isHeldTab ? '開催済み' : EMPTY}スケジュール`}
            title={`${totalCount}件の${isHeldTab ? '開催済み' : EMPTY}スケジュール`}
            colWidth={COLUMN_WIDTH}
            header={HEADER}
            rows={rows}
            moreHorizMenusForTable={moreHorizMenusForTable}
            offset={offset}
            limit={EventScheduleSearchOption.LIMIT}
            totalCount={totalCount}
            placeholder='スケジュール名で検索'
            searchingWord={searchingWord}
            isLoaderShown={isLoaderShown}
            headerRightComponent={
              <ButtonV2
                size='large'
                startIcon={<AddIcon />}
                label='新規作成'
                onClick={openRegisterEventScheduleV2Screen}
              />
            }
            onChange={handleSearchingWordChanged}
            onClickRow={openEventScheduleDetailsV2Screen}
            setOffset={setOffset}
          />
        </>
      )}

      {selectedDeleteEventSchedule && (
        <DeleteEventScheduleModal
          isOpen={isDeleteEventScheduleModalOpen}
          editOrDelete='delete'
          onClick={recurrenceTarget => deleteEventSchedule(recurrenceTarget, selectedDeleteEventSchedule)}
          onClose={() => setIsDeleteEventScheduleModalOpen(false)}
        />
      )}
    </Container>
  );
});

EventScheduleList.displayName = 'EventScheduleList';
export default EventScheduleList;

const Container = styled.div`
  width: 100%;
`;

const NoScheduleWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const NoScheduleCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  gap: 16px;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 12px;
`;

const StyledDateRangeIcon = styled(DateRangeIcon)`
  width: 48px;
  height: 48px;
  color: ${themeV2.mixins.v2.color.font.lightGray};
`;

const NoScheduleMessage = styled.div`
  ${themeV2.mixins.v2.typography.title.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const TabWrapper = styled.div`
  margin-bottom: ${themeV2.mixins.v2.spacing * 2}px;
`;

const StatusWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
