import {
  BackButtonV2,
  Component,
  MoreHorizMenu,
  MoreHorizMenuButton,
  PageHeaderV2,
  TabProperty,
  TabsV3,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  DELETE_EVENT_SCHEDULE_V2_FOR_ADMIN,
  DeleteEventScheduleV2ForAdminRequest,
  DeleteEventScheduleV2ForAdminResponse,
  EVENT_ID_V2,
  EVENT_SCHEDULE_ID_V2,
  EventIdV2,
  EventScheduleIdV2,
  EventScheduleV2,
  FETCH_EVENT_SCHEDULE_V2_FOR_ADMIN,
  FetchEventScheduleV2ForAdminRequest,
  FetchEventScheduleV2ForAdminResponse,
  MessageType,
  User
} from '@atomica.co/irori';
import { Index } from '@atomica.co/types';
import { SLASH, ZERO, builder, embedIdInPath } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { EVENE_SCHEDULE_DETAIL_PATHS } from '../../constants/event-v2-const';
import { EventSchedulePathEnum } from '../../enums/event-v2-enum';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../router/Routes';
import { EVENT_SCHEDULE_TAB_LABELS } from '../../texts/event-text';
import ScheduleMessages from './schedule-messages/ScheduleMessages';
import ScheduleParticipants from './schedule-participants/ScheduleParticipants';
import ScheduleSummary from './schedule-summary/ScheduleSummary';

const EVENT_SCHEDULE_DETAILS_CONTENT_MAX_WIDTH = 1280;
const EVENT_SCHEDULE_DETAILS_CONTENT_MIN_WIDTH = 756;

interface P {
  isDrawerOpen: boolean;
  base: BaseDto;
  user: User;
}

const tabProperties = Object.values(EVENT_SCHEDULE_TAB_LABELS).map<TabProperty>(label => ({
  label,
  disabled: false
}));

const EventScheduleV2DetailsScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { path, params, openPath } = usePath();
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [eventSchedule, setEventSchedule] = useSafeState<EventScheduleV2>(unmountRef);

  const initialTabIndex = useMemo<Index>(() => {
    const lastOfPath = path?.split(SLASH).pop();
    return Object.values(EventSchedulePathEnum).indexOf(lastOfPath as EventSchedulePathEnum);
  }, [path]);

  const [selectedTabIdx, setSelectedTabIdx] = useSafeState<Index>(unmountRef, initialTabIndex);

  const handleButtonClicked = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.EVENT_V2_DETAILS, PATH_IDS, [base.baseCode, params[EVENT_ID_V2]]));
  }, [openPath, base, params]);

  const eventId = useMemo<EventIdV2>(() => params[EVENT_ID_V2], [params]);
  const eventScheduleId = useMemo<EventScheduleIdV2>(() => params[EVENT_SCHEDULE_ID_V2], [params]);

  const isHeld = useMemo<boolean>(() => !!eventSchedule && eventSchedule.endAtV2 <= new Date(), [eventSchedule]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const scheduleRequest = builder<FetchEventScheduleV2ForAdminRequest>()
      .baseId(base.baseId)
      .eventScheduleIdV2(eventScheduleId)
      .build();
    const [scheduleResponse] = await Promise.all([
      commonRequest<FetchEventScheduleV2ForAdminRequest, FetchEventScheduleV2ForAdminResponse>(
        FETCH_EVENT_SCHEDULE_V2_FOR_ADMIN,
        scheduleRequest
      )
    ]);
    const { eventScheduleV2 } = scheduleResponse;

    if (!eventScheduleV2) {
      setIsLoaderShown(false);
      return;
    }

    setEventSchedule(eventScheduleV2);
    setIsLoaderShown(false);
  }, [base.baseId, commonRequest, eventScheduleId, setEventSchedule, setIsLoaderShown]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const openPathForEventScheduleDetail = useSafeCallback(
    (path: Path, eventScheduleId: EventScheduleIdV2) => {
      return openPath(embedIdInPath(path, PATH_IDS, [base.baseCode, eventId, eventScheduleId]));
    },
    [base, eventId, openPath]
  );

  const selectPath = useSafeCallback(
    (selectedTabIdx: Index, selectedEventScheduleId?: EventScheduleIdV2) => {
      const eventScheduleIdForPath = selectedEventScheduleId ? selectedEventScheduleId : eventScheduleId;
      openPathForEventScheduleDetail(EVENE_SCHEDULE_DETAIL_PATHS[selectedTabIdx], eventScheduleIdForPath);
    },
    [eventScheduleId, openPathForEventScheduleDetail]
  );

  useEffect(() => {
    setSelectedTabIdx(selectedTabIdx => initialTabIndex || selectedTabIdx);
  }, [path, initialTabIndex, setSelectedTabIdx]);

  useEffect(() => {
    selectPath(selectedTabIdx);
  }, [selectedTabIdx, selectPath]);

  const deleteEventSchedule = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const request = builder<DeleteEventScheduleV2ForAdminRequest>()
      .baseId(base.baseId)
      .eventScheduleId(eventScheduleId)
      .build();
    const response = await commonRequest<DeleteEventScheduleV2ForAdminRequest, DeleteEventScheduleV2ForAdminResponse>(
      DELETE_EVENT_SCHEDULE_V2_FOR_ADMIN,
      request
    );

    if (!response.eventScheduleId) {
      setIsLoaderShown(false);
      return;
    }

    openPath(embedIdInPath(Path.EVENT_V2_DETAILS, PATH_IDS, [base.baseCode, eventId]));
    setIsLoaderShown(false);
  }, [base.baseId, eventScheduleId, openPath, setIsLoaderShown]);

  const subMenus = useMemo<MoreHorizMenu[]>(() => {
    return [{ label: '削除', onClick: deleteEventSchedule }];
  }, [deleteEventSchedule]);

  return (
    <Component
      loading={isLoaderShown}
      style={{ width: '100%', height: '100%' }}
      className='eventSchedule-details-screen'
    >
      <Container>
        {!!eventSchedule && (
          <Content>
            <HeaderWrapper>
              <BackButtonV2 label='戻る' onClick={handleButtonClicked} />

              <PageHeaderV2
                title={eventSchedule.name}
                rightComponent={isHeld ? <></> : <MoreHorizMenuButton size='medium' menuButtons={subMenus} />}
              />
            </HeaderWrapper>
            <TabsWrapper>
              <TabsV3 tabs={tabProperties} selectedTabIdx={selectedTabIdx || ZERO} onChange={setSelectedTabIdx} />
              <TabContent>
                {selectedTabIdx === 0 && <ScheduleSummary base={base} eventSchedule={eventSchedule} />}
                {selectedTabIdx === 1 && <ScheduleParticipants base={base} eventSchedule={eventSchedule} />}
                {selectedTabIdx === 2 && (
                  <ScheduleMessages
                    base={base}
                    user={user}
                    messageType={MessageType.ENTRY}
                    eventSchedule={eventSchedule}
                  />
                )}
                {selectedTabIdx === 3 && (
                  <ScheduleMessages
                    base={base}
                    user={user}
                    messageType={MessageType.REMIND}
                    eventSchedule={eventSchedule}
                  />
                )}
              </TabContent>
            </TabsWrapper>
          </Content>
        )}
      </Container>
    </Component>
  );
});

EventScheduleV2DetailsScreen.displayName = 'EventScheduleV2DetailsScreen';
export default EventScheduleV2DetailsScreen;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${themeV2.mixins.v2.spacing}px 0px ${themeV2.mixins.v2.spacing * 3}px;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  max-width: ${EVENT_SCHEDULE_DETAILS_CONTENT_MAX_WIDTH}px;
  min-width: ${EVENT_SCHEDULE_DETAILS_CONTENT_MIN_WIDTH}px;
  margin: 0 auto ${themeV2.mixins.v2.spacing * 2}px;
  padding: 0 ${themeV2.mixins.v2.spacing * 3}px;
`;

const TabsWrapper = styled.div`
  width: 100%;
  flex: 1;
  max-width: ${EVENT_SCHEDULE_DETAILS_CONTENT_MAX_WIDTH}px;
  min-width: ${EVENT_SCHEDULE_DETAILS_CONTENT_MIN_WIDTH}px;
  margin: 0 auto;
  padding: 0 ${themeV2.mixins.v2.spacing * 3}px 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  width: 100%;
  height: 100%;
`;
