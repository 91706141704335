import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  BaseDto,
  ErrorCode,
  FetchBaseRelatedInfoLoginService,
  LoginServiceType,
  LogLevel,
  RECORD_LOGS,
  RecordLogsRequest,
  RecordLogsResponse,
  RelatedInfoType,
  User,
  VERIFY_USER,
  VerifyUserRequest,
  VerifyUserResponse
} from '@atomica.co/irori';
import { Code, Details, Email, Message, ProviderId, QueryParamStr, Token, UserId } from '@atomica.co/types';
import { builder, CODE, EMAIL, EMPTY, hasLength, LINE_PREFIX, stringify } from '@atomica.co/utils';
import { useMemo } from 'react';
import { getBsBase } from '../../__generated/public/bs-base/bs-base';
import { PROVIDER_ID_SAKURA_OIDC } from '../../constants/auth-const';
import { Process } from '../../enums/action-enum';
import firebase, { auth } from '../../firebase';
import { constructLineLoginURL, isInLineClient } from '../../line';
import AuthRequest from '../../requests/auth-request';
import { Path } from '../../router/Routes';
import { AuthService } from '../../services/auth-service';
import { ERROR_MESSAGES } from '../../texts/error-text';
import { getBaseConsumerHomePathAndName } from '../../utils/path-util';
import { toProviderId } from '../../utils/user-util';
import useCachedEmail from './useCachedEmail';
import useCachedInvitationEmail from './useCachedInvitationEmail';
import useCachedJoinURL from './useCachedJoinURL';
import useCachedURL from './useCachedURL';
import useCommonRequest from './useCommonRequest';
import usePath from './usePath';
import useProcess from './useProcess';
import useUser from './useUser';

interface Props {
  base: BaseDto;
  user?: User;
}

interface Response {
  usableLoginServiceTypes: ReturnType<typeof getLoginServiceVisibility>;
  loadLoginServices: () => Promise<void>;
  openGoogleLoginPage: () => void;
  openLineLoginPage: () => void;
  openSakuraLoginPage: () => void;
  handleSignInWithRedirectResult: () => Promise<void>;
  succeededToSignIn: (credential: firebase.auth.UserCredential) => Promise<void>;
  errorMessage: Message;
}

interface SignUpInError {
  errorCode: ErrorCode;
  error?: Details;
  baseCode: Code;
  userId?: UserId;
  email?: Email;
  credential?: Details;
  idToken?: Token;
  path: Path | undefined;
  queryParamStr?: QueryParamStr;
}

function useLogin({ base, user }: Props): Response {
  const { commonRequest } = useCommonRequest();
  const unmountRef = useUnmountRef();
  const [usableLoginServiceTypes, setUsableLoginServiceTypes] = useSafeState<
    ReturnType<typeof getLoginServiceVisibility>
  >(unmountRef, []);
  const { process, saveProcess } = useProcess();
  const { createNonActivatedUserIfNotExisted } = useUser({ noInitialize: true });
  const { hasCachedURL, openCachedURL } = useCachedURL();
  const { popCachedInvitationEmail } = useCachedInvitationEmail();
  const { hasCachedJoinURL, openCachedJoinURL } = useCachedJoinURL();
  const { saveCachedEmail } = useCachedEmail();
  const { path, queryParams, openBasePath } = usePath();

  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);
  const lineCode = useMemo<Code>(() => queryParams[CODE], [queryParams]);

  const obtainFromErrorCode = (e: unknown): ErrorCode => {
    if (typeof e === 'object' && e !== null) {
      if ('message' in e && typeof e.message === 'string') return ErrorCode[e.message];
      if ('code' in e && typeof e.code === 'string') return ErrorCode[e.code];
    }
    return ErrorCode.SYSTEM_ERROR;
  };

  const loadLoginServices = useSafeCallback(async (): Promise<void> => {
    const { data } = await getBsBase().fetchBaseRelatedInfo(base.baseId, {
      relatedInfoType: RelatedInfoType.LOGIN_SERVICE
    });
    if (data?.result) {
      setUsableLoginServiceTypes(getLoginServiceVisibility(base.isPublic, data.loginServices));
    }
  }, [base, setUsableLoginServiceTypes]);

  const openGoogleLoginPage = useSafeCallback((): void => {
    saveProcess(Process.PROCESSING);
    AuthService.signInWithRedirect({ scopes: [EMAIL] });
  }, [saveProcess]);

  const openLineLoginPage = useSafeCallback((): void => {
    saveProcess(Process.PROCESSING);
    const lineLoginURL = constructLineLoginURL(base.baseCode, base.lineClientId);
    window.location.href = lineLoginURL;
  }, [base.baseCode, base.lineClientId, saveProcess]);

  const openSakuraLoginPage = useSafeCallback((): void => {
    saveProcess(Process.PROCESSING);
    const provider = new firebase.auth.OAuthProvider(PROVIDER_ID_SAKURA_OIDC);
    AuthService.signInWithRedirect({ provider });
  }, [saveProcess]);

  const handleError = useSafeCallback(
    async (error: SignUpInError, level: LogLevel): Promise<void> => {
      setErrorMessage(ERROR_MESSAGES[error.errorCode]);
      saveProcess(Process.INITIAL);
      const request = builder<RecordLogsRequest>().jsonToRecord(stringify(error)).level(level).build();
      await commonRequest<RecordLogsRequest, RecordLogsResponse>(RECORD_LOGS, request);
    },
    [setErrorMessage, saveProcess, commonRequest]
  );
  /* NOTE: エラーハンドリング処理が完了したタイミングで、handleErrorにrename */
  const handleErrorDetail = useSafeCallback(
    async (error: SignUpInError, level: LogLevel): Promise<void> => {
      const errorMessage = ERROR_MESSAGES[error.errorCode]
        ? ERROR_MESSAGES[error.errorCode]
        : ERROR_MESSAGES[ErrorCode.SYSTEM_ERROR];
      setErrorMessage(errorMessage);
      saveProcess(Process.INITIAL);
      const request = builder<RecordLogsRequest>().jsonToRecord(stringify(error)).level(level).build();
      await commonRequest<RecordLogsRequest, RecordLogsResponse>(RECORD_LOGS, request);
    },
    [setErrorMessage, saveProcess, commonRequest]
  );

  const toSignUpInError = useSafeCallback(
    (
      errorCode: ErrorCode,
      error?,
      credential?: firebase.auth.UserCredential,
      email?: Email,
      idToken?: Token
    ): SignUpInError => {
      return builder<SignUpInError>()
        .errorCode(errorCode)
        .error(error ? stringify(error) : EMPTY)
        .baseCode(base.baseCode)
        .userId(user?.userId || EMPTY)
        .email(email || EMPTY)
        .credential(credential ? stringify(credential) : EMPTY)
        .idToken(idToken || EMPTY)
        .path(path)
        .queryParamStr(queryParams ? stringify(queryParams) : EMPTY)
        .build();
    },
    [base.baseCode, user?.userId, path, queryParams]
  );

  const handleAfterAuthentication = useSafeCallback(
    async (credential: firebase.auth.UserCredential): Promise<void> => {
      const fbUser = credential.user;

      if (!fbUser) return;
      const email = credential.additionalUserInfo?.profile?.[EMAIL] ?? fbUser.email;

      try {
        saveCachedEmail(toProviderId(fbUser) === ProviderId.LINE ? email.replace(LINE_PREFIX, EMPTY) : email);

        // 招待参加している場合、招待メールアドレスと一致していなければユーザー作成＆サインインは行わない
        const invitationEmail = popCachedInvitationEmail();
        if (
          invitationEmail !== undefined &&
          hasCachedJoinURL() &&
          invitationEmail !== email &&
          LINE_PREFIX.concat(invitationEmail) !== email
        ) {
          saveProcess(Process.COMPLETED);
          openCachedJoinURL();
          return;
        }

        const registeredKpUser = await createNonActivatedUserIfNotExisted(base, fbUser, email);
        saveProcess(Process.COMPLETED);

        if (!registeredKpUser || !registeredKpUser.isActivated) {
          openBasePath(Path.REGISTER_ACCOUNT);
          return;
        }

        hasCachedURL() ? openCachedURL() : openBasePath(baseCosumerHomePath(base));
      } catch (e) {
        await handleError(toSignUpInError(ErrorCode.SYSTEM_ERROR_TWO, e, credential, email), LogLevel.ERROR);
      }
    },
    [
      base,
      createNonActivatedUserIfNotExisted,
      saveProcess,
      saveCachedEmail,
      hasCachedURL,
      openCachedURL,
      hasCachedJoinURL,
      openCachedJoinURL,
      openBasePath,
      popCachedInvitationEmail,
      handleError,
      toSignUpInError
    ]
  );

  const succeededToSignIn = useSafeCallback(
    async (credential: firebase.auth.UserCredential): Promise<void> => {
      const firebaseUser = credential.user;
      if (!firebaseUser) {
        // NOTE: 過去SYSTEM_ERROR_ONEだったもの
        handleErrorDetail(toSignUpInError(ErrorCode.LOGIN_ACCESS_EXPIRED, undefined, credential), LogLevel.ERROR);
        return;
      }

      await handleAfterAuthentication(credential);
    },
    [handleAfterAuthentication, handleErrorDetail, toSignUpInError]
  );

  const failedToSignIn = useSafeCallback(
    async (e): Promise<void> => {
      // NOTE: 過去SYSTEM_ERROR_TRHEEだったもの
      await handleErrorDetail(toSignUpInError(obtainFromErrorCode(e), e), LogLevel.ERROR);
    },
    [handleErrorDetail, toSignUpInError]
  );

  const fetchIdToken = useSafeCallback(
    async (lineCode: Code, base: BaseDto): Promise<Token | undefined> => {
      try {
        const idToken = await AuthRequest.fetchIdToken(lineCode, base);
        if (!idToken) throw new Error();
        return idToken;
      } catch (e) {
        // NOTE: 過去SYSTEM_ERROR_FOURだったもの
        await handleErrorDetail(toSignUpInError(obtainFromErrorCode(e), e), LogLevel.ERROR);
      }
    },
    [handleErrorDetail, toSignUpInError]
  );

  const signInWithLine = useSafeCallback(async (): Promise<void> => {
    const idToken = await fetchIdToken(lineCode, base);
    if (!idToken) return;

    const request = builder<VerifyUserRequest>()
      .idToken(idToken)
      .providerId(ProviderId.LINE)
      .baseId(base.baseId)
      .build();
    const response = await commonRequest<VerifyUserRequest, VerifyUserResponse>(VERIFY_USER, request);

    if (!response) {
      await handleError(
        toSignUpInError(ErrorCode.SYSTEM_ERROR_FIVE, undefined, undefined, undefined, idToken),
        LogLevel.ERROR
      );
      return;
    }

    const { customToken, errorCode } = response;

    if (errorCode) {
      await handleError(toSignUpInError(errorCode, undefined, undefined, undefined, idToken), LogLevel.INFO);
      return;
    }

    await AuthService.signInWithCustomToken(customToken)
      .then(async credential => await succeededToSignIn(credential))
      .catch(failedToSignIn);
  }, [base, commonRequest, failedToSignIn, fetchIdToken, handleError, lineCode, succeededToSignIn, toSignUpInError]);

  const signInWithRedirect = useSafeCallback(async (): Promise<void> => {
    await auth
      .getRedirectResult()
      .then(async credential => await succeededToSignIn(credential))
      .catch(failedToSignIn);
  }, [succeededToSignIn, failedToSignIn]);

  const handleSignInWithRedirectResult = useSafeCallback(async (): Promise<void> => {
    if (process !== Process.PROCESSING) return;
    lineCode ? await signInWithLine() : await signInWithRedirect();
  }, [lineCode, process, signInWithLine, signInWithRedirect]);

  return {
    usableLoginServiceTypes,
    loadLoginServices,
    openGoogleLoginPage,
    openLineLoginPage,
    openSakuraLoginPage,
    handleSignInWithRedirectResult,
    succeededToSignIn,
    errorMessage
  };
}

const baseCosumerHomePath = (base: BaseDto) => {
  const [path] = getBaseConsumerHomePathAndName(base);
  return path;
};

const getLoginServiceVisibility = (
  isPublic: boolean,
  loginServices?: FetchBaseRelatedInfoLoginService[]
): LoginServiceType[] => {
  if (!hasLength(loginServices)) {
    return getDefaultLoginServices(isPublic);
  }

  return loginServices.reduce<LoginServiceType[]>((visibleServices, service) => {
    if (shouldShowLoginService(service.loginServiceType, isPublic)) {
      visibleServices.push(service.loginServiceType);
    }
    return visibleServices;
  }, []);
};

const getDefaultLoginServices = (isPublic: boolean): LoginServiceType[] => [
  LoginServiceType.MAIL,
  ...(isPublic && !isInLineClient() ? [LoginServiceType.GOOGLE] : []),
  ...(isPublic ? [LoginServiceType.LINE] : [])
];

const shouldShowLoginService = (serviceType: LoginServiceType, isPublic: boolean): boolean => {
  switch (serviceType) {
    case LoginServiceType.GOOGLE:
      return isPublic && !isInLineClient();
    case LoginServiceType.LINE:
      return isPublic;
    default:
      return true;
  }
};

export default useLogin;
