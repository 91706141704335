import {
  ButtonV2,
  Component,
  styleForFullExpansion,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseId,
  DELETE_EVENT_SCHEDULE_GROUP_V2_FOR_ADMIN,
  DeleteEventScheduleGroupV2ForAdminRequest,
  DeleteEventScheduleGroupV2ForAdminResponse,
  EVENT_SCHEDULE_ID_V2,
  EventScheduleGroupV2,
  EventScheduleIdV2,
  EventScheduleParticipantV2,
  EventScheduleV2,
  FETCH_EVENT_SCHEDULE_V2_FOR_ADMIN,
  FetchEventScheduleV2ForAdminRequest,
  FetchEventScheduleV2ForAdminResponse,
  SEARCH_EVENT_SCHEDULE_GROUPS_V2_FOR_ADMIN,
  SearchEventScheduleGroupsV2ForAdminRequest,
  SearchEventScheduleGroupsV2ForAdminResponse
} from '@atomica.co/irori';
import { Code, Index, UserId, Word } from '@atomica.co/types';
import { builder, hasLength } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import useCommonRequest from '../../../../redux/hooks/useCommonRequest';
import usePath from '../../../../redux/hooks/usePath';
import DeleteGroupModal from '../../modal/DeleteGroupModal';
import RegisterGroupModal from '../../modal/RegisterGroupModal';
import RegisterIncrementalGroupsModal from '../../modal/RegisterIncrementalGroupsModal';
import GroupedParticipantSearchList from './GroupedParticipantSearchList';

interface P {
  baseCode: Code;
  baseId: BaseId;
  regularUserIds: UserId[];
  searchingWord: Word;
  reloadQueue: boolean;
  handleGroupsLoaded(groups: EventScheduleGroupV2[]): void;
}

const GroupedParticipants: React.FC<P> = React.memo(props => {
  const { baseCode, baseId, regularUserIds, reloadQueue, searchingWord, handleGroupsLoaded } = props;
  const { params } = usePath();
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, true);

  const [isDeleteGroupModalOpen, setIsDeleteGroupModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [isRegisterIncrementalGroupModalOpen, setIsRegisterIncrementalGroupModalOpen] = useSafeState<boolean>(
    unmountRef,
    false
  );
  const [isRegisterGroupModalOpen, setIsRegisterGroupModalOpen] = useSafeState<boolean>(unmountRef, false);

  const [eventSchedule, setEventSchedule] = useSafeState<EventScheduleV2 | undefined>(unmountRef);
  const [eventScheduleGroups, setEventScheduleGroups] = useSafeState<EventScheduleGroupV2[]>(unmountRef, []);
  const [selectedGroup, setSelectedGroup] = useSafeState<EventScheduleGroupV2 | undefined>(unmountRef);

  const eventScheduleId = useMemo<EventScheduleIdV2>(() => params[EVENT_SCHEDULE_ID_V2], [params]);

  const notGroupedParticipants = useMemo<EventScheduleParticipantV2[]>(() => {
    if (!eventSchedule || !eventSchedule.participants) return [];
    const groupedParticipants = eventScheduleGroups.reduce(
      (participants: EventScheduleParticipantV2[], grouped: EventScheduleGroupV2) => {
        if (grouped.participants) participants.push(...grouped.participants);
        return participants;
      },
      []
    );
    return eventSchedule.participants.filter(participant =>
      groupedParticipants.every(
        groupedParticipant => participant.eventScheduleParticipantId !== groupedParticipant.eventScheduleParticipantId
      )
    );
  }, [eventSchedule, eventScheduleGroups]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const eventScheduleRequest = builder<FetchEventScheduleV2ForAdminRequest>()
      .baseId(baseId)
      .eventScheduleIdV2(eventScheduleId)
      .build();
    const groupRequest = builder<SearchEventScheduleGroupsV2ForAdminRequest>()
      .baseId(baseId)
      .eventScheduleIdV2(eventScheduleId)
      .word(searchingWord)
      .build();

    const [eventScheduleResponse, groupsResponse] = await Promise.all([
      commonRequest<FetchEventScheduleV2ForAdminRequest, FetchEventScheduleV2ForAdminResponse>(
        FETCH_EVENT_SCHEDULE_V2_FOR_ADMIN,
        eventScheduleRequest
      ),
      commonRequest<SearchEventScheduleGroupsV2ForAdminRequest, SearchEventScheduleGroupsV2ForAdminResponse>(
        SEARCH_EVENT_SCHEDULE_GROUPS_V2_FOR_ADMIN,
        groupRequest
      )
    ]);

    const { eventScheduleV2 } = eventScheduleResponse;
    const { eventScheduleGroupsV2 } = groupsResponse;

    if (!eventScheduleV2) {
      setIsLoaderShown(false);
      return;
    }

    setEventSchedule(eventScheduleV2);
    setEventScheduleGroups(eventScheduleGroupsV2);
    setIsLoaderShown(false);
    handleGroupsLoaded(eventScheduleGroupsV2);
  }, [
    baseId,
    commonRequest,
    eventScheduleId,
    searchingWord,
    setIsLoaderShown,
    setEventSchedule,
    setEventScheduleGroups,
    handleGroupsLoaded
  ]);

  const handleRegisterIncrementalGroupsModalClosed = useSafeCallback(
    (isUpdated: boolean): void => {
      setIsRegisterIncrementalGroupModalOpen(false);
      if (isUpdated) initialize();
    },
    [initialize, setIsRegisterIncrementalGroupModalOpen]
  );

  const deleteEventScheduleGroup = useSafeCallback(
    async (eventScheduleGroup: EventScheduleGroupV2): Promise<void> => {
      if (!eventScheduleGroup) return;
      setIsLoaderShown(true);
      const request = builder<DeleteEventScheduleGroupV2ForAdminRequest>()
        .baseId(baseId)
        .groupToDelete(eventScheduleGroup)
        .build();
      await commonRequest<DeleteEventScheduleGroupV2ForAdminRequest, DeleteEventScheduleGroupV2ForAdminResponse>(
        DELETE_EVENT_SCHEDULE_GROUP_V2_FOR_ADMIN,
        request
      );
      initialize();
    },
    [baseId, commonRequest, initialize, setIsLoaderShown]
  );

  const handleDeleteGroupClicked = useSafeCallback(
    async (clickedGroup?: EventScheduleGroupV2): Promise<void> => {
      if (!clickedGroup) return;
      if (!hasLength(clickedGroup.participants)) {
        await deleteEventScheduleGroup(clickedGroup);
        return;
      }
      setSelectedGroup(clickedGroup);
      setIsDeleteGroupModalOpen(true);
    },
    [deleteEventScheduleGroup, setIsDeleteGroupModalOpen, setSelectedGroup]
  );

  const handleEditGroupNameClicked = useSafeCallback(
    (clickedGroup?: EventScheduleGroupV2): void => {
      if (!clickedGroup) return;
      setSelectedGroup(clickedGroup);
      setIsRegisterGroupModalOpen(true);
    },
    [setSelectedGroup, setIsRegisterGroupModalOpen]
  );

  const handleModalClosed = useSafeCallback(() => {
    setSelectedGroup(undefined);
    setIsDeleteGroupModalOpen(false);
    setIsRegisterGroupModalOpen(false);
    setIsRegisterIncrementalGroupModalOpen(false);
    initialize();
  }, [
    initialize,
    setIsDeleteGroupModalOpen,
    setIsRegisterGroupModalOpen,
    setIsRegisterIncrementalGroupModalOpen,
    setSelectedGroup
  ]);

  useEffect(() => {
    initialize();
  }, [initialize, reloadQueue]);

  return (
    <Component style={styleForFullExpansion} loading={isLoaderShown} className='grouped_participants'>
      <Container>
        {eventSchedule && (
          <Content>
            {!hasLength(eventScheduleGroups) && (
              <ButtonWrapper>
                <Message>グループがありません</Message>
                <ButtonV2
                  type='primary'
                  startIcon={<AddIcon />}
                  label='グループを新規作成'
                  onClick={() => setIsRegisterIncrementalGroupModalOpen(true)}
                />
              </ButtonWrapper>
            )}
            {hasLength(eventScheduleGroups) && (
              <>
                {!searchingWord && (
                  <GroupedParticipantSearchList
                    baseCode={baseCode}
                    baseId={baseId}
                    title='未割り当ての参加者'
                    eventSchedule={eventSchedule}
                    eventScheduleParticipants={notGroupedParticipants}
                    regularUserIds={regularUserIds}
                    handleListInitialized={initialize}
                  />
                )}
                {hasLength(eventScheduleGroups) && (
                  <>
                    {eventScheduleGroups.map((group: EventScheduleGroupV2, index: Index) => (
                      <GroupedParticipantSearchList
                        baseCode={baseCode}
                        baseId={baseId}
                        key={`group-list-${index}`}
                        title={group.name}
                        eventSchedule={eventSchedule}
                        eventScheduleParticipants={group.participants}
                        eventScheduleGroup={group}
                        regularUserIds={regularUserIds}
                        handleDeleteGroupClicked={handleDeleteGroupClicked}
                        handleEditGroupNameClicked={handleEditGroupNameClicked}
                        handleListInitialized={initialize}
                      />
                    ))}
                    <ButtonV2
                      type='tertiary'
                      startIcon={<AddIcon />}
                      label='グループを追加'
                      onClick={() => setIsRegisterIncrementalGroupModalOpen(true)}
                    />
                  </>
                )}
              </>
            )}

            <RegisterIncrementalGroupsModal
              baseId={baseId}
              isOpen={isRegisterIncrementalGroupModalOpen}
              eventScheduleV2={eventSchedule}
              groups={eventScheduleGroups}
              onClose={isUpdated => handleRegisterIncrementalGroupsModalClosed(isUpdated)}
            />
            <RegisterGroupModal
              baseId={baseId}
              isOpen={isRegisterGroupModalOpen}
              eventScheduleV2={eventSchedule}
              eventScheduleGroupV2={selectedGroup}
              mode='edit'
              onClose={handleModalClosed}
            />
            <DeleteGroupModal
              isOpen={isDeleteGroupModalOpen}
              baseId={baseId}
              eventScheduleV2={eventSchedule}
              selectedGroup={selectedGroup}
              onClose={handleModalClosed}
            />
          </Content>
        )}
      </Container>
    </Component>
  );
});

GroupedParticipants.displayName = 'GroupedParticipants';
export default GroupedParticipants;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: ${themeV2.mixins.v2.spacing * 2}px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const ButtonWrapper = styled.div`
  margin-top: ${themeV2.mixins.v2.spacing * 2}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing / 2}px;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 12px;
`;

const Message = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.gray};
`;
