import {
  CardV2,
  CardWithLabelV2,
  ColWidth,
  Component,
  Header,
  LabelV2,
  TableV3,
  themeV2
} from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  ContractV2,
  CreditCard,
  PaymentMethod,
  isBaseFunctionToggleEnabled
} from '@atomica.co/irori';
import { Id, Label, Name } from '@atomica.co/types';
import { EMPTY, ONE, ZERO, builder, hasLength } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  DetailForContractDetailScreen,
  DetailsForContractDetailScreen,
  PaymentForContractDetailScreen,
  PlanForContractDetailScreen,
  toDisplayContractDetails
} from '../../../converters/contract-v2-converter';
import { PAYMENT_METHOD_LABELS } from '../../../texts/contract-v2-text';
import { constructFullContractorAddress } from '../../../utils/contract-v2-util';
import ContractDetailsPayment from './content/ContractDetailsPayment';

const COLUMN_WIDTH: ColWidth = { itemName: 'auto', unitPrice: 150, recurrenceCount: 100 };
const HEADER: Header = {
  itemName: { label: '品目' },
  unitPrice: { label: '単価（円）', align: 'right' },
  recurrenceCount: { label: '割引期間' }
};

interface DetailRow {
  id: Id;
  itemName: Name;
  unitPrice: Label;
  recurrenceCount: Label;
}

interface P {
  base: BaseDto;
  contract: ContractV2 | undefined;
  creditCard: CreditCard | undefined;
}

const ContractDetail: React.FC<P> = React.memo(props => {
  const { base, contract, creditCard } = props;

  const displayContractDetails = useMemo<DetailsForContractDetailScreen | undefined>(
    () => toDisplayContractDetails(contract),
    [contract]
  );
  const detail = useMemo<DetailForContractDetailScreen | undefined>(
    () => displayContractDetails?.detail,
    [displayContractDetails]
  );
  const plan = useMemo<PlanForContractDetailScreen | undefined>(
    () => displayContractDetails?.plan,
    [displayContractDetails]
  );

  const payment = useMemo<PaymentForContractDetailScreen | undefined>(
    () => displayContractDetails?.payment,
    [displayContractDetails]
  );

  const paymentMethodLabel = useMemo<Label>(() => {
    if (!payment) return EMPTY;
    switch (payment.method) {
      case PaymentMethod.CREDIT_CARD:
        return `${PAYMENT_METHOD_LABELS[payment.method]}（${
          payment.count === ONE ? '一括' : `${payment.count}回払い`
        }）`;
      default:
        return PAYMENT_METHOD_LABELS[payment.method];
    }
  }, [payment]);

  const rows = useMemo<DetailRow[]>(() => {
    if (!plan || !hasLength(plan.discounts)) return [];
    return plan.discounts.map(discount =>
      builder<DetailRow>()
        .id(discount.itemId)
        .itemName(discount.itemName)
        .unitPrice(Math.abs(discount.unitPrice).toLocaleString())
        .recurrenceCount(`${discount.recurrenceCount}ヵ月`)
        .build()
    );
  }, [plan]);

  const isPayWithPayment = useMemo(() => {
    return isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_STRIPE);
  }, [base]);

  return (
    <Component className='contract-detail'>
      <Container>
        <Contents>
          {detail && (
            <DetailContent>
              <ContentTitle>基本情報</ContentTitle>
              <Rows>
                <CardWithLabelV2 label='契約番号' text={detail.contractNo} />
                <Row>
                  <CardWithLabelV2 label='契約開始日' text={detail.startDate.toLocaleDateString()} />
                  <CardWithLabelV2 label='契約終了日' text={detail.endDate?.toLocaleDateString() || '未定'} />
                </Row>
                <CardWithLabelV2 label='契約者' text={detail.contractor} />
                <CardWithLabelV2 label='契約者住所' text={constructFullContractorAddress(detail)} />
                <CardWithLabelV2 label='契約先担当者' text={detail.contractorName} />
                <CardWithLabelV2 label='契約先担当者 メールアドレス' text={detail.contractorEmail} />
                <CardWithLabelV2 label='契約先担当者 電話番号' text={detail.contractorPhone} />
                <CardWithLabelV2 label='備考' text={detail.remarks} />
              </Rows>
            </DetailContent>
          )}
          {plan && (
            <PlanContent>
              <ContentTitle>契約プラン</ContentTitle>
              <Rows>
                <CustomRow>
                  <LabelV2 text='基本プラン' />
                  <CustomCard>
                    <BasicPlanName>{plan.planName}</BasicPlanName>
                    <SubText>{`基本料金: \xA5${plan.basicCharge.toLocaleString()} / 月（税込）`}</SubText>
                    <SubText>{`会議室利用: ${
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      parseFloat(plan.freeTier as any) || ZERO
                    }時間まで無料`}</SubText>
                    <SubText>{`会議室利用料の割引率: ${plan.discountRate}%`}</SubText>
                  </CustomCard>

                  {isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USER_POINT) && (
                    <>
                      <LabelV2 text='ポイント' />
                      <CustomCard>
                        <MainText>{`${contract?.grantedPoints?.toLocaleString()} ポイント / 月`}</MainText>
                        <SubText>
                          ポイント付与日:
                          {contract?.pointGrantDate
                            ? `毎月${contract.pointGrantDate}日`
                            : '未指定のため、付与されません'}
                        </SubText>
                        <SubText>ポイント有効期限:付与日の月末23:59 まで</SubText>
                      </CustomCard>
                    </>
                  )}
                </CustomRow>

                <CardWithLabelV2 label='鍵利用' text={`${plan.usersCount}名`} />

                <CardWithLabelV2 label='住所利用' text={plan.useAddress ? '利用する' : '利用しない'} />
                <CardWithLabelV2
                  label='ロッカー利用'
                  text={plan.lockerCount ? `利用（${plan.lockerCount}個）` : '利用しない'}
                />

                <CustomRow>
                  <LabelV2 text='割引' />
                  <DiscountField>
                    {hasLength(plan.discounts) && (
                      <TableV3 showBorder shape='circle' colWidth={COLUMN_WIDTH} header={HEADER} rows={rows} />
                    )}
                    {!hasLength(plan.discounts) && <LabelV2 text='割引項目はありません' />}
                  </DiscountField>
                </CustomRow>
              </Rows>
            </PlanContent>
          )}
          {isPayWithPayment ? (
            <ContractDetailsPayment base={base} contract={contract} creditCard={creditCard} />
          ) : (
            <PaymentContent>
              <ContentTitle>支払方法</ContentTitle>
              <Rows>
                <CardV2 text={paymentMethodLabel} />
              </Rows>
            </PaymentContent>
          )}
        </Contents>
      </Container>
    </Component>
  );
});

ContractDetail.displayName = 'ContractDetail';
export default ContractDetail;

const Container = styled.div`
  width: 100%;
`;
const Contents = styled.div`
  display: grid;
  grid-template-areas:
    'detail plan'
    'detail payment';
  grid-template-rows: max-content 1fr;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  padding: 0px 0px ${themeV2.mixins.v2.spacing * 8}px;
`;
const Content = styled.div`
  background-color: ${themeV2.mixins.v2.color.background.white};
  border-radius: 8px;
  padding: ${`${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px`};
`;
const DetailContent = styled(Content)`
  grid-area: detail;
  height: min-content;
`;
const PlanContent = styled(Content)`
  grid-area: plan;
`;
const PaymentContent = styled(Content)`
  grid-area: payment;
`;

const ContentTitle = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.black};
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
  padding: ${themeV2.mixins.v2.spacing}px 0;
`;

const Rows = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 3}px 0;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;
const Row = styled.div`
  display: flex;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;
const CustomRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;
const CustomCard = styled.div`
  background-color: ${themeV2.mixins.v2.color.background.offWhite};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  gap: ${themeV2.mixins.v2.spacing / 4}px;
`;
const BasicPlanName = styled(Typography)`
  color: #4f4f4f; //FIXME
  ${themeV2.mixins.v2.typography.label.large}
`;
const MainText = styled(Typography)`
  font-size: ${themeV2.mixins.v2.typography.body.medium}px;
  color: ${themeV2.mixins.v2.color.font.black};
`;
const SubText = styled(Typography)`
  font-size: ${themeV2.mixins.v2.typography.body.small}px;
  color: ${themeV2.mixins.v2.color.font.gray};
`;
const DiscountField = styled.div``;
