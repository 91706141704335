import {
  ButtonV2,
  IconButtonV2,
  InputWithLabelV2,
  LabelV2,
  PullDownOption,
  PullDownV2,
  SwitchV2,
  TextFieldV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { QuestionnaireItem, QuestionnaireItemOption, QuestionnaireItemType } from '@atomica.co/irori';
import { Gap, Index, Name, Option, Text } from '@atomica.co/types';
import { builder, uuid } from '@atomica.co/utils';
import CheckBoxOutlineBlankRoundedIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { QUESTIONNAIRE_ITEM_TYPE_LABEL } from '../../../texts/questionnaire-text';
import { partialBuilder } from '../../../utils/common-util';

interface P {
  isEditOnly: boolean;
  isSelected: boolean;
  isQuestionFocused: boolean;
  item: QuestionnaireItem;
  onChange: (item: QuestionnaireItem) => void;
  onCopy: (item: QuestionnaireItem) => void;
  onDelete: () => void;
  onFocus: () => void;
}

const pullDownOptions = Object.values(QuestionnaireItemType).map(type =>
  builder<PullDownOption>().id(type).label(QUESTIONNAIRE_ITEM_TYPE_LABEL[type]).build()
);

const getItemToCopy = (
  name: Name,
  type: QuestionnaireItemType,
  isAnswerRequired: boolean,
  QuestionnaireItemOptions: QuestionnaireItemOption[]
) => {
  const newQuestionnaireItemOptions = QuestionnaireItemOptions.map(option =>
    partialBuilder(option, { questionnaireItemOptionId: uuid() })
  );
  return builder<QuestionnaireItem>()
    .questionnaireItemId(uuid())
    .name(name)
    .type(type)
    .isAnswerRequired(isAnswerRequired)
    .QuestionnaireItemOptions(newQuestionnaireItemOptions)
    .build();
};

const orderOptions = (options: QuestionnaireItemOption[]): QuestionnaireItemOption[] => {
  return options.map((option, order) => partialBuilder(option, { order }));
};

const QuestionCard: React.FC<P> = React.memo(props => {
  const { isEditOnly, isSelected, isQuestionFocused, item, onChange, onCopy, onDelete, onFocus } = props;
  const { name, type, QuestionnaireItemOptions = [], isAnswerRequired } = item;

  const unmountRef = useUnmountRef();
  const [selectedIdx, setselectedIdx] = useSafeState<Index>(unmountRef, -1);

  const hasOtherItemOption = useMemo<boolean>(
    () => QuestionnaireItemOptions?.some(option => option.isOtherItemOption) || false,
    [QuestionnaireItemOptions]
  );

  const icon = useMemo<JSX.Element>(() => {
    switch (type) {
      case QuestionnaireItemType.CHECKBOX:
        return <StyledCheckBoxOutlineBlankRoundedIcon />;
      case QuestionnaireItemType.RADIO:
        return <StyledRadioButtonUncheckedRoundedIcon />;
      case QuestionnaireItemType.TEXT:
      default:
        return <></>;
    }
  }, [type]);

  const handleQuestionChanged = useSafeCallback(
    (name: Text): void => {
      onChange(partialBuilder(item, { name }));
    },
    [item, onChange]
  );

  const handlePullDownChanged = useSafeCallback(
    (type: QuestionnaireItemType): void => {
      onChange(partialBuilder(item, { type }));
    },
    [item, onChange]
  );

  const handleOptionChanged = useSafeCallback(
    (newOption: Option, index: Index): void => {
      QuestionnaireItemOptions.splice(index, 1, partialBuilder(QuestionnaireItemOptions[index], { name: newOption }));
      onChange(partialBuilder(item, { QuestionnaireItemOptions: QuestionnaireItemOptions.concat() }));
    },
    [item, onChange, QuestionnaireItemOptions]
  );

  const handleOptionInserted = useSafeCallback(
    (name: Name, index: Index, isOtherItemOption = false): void => {
      if (isEditOnly) return;
      const newQuestionnaireItemOption = builder<QuestionnaireItemOption>()
        .questionnaireItemOptionId(uuid())
        .name(name)
        .order(index)
        .isOtherItemOption(isOtherItemOption)
        .build();
      QuestionnaireItemOptions.splice(index, 0, newQuestionnaireItemOption);
      const newQuestionnaireItemOptions = orderOptions(QuestionnaireItemOptions);
      onChange(partialBuilder(item, { QuestionnaireItemOptions: newQuestionnaireItemOptions }));
      setselectedIdx(index);
    },
    [item, isEditOnly, onChange, QuestionnaireItemOptions, setselectedIdx]
  );

  const handleOptionDeleted = useSafeCallback(
    (targetIndex: Index): void => {
      const newQuestionnaireItemOptions = orderOptions(
        QuestionnaireItemOptions.filter((_, index) => index !== targetIndex)
      );
      onChange(partialBuilder(item, { QuestionnaireItemOptions: newQuestionnaireItemOptions }));
    },
    [item, onChange, QuestionnaireItemOptions]
  );

  const handleAddButtonClicked = useSafeCallback((): void => {
    const index = hasOtherItemOption ? QuestionnaireItemOptions.length - 1 : QuestionnaireItemOptions.length;
    handleOptionInserted(`選択肢${index + 1}`, index);
  }, [handleOptionInserted, hasOtherItemOption, QuestionnaireItemOptions]);

  const handleSWitchChanged = useSafeCallback(
    (isAnswerRequired: boolean): void => {
      onChange(partialBuilder(item, { isAnswerRequired }));
    },
    [item, onChange]
  );

  useEffect(() => {
    if (selectedIdx) setselectedIdx(-1);
  }, [selectedIdx, setselectedIdx]);

  return (
    <Container isSelected={isSelected}>
      <Content>
        <InputWithLabelV2
          focusAndScroll={isQuestionFocused}
          required
          text='質問文'
          value={name}
          onChange={handleQuestionChanged}
          onFocus={onFocus}
        />
        <PullDownWrapper>
          <LabelV2 text='回答形式' />
          <PullDownV2 disabled={isEditOnly} id={type} options={pullDownOptions} onClick={handlePullDownChanged} />
        </PullDownWrapper>
        {type !== QuestionnaireItemType.TEXT && (
          <OptionWrapper>
            <LabelV2 text='選択肢' />
            <OptionWrapper gap={8}>
              {QuestionnaireItemOptions.map((option, index) => (
                <OptionRow key={`option-row-${index}`}>
                  {icon}
                  <TextFieldV2
                    focused={index === selectedIdx}
                    readonly={option.isOtherItemOption}
                    value={option.name}
                    onChange={value => handleOptionChanged(value, index)}
                    onEnter={() => handleOptionInserted(`選択肢${index + 2}`, index + 1)}
                    onFocus={onFocus}
                  />
                  {QuestionnaireItemOptions.length > 1 && !isEditOnly && (
                    <IconButtonV2 icon={<ClearRoundedIcon />} onClick={() => handleOptionDeleted(index)} />
                  )}
                </OptionRow>
              ))}
              {!isEditOnly && (
                <OptionRow>
                  {icon}
                  <ButtonV2 label='選択肢を追加' onClick={handleAddButtonClicked} />
                  {!hasOtherItemOption && (
                    <>
                      または
                      <ButtonV2
                        label='「その他」を追加'
                        onClick={() => handleOptionInserted('その他', QuestionnaireItemOptions.length, true)}
                      />
                    </>
                  )}
                </OptionRow>
              )}
            </OptionWrapper>
          </OptionWrapper>
        )}
      </Content>
      {!isEditOnly && (
        <Footer>
          <SwitchRow>
            <Label>必須</Label>
            <SwitchV2 checked={isAnswerRequired} onChange={handleSWitchChanged} />
          </SwitchRow>

          <ButtonWrapper>
            <ButtonV2
              startIcon={<FileCopyOutlinedIcon />}
              label='コピー'
              onClick={() => onCopy(getItemToCopy(name, type, isAnswerRequired, QuestionnaireItemOptions))}
            />
            <ButtonV2 startIcon={<DeleteOutlineRoundedIcon />} label='削除' onClick={onDelete} />
          </ButtonWrapper>
        </Footer>
      )}
    </Container>
  );
});

QuestionCard.displayName = 'QuestionCard';
export default QuestionCard;

const Container = styled.div<{ isSelected: boolean }>`
  border: 2px solid
    ${({ isSelected }) =>
      isSelected ? themeV2.mixins.v2.color.border.pink : themeV2.mixins.v2.color.background.offWhite};
  border-radius: 8px;
  background: ${themeV2.mixins.v2.color.background.offWhite};
  box-shadow: ${themeV2.mixins.v2.shadow.elevation1};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 3}px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing / 2}px;
`;

const PullDownWrapper = styled(Wrapper)`
  width: 280px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const OptionWrapper = styled(Wrapper)<{ gap?: Gap }>`
  gap: ${({ gap = themeV2.mixins.v2.spacing / 2 }) => gap}px;
`;

const OptionRow = styled(Row)``;

const StyledRadioButtonUncheckedRoundedIcon = styled(RadioButtonUncheckedRoundedIcon)`
  color: ${themeV2.mixins.v2.color.font.lightGray};
`;

const StyledCheckBoxOutlineBlankRoundedIcon = styled(CheckBoxOutlineBlankRoundedIcon)`
  color: ${themeV2.mixins.v2.color.font.lightGray};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${themeV2.mixins.v2.color.border.gray};
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
`;

const SwitchRow = styled(Row)``;

const Label = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;
