import {
  ButtonV2,
  ColWidth,
  Component,
  Header,
  MoreHorizMenuForTable,
  SearchListV2,
  SortCol,
  StatusV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  ContractUserId,
  ContractUserV2,
  ContractUsersSortColumn,
  ContractV2,
  DELETE_CONTRACT_USER_V2_FOR_ADMIN,
  DeleteContractUserV2ForAdminRequest,
  DeleteContractUserV2ForAdminResponse,
  InvalidSendInvitationDataForAdmin,
  InvitationStatus,
  SEARCH_CONTRACT_USERS_V2_FOR_ADMIN,
  SEND_INVITATION_TO_CONTRACT_USERS_V2_FOR_ADMIN,
  SearchContractUsersV2ForAdminRequest,
  SearchContractUsersV2ForAdminResponse,
  SendInvitationToContractUsersV2ForAdminRequest,
  SendInvitationToContractUsersV2ForAdminResponse,
  Sort,
  toFullName
} from '@atomica.co/irori';
import { Count, Email, Index, Name, Offset, Phone, Word } from '@atomica.co/types';
import { EMPTY, ZERO, builder, hasLength } from '@atomica.co/utils';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ERROR, SUCCESS } from '../../../constants/snackbar-const';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import { CONTRACT_USER_STATUS_LABELS } from '../../../texts/contract-v2-text';
import {
  CANCEL_INVITATION_ERROR,
  CANCEL_INVITATION_SUCCESS,
  SEND_INVITATION_SUCCESS
} from '../../../texts/snackbar-text';
import InviteMemberModal from '../modal/InviteMemberModal';

const LIMIT = 50;

const COLUMN_WIDTH: ColWidth = { icon: 56, email: 'auto', name: 180, phone: 180, status: 120 };

const HEADER: Header = {
  icon: { label: EMPTY },
  email: { label: 'メールアドレス' },
  name: { label: '氏名' },
  phone: { label: '電話番号' },
  status: { label: '登録状況' }
};

const SORT_COLUMNS: SortCol = {
  email: ContractUsersSortColumn.INVITAION_EMAIL,
  name: ContractUsersSortColumn.USER_FAMILY_NAME,
  phone: ContractUsersSortColumn.USER_PHONE,
  status: ContractUsersSortColumn.INVITAION_STATUS
};

interface ContractUserRow {
  id: ContractUserId;
  icon: JSX.Element;
  email: Email;
  name: Name;
  phone: Phone;
  status: JSX.Element;
}

interface P {
  base: BaseDto;
  contract: ContractV2 | undefined;
}

const ContractUsers: React.FC<P> = React.memo(props => {
  const { base, contract } = props;
  const unmountRef = useUnmountRef();
  const { enqueueSnackbar } = useSnackbar();
  const { commonRequest } = useCommonRequest();

  const [offset, setOffset] = useSafeState<Offset>(unmountRef, ZERO);
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, EMPTY);
  const [contractUsers, setContractUsers] = useSafeState<ContractUserV2[]>(unmountRef, []);
  const [selectedContractUserIds, setSelectedContractUserIds] = useSafeState<ContractUserId[]>(unmountRef, []);
  const [totalRecordCount, setTotalRecordCount] = useSafeState<Count>(unmountRef, 0);
  const [sortKey, setSortKey] = useSafeState<ContractUsersSortColumn>(
    unmountRef,
    ContractUsersSortColumn.INVITAION_STATUS
  );
  const [sort, setSort] = useSafeState<Sort>(unmountRef, Sort.ASC);
  const [invalidSendInvitationData, setInvalidSendInvitationData] = useSafeState<InvalidSendInvitationDataForAdmin[]>(
    unmountRef,
    []
  );
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, true);
  const [rows, setRows] = useSafeState<ContractUserRow[]>(unmountRef, []);

  const handleSortColClicked = useSafeCallback(
    (sortKey: ContractUsersSortColumn, sort: Sort): void => {
      setSortKey(sortKey);
      setSort(sort);
    },
    [setSortKey, setSort]
  );

  const initContractUsers = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const request = builder<SearchContractUsersV2ForAdminRequest>()
      .baseId(base.baseId)
      .contractId(contract!.contractId)
      .limit(LIMIT)
      .offset(offset)
      .word(searchingWord)
      .sortCol(sortKey)
      .sort(sort)
      .build();
    const { contractUsers, count } = await commonRequest<
      SearchContractUsersV2ForAdminRequest,
      SearchContractUsersV2ForAdminResponse
    >(SEARCH_CONTRACT_USERS_V2_FOR_ADMIN, request);

    const rows = contractUsers.map(contractUser => {
      const { user } = contractUser;
      return builder<ContractUserRow>()
        .id(contractUser.contractUserId)
        .icon(user ? <Icon src={user.photoURL} /> : <CustomFaceOutlinedIcon />)
        .email(contractUser.invitationEmail)
        .name(user ? toFullName(user) : '未登録')
        .phone(user && user.phone ? user.phone : '未登録')
        .status(
          <StatusV2
            label={CONTRACT_USER_STATUS_LABELS[contractUser.invitationStatus]}
            variant={contractUser.invitationStatus === InvitationStatus.INVITED ? 'outlined' : 'normal'}
            status={
              contractUser.invitationStatus === InvitationStatus.INVITED
                ? 'pink'
                : contractUser.invitationStatus === InvitationStatus.DELETED
                  ? 'default'
                  : 'success'
            }
            width={74}
          />
        )
        .build();
    });

    setInvalidSendInvitationData([]);
    setRows(rows);
    setContractUsers(contractUsers);
    setTotalRecordCount(count);
    setIsLoaderShown(false);
  }, [
    base,
    commonRequest,
    contract,
    offset,
    searchingWord,
    setContractUsers,
    setInvalidSendInvitationData,
    setIsLoaderShown,
    setRows,
    setTotalRecordCount,
    sortKey,
    sort
  ]);

  const handleSearchingWordChanged = useSafeCallback(
    (word: Word): void => {
      setSearchingWord(word);
      setOffset(0);
    },
    [setSearchingWord, setOffset]
  );

  const findContractUserByIndex = useSafeCallback(
    (index: Index) => contractUsers.find(contractUser => contractUser.contractUserId === rows[index].id),
    [contractUsers, rows]
  );

  const cancelInvitaion = useSafeCallback(
    async (contractUserId: ContractUserId): Promise<void> => {
      setIsLoaderShown(true);
      const request = builder<DeleteContractUserV2ForAdminRequest>()
        .baseId(base.baseId)
        .contractUserId(contractUserId)
        .build();
      const response = await commonRequest<DeleteContractUserV2ForAdminRequest, DeleteContractUserV2ForAdminResponse>(
        DELETE_CONTRACT_USER_V2_FOR_ADMIN,
        request
      );

      const isSavedSuccessfully = response && response.contractUserId;
      enqueueSnackbar(isSavedSuccessfully ? CANCEL_INVITATION_SUCCESS : CANCEL_INVITATION_ERROR, {
        variant: isSavedSuccessfully ? SUCCESS : ERROR
      });
      setIsLoaderShown(false);
      if (!isLoaderShown) initContractUsers();
    },
    [isLoaderShown, setIsLoaderShown, enqueueSnackbar, initContractUsers, base]
  );

  useEffect(() => {
    initContractUsers();
  }, [initContractUsers]);

  const openModal = useSafeCallback((): void => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const onCloseModal = useSafeCallback((): void => {
    initContractUsers();
    setIsModalOpen(false);
  }, [initContractUsers, setIsModalOpen]);

  const sendInvitaionEmail = useSafeCallback(
    async (emails: Email[]): Promise<void> => {
      const request = builder<SendInvitationToContractUsersV2ForAdminRequest>()
        .baseId(base.baseId)
        .contractId(contract!.contractId)
        .emails(emails)
        .build();
      const response = await commonRequest<
        SendInvitationToContractUsersV2ForAdminRequest,
        SendInvitationToContractUsersV2ForAdminResponse
      >(SEND_INVITATION_TO_CONTRACT_USERS_V2_FOR_ADMIN, request);

      const invalidSendInvitationData: InvalidSendInvitationDataForAdmin[] = response.invalidSendInvitationData;
      const isSavedFailure = hasLength(invalidSendInvitationData);

      if (isSavedFailure) {
        setInvalidSendInvitationData(invalidSendInvitationData);
        return;
      }

      enqueueSnackbar(SEND_INVITATION_SUCCESS, {
        variant: SUCCESS,
        autoHideDuration: 5000
      });

      onCloseModal();
    },
    [base.baseId, commonRequest, contract, enqueueSnackbar, onCloseModal, setInvalidSendInvitationData]
  );

  const resendInvitaionEmail = useSafeCallback(
    async (invitationEmail: Email): Promise<void> => {
      setIsLoaderShown(true);
      await sendInvitaionEmail([invitationEmail]);
      setIsLoaderShown(false);
    },
    [sendInvitaionEmail, setIsLoaderShown]
  );

  const getMoreHorizMenusForTable = useSafeCallback(
    (rowIndex: Index): MoreHorizMenuForTable[] => {
      const contractUser = findContractUserByIndex(rowIndex);
      if (!contractUser) return [];
      const { invitationStatus, invitationEmail, contractUserId } = contractUser;
      switch (invitationStatus) {
        case InvitationStatus.REGISTERED:
          return [
            {
              label: 'メンバーの削除',
              onClick: async (): Promise<void> => await cancelInvitaion(contractUserId)
            }
          ];
        case InvitationStatus.INVITED:
        default:
          return [
            {
              label: '招待メールを再送信',
              color: themeV2.mixins.v2.color.font.black,
              onClick: async (): Promise<void> => await resendInvitaionEmail(invitationEmail)
            },
            {
              label: '招待の取消',
              onClick: async (): Promise<void> => await cancelInvitaion(contractUserId)
            }
          ];
      }
    },
    [cancelInvitaion, resendInvitaionEmail]
  );

  return (
    <Component className='contract-users' style={styleForComponent}>
      <Container>
        <SearchListWrapper>
          <SearchListV2
            name='メンバー'
            title={`${totalRecordCount}人のメンバー`}
            sortKey={sortKey}
            sortCol={SORT_COLUMNS}
            colWidth={COLUMN_WIDTH}
            header={HEADER}
            rows={rows}
            offset={offset}
            limit={LIMIT}
            totalCount={totalRecordCount}
            placeholder='メールアドレス・氏名・電話番号'
            searchingWord={searchingWord}
            isLoaderShown={isLoaderShown}
            selectedIds={selectedContractUserIds}
            headerRightComponent={<ButtonV2 type='primary' onClick={openModal} label='メンバーを追加' />}
            getMoreHorizMenus={getMoreHorizMenusForTable}
            onChange={handleSearchingWordChanged}
            onClickSortCol={handleSortColClicked}
            setOffset={setOffset}
            setSelectedIds={setSelectedContractUserIds}
          />
        </SearchListWrapper>
        <InviteMemberModal
          existingContractUsers={contractUsers}
          invalidSendInvitationData={invalidSendInvitationData}
          isOpen={isModalOpen}
          onClose={onCloseModal}
          sendInvitation={sendInvitaionEmail}
        />
      </Container>
    </Component>
  );
});

ContractUsers.displayName = 'ContractUsers';
export default ContractUsers;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const SearchListWrapper = styled.div`
  display: flex;
  margin-top: ${themeV2.mixins.v2.spacing * 2}px;
  padding-bottom: ${themeV2.mixins.v2.spacing * 8}px;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
`;

const CustomFaceOutlinedIcon = styled(FaceOutlinedIcon)`
  font-size: 40px;
  color: ${themeV2.mixins.v2.color.border.gray};
`;

const styleForComponent: CSSProperties = {
  width: '100%',
  height: '100%'
};
