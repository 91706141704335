import {
  ButtonV2,
  CheckBoxV2,
  StatusV2,
  TableV3,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  SEARCH_WISHES_FOR_ADMIN,
  SearchWishesForAdminRequest,
  SearchWishesForAdminResponse,
  User,
  Wish,
  WishStatus,
  toFullName
} from '@atomica.co/irori';
import { Count, Index } from '@atomica.co/types';
import { NOT_FOUND_INDEX, ZERO, builder, hasLength } from '@atomica.co/utils';
import AddIcon from '@material-ui/icons/Add';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import useCommonRequest from '../../../../redux/hooks/useCommonRequest';
import { WISH_STATUS_TEXT } from '../../../../texts/wish-text';
import { toStatus } from '../../../../utils/wish-util';
import { WISH_COLUMN_WIDTH, WISH_HEADER, WishRow } from '../../../wish/WishListScreen';
import RegisterWishModal from '../../modal/RegisterWishModal';

const LIMIT = 5;

const INITIAL_STATUSES = [WishStatus.TODO, WishStatus.IN_PROGRESS, WishStatus.WAITING];

interface P {
  base: BaseDto;
  selectedUser: User;
  signInUser: User;
}

const WishSection: React.FC<P> = React.memo(props => {
  const { base, selectedUser, signInUser } = props;
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [statuses, setStatuses] = useSafeState<WishStatus[]>(unmountRef, []);
  const [selectedWish, setSelectedWish] = useSafeState<Wish | undefined>(unmountRef);
  const [wishes, setWishes] = useSafeState<Wish[]>(unmountRef, []);
  const [totalRecordCount, setTotalRecordCount] = useSafeState<Count | undefined>(unmountRef, ZERO);
  const [rows, setRows] = useSafeState<WishRow[]>(unmountRef, []);
  // const [offset, setOffset] = useSafeState<Offset>(unmountRef, ZERO);
  const [isInitialized, setIsInitialized] = useSafeState<boolean>(unmountRef, false);

  const hasWishes = useRef<boolean>(false);

  const searchWishes = useSafeCallback(
    async (statuses: WishStatus[]): Promise<void> => {
      const request = builder<SearchWishesForAdminRequest>()
        .baseId(base.baseId)
        .fromUserIds([selectedUser.userId])
        .statuses(statuses)
        .limit(LIMIT)
        .offset(ZERO)
        .build();
      const response = await commonRequest<SearchWishesForAdminRequest, SearchWishesForAdminResponse>(
        SEARCH_WISHES_FOR_ADMIN,
        request
      );
      const { wishes, totalCount } = response;

      if (!isInitialized && hasLength(wishes)) hasWishes.current = true;

      const rows = wishes.map(wish =>
        builder<WishRow>()
          .id(wish.wishId)
          .title(wish.content)
          .content(wish.remarks)
          .fromUser(
            <>
              {wish.fromUser && (
                <>
                  <UserText>{toFullName(wish.fromUser)}</UserText>
                  {wish.fromUser.companyName && <UserText>{wish.fromUser.companyName}</UserText>}
                </>
              )}
            </>
          )
          .status(<StatusV2 label={WISH_STATUS_TEXT[wish.status]} status={toStatus(wish.status)} width={74} />)
          .toUser(wish.toUser?.photoURL ? <TableImage src={wish.toUser.photoURL} /> : toFullName(wish.toUser))
          .build()
      );

      setRows(rows);
      setWishes(wishes);
      setTotalRecordCount(totalCount);
      setIsInitialized(true);
    },
    [
      base,
      commonRequest,
      isInitialized,
      selectedUser,
      // offset,
      setIsInitialized,
      setRows,
      setWishes,
      setTotalRecordCount
    ]
  );

  const initialize = useSafeCallback(async (): Promise<void> => {
    await searchWishes(statuses);
  }, [searchWishes, statuses]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleCheckBoxClicked = useSafeCallback(async (): Promise<void> => {
    setStatuses(prevStatuses => {
      const statusesToUpdate = hasLength(prevStatuses) ? [] : INITIAL_STATUSES;
      searchWishes(statusesToUpdate);
      return statusesToUpdate;
    });
  }, [searchWishes, setStatuses]);

  const handleWishClicked = useSafeCallback(
    (index: Index): void => {
      setSelectedWish(wishes.find(wish => wish.wishId === rows[index].id));
      setIsModalOpen(true);
    },
    [rows, wishes, setSelectedWish, setIsModalOpen]
  );

  const handleModalClosed = useSafeCallback((): void => {
    setSelectedWish(undefined);
    setIsModalOpen(false);
  }, [setSelectedWish, setIsModalOpen]);

  const handleWishSaved = useSafeCallback(
    async (savedWish: Wish): Promise<void> => {
      setWishes(prevWishes => {
        const index = prevWishes.findIndex(wish => wish.wishId === savedWish.wishId);
        index !== NOT_FOUND_INDEX && (prevWishes[index] = savedWish);
        return index === NOT_FOUND_INDEX ? [savedWish, ...prevWishes] : prevWishes;
      });
      setIsModalOpen(false);
      setIsInitialized(false);
      await searchWishes(statuses);
    },
    [searchWishes, setWishes, setIsInitialized, setIsModalOpen, statuses]
  );

  return (
    <Container>
      {!isInitialized && (
        <SkeletonArea>
          <SkeletonRow>
            <SkeletonHeaderLabel>
              <StyledSkeleton />
            </SkeletonHeaderLabel>
          </SkeletonRow>
          <SkeletonBody>
            {[0, 1].map(idx => (
              <SkeletonRow key={`skeleton-${idx}`}>
                <SkeletonColLeft>
                  <StyledSkeleton />
                </SkeletonColLeft>
                <SkeletonColCenter>
                  <StyledSkeleton />
                </SkeletonColCenter>
                <SkeletonColRight>
                  <StyledSkeleton />
                </SkeletonColRight>
                <SkeletonColRight>
                  <StyledSkeleton />
                </SkeletonColRight>
              </SkeletonRow>
            ))}
          </SkeletonBody>
        </SkeletonArea>
      )}
      {isInitialized && (
        <HeaderRow>
          <TitleWrapper>
            <Title>{hasWishes.current ? `${totalRecordCount}件のWish` : 'Wish'}</Title>

            {hasWishes.current && (
              <CheckBoxV2 checked={hasLength(statuses)} onChange={handleCheckBoxClicked}>
                未実現のWishのみ表示
              </CheckBoxV2>
            )}
          </TitleWrapper>

          {hasWishes.current && (
            <ButtonV2 startIcon={<AddIcon />} label='新規作成' onClick={() => setIsModalOpen(true)} />
          )}
        </HeaderRow>
      )}

      {isInitialized && hasWishes.current && (
        <TableWrapper>
          <StyledTable
            shape='circle'
            colWidth={WISH_COLUMN_WIDTH}
            header={WISH_HEADER}
            rows={rows}
            onClickRow={handleWishClicked}
          />
        </TableWrapper>
      )}

      {isInitialized && !hasWishes.current && (
        <NoIntroArea>
          <NoIntroMessage>まだWishがありません。</NoIntroMessage>
          <ButtonV2
            type='secondary'
            label='新規作成'
            startIcon={<AddIcon color='inherit' />}
            onClick={() => setIsModalOpen(true)}
          />
        </NoIntroArea>
      )}

      <RegisterWishModal
        isOpen={isModalOpen}
        base={base}
        selectedUser={selectedUser}
        signInUser={signInUser}
        prevWish={selectedWish}
        onSave={handleWishSaved}
        onClose={handleModalClosed}
      />
    </Container>
  );
});

WishSection.displayName = 'WishSection';
export default WishSection;

const Container = styled.div`
  background-color: ${themeV2.mixins.v2.color.background.white};
  border-radius: 8px;
`;
const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${themeV2.mixins.v2.color.font.black};
  padding: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 3}px;
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const Title = styled.div`
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.black};
  min-width: max-content;
`;

const TableWrapper = styled.div``;

const TableImage = styled.img`
  max-height: 40px;
  max-width: 40px;
  border-radius: 20px;
  overflow: hidden;
  border: 2px solid ${themeV2.mixins.v2.color.border.gray};
`;

const NoIntroArea = styled.div`
  width: 100%;
  min-height: 120px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const NoIntroMessage = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  margin-bottom: ${themeV2.mixins.v2.spacing * 2}px;
`;

const StyledTable = styled(TableV3)`
  //FIX_ME
  > tbody {
    > tr {
      &:last-child {
        > td {
          border-bottom: none !important;
        }
      }
    }
  }
`;

const StyledSkeleton = styled(Skeleton)`
  display: inline-block;
  width: 100%;
  height: 100%;
`;
const SkeletonArea = styled.div`
  width: 100%;
  height: 140px;
  padding: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 3}px;
  display: flex;
  flex-direction: column;
`;
const SkeletonHeaderLabel = styled.div`
  height: 100%;
  width: 240px;
`;
const SkeletonBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  justify-content: end;
`;
const SkeletonRow = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const SkeletonColLeft = styled.div`
  width: 100px;
`;
const SkeletonColCenter = styled.div`
  flex: 1;
`;
const SkeletonColRight = styled.div`
  width: 100px;
  margin-left: auto;
`;

const UserText = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
`;
