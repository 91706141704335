import { ButtonV2, PageHeaderV2, themeV3, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { BaseDto, DropInPlanId, SpaceId } from '@atomica.co/irori';
import { Id, Name, Price, Rate } from '@atomica.co/types';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { SearchDropInItems200DropInItemsItem } from '../../__generated/model';
import { getBsSpace } from '../../__generated/user/bs-space/bs-space';
import { convertToAvailableTimes } from '../../converters/space-converter';
import usePath from '../../redux/hooks/usePath';
import { Path } from '../../router/Routes';
import SpaceCard from '../space/card/SpaceCard';

export type DropInItemForHistoryState = {
  spaceId: SpaceId | undefined;
  spaceName: Name | undefined;
  spacePlanId: DropInPlanId | undefined;
  planName: Name | undefined;
  unitPrice: Price | undefined;
  taxRate: Rate | undefined;
};

interface P {
  base: BaseDto;
}

const DropInEntryScreen: React.FC<P> = React.memo(props => {
  const { base } = props;

  const { openBasePath } = usePath();

  const unmountRef = useUnmountRef();
  const [dropInItems, setDropInItems] = useSafeState<SearchDropInItems200DropInItemsItem[]>(unmountRef);
  const [selectedDropInItemId, setSelectedDropInItemId] = useSafeState<Id>(unmountRef);
  const [selectedSpacePlanId, setSelectedSpacePlanId] = useSafeState<Id>(unmountRef);

  const selectedDropItem = useMemo<SearchDropInItems200DropInItemsItem | undefined>(() => {
    return dropInItems?.find(item => item.spaceId === selectedDropInItemId);
  }, [dropInItems, selectedDropInItemId]);

  const searchDropInItems = useSafeCallback(async (): Promise<void> => {
    const response = await getBsSpace().searchDropInItems(base.baseId);
    setDropInItems(response.data.dropInItems);
  }, [base.baseId, setDropInItems]);

  const openDropInConfirmScreen = useSafeCallback((): void => {
    const selectedDropPlan = selectedDropItem?.plans.find(plan => plan.spacePlanId === selectedSpacePlanId);

    openBasePath<DropInItemForHistoryState>(Path.DROP_IN_CONFIRM, {
      spaceId: selectedDropItem?.spaceId,
      spaceName: selectedDropItem?.spaceName,
      spacePlanId: selectedDropPlan?.spacePlanId,
      planName: selectedDropPlan?.planName,
      unitPrice: selectedDropPlan?.totalPrice,
      taxRate: selectedDropPlan?.taxRate
    });
  }, [
    openBasePath,
    selectedDropItem?.plans,
    selectedDropItem?.spaceId,
    selectedDropItem?.spaceName,
    selectedSpacePlanId
  ]);

  useEffect(() => {
    searchDropInItems();
  }, [searchDropInItems]);

  return (
    <Container data-testid='drop-in-entry-screen'>
      <PageHeaderV2 title='ドロップイン' />
      <Content data-testid='select-space'>
        <TextWrapper>
          <Title>利用スペースの選択（1/2）</Title>
          <BodyLarge>ドロップイン利用したいスペースを選択してください。</BodyLarge>
        </TextWrapper>
        <DropInItems>
          {dropInItems?.map((item, idx) => (
            <SpaceCard
              key={`space-card-${idx}`}
              id={item.spaceId}
              selectedIds={selectedDropInItemId ? [selectedDropInItemId] : []}
              handleClicked={setSelectedDropInItemId}
            >
              <CardBody>
                <Title>{item.spaceName}</Title>
                <LabelLarge>料金: ￥{item.minimumPrice.toLocaleString()} 〜</LabelLarge>
                <BodyMedium>混雑度: {item.crowdingLevel}</BodyMedium>
                <CardRow>
                  <BodyMedium>利用可能時間: </BodyMedium>
                  <div>
                    {convertToAvailableTimes(item.availableTimes, base.timezone).map((time, index) => (
                      <BodyMedium key={index}>
                        {time.weekday} {time.time}
                      </BodyMedium>
                    ))}
                  </div>
                </CardRow>
              </CardBody>
            </SpaceCard>
          ))}
        </DropInItems>
      </Content>
      <Content data-testid='select-pack' hidden={!selectedDropItem}>
        <TextWrapper>
          <Title>パック料金の選択（2/2）</Title>
          <BodyLarge>ご利用になるパック料金を選択してください。</BodyLarge>
        </TextWrapper>
        <DropInItems>
          {selectedDropItem?.plans?.map((plan, idx) => (
            <SpaceCard
              key={`pack-card-${idx}`}
              id={plan.spacePlanId}
              selectedIds={selectedSpacePlanId ? [selectedSpacePlanId] : []}
              handleClicked={setSelectedSpacePlanId}
            >
              <CardBody>
                <Title>{plan.planName}</Title>
                <LabelLarge>
                  料金: ￥{plan.totalPrice.toLocaleString()}
                  {plan.unitPrice && <LabelSmallThin>（￥{plan.unitPrice.toLocaleString()}/時間）</LabelSmallThin>}
                </LabelLarge>
                <CardRow>
                  <BodyMedium>利用可能時間: </BodyMedium>
                  <div>
                    {convertToAvailableTimes(selectedDropItem.availableTimes, base.timezone).map((time, index) => (
                      <BodyMedium key={index}>
                        {time.weekday} {time.time}
                      </BodyMedium>
                    ))}
                  </div>
                </CardRow>
              </CardBody>
            </SpaceCard>
          ))}
        </DropInItems>
      </Content>
      <Content data-testid='notes'>
        <Title>ご利用方法・注意事項</Title>
        <BodyLarge>{base.spaceNotes}</BodyLarge>
      </Content>
      <Content data-testid='payments'>
        <Title>お支払方法</Title>
        <BodyLarge>{base.spacePayments}</BodyLarge>
      </Content>
      <StyledButton
        disabled={!selectedSpacePlanId}
        width={272}
        type='primary'
        size='large'
        label='確認画面へ'
        onClick={openDropInConfirmScreen}
      />
    </Container>
  );
});

DropInEntryScreen.displayName = 'DropInEntryScreen';
export default DropInEntryScreen;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing * 2}px;
  padding: ${themeV3.mixins.v3.spacing * 2}px;
`;

const Content = styled.div`
  background: ${themeV3.mixins.v3.color.object.white};
  border-radius: 12px;
  padding: ${themeV3.mixins.v3.spacing * 3}px;
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing * 2}px;

  ${media.lessThan('small')`
    padding: ${themeV3.mixins.v3.spacing * 2}px;
  `}
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing}px;
`;

const DropInItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${themeV3.mixins.v3.spacing * 3}px;

  ${media.lessThan('small')`
    flex-direction: column;
    gap: ${themeV3.mixins.v3.spacing * 2}px;
  `}
`;

const Title = styled.div`
  ${themeV3.mixins.v3.typography.title.large};
  color: ${themeV3.mixins.v3.color.object.black};
`;

const BodyLarge = styled.div`
  ${themeV3.mixins.v3.typography.body.large};
  color: ${themeV3.mixins.v3.color.object.black};
  white-space: pre-wrap;
`;

const BodyMedium = styled.div`
  ${themeV3.mixins.v3.typography.body.medium};
  color: ${themeV3.mixins.v3.color.object.black};
  white-space: pre-wrap;
`;

const LabelLarge = styled.div`
  ${themeV3.mixins.v3.typography.label.large};
  color: ${themeV3.mixins.v3.color.object.black};
  white-space: pre-wrap;
`;

const LabelSmallThin = styled.span`
  ${themeV3.mixins.v3.typography.label.smallThin};
  color: ${themeV3.mixins.v3.color.object.black};
`;

const CardBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV3.mixins.v3.spacing}px;
`;

const CardRow = styled.div`
  width: 100%;
  display: flex;
`;

const StyledButton = styled(ButtonV2)`
  margin-inline: auto;
  ${media.lessThan('small')`
    width: 100% !important;
  `}
`;
