import {
  BackButtonV2,
  CircularLoader,
  Component,
  LabelV2,
  MOBILE_MAX_WIDTH,
  MOBILE_MIN_WIDTH,
  PageHeaderV2,
  PullDownOption,
  PullDownV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  SEARCH_USER_POINT_LOGS,
  SearchUserPointLogsRequest,
  SearchUserPointLogsResponse,
  User,
  UserPoint,
  UserPointLog,
  toFullName
} from '@atomica.co/irori';
import { Id, Index, Point, Text } from '@atomica.co/types';
import { EMPTY, FIRST_INDEX, builder, hasLength, isGreaterThanZero, isLessThanZero } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { eachMonthOfInterval, format } from 'date-fns';
import React, { CSSProperties, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import ListV2, { ListRow } from '../../../components/list/List';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import usePath from '../../../redux/hooks/usePath';
import { Path } from '../../../router/Routes';

interface P {
  base: BaseDto;
  user: User;
}

const getSubText = (userPointLog: UserPointLog): Text => {
  const isSave = isGreaterThanZero(userPointLog.point);
  return `${
    userPointLog.createdUser ? `${toFullName(userPointLog.createdUser)}による${isSave ? '追加' : '削除'}` : EMPTY
  }${userPointLog.createdUser && isSave ? '、' : EMPTY}${
    isSave ? `${format(userPointLog.expirationDate!, 'yyyy/MM/dd HH:mm')}まで有効` : EMPTY
  }`;
};

const getPullDownOptions = (): PullDownOption[] => {
  const startDate = new Date(2023, 3);
  const endDate = new Date();
  const months = eachMonthOfInterval({ start: startDate, end: endDate });
  return months.map(month =>
    builder<PullDownOption>().id(format(month, 'yyyy/MM')).label(format(month, 'yyyy年MM月')).build()
  );
};

const AccountPointUsageLogs: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { openBasePath } = usePath();
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [selectedId, setSelectedId] = useSafeState<Id>(unmountRef, format(new Date(), 'yyyy/MM'));
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [userPointLogs, setUserPointLogs] = useSafeState<UserPointLog[]>(unmountRef, []);

  const groupedUserPointLogs = useMemo<UserPointLog[][]>(() => {
    const grouped = userPointLogs.reduce((groups, log) => {
      if (!log.createdAt) return groups;
      const date = format(log.createdAt, 'yyyy/MM/dd');
      if (!groups[date]) groups[date] = [];
      groups[date].push(log);
      return groups;
    }, {});
    return Object.values(grouped);
  }, [userPointLogs]);

  const totalUserPoint = useMemo<Point>(() => {
    if (!user || !hasLength(user.userPoints)) return 0;
    return user.userPoints!.reduce((total: Point, userPoint: UserPoint): Point => total + userPoint.point, 0);
  }, [user]);

  const searchUserPointLogs = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const request = builder<SearchUserPointLogsRequest>()
      .userId(user.userId)
      .baseId(base.baseId)
      .date(new Date(selectedId))
      .build();
    const { userPointLogs } = await commonRequest<SearchUserPointLogsRequest, SearchUserPointLogsResponse>(
      SEARCH_USER_POINT_LOGS,
      request
    );
    setUserPointLogs(userPointLogs);
    setIsLoaderShown(false);
  }, [base, commonRequest, selectedId, setIsLoaderShown, setUserPointLogs, user]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    await searchUserPointLogs();
  }, [searchUserPointLogs]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const openAccountProfileScreen = useSafeCallback((): void => {
    openBasePath(Path.ACCOUNT_PROFILE);
  }, [openBasePath]);

  return (
    <Component style={styleForComponent} className='account-point-usage-logs' title='ポイント利用履歴'>
      <Container>
        <Content>
          <BackButtonV2 label='マイページへ' onClick={openAccountProfileScreen} />
          <PageHeaderV2 title='ポイント利用履歴' />
          <Card>
            <CardLabel>利用可能ポイント</CardLabel>
            <CardRow>
              <CardText>{totalUserPoint.toLocaleString()} ポイント</CardText>
            </CardRow>
          </Card>
          <CardWrapper>
            <div>
              <Title>ポイント利用履歴</Title>
              <PullDownWrapper>
                <PullDownV2 id={selectedId} placeholder='選択' options={getPullDownOptions()} onClick={setSelectedId} />
              </PullDownWrapper>
            </div>
            {isLoaderShown && (
              <LoaderWrapper>
                <CircularLoader />
              </LoaderWrapper>
            )}
            {!isLoaderShown &&
              groupedUserPointLogs.map((userPointLogs: UserPointLog[], cardIndex: Index) => (
                <div key={`card-${cardIndex}`}>
                  <LabelV2 text={format(userPointLogs[FIRST_INDEX].createdAt!, 'yyyy/MM/dd')} />
                  <ListV2
                    rows={userPointLogs.map(userPointLog =>
                      builder<ListRow>()
                        .text(userPointLog.comment ?? EMPTY)
                        .subText(getSubText(userPointLog))
                        .secondaryAction(
                          <PointText point={userPointLog.point}>
                            {!isLessThanZero(userPointLog.point) && '+'}
                            {userPointLog.point.toLocaleString()}
                          </PointText>
                        )
                        .build()
                    )}
                  />
                </div>
              ))}
          </CardWrapper>
        </Content>
      </Container>
    </Component>
  );
});

AccountPointUsageLogs.displayName = 'AccountPointUsageLogs';
export default AccountPointUsageLogs;

const styleForComponent: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center'
};

const Container = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
`;
const Content = styled.div`
  width: 100%;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing}px;
  margin: 0 auto;
`;

const Card = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 12px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  margin: ${themeV2.mixins.v2.spacing * 3}px 0;
`;

const CardLabel = styled.div`
  ${themeV2.mixins.v2.typography.title.small};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const CardRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CardText = styled(Typography)`
  ${themeV2.mixins.v2.typography.body.large};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const Title = styled.div`
  ${themeV2.mixins.v2.typography.title.large};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const PullDownWrapper = styled.div`
  height: 48px;
  margin-top: ${themeV2.mixins.v2.spacing}px;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const PointText = styled.div<{ point: Point }>`
  ${({ point }) => isLessThanZero(point) && `color: ${themeV2.mixins.v2.color.status.error};`}
`;

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
