import { LabelV2, themeV2, themeV3, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  BaseFunctionToggleCode,
  ContractPlanUtils,
  ContractV2,
  ItemV2,
  SpaceReservation,
  SpaceReservationType
} from '@atomica.co/irori';
import { Count, Hour, Label, Name, Price, Text } from '@atomica.co/types';
import {
  EMPTY,
  MINUS_ONE,
  ONE,
  ZERO,
  calcTimeDiff,
  isGreaterThanZero,
  isLessThanZero,
  isZero,
  toHour
} from '@atomica.co/utils';
import { Divider } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import usePath from '../../../redux/hooks/usePath';
import { Path } from '../../../router/Routes';
import ItemService, { TotalPriceDetail } from '../../../services/item-service';
import { SPACE_RESERVATION_TYPE_LABEL } from '../../../texts/space-text';
import { calcUsagePeriod } from '../../../utils/space-reservation-util';

const STRIPE_TRANSACTION_FEE_PERCENT = 3.6;

interface P {
  isEnabledCreditCardPayment?: boolean;
  reservationType: SpaceReservationType;
  name: Name;
  item: ItemV2 | undefined;
  selectedStartDate: Date | null | undefined;
  selectedEndDate: Date | null | undefined;
  contract: ContractV2 | undefined;
  currentReservation?: SpaceReservation;
  roomCount?: Count;
  isContractPlanDiscountRateTarget: boolean;
  enableFunctionToggleCodes: BaseFunctionToggleCode[];
}

const SpacePriceDetail: React.FC<P> = React.memo(props => {
  const {
    isEnabledCreditCardPayment,
    reservationType,
    name,
    item,
    selectedStartDate,
    selectedEndDate,
    contract,
    currentReservation,
    roomCount,
    isContractPlanDiscountRateTarget
  } = props;

  const { path } = usePath();

  const unmountRef = useUnmountRef();
  const [loadedBillingAmount, setLoadedBillingAmount] = useSafeState<Price>(unmountRef, 0);

  const isReservationTimeExtension = useMemo<boolean>(() => path === Path.RESERVE_SPACE_EXTENSION, [path]);
  const isReservationTimeEdit = useMemo<boolean>(() => path === Path.RESERVE_SPACE_EDIT, [path]);
  const isUpdate = useMemo<boolean>(
    () => isReservationTimeEdit || isReservationTimeExtension,
    [isReservationTimeEdit, isReservationTimeExtension]
  );
  const unitPrice = useMemo<Price>(() => {
    return item?.unitPrice ?? 0;
  }, [item]);

  const priceText = useMemo<Text>(() => {
    if (isZero(unitPrice)) return EMPTY;
    return '¥' + unitPrice.toLocaleString() + ' / ' + SPACE_RESERVATION_TYPE_LABEL[reservationType];
  }, [reservationType, unitPrice]);

  const currentUsage = useMemo<Hour>(() => {
    if (!selectedStartDate || !selectedEndDate) return ZERO;
    return calcUsagePeriod(reservationType, new Date(selectedStartDate), new Date(selectedEndDate));
  }, [reservationType, selectedEndDate, selectedStartDate]);

  const calculateDiscountRate = useMemo<number>(() => {
    return ContractPlanUtils.applyDiscount(
      contract?.contractPlan?.discountRate ?? ZERO,
      isContractPlanDiscountRateTarget
    );
  }, [contract?.contractPlan?.discountRate, isContractPlanDiscountRateTarget]);

  const prevUsageHour = useMemo<Hour>(() => {
    if (!currentReservation) return 0;
    const { startAt, endAt } = currentReservation;
    return toHour(calcTimeDiff(startAt!, endAt!));
  }, [currentReservation]);

  const actualUsageHour = useMemo<Hour>(() => {
    return (currentUsage - prevUsageHour) * (roomCount || ONE);
  }, [currentUsage, prevUsageHour, roomCount]);

  const { taxIncludedTotalPrice, taxPrice } = useMemo<TotalPriceDetail>(() => {
    return ItemService.getTotalPricesAndTaxPrice(item, actualUsageHour);
  }, [actualUsageHour, item]);

  const contractDiscount = useMemo<Price>(() => {
    if (!contract) return 0;
    return Math.round((taxIncludedTotalPrice * calculateDiscountRate) / 100);
  }, [contract, taxIncludedTotalPrice, calculateDiscountRate]);

  const billingAmount = useMemo<Price>(() => {
    return Math.round(taxIncludedTotalPrice - contractDiscount);
  }, [contractDiscount, taxIncludedTotalPrice]);

  const refundFee = useMemo<Price>(() => {
    return isEnabledCreditCardPayment && isLessThanZero(actualUsageHour)
      ? Math.ceil(taxIncludedTotalPrice * (STRIPE_TRANSACTION_FEE_PERCENT / 100) * MINUS_ONE)
      : 0;
  }, [isEnabledCreditCardPayment, actualUsageHour, taxIncludedTotalPrice]);

  const refundAmount = useMemo<Price>(() => {
    return isLessThanZero(actualUsageHour) ? taxIncludedTotalPrice * MINUS_ONE - refundFee : 0;
  }, [actualUsageHour, taxIncludedTotalPrice, refundFee]);

  const refundAmountWithDiscountRate = useMemo((): number => {
    return calculateDiscountRate === 0
      ? refundAmount
      : refundAmount * ContractPlanUtils.normalizeDiscountRate(calculateDiscountRate);
  }, [calculateDiscountRate, refundAmount]);

  const label = useMemo<Label>(() => {
    if (isReservationTimeExtension) return '延長時間';
    if (!isUpdate || isLessThanZero(actualUsageHour)) return '利用時間';
    return '追加利用時間';
  }, [actualUsageHour, isReservationTimeExtension, isUpdate]);

  useEffect(() => {
    if (!loadedBillingAmount) setLoadedBillingAmount(billingAmount);
  }, [billingAmount, loadedBillingAmount, setLoadedBillingAmount]);

  return (
    <Container>
      <LabelV2 fontSize='large' text='明細' />
      <Content>
        <CardRow>
          <BoldLabel>{name ?? EMPTY}利用料金</BoldLabel>
          <GrayLargeText>{priceText}</GrayLargeText>
        </CardRow>
        {reservationType === SpaceReservationType.TIME && (
          <>
            <CardRow>
              <BoldLabel>{label}</BoldLabel>
              <GrayLargeText>{actualUsageHour}時間</GrayLargeText>
            </CardRow>
            {isLessThanZero(taxIncludedTotalPrice) && isGreaterThanZero(actualUsageHour) && (
              <CardRow>
                <BoldLabel>無料枠適用時間</BoldLabel>
                <GrayLargeText>-{currentUsage}時間</GrayLargeText>
              </CardRow>
            )}
          </>
        )}
        {reservationType === SpaceReservationType.DATE && (
          <CardRow>
            <BoldLabel>利用日数</BoldLabel>
            <GrayLargeText>{currentUsage}日</GrayLargeText>
          </CardRow>
        )}
        {roomCount && (
          <CardRow>
            <BoldLabel>利用部屋数</BoldLabel>
            <GrayLargeText>{roomCount}部屋</GrayLargeText>
          </CardRow>
        )}
      </Content>
      {!isZero(taxIncludedTotalPrice) && (
        <Content>
          <StyledDivider />
          <CardRow>
            <BoldLabel>小計</BoldLabel>
            <GrayLargeText>¥{Math.abs(taxIncludedTotalPrice).toLocaleString()}</GrayLargeText>
          </CardRow>
          <CardRow>
            <GrayLargeText>うち消費税（{item?.tax?.taxRate}%）</GrayLargeText>
            <GrayLargeText>¥{Math.abs(taxPrice).toLocaleString()}</GrayLargeText>
          </CardRow>
          {!!calculateDiscountRate && (
            <CardRow>
              <GrayLargeText>契約割引（{calculateDiscountRate}%）</GrayLargeText>
              <GrayLargeText>-¥{contractDiscount.toLocaleString()}</GrayLargeText>
            </CardRow>
          )}
          {isEnabledCreditCardPayment && isLessThanZero(actualUsageHour) && (
            <CardRow>
              <BoldLabel>返金手数料</BoldLabel>
              <GrayLargeText>¥-{refundFee.toLocaleString()}</GrayLargeText>
            </CardRow>
          )}
        </Content>
      )}
      {!isLessThanZero(taxIncludedTotalPrice) && (
        <TotalPriceArea>
          <LargeText>合計</LargeText>
          <TotalPriceLabel>¥{billingAmount.toLocaleString()}</TotalPriceLabel>
        </TotalPriceArea>
      )}
      {isLessThanZero(taxIncludedTotalPrice) && (
        <TotalPriceArea>
          <LargeText>返金額</LargeText>
          <TotalPriceLabel>¥{refundAmountWithDiscountRate.toLocaleString()}</TotalPriceLabel>
        </TotalPriceArea>
      )}
    </Container>
  );
});

SpacePriceDetail.displayName = 'SpacePriceDetail';
export default SpacePriceDetail;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 8px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const CardRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BoldLabel = styled.div`
  ${themeV2.mixins.v2.typography.label.large};
  color: ${themeV3.mixins.v3.color.object.gray};
`;

const TotalPriceArea = styled(CardRow)`
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  background: ${themeV3.mixins.v3.color.container.neutral.row};
  border-radius: 12px;
`;

const StyledDivider = styled(Divider)`
  color: ${themeV3.mixins.v3.color.border.gray};
`;

const LargeText = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  color: ${themeV3.mixins.v3.color.object.black};
`;

const GrayLargeText = styled(LargeText)`
  color: ${themeV3.mixins.v3.color.object.gray};
`;

const TotalPriceLabel = styled(LargeText)`
  font-weight: 700;
`;
