import {
  InputDateWithLabelV2,
  InputWithLabelV2,
  LabelV2,
  RadioBoxV2,
  TextFieldV2,
  themeV2
} from '@atomica.co/components';
import { ContractorType, InflowSource } from '@atomica.co/irori';
import { Address, Email, Name, NoStr, Phone, Post, Remarks } from '@atomica.co/types';
import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { CONTRACTOR_TYPE_LABELS, CONTRACT_INFLOW_SOURCE_LABELS } from '../../../texts/contract-v2-text';

export interface BasicInputValues {
  contractNo: NoStr;
  startDate: Date | null;
  endDate: Date | null;
  contractorType: ContractorType;
  entityName: Name;
  familyName: Name;
  firstName: Name;
  representativeFamilyName: Name;
  representativeFirstName: Name;
  representativeFamilyNameKana: Name;
  representativeFirstNameKana: Name;
  contractorFamilyName: Name;
  contractorFirstName: Name;
  contractorFamilyNameKana: Name;
  contractorFirstNameKana: Name;
  contractorPost: Post;
  contractorAddress: Address;
  email: Email;
  phone: Phone;
  remarks: Remarks;
  inFlowSource: InflowSource;
}

export interface BasicInputOnChanges {
  setContractNo: (contractNo: NoStr) => void;
  setStartDate: (startDate: Date) => void;
  setEndDate: (endDate: Date | null) => void;
  setContractorType: (contractorType: ContractorType) => void;
  setEntityName: (entityName: Name) => void;
  setFamilyName: (familyName: Name) => void;
  setFirstName: (firstName: Name) => void;
  setRepresentativeFamilyName: (representativeFamilyName: Name) => void;
  setRepresentativeFirstName: (representativeFirstName: Name) => void;
  setRepresentativeFamilyNameKana: (representativeFamilyNameKana: Name) => void;
  setRepresentativeFirstNameKana: (representativeFirstNameKana: Name) => void;
  setContractorFamilyName: (contractorFamilyName: Name) => void;
  setContractorFirstName: (contractorFirstName: Name) => void;
  setContractorFamilyNameKana: (contractorFamilyNameKana: Name) => void;
  setContractorFirstNameKana: (contractorFirstNameKana: Name) => void;
  setContractorPost: (contractorPost: Post) => void;
  setContractorAddress: (contractorAddress: Address) => void;
  setEmail: (email: Email) => void;
  setPhone: (phone: Phone) => void;
  setRemarks: (remarks: Remarks) => void;
  setInFlowSource: (inFlowSource: InflowSource) => void;
}

interface P {
  values: BasicInputValues;
  onChanges: BasicInputOnChanges;
}

const BasicInput: React.FC<P> = React.memo(props => {
  const { values, onChanges } = props;
  const {
    contractNo,
    startDate,
    endDate,
    contractorType,
    entityName,
    familyName,
    firstName,
    representativeFamilyName,
    representativeFirstName,
    contractorFamilyName,
    contractorFirstName,
    contractorPost,
    contractorAddress,
    email,
    phone,
    remarks,
    inFlowSource
  } = values;

  const {
    setContractNo,
    setStartDate,
    setEndDate,
    setContractorType,
    setEntityName,
    setFamilyName,
    setFirstName,
    setRepresentativeFamilyName,
    setRepresentativeFirstName,
    setContractorFamilyName,
    setContractorFirstName,
    setContractorPost,
    setContractorAddress,
    setEmail,
    setPhone,
    setRemarks,
    setInFlowSource
  } = onChanges;

  return (
    <BasicContent>
      <ContentTitle>基本情報</ContentTitle>
      <Rows>
        {contractNo && (
          <Row>
            <Cell colspan={4}>
              <InputWithLabelV2 readonly text='契約番号' value={contractNo} onChange={setContractNo} />
            </Cell>
            <Cell colspan={4} />
          </Row>
        )}
        <Row>
          <Cell colspan={4}>
            <InputDateWithLabelV2 text='契約開始日' value={startDate} onChange={setStartDate} required />
          </Cell>
          <Cell colspan={4}>
            <InputDateWithLabelV2 text='契約終了日' value={endDate} onChange={setEndDate} />
          </Cell>
        </Row>
        <CustomRow>
          <LabelV2 required text='契約者' />
          <RadioWrapper>
            <RadioBoxV2
              options={[ContractorType.ENTITY, ContractorType.PERSON]}
              labels={CONTRACTOR_TYPE_LABELS}
              onChange={setContractorType}
              value={contractorType}
              gap={40}
            />
          </RadioWrapper>
          {contractorType === ContractorType.ENTITY && (
            <Cell>
              <TextFieldV2 placeholder='法人名を入力' value={entityName} onChange={setEntityName} />
            </Cell>
          )}

          {contractorType === ContractorType.PERSON && (
            <Row>
              <Cell colspan={4}>
                <TextFieldV2 placeholder='姓' value={familyName} onChange={setFamilyName} />
              </Cell>
              <Cell colspan={4}>
                <TextFieldV2 placeholder='名' value={firstName} onChange={setFirstName} />
              </Cell>
            </Row>
          )}
        </CustomRow>

        {contractorType === ContractorType.ENTITY && (
          <CustomRow>
            <LabelV2 required text='代表者氏名' />
            <Row>
              <Cell colspan={4}>
                <TextFieldV2 placeholder='姓' value={representativeFamilyName} onChange={setRepresentativeFamilyName} />
              </Cell>
              <Cell colspan={4}>
                <TextFieldV2 placeholder='名' value={representativeFirstName} onChange={setRepresentativeFirstName} />
              </Cell>
            </Row>
          </CustomRow>
        )}
        <CustomRow gap={16}>
          <Row>
            <Cell colspan={2}>
              <InputWithLabelV2
                required
                text='契約者住所'
                placeholder='郵便番号'
                value={contractorPost}
                onChange={setContractorPost}
              />
            </Cell>
            <Cell colspan={6} />
          </Row>
          <Row>
            <Cell>
              <TextFieldV2 placeholder='住所' value={contractorAddress} onChange={setContractorAddress} />
            </Cell>
          </Row>
        </CustomRow>
        {contractorType === ContractorType.ENTITY && (
          <CustomRow>
            <LabelV2 required text='契約先担当者' />
            <Row>
              <Cell colspan={4}>
                <TextFieldV2 placeholder='姓' value={contractorFamilyName} onChange={setContractorFamilyName} />
              </Cell>
              <Cell colspan={4}>
                <TextFieldV2 placeholder='名' value={contractorFirstName} onChange={setContractorFirstName} />
              </Cell>
            </Row>
          </CustomRow>
        )}
        <Row>
          <Cell colspan={4}>
            <InputWithLabelV2 text='契約先担当者 メールアドレス' required value={email} onChange={setEmail} />
          </Cell>
          <Cell colspan={4}>
            <InputWithLabelV2 required text='契約先担当者 電話番号' value={phone} onChange={setPhone} />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <InputWithLabelV2 text='備考' multiline value={remarks} onChange={setRemarks} />
          </Cell>
        </Row>
        <CustomRow>
          <LabelV2 required text='流入経路' />
          <RadioWrapper>
            <RadioBoxV2
              options={[InflowSource.ORGANIC, InflowSource.DIRECT, InflowSource.SHOT]}
              labels={CONTRACT_INFLOW_SOURCE_LABELS}
              onChange={setInFlowSource}
              value={inFlowSource}
              gap={40}
            />
          </RadioWrapper>
        </CustomRow>
      </Rows>
    </BasicContent>
  );
});

BasicInput.displayName = 'BasicInput';
export default BasicInput;

const Content = styled.div`
  background-color: #faf9f7;
  border-radius: 6px;
  padding: ${themeV2.mixins.v2.spacing * 3}px 0 ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 3}px;
`;
const Rows = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 3}px 0;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  align-items: end;
`;
const Cell = styled.div<{ colspan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 }>`
  width: ${({ colspan = 8 }) => `calc(calc(calc(100% / 8) * ${colspan}) - ${themeV2.mixins.v2.spacing * 3}px )`};
`;
const BasicContent = styled(Content)``;

const ContentTitle = styled(Typography)`
  grid-area: title2;
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.black};
  padding: ${themeV2.mixins.v2.spacing}px 0;
  width: 100%;
`;

const CustomRow = styled.div<{ gap?: number }>`
  width: 100%;
  min-height: 24px;
  display: flex;
  flex-direction: column;
  gap: ${({ gap = themeV2.mixins.v2.spacing / 2 }) => gap}px;
`;

const RadioWrapper = styled.div`
  margin-left: ${themeV2.mixins.v2.spacing * 2}px;
`;
