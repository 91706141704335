import {
  ButtonV2,
  CardWithLabelV2,
  ColWidth,
  Component,
  Header,
  LinkButton,
  SearchListV2,
  StatusV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  EVENT_SCHEDULE_ID_V2,
  EventScheduleIdV2,
  EventScheduleV2,
  FETCH_EVENT_SCHEDULE_V2_FOR_ADMIN,
  FetchEventScheduleV2ForAdminRequest,
  FetchEventScheduleV2ForAdminResponse,
  MessageId,
  MessageType,
  MessageV2,
  SEARCH_MESSAGES_V2_FOR_ADMIN,
  SearchMessagesV2ForAdminRequest,
  SearchMessagesV2ForAdminResponse,
  SendingStatus,
  User
} from '@atomica.co/irori';
import { Count, DateStr, Index, Message, Offset, Text, TimeZone, Word } from '@atomica.co/types';
import {
  EMPTY,
  Language,
  ZERO,
  builder,
  embedIdInPath,
  toDateTimeStr,
  toDatetimeFromRelativeFormat
} from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { messageToLabel } from '../../../converters/message-v2-converter';
import { SearchOption } from '../../../enums/event-v2-enum';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import usePath from '../../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../../router/Routes';
import { MESSAGE_SENDING_STATUS } from '../../../statuses/message-status';
import { toZonedTimeStr } from '../../../utils/date-util';
import SendMessageModal from '../modal/SendMessageModal';

const COLUMN_WIDTH: ColWidth = { date: 194, lineMessage: 394, emailMessage: 394, status: 120 };

const HEADER: Header = {
  date: { label: '送信日時' },
  lineMessage: { label: 'LINEメッセージ' },
  emailMessage: { label: 'Emailメッセージ' },
  status: { label: 'ステータス', align: 'center' }
};

interface MessageRow {
  id: MessageId;
  date: DateStr;
  lineMessage: Message;
  emailMessage: Message;
  status: JSX.Element;
}

interface P {
  base: BaseDto;
  user: User;
  messageType: MessageType;
  eventSchedule: EventScheduleV2;
}

const statusComponent = (message: MessageV2, messageType: MessageType): JSX.Element => {
  return (
    <StatusWrapper>
      <StatusV2
        label={messageToLabel(message, messageType)}
        variant='normal'
        status={message ? MESSAGE_SENDING_STATUS[message.status] : 'default'}
        width={74}
      />
    </StatusWrapper>
  );
};

const toTextForEntryFormDeadline = (eventSchedule: EventScheduleV2, timezone: TimeZone): Text => {
  const date =
    toDatetimeFromRelativeFormat(
      eventSchedule.startAtV2,
      eventSchedule.deadlineBeforeDate ?? null,
      eventSchedule.deadlineDateUnit ?? null,
      toZonedTimeStr(eventSchedule.deadlineTime || EMPTY, timezone)
    ) || eventSchedule.endAtV2;

  return toDateTimeStr(date, Language.JAPANESE);
};

const MessageList: React.FC<P> = React.memo(props => {
  const { base, user, messageType, eventSchedule } = props;
  const { params, openPathInNewTab } = usePath();
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, true);
  const [rows, setRows] = useSafeState<MessageRow[]>(unmountRef, []);
  const [messages, setMessages] = useSafeState<MessageV2[]>(unmountRef, []);

  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, EMPTY);
  const [offset, setOffset] = useSafeState<Offset>(unmountRef, SearchOption.OFFSET);
  const [totalCount, setTotalCount] = useSafeState<Count>(unmountRef, ZERO);
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [messageForModal, setMessageForModal] = useSafeState<MessageV2 | undefined>(unmountRef);
  const [sendingStatus, setSendingStatus] = useSafeState<SendingStatus>(unmountRef);

  const eventScheduleId = useMemo<EventScheduleIdV2>(() => params[EVENT_SCHEDULE_ID_V2], [params]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const eventScheduleRequest = builder<FetchEventScheduleV2ForAdminRequest>()
      .baseId(base.baseId)
      .eventScheduleIdV2(eventScheduleId)
      .build();
    const messagesRequest = builder<SearchMessagesV2ForAdminRequest>()
      .baseId(base.baseId)
      .eventScheduleIdV2(eventScheduleId)
      .messageType(messageType)
      .limit(SearchOption.LIMIT)
      .offset(offset)
      .word(searchingWord)
      .build();

    const [eventScheduleResponse, messagesResponse] = await Promise.all([
      await commonRequest<FetchEventScheduleV2ForAdminRequest, FetchEventScheduleV2ForAdminResponse>(
        FETCH_EVENT_SCHEDULE_V2_FOR_ADMIN,
        eventScheduleRequest
      ),
      await commonRequest<SearchMessagesV2ForAdminRequest, SearchMessagesV2ForAdminResponse>(
        SEARCH_MESSAGES_V2_FOR_ADMIN,
        messagesRequest
      )
    ]);

    if (!eventScheduleResponse.eventScheduleV2) {
      setIsLoaderShown(false);
      return;
    }

    const { messagesV2, totalCount } = messagesResponse;
    const rows = messagesV2.map(message =>
      builder<MessageRow>()
        .id(message.messageId)
        .date(toDateTimeStr(message.sendAtV2!, Language.JAPANESE))
        .lineMessage(message.lineMessage ?? EMPTY)
        .emailMessage(message.emailMessage ?? EMPTY)
        .status(statusComponent(message, messageType))
        .build()
    );

    setMessages(messagesV2);
    setRows(rows);
    setTotalCount(totalCount);
    setIsLoaderShown(false);
  }, [
    base.baseId,
    commonRequest,
    eventScheduleId,
    messageType,
    offset,
    searchingWord,
    setMessages,
    setRows,
    setIsLoaderShown,
    setTotalCount
  ]);

  const handleSearchingWordChanged = useSafeCallback(
    (word: Word): void => {
      setSearchingWord(word);
    },
    [setSearchingWord]
  );

  const handleModalOpened = useSafeCallback(
    (index: Index): void => {
      const message = messages[index];
      setMessageForModal(message);
      setSendingStatus(message.status);
      setIsModalOpen(true);
    },
    [messages, setMessageForModal, setSendingStatus, setIsModalOpen]
  );

  const handleModalClosed = useSafeCallback(
    (isUpdated: boolean): void => {
      setIsModalOpen(false);
      if (isUpdated) {
        setRows([]);
        initialize();
      }
    },
    [setIsModalOpen, setRows, initialize]
  );

  const openAccountEventEntryForm = useSafeCallback((): void => {
    openPathInNewTab(embedIdInPath(Path.ACCOUNT_EVENT_ENTRY_FORM, PATH_IDS, [base.baseCode, eventScheduleId]));
  }, [base, eventScheduleId, openPathInNewTab]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Component className='schedule-messages'>
      <Container>
        <Content>
          {messageType === MessageType.ENTRY && (
            <SummaryWrapper>
              <Title>申し込みフォーム</Title>
              <EntryFormInfoSummary>
                <SummaryInfoWrapper>
                  <CardWithLabelV2
                    label='申し込みフォーム'
                    text={`${eventSchedule.name}申し込みフォーム`}
                    component={<LinkButton label='詳細' onClick={openAccountEventEntryForm}></LinkButton>}
                  />
                </SummaryInfoWrapper>
                <SummaryInfoWrapper>
                  <CardWithLabelV2
                    label='申し込みフォーム回答締め切り'
                    text={toTextForEntryFormDeadline(eventSchedule, base.timezone)}
                  />
                </SummaryInfoWrapper>
              </EntryFormInfoSummary>
            </SummaryWrapper>
          )}
          <SearchListV2
            name='送信履歴'
            colWidth={COLUMN_WIDTH}
            header={HEADER}
            rows={rows}
            offset={SearchOption.OFFSET}
            limit={SearchOption.LIMIT}
            totalCount={totalCount}
            placeholder='メッセージ'
            searchingWord={searchingWord}
            isLoaderShown={isLoaderShown}
            headerRightComponent={
              <ButtonV2
                onClick={() => {
                  setMessageForModal(undefined);
                  setSendingStatus(SendingStatus.RETRY);
                  setIsModalOpen(true);
                }}
                label='再送信'
              />
            }
            onChange={handleSearchingWordChanged}
            onClickRow={handleModalOpened}
            setOffset={setOffset}
          />
          <SendMessageModal
            base={base}
            user={user}
            isOpen={isModalOpen}
            messageType={messageType}
            sendingStatus={sendingStatus}
            eventScheduleV2={eventSchedule}
            messageV2={messageForModal}
            onClose={isUpdated => handleModalClosed(isUpdated)}
          />
        </Content>
      </Container>
    </Component>
  );
});

MessageList.displayName = 'MessageList';
export default MessageList;

const Container = styled.div`
  width: 100%;
  height: auto;
  margin: ${themeV2.mixins.v2.spacing * 2}px 0 ${themeV2.mixins.v2.spacing * 5}px 0;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1280px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const SummaryWrapper = styled.div`
  width: 100%;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: ${themeV2.mixins.v2.spacing}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 3}px;
  margin-bottom: ${themeV2.mixins.v2.spacing * 3}px;
`;

const EntryFormInfoSummary = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;

const SummaryInfoWrapper = styled.div`
  width: 50%;
  height: auto;
  margin-top: ${themeV2.mixins.v2.spacing * 3}px;
`;

const Title = styled.div`
  ${themeV2.mixins.v2.typography.title.xLarge};
  padding-bottom: ${themeV2.mixins.v2.spacing}px;
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
`;

const StatusWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
