import {
  FormGroupPullDown,
  InputDateWithLabelV2,
  LabelV2,
  PullDownOption,
  themeV2,
  themeV3,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseResourceCategoryRelationship,
  ChildReservationMap,
  SpaceReservation,
  SpaceReservationId
} from '@atomica.co/irori';
import { Count, Id, Label, Message, Placeholder, Remarks } from '@atomica.co/types';
import { EMPTY, builder, hasLength, isZero, toBeginningOfDay, toEndOfDay } from '@atomica.co/utils';
import { addDays, format, subDays } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { MAXIMAMU_RESERVE_LIMIT_AUTOMATICALLY } from '../../../constants/space-const';
import { CachedDataForSaveSpaceReservationAutomatically } from '../../../redux/hooks/useCachedDataForSaveSpaceReservationAutomatically';

type Type = 'search' | 'confirm';

interface SpaceConditionValues {
  selectedParentSpaceReservationId: SpaceReservationId;
  selectedStartDate: Date | null;
  selectedEndDate: Date | null;
  roomCount: Count;
}

interface SpaceConditionOnChanges {
  setSelectedParentSpaceReservationId: (selectedParentSpaceReservationId: SpaceReservationId) => void;
  setSelectedStartDate: (selectedStartDate: Date | null) => void;
  setSelectedEndDate: (selectedEndDate: Date | null) => void;
  setRoomCount: (roomCount: Count) => void;
}

interface P {
  type: Type;
  parentSpaceReservations: SpaceReservation[];
  childReservationMap: ChildReservationMap | undefined;
  baseResourceCategoryLabel: Label;
  baseResourceCategoryRelationship: BaseResourceCategoryRelationship;
  values: SpaceConditionValues;
  onChanges: SpaceConditionOnChanges;
  checkSpaceReservable?: () => Promise<void>;
  initData?: CachedDataForSaveSpaceReservationAutomatically;
}

const SpaceConditionDateInput: React.FC<P> = React.memo(props => {
  const {
    type,
    parentSpaceReservations,
    childReservationMap,
    baseResourceCategoryLabel,
    baseResourceCategoryRelationship,
    values,
    onChanges,
    checkSpaceReservable,
    initData
  } = props;
  const { selectedParentSpaceReservationId, selectedStartDate, selectedEndDate, roomCount } = values;
  const { setSelectedParentSpaceReservationId, setSelectedStartDate, setSelectedEndDate, setRoomCount } = onChanges;
  const unmountRef = useUnmountRef();
  const [, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const isConfirmScreen = useMemo<boolean>(() => type === 'confirm', [type]);

  const selectedParentSpaceReservation = useMemo<SpaceReservation | undefined>(() => {
    return parentSpaceReservations.find(
      reservation => reservation.spaceReservationId === selectedParentSpaceReservationId
    );
  }, [parentSpaceReservations, selectedParentSpaceReservationId]);

  const reservationOptionLabel = useMemo<Label | undefined>(() => {
    const categoryOptions = baseResourceCategoryRelationship?.baseResourceCategory?.baseResourceCategoryOptions;
    if (!hasLength(categoryOptions)) return;
    return categoryOptions.find(option => option.isReservationNo)?.optionLabel;
  }, [baseResourceCategoryRelationship]);

  const relationalBaseResourceCategoryLabel = useMemo<Label>(() => {
    return baseResourceCategoryRelationship?.baseResourceCategory?.categoryLabel ?? '施設・設備';
  }, [baseResourceCategoryRelationship]);

  const [parentStartAt, reservableStartAt] = useMemo<[Date | undefined, Date | undefined]>(() => {
    if (!selectedParentSpaceReservation) return [undefined, undefined];

    return [
      selectedParentSpaceReservation.startAt,
      subDays(selectedParentSpaceReservation.startAt!, baseResourceCategoryRelationship.beforeMarginDays)
    ];
  }, [baseResourceCategoryRelationship, selectedParentSpaceReservation]);

  const [parentEndAt, reservableEndAt] = useMemo<[Date | undefined, Date | undefined]>(() => {
    if (!selectedParentSpaceReservation) return [undefined, undefined];
    return [
      selectedParentSpaceReservation.endAt,
      addDays(selectedParentSpaceReservation.endAt!, baseResourceCategoryRelationship.afterMarginDays)
    ];
  }, [baseResourceCategoryRelationship, selectedParentSpaceReservation]);

  const pullDownOptions = useMemo<PullDownOption[]>(() => {
    return parentSpaceReservations.map(reservation =>
      builder<PullDownOption>().id(reservation.spaceReservationId).label(reservation.reservationNo).build()
    );
  }, [parentSpaceReservations]);

  const callCheckSpaceReservable = useSafeCallback(async (): Promise<void> => {
    if (!checkSpaceReservable || !reservableStartAt || !selectedStartDate || !selectedEndDate || !reservableEndAt)
      return;
    if (selectedStartDate < toBeginningOfDay(reservableStartAt)! || toEndOfDay(reservableEndAt)! < selectedEndDate)
      return;
    await checkSpaceReservable();
  }, [checkSpaceReservable]);

  useEffect(() => {
    callCheckSpaceReservable();
  }, [callCheckSpaceReservable]);

  useEffect(() => {
    setLoaded(loaded => {
      if (!reservableStartAt || !reservableEndAt) return true;
      setSelectedStartDate(!loaded && initData ? initData.startAt : reservableStartAt);
      setSelectedEndDate(!loaded && initData ? initData.endAt : reservableEndAt);
      return true;
    });
  }, [initData, reservableStartAt, reservableEndAt, setLoaded, setSelectedStartDate, setSelectedEndDate]);

  useEffect(() => {
    if (!baseResourceCategoryRelationship) setRoomCount(1);
  }, [baseResourceCategoryRelationship, setRoomCount]);

  const reservableRoomLeft = useMemo<Count>(() => {
    if (!selectedParentSpaceReservationId || !childReservationMap) {
      return MAXIMAMU_RESERVE_LIMIT_AUTOMATICALLY;
    }
    const reservedRoomCount = childReservationMap[selectedParentSpaceReservationId]?.count ?? 0;
    const reservedRoomLimit = childReservationMap[selectedParentSpaceReservationId]?.limit ?? 0;
    return reservedRoomLimit < 0
      ? MAXIMAMU_RESERVE_LIMIT_AUTOMATICALLY
      : Math.max(0, reservedRoomLimit - reservedRoomCount);
  }, [selectedParentSpaceReservationId, childReservationMap]);

  const roomCountPullDownOptions = useMemo<PullDownOption[]>(() => {
    return [...Array(reservableRoomLeft)].map((_v, ix) =>
      builder<PullDownOption>()
        .id(String(ix + 1))
        .label(`${ix + 1}部屋`)
        .build()
    );
  }, [reservableRoomLeft]);

  const roomCountPullDownRemarks = useMemo<Remarks>(() => {
    if (!selectedParentSpaceReservationId || isZero(reservableRoomLeft)) return EMPTY;
    return `最大${reservableRoomLeft}部屋まで予約可能`;
  }, [selectedParentSpaceReservationId, reservableRoomLeft]);

  const roomCountPullDownPlaceHolder = useMemo<Placeholder>(() => {
    if (!selectedParentSpaceReservationId) return '予約番号が選択されていません';
    if (!isZero(reservableRoomLeft)) return '選択してください';
    return 'これ以上部屋は選択できません';
  }, [selectedParentSpaceReservationId, reservableRoomLeft]);

  const roomCountPullDownErrorMessage = useMemo<Message>(() => {
    if (!selectedParentSpaceReservationId) return EMPTY;
    if (isZero(reservableRoomLeft)) return '予約可能な部屋数に達しています';
    if (isZero(roomCount)) return '部屋数を選択して下さい';
    return EMPTY;
  }, [selectedParentSpaceReservationId, reservableRoomLeft, roomCount]);

  const resourceCategoryOptionRemarks = useMemo<Remarks>(() => {
    const mainRemarks = !reservationOptionLabel
      ? `予約した${relationalBaseResourceCategoryLabel}の予約番号を選択してください。`
      : `${relationalBaseResourceCategoryLabel}を予約した「${reservationOptionLabel}」を選択してください。`;
    return `${mainRemarks}${!isConfirmScreen ? '\n※未選択でも空き状況の照会は可能です。' : EMPTY}`;
  }, [isConfirmScreen, relationalBaseResourceCategoryLabel, reservationOptionLabel]);

  return (
    <React.Fragment>
      {baseResourceCategoryRelationship && (
        <FormGroupPullDown
          required={isConfirmScreen}
          label={reservationOptionLabel || `${relationalBaseResourceCategoryLabel}の予約番号`}
          remarks={resourceCategoryOptionRemarks}
          errorMessage={
            isConfirmScreen && !selectedParentSpaceReservationId
              ? `${reservationOptionLabel || `${relationalBaseResourceCategoryLabel}の予約番号`}を選択してください。`
              : undefined
          }
          disabled={!hasLength(parentSpaceReservations)}
          id={selectedParentSpaceReservationId}
          nullable
          placeholder='選択してください'
          options={pullDownOptions}
          onClick={setSelectedParentSpaceReservationId}
        />
      )}
      {parentStartAt && parentEndAt && reservableStartAt && reservableEndAt && (
        <ParentSpaceReservationInfo>
          <TextWrapper>
            <LabelV2 text={`${relationalBaseResourceCategoryLabel}の予約日時`} />
            <BodyMediumText>
              {`${format(parentStartAt, 'yyyy/MM/dd HH:mm')} - ${format(parentEndAt, 'MM/dd HH:mm')}`}
            </BodyMediumText>
          </TextWrapper>
          <TextWrapper>
            <LabelV2 text={`${baseResourceCategoryLabel}の予約可能な日`} />
            <BodyMediumText>
              {`${format(reservableStartAt, 'yyyy/MM/dd')} - ${format(reservableEndAt, 'MM/dd')}`}
            </BodyMediumText>
          </TextWrapper>
        </ParentSpaceReservationInfo>
      )}
      <InputDateWithLabelV2
        required={isConfirmScreen}
        minDate={reservableStartAt || new Date()}
        maxDate={reservableEndAt && subDays(reservableEndAt, 1)}
        errorMessage={isConfirmScreen && !selectedStartDate ? 'チェックインする日を選択してください。' : EMPTY}
        text='チェックイン'
        value={selectedStartDate}
        onChange={setSelectedStartDate}
      />
      <InputDateWithLabelV2
        required={isConfirmScreen}
        minDate={addDays(reservableStartAt || new Date(), 1)}
        maxDate={reservableEndAt}
        errorMessage={isConfirmScreen && !selectedEndDate ? 'チェックアウトする日を選択してください。' : EMPTY}
        text='チェックアウト'
        value={selectedEndDate}
        onChange={setSelectedEndDate}
      />
      {baseResourceCategoryRelationship && (
        <FormGroupPullDown
          required
          label='利用する部屋数'
          remarks={roomCountPullDownRemarks}
          errorMessage={roomCountPullDownErrorMessage}
          disabled={isZero(reservableRoomLeft)}
          id={isZero(roomCount) ? EMPTY : String(roomCount)}
          nullable
          placeholder={roomCountPullDownPlaceHolder}
          options={roomCountPullDownOptions}
          onClick={(id: Id) => setRoomCount(Number(id))}
        />
      )}
    </React.Fragment>
  );
});

SpaceConditionDateInput.displayName = 'SpaceConditionDateInput';
export default SpaceConditionDateInput;

const ParentSpaceReservationInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  background: ${themeV3.mixins.v3.color.container.neutral.row};
  border-radius: 8px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing / 2}px;
`;

const BodyMediumText = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV3.mixins.v3.color.object.black};
`;
