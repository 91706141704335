import {
  BackButtonV2,
  ButtonV2,
  CardWithLabelV2,
  Component,
  LabelV2,
  MoreHorizMenu,
  MoreHorizMenuButton,
  PageHeaderV2,
  TabProperty,
  TabsV3,
  Tag,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  DELETE_EVENT_V2_FOR_ADMIN,
  DeleteEventV2ForAdminRequest,
  DeleteEventV2ForAdminResponse,
  EVENT_ID_V2,
  EventIdV2,
  EventMembership,
  EventV2,
  FETCH_EVENT_V2_FOR_ADMIN,
  FetchEventV2ForAdminRequest,
  FetchEventV2ForAdminResponse,
  User
} from '@atomica.co/irori';
import { Index } from '@atomica.co/types';
import { ONE, ZERO, builder, embedIdInPath, hasLength } from '@atomica.co/utils';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import mojaco from '../../assets/mojaco/mojaco_break.png';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../router/Routes';
import { EVENT_DETAIL_TAB_LABELS } from '../../texts/event-text';
import EventScheduleList from './event-schedules/EventScheduleList';
import RegularParticipantList from './regular-participants/RegularParticipantList';

const EVENT_DETAILS_HEADER_HEIGHT = 100;
const EVENT_DETAILS_CONSOLE_FOOTER_HEIGHT = 48;
const EVENT_DETAILS_CONTENT_MAX_WIDTH = 1280;
const EVENT_DETAILS_CONTENT_MIN_WIDTH = 756;

interface P {
  isDrawerOpen: boolean;
  base: BaseDto;
  user: User;
  onChange(path: Path): void;
}

const tabProperties = Object.values(EVENT_DETAIL_TAB_LABELS).map<TabProperty>(label => ({
  label,
  disabled: false
}));

const EventV2DetailsScreen: React.FC<P> = React.memo(props => {
  const { base } = props;
  const { params, openPath } = usePath();
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [event, setEvent] = useSafeState<EventV2>(unmountRef);
  const [selectedTabIdx, setSelectedTabIdx] = useSafeState<Index>(unmountRef, ZERO);
  const [isThumbnailExisting, setIsThumbnailExisting] = useSafeState<boolean>(unmountRef, true);

  const eventId = useMemo<EventIdV2>(() => params[EVENT_ID_V2], [params]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const request = builder<FetchEventV2ForAdminRequest>().baseId(base.baseId).eventIdV2(eventId).build();
    const response = await commonRequest<FetchEventV2ForAdminRequest, FetchEventV2ForAdminResponse>(
      FETCH_EVENT_V2_FOR_ADMIN,
      request
    );
    const eventV2 = response.eventV2;

    if (!eventV2) {
      setIsLoaderShown(false);
      return;
    }

    setEvent(eventV2);
    setIsLoaderShown(false);
  }, [base, commonRequest, eventId, setEvent, setIsLoaderShown]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleButtonClicked = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.EVENT_V2_LIST, PATH_IDS, [base.baseCode, params[EVENT_ID_V2]]));
  }, [base.baseCode, params, openPath]);

  const deleteEvent = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const request = builder<DeleteEventV2ForAdminRequest>().baseId(base.baseId).eventId(eventId).build();
    const response = await commonRequest<DeleteEventV2ForAdminRequest, DeleteEventV2ForAdminResponse>(
      DELETE_EVENT_V2_FOR_ADMIN,
      request
    );

    if (!response.eventId) {
      setIsLoaderShown(false);
      return;
    }

    openPath(embedIdInPath(Path.EVENT_V2_LIST, PATH_IDS, [base.baseCode]));
    setIsLoaderShown(false);
  }, [eventId, base, openPath, setIsLoaderShown]);

  const forwardToEditEventScreen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.EDIT_EVENT_V2, PATH_IDS, [base.baseCode, eventId]));
  }, [base, eventId, openPath]);

  const subMenus = useMemo<MoreHorizMenu[]>(() => {
    return [{ label: '削除', onClick: deleteEvent }];
  }, [deleteEvent]);

  return (
    <Component loading={isLoaderShown} style={{ width: '100%', height: '100%' }} className='event-details-screen'>
      {!!event && (
        <Container>
          <BackGroundThumbnail
            url={isThumbnailExisting && event.photoURL ? event.photoURL : mojaco}
            onError={() => setIsThumbnailExisting(false)}
          />
          <Content>
            <BackButtonV2 label='戻る' fontColor='white' onClick={handleButtonClicked} />

            <HeaderWrapper>
              <PageHeaderV2
                title={`${event.name} の詳細`}
                rightComponent={<MoreHorizMenuButton size='medium' menuButtons={subMenus} />}
                fontColor='white'
              />
            </HeaderWrapper>

            <SummaryWrapper>
              <Summary>
                <TitleSummary>
                  <SubTitle>イベント概要</SubTitle>
                  <ButtonV2
                    size='large'
                    type='default'
                    startIcon={<EditOutlinedIcon />}
                    label='編集'
                    onClick={forwardToEditEventScreen}
                  />
                </TitleSummary>
                <SummaryInfoWrapper>
                  <CardWithLabelV2 label='イベント名' text={event.name} />
                </SummaryInfoWrapper>
                <SummaryInfoWrapper>
                  <CardWithLabelV2 label='イベントの概要' text={event.description} />
                </SummaryInfoWrapper>
                {hasLength(event.eventMemberships) && (
                  <SummaryInfoWrapper>
                    <LabelV2 text='参加可能な会員種別' />
                    <TagWrapper>
                      {event.eventMemberships?.map((eventMenmership: EventMembership, index: Index) => (
                        <Tag key={`tag-${index}`} label={eventMenmership.authority!.authorityName} status='secondary' />
                      ))}
                    </TagWrapper>
                  </SummaryInfoWrapper>
                )}
              </Summary>
            </SummaryWrapper>

            <TabsV3 tabs={tabProperties} selectedTabIdx={selectedTabIdx} onChange={setSelectedTabIdx} />

            <SearchListWrapper>
              {selectedTabIdx === ZERO && <EventScheduleList base={base} eventId={eventId} />}
              {selectedTabIdx === ONE && <RegularParticipantList base={base} event={event} />}
            </SearchListWrapper>
          </Content>
        </Container>
      )}
    </Component>
  );
});

EventV2DetailsScreen.displayName = 'EventV2DetailsScreen;';
export default EventV2DetailsScreen;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-flow: column;
  position: relative;
  padding-bottom: ${themeV2.mixins.v2.spacing * 6}px;
`;

const Content = styled.div`
  width: 100%;
  flex: 1;
  max-width: ${EVENT_DETAILS_CONTENT_MAX_WIDTH}px;
  min-width: ${EVENT_DETAILS_CONTENT_MIN_WIDTH}px;
  margin: 0 auto;
  padding: 0 ${themeV2.mixins.v2.spacing * 3}px 0;
  height: calc(100% - ${EVENT_DETAILS_HEADER_HEIGHT + EVENT_DETAILS_CONSOLE_FOOTER_HEIGHT}px);
  z-index: 1000;
`;

const BackGroundThumbnail = styled.div<{ url: string }>`
  width: 100%;
  height: 240px;
  position: absolute;
  top: 0;
  background-size: cover;
  z-index: 0;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${({ url }) => url});
  background-position-y: center;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${themeV2.mixins.v2.spacing * 2}px 0;
`;

const SubTitle = styled.div`
  color: ${themeV2.mixins.v2.color.font.black};
  ${themeV2.mixins.v2.typography.title.xLarge};
`;

const SummaryWrapper = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: ${themeV2.mixins.v2.spacing * 3}px;
`;

const Summary = styled.div`
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: ${themeV2.mixins.v2.spacing}px;
  padding: ${themeV2.mixins.v2.spacing * 3}px;
`;

const TitleSummary = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${themeV2.mixins.v2.spacing}px;
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
`;

const SummaryInfoWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: ${themeV2.mixins.v2.spacing * 2}px;
`;

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${themeV2.mixins.v2.spacing}px;
  gap: 8px;
`;

const SearchListWrapper = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 2}px 0 ${themeV2.mixins.v2.spacing}px;

  tr {
    > th {
      &:nth-child(3),
      &:nth-child(4) {
        padding: 0;
      }
    }
  }
`;
