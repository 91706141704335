import {
  ButtonV2,
  ColWidth,
  Header,
  MoreHorizMenu,
  MoreHorizMenuButton,
  MoreHorizMenuForTable,
  SearchListV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseId,
  DELETE_EVENT_SCHEDULE_PARTICIPANT_V2_FOR_ADMIN,
  DeleteEventScheduleParticipantV2ForAdminRequest,
  DeleteEventScheduleParticipantV2ForAdminResponse,
  EVENT_SCHEDULE_ID_V2,
  EventScheduleGroupId,
  EventScheduleGroupV2,
  EventScheduleIdV2,
  EventScheduleParticipantId,
  EventScheduleParticipantV2,
  EventScheduleV2,
  SEARCH_EVENT_SCHEDULE_PARTICIPANTS_V2_FOR_ADMIN,
  SearchEventScheduleParticipantsV2ForAdminRequest,
  SearchEventScheduleParticipantsV2ForAdminResponse,
  toFullName
} from '@atomica.co/irori';
import { Code, Count, Email, Id, Index, Label, Name, Offset, Phone, Title, UserId, Word } from '@atomica.co/types';
import { EMPTY, ONE, ZERO, builder, embedIdInPath, hasLength, isGreaterThanZero, noop } from '@atomica.co/utils';
import React, { useEffect, useMemo, useRef } from 'react';
import { CSVDownload } from 'react-csv';
import styled from 'styled-components';
import DefaultUserIcon from '../../../../assets/default_user_icon.png';
import { ParticipantCSV, convertToParticipantV2CSV } from '../../../../converters/export-converter';
import { SearchOption } from '../../../../enums/event-v2-enum';
import { CSVTemplate } from '../../../../models/common-model';
import useCommonRequest from '../../../../redux/hooks/useCommonRequest';
import usePath from '../../../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../../../router/Routes';
import { EVENT_HEADERS } from '../../../../texts/event-text';
import AssignGroupModal from '../../modal/AssignGroupModal';
import RegisterGroupModal from '../../modal/RegisterGroupModal';

export interface GroupedParticipantRow {
  id: Id;
  icon: JSX.Element;
  name: Name;
  phone: Phone;
  email: Email;
  regular: Label;
}

interface P {
  baseCode: Code;
  baseId: BaseId;
  title: Title;
  eventSchedule: EventScheduleV2;
  eventScheduleParticipants?: EventScheduleParticipantV2[];
  eventScheduleGroup?: EventScheduleGroupV2;
  regularUserIds: UserId[];
  handleDeleteGroupClicked?: (eventScheduleGroup?: EventScheduleGroupV2) => Promise<void>;
  handleEditGroupNameClicked?: (eventScheduleGroup?: EventScheduleGroupV2) => void;
  handleListInitialized: () => Promise<void>;
}

const COLUMN_WIDTH: ColWidth = { icon: 56, name: 'auto', phone: 'auto', email: 'auto', regular: 177 };
const HEADER: Header = {
  icon: { label: EMPTY },
  name: { label: '氏名' },
  phone: { label: '電話番号' },
  email: { label: 'メールアドレス' },
  regular: { label: '定期参加者' }
};

const photoComponent = (participant: EventScheduleParticipantV2): JSX.Element => {
  return (
    <IconWrapper>
      <Icon src={participant.user?.photoURL || DefaultUserIcon} />
    </IconWrapper>
  );
};

const GroupedParticipantSearchList: React.FC<P> = React.memo(props => {
  const {
    baseCode,
    baseId,
    title,
    eventSchedule,
    eventScheduleGroup,
    eventScheduleParticipants = [],
    regularUserIds = [],
    handleDeleteGroupClicked = noop,
    handleEditGroupNameClicked = noop,
    handleListInitialized
  } = props;
  const { params, openPath } = usePath();
  const { commonRequest } = useCommonRequest();
  const unmountRef = useUnmountRef();
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [rows, setRows] = useSafeState<GroupedParticipantRow[]>(unmountRef, []);
  const [selectedParticipantIds, setSelectedParticipantIds] = useSafeState<EventScheduleParticipantId[]>(
    unmountRef,
    []
  );
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, EMPTY);
  const [offset, setOffset] = useSafeState<Offset>(unmountRef, SearchOption.OFFSET);
  const [totalCount, setTotalCount] = useSafeState<Count>(unmountRef, ZERO);
  const [csvHeaders, setCsvHeaders] = useSafeState<CSVTemplate[]>(unmountRef, []);
  const [csvContent, setCsvContent] = useSafeState<ParticipantCSV[]>(unmountRef, []);
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [isRegisterGroupModalOpen, setIsRegisterGroupModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [savedGroupName, setSavedGroupName] = useSafeState<Name>(unmountRef, EMPTY);

  const eventScheduleId = useMemo<EventScheduleIdV2>(() => params[EVENT_SCHEDULE_ID_V2], [params]);
  const eventScheduleGroupId = useMemo<EventScheduleGroupId>(
    () => eventScheduleGroup?.eventScheduleGroupId || EMPTY,
    [eventScheduleGroup]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const csvInstance = useRef<any>();

  const exportCSV = useSafeCallback((): void => {
    if (!eventScheduleGroup) return;
    setCsvHeaders(EVENT_HEADERS);
    setCsvContent(convertToParticipantV2CSV(eventSchedule, eventScheduleParticipants, eventScheduleGroup.name));
  }, [eventSchedule, eventScheduleParticipants, eventScheduleGroup, setCsvHeaders, setCsvContent]);

  const toRegularParticipantLabel = useSafeCallback(
    (participant: EventScheduleParticipantV2): Label => {
      return hasLength(regularUserIds) && regularUserIds.includes(participant.user!.userId) ? '定期参加者' : EMPTY;
    },
    [regularUserIds]
  );

  const searchParticipants = useSafeCallback(
    async (searchingWord: Word): Promise<void> => {
      setIsLoaderShown(true);

      const preBuild = builder<SearchEventScheduleParticipantsV2ForAdminRequest>()
        .baseId(baseId)
        .eventScheduleIdV2(eventScheduleId)
        .limit(SearchOption.LIMIT)
        .offset(offset)
        .word(searchingWord);

      const request = eventScheduleGroupId
        ? preBuild.eventScheduleGroupId(eventScheduleGroupId).build()
        : preBuild.grouped(false).build();

      const response = await commonRequest<
        SearchEventScheduleParticipantsV2ForAdminRequest,
        SearchEventScheduleParticipantsV2ForAdminResponse
      >(SEARCH_EVENT_SCHEDULE_PARTICIPANTS_V2_FOR_ADMIN, request);
      const { eventScheduleParticipantsV2, totalCount } = response;

      const rows = eventScheduleParticipantsV2.map(participant =>
        builder<GroupedParticipantRow>()
          .id(participant.eventScheduleParticipantId)
          .icon(photoComponent(participant))
          .name(toFullName(participant.user))
          .phone(participant.user?.phone || EMPTY)
          .email(participant.user!.email)
          .regular(toRegularParticipantLabel(participant))
          .build()
      );

      if (!hasLength(rows) && isGreaterThanZero(offset)) setOffset(offset - ONE);

      setRows(rows);
      setTotalCount(totalCount);
      setIsLoaderShown(false);
    },
    [
      baseId,
      commonRequest,
      eventScheduleId,
      eventScheduleGroupId,
      offset,
      setOffset,
      setRows,
      setTotalCount,
      setIsLoaderShown,
      toRegularParticipantLabel
    ]
  );

  const handleSearchingWordChanged = useSafeCallback(
    (word: Word): void => {
      setRows([]);
      setSearchingWord(word);
      searchParticipants(word);
    },
    [searchParticipants, setRows, setSearchingWord]
  );

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (!hasLength(eventScheduleParticipants)) return;

    if (isGreaterThanZero(offset)) {
      await searchParticipants(EMPTY);
      return;
    }

    const rows = eventScheduleParticipants.map(participant =>
      builder<GroupedParticipantRow>()
        .id(participant.eventScheduleParticipantId)
        .icon(photoComponent(participant))
        .name(toFullName(participant.user))
        .phone(participant.user?.phone || EMPTY)
        .email(participant.user!.email)
        .regular(toRegularParticipantLabel(participant))
        .build()
    );

    setRows(rows);
    setTotalCount(rows.length);
  }, [eventScheduleParticipants, offset, searchParticipants, setRows, setTotalCount, toRegularParticipantLabel]);

  const deleteParticipant = useSafeCallback(
    async (eventScheduleParticipantId: EventScheduleParticipantId): Promise<void> => {
      setIsLoaderShown(true);
      setRows([]);

      const request = builder<DeleteEventScheduleParticipantV2ForAdminRequest>()
        .baseId(baseId)
        .eventScheduleParticipantId(eventScheduleParticipantId)
        .build();

      await commonRequest<
        DeleteEventScheduleParticipantV2ForAdminRequest,
        DeleteEventScheduleParticipantV2ForAdminResponse
      >(DELETE_EVENT_SCHEDULE_PARTICIPANT_V2_FOR_ADMIN, request);

      await searchParticipants(searchingWord);
    },
    [baseId, searchParticipants, searchingWord, setIsLoaderShown, setRows]
  );

  const handleCancelMenuClicked = useSafeCallback(
    async (rowIndex: Index): Promise<void> => {
      const participant = rows[rowIndex];
      await deleteParticipant(participant.id);
    },
    [deleteParticipant, rows]
  );

  const handleParticipantsGroupModalClosed = useSafeCallback(
    async (isUpdated: boolean): Promise<void> => {
      setIsModalOpen(false);
      if (isUpdated) {
        initialize();
        await handleListInitialized();
      }
    },
    [initialize, setIsModalOpen]
  );

  const handleGroupSelected = useSafeCallback((): void => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const moreHorizMenusForSearchList = useMemo<MoreHorizMenu[]>(
    () => [
      {
        label: 'グループ名を編集',
        onClick: async () => await handleEditGroupNameClicked(eventScheduleGroup)
      },
      {
        label: 'グループを削除',
        onClick: async () => await handleDeleteGroupClicked(eventScheduleGroup)
      },
      {
        label: 'CSVダウンロード',
        onClick: exportCSV
      }
      // {
      //   label: 'スプレッドシートを開く',
      //   onClick: noop
      // }
    ],
    [eventScheduleGroup, exportCSV, handleDeleteGroupClicked, handleEditGroupNameClicked]
  );

  const moreHorizMenusForTable = useMemo<MoreHorizMenuForTable[]>(
    () => [
      {
        color: themeV2.mixins.v2.color.font.gray,
        label: '参加者を削除する',
        onClick: rowIndex => handleCancelMenuClicked(rowIndex)
      }
    ],
    [handleCancelMenuClicked]
  );

  useEffect(() => {
    initialize();
  }, [initialize]);

  const openUserOverviewScreen = useSafeCallback(
    (index: Index): void => {
      if (!baseCode) return;
      const user = eventScheduleParticipants[index].user;
      openPath(embedIdInPath(Path.USER_OVERVIEW, PATH_IDS, [baseCode, user!.userId]));
    },
    [baseCode, openPath, eventScheduleParticipants]
  );

  return (
    <Container>
      <AssignGroupModal
        baseId={baseId}
        isOpen={isModalOpen}
        eventScheduleV2={eventSchedule}
        eventScheduleParticipants={selectedParticipantIds.map(
          participantId =>
            eventScheduleParticipants.find(participant => participant.eventScheduleParticipantId === participantId)!
        )}
        savedGroupName={savedGroupName}
        onChange={setSavedGroupName}
        onClose={isUpdated => handleParticipantsGroupModalClosed(isUpdated)}
        openRegisterGroupModal={() => setIsRegisterGroupModalOpen(true)}
      />
      <RegisterGroupModal
        baseId={baseId}
        isOpen={isRegisterGroupModalOpen}
        eventScheduleV2={eventSchedule}
        mode='register'
        onChange={setSavedGroupName}
        onClose={isUpdated => setIsRegisterGroupModalOpen(isUpdated)}
      />
      <SearchListV2
        title={title}
        name='参加者'
        colWidth={COLUMN_WIDTH}
        header={HEADER}
        rows={rows}
        offset={offset}
        limit={SearchOption.LIMIT}
        totalCount={totalCount}
        placeholder='氏名・メールアドレスで検索'
        searchingWord={searchingWord}
        isLoaderShown={isLoaderShown}
        selectedIds={selectedParticipantIds}
        noDataMessage={searchingWord ? '検索条件に該当する参加者が見つかりません' : '参加者が割り当てられていません'}
        headerRightComponent={
          eventScheduleGroup && <MoreHorizMenuButton type='default' menuButtons={moreHorizMenusForSearchList} />
        }
        moreHorizMenusForTable={moreHorizMenusForTable}
        tableRightComponent={<ButtonV2 type='primary' label='割当先を変更' onClick={handleGroupSelected} />}
        setOffset={setOffset}
        setSelectedIds={setSelectedParticipantIds}
        onChange={handleSearchingWordChanged}
        onClickRow={index => openUserOverviewScreen(index)}
      />
      {hasLength(csvContent) && <CSVDownload ref={csvInstance} headers={csvHeaders} data={csvContent} />}
    </Container>
  );
});

GroupedParticipantSearchList.displayName = 'GroupedParticipantSearchList';
export default GroupedParticipantSearchList;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-bottom: ${themeV2.mixins.v2.spacing * 2}px;
  position: relative;
`;
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const Icon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
`;
