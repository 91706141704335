import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  BaseDto,
  BaseId,
  FETCH_FB_USER_EXISTS_BY_EMAIL,
  FETCH_USER_BY_EXTERNAL_ID,
  FetchFbUserExistsByEmailRequest,
  FetchFbUserExistsByEmailResponse,
  FetchUserByExternalIdRequest,
  FetchUserByExternalIdResponse,
  ProviderInfo,
  User
} from '@atomica.co/irori';
import { Code, Email, ExternalId, ProviderId, UserId } from '@atomica.co/types';
import { EMPTY, FIRST_INDEX, builder, isUndefined, noop, uuid } from '@atomica.co/utils';
import { useEffect, useMemo } from 'react';
import { ImageCategory } from '../../enums/common-enum';
import firebase, { auth } from '../../firebase';
import { getLineProfile } from '../../line';
import CommonRequest from '../../requests/common-request';
import { ImageService } from '../../services/image-service';
import { toProviderId } from '../../utils/user-util';
import useCommonRequest from './useCommonRequest';
import { SaveUserBody, UserDto } from '../../__generated/model';
import { getBsUser } from '../../__generated/user/bs-user/bs-user';

interface P {
  loaded: boolean;
  firebase: firebase.User | undefined;
  user: User | undefined;
  isLineUser: boolean;
  getFirebaseEmail: () => Promise<Email>;
  getProviders: (base: BaseDto) => Promise<ProviderInfo[]>;
  createNonActivatedUserIfNotExisted: (
    base: BaseDto,
    firebase: firebase.User,
    email: Email
  ) => Promise<ExistingUser | undefined>;
  saveActivatedUser: (
    base: BaseDto,
    userToSave: UserDto,
    authorityCode?: Code,
    baseId?: BaseId
  ) => Promise<UserId | undefined>;
  fetchUser: () => Promise<User | undefined>;
}

export interface ExistingUser {
  userId: UserId;
  isActivated: boolean;
}

function useUser(args: { noInitialize?: boolean } = {}): P {
  const { noInitialize } = args;
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [firebase, setFirebase] = useSafeState<firebase.User | null>(unmountRef);
  const [user, setUser] = useSafeState<User | undefined>(unmountRef);

  const isLineUser = useMemo<boolean>(() => !!firebase && toProviderId(firebase) === ProviderId.LINE, [firebase]);

  const _getFbUser = useSafeCallback(async (): Promise<firebase.User | null> => {
    const fbUser = auth.currentUser;
    if (fbUser) return fbUser;

    return await new Promise(resolve => {
      const unsubscribe = auth.onAuthStateChanged(fbUser => {
        resolve(fbUser);
        unsubscribe();
      });
    });
  }, []);

  const _getKpUser = useSafeCallback(async (externalId: ExternalId): Promise<User | undefined> => {
    const request = builder<FetchUserByExternalIdRequest>().externalId(externalId).build();
    const response = await CommonRequest.call<FetchUserByExternalIdRequest, FetchUserByExternalIdResponse>(
      FETCH_USER_BY_EXTERNAL_ID,
      request
    );
    return response.user;
  }, []);

  const fetchUser = useSafeCallback(async (): Promise<User | undefined> => {
    const firebase = await _getFbUser();
    return firebase ? await _getKpUser(firebase.uid) : undefined;
  }, [_getFbUser, _getKpUser]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const firebase = await _getFbUser();
    const user = firebase ? await _getKpUser(firebase.uid) : undefined;
    setFirebase(firebase);
    setUser(user);
    setLoaded(true);
  }, [_getFbUser, _getKpUser, setFirebase, setUser, setLoaded]);

  useEffect(() => {
    if (noInitialize) return;
    initialize();
  }, [initialize, noInitialize]);

  const createNonActivatedUserIfNotExisted = useSafeCallback(
    async (base: BaseDto, firebase: firebase.User, email: Email): Promise<ExistingUser | undefined> => {
      await firebase.updateEmail(email).catch(noop);

      const fetchRequest = builder<FetchUserByExternalIdRequest>().externalId(firebase.uid).build();
      const fetchResponse = await commonRequest<FetchUserByExternalIdRequest, FetchUserByExternalIdResponse>(
        FETCH_USER_BY_EXTERNAL_ID,
        fetchRequest
      );

      if (fetchResponse.user) {
        return builder<ExistingUser>()
          .userId(fetchResponse.user.userId)
          .isActivated(fetchResponse.user.isActivated)
          .build();
      }

      const fetchFbUserExistsRequest = builder<FetchFbUserExistsByEmailRequest>().email(email).build();
      const fetchFbUserExistsResponse = await commonRequest<
        FetchFbUserExistsByEmailRequest,
        FetchFbUserExistsByEmailResponse
      >(FETCH_FB_USER_EXISTS_BY_EMAIL, fetchFbUserExistsRequest);

      const providers: ProviderInfo[] = [];
      const firebaseProvider = builder<ProviderInfo>()
        .providerId(toProviderId(firebase))
        .externalId(firebase.uid)
        .build();
      providers.push(firebaseProvider);

      const [lineUser, photoURL] = await Promise.all([
        getLineProfile(base.lineLiffId),
        ImageService.uploadImageUrlToFirebase(firebase.photoURL, ImageCategory.USER_PROFILE, uuid())
      ]);

      if (lineUser) {
        const lineProvider = builder<ProviderInfo>().providerId(ProviderId.LINE).externalId(lineUser.userId).build();
        providers.push(lineProvider);
      }

      const newUserSaveRequest = builder<SaveUserBody>()
        .email(email)
        .isActivated(false)
        .familyName(EMPTY)
        .firstName(EMPTY)
        .phone(firebase.phoneNumber || EMPTY)
        .photoURL(photoURL)
        .providers(providers)
        .userFamilyMembers([])
        .build();

      const currentUserSaveRequest = builder<SaveUserBody>()
        .email(email)
        .providers(providers)
        // FIXME: userFamilyMembersは必須プロパティになっているので必ず値を渡す必要があるが
        // 空配列でSaveUserを実行するとuserFamilyMembersの紐付けが切れてしまう
        // 記載時点でFamilyMemberが活用されているケースが少なそう かつ このコードを通るのは
        // 同一のメールアドレスでGoogle x Lineの複数プロバイダー認証の場合だけなので一旦妥協
        .userFamilyMembers([])
        .build();

      const req = fetchFbUserExistsResponse.isExist ? currentUserSaveRequest : newUserSaveRequest;
      const { data: saveResponse } = await getBsUser().saveUser(req);

      if (isUndefined(saveResponse.userId)) return;

      return builder<ExistingUser>().userId(saveResponse.userId).isActivated(false).build();
    },
    [commonRequest]
  );

  const getFirebaseEmail = async (): Promise<Email> => {
    const firebaseUser = await _getFbUser();
    return (
      firebaseUser?.email ||
      firebaseUser?.providerData.filter(data => data?.email).map(data => (data as firebase.UserInfo).email)[
        FIRST_INDEX
      ] ||
      EMPTY
    );
  };

  const getProviders = async (base: BaseDto): Promise<ProviderInfo[]> => {
    const providers: ProviderInfo[] = [];
    const firebaseUser = await _getFbUser();
    if (!firebaseUser) return providers;

    const firebaseProvider = builder<ProviderInfo>()
      .providerId(toProviderId(firebaseUser))
      .externalId(firebaseUser.uid)
      .build();
    providers.push(firebaseProvider);

    const lineUser = await getLineProfile(base.lineLiffId);
    if (lineUser) {
      const lineProvider = builder<ProviderInfo>().providerId(ProviderId.LINE).externalId(lineUser.userId).build();
      providers.push(lineProvider);
    }

    return providers;
  };

  /** @deprecated */
  const saveActivatedUser = async (
    base: BaseDto,
    userToSave: UserDto,
    authorityCode?: Code,
    baseId?: BaseId
  ): Promise<UserId | undefined> => {
    const firebase = await _getFbUser();
    if (!firebase) return;

    const email = userToSave.email
      ? userToSave.email
      : firebase.email
        ? firebase.email
        : firebase.providerData.filter(data => data?.email).map(data => (data as firebase.UserInfo).email)[FIRST_INDEX];

    const providers: ProviderInfo[] = [];
    const firebaseProvider = builder<ProviderInfo>()
      .providerId(toProviderId(firebase))
      .externalId(firebase.uid)
      .build();
    providers.push(firebaseProvider);

    const lineUser = await getLineProfile(base.lineLiffId);
    if (lineUser) {
      const lineProvider = builder<ProviderInfo>().providerId(ProviderId.LINE).externalId(lineUser.userId).build();
      providers.push(lineProvider);
    }

    const request = builder<SaveUserBody>()
      .userId(userToSave.userId)
      .email(email!)
      .isActivated(true)
      .startDate(userToSave.startDate!)
      .endDate(userToSave.endDate!)
      .familyName(userToSave.familyName!)
      .firstName(userToSave.firstName!)
      .campanyName(userToSave.companyName!)
      .dateOfBirthV2(userToSave.dateOfBirthV2!)
      .phone(userToSave.phone!)
      .photoURL(userToSave.photoURL || EMPTY) // メアド認証の場合nullのままなので
      .faceRecognitionPhotoURL(userToSave.faceRecognitionPhotoURL!)
      .faceFeatureStr(userToSave.faceFeatureStr! as string)
      .postalCode(userToSave.postalCode!)
      .prefecture(userToSave.prefecture!)
      .address(userToSave.address!)
      .userDiv(userToSave.userDiv)
      .userInflowSource(userToSave.userInflowSource)
      .dateOfAgreement(userToSave.dateOfAgreement!)
      .providers(providers)
      .userFamilyMembers([])
      .authorityCode(authorityCode)
      .baseId(baseId)
      .build();

    const { data: response } = await getBsUser().saveUser(request);

    return response.userId;
  };

  return {
    loaded,
    firebase,
    user,
    isLineUser,
    getFirebaseEmail,
    getProviders,
    createNonActivatedUserIfNotExisted,
    saveActivatedUser,
    fetchUser
  } as P;
}

export default useUser;
