import {
  ButtonV2,
  ColWidth,
  Component,
  Header,
  PageHeaderV2,
  PAGINATE_HEIGHT,
  SearchListV2,
  SelectBoxV2,
  styleForFullExpansion,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  ContractId,
  ContractIdV2,
  ContractorType,
  ContractSortColumn,
  ContractV2,
  SEARCH_CONTRACTS_V2_FOR_ADMIN,
  SearchContractsV2ForAdminRequest,
  SearchContractsV2ForAdminResponse,
  Sort,
  User
} from '@atomica.co/irori';
import { Count, DateStr, Id, Index, Name, NoStr, Offset, Word } from '@atomica.co/types';
import { builder, embedIdInPath, EMPTY, hasLength, ZERO } from '@atomica.co/utils';
import { Add } from '@material-ui/icons';
import { format } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { CONTRACT_ACTION_OPTIONS } from '../../constants/contract-v2-const';
import { ContractAction } from '../../enums/contract-v2-enum';
import useCachedSearchContractInfo, { CachedSearchContractInfo } from '../../redux/hooks/useCachedContractList';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { Path, PATH_IDS } from '../../router/Routes';
import { CONTRACT_ACTION_LABEL } from '../../texts/contract-v2-text';
import { toFullName } from '../../utils/user-util';

const LIMIT = 50;

const COLUMN_WIDTH: ColWidth = {
  contractNo: 60,
  contractorName: 120,
  contractPlanName: 120,
  startDate: 100,
  familyName: 80,
  updatedAt: 80
};

const HEADER: Header = {
  contractNo: { label: '契約番号' },
  contractorName: { label: '契約者' },
  contractPlanName: { label: '契約プラン' },
  startDate: { label: '契約期間' },
  familyName: { label: '作成者' },
  updatedAt: { label: '最終更新日' }
};

const SORT_COLUMNS = {
  contractNo: ContractSortColumn.CONTRACT_NO,
  contractorName: ContractSortColumn.CONTRACTOR_NAME,
  contractPlanName: ContractSortColumn.PLAN_NAME,
  startDate: ContractSortColumn.START_DATE,
  familyName: ContractSortColumn.CREATED_USER,
  updatedAt: ContractSortColumn.UPDATED_AT
};

interface ContractRow {
  id: Id;
  contractNo: NoStr;
  contractorName: Name;
  contractPlanName: Name;
  startDate: DateStr;
  familyName: Name;
  updatedAt: DateStr;
}

interface P {
  base: BaseDto;
  user: User;
}

const ContractListScreen: React.FC<P> = React.memo(props => {
  const { base } = props;
  const { openPath, openPathInNewTab, openBasePath } = usePath();
  const { cachedSearchContractInfo, saveCachedSearchContractInfo } = useCachedSearchContractInfo();
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, false);
  const [selectedAction, setSelectedAction] = useSafeState<ContractAction>(unmountRef, ContractAction.OPERATE_ALL);
  const [offset, setOffset] = useSafeState<Offset>(
    unmountRef,
    cachedSearchContractInfo ? cachedSearchContractInfo.offset : ZERO
  );
  const [searchingWord, setSearchingWord] = useSafeState<Word>(
    unmountRef,
    cachedSearchContractInfo ? cachedSearchContractInfo.searchingWord : EMPTY
  );
  const [contracts, setContracts] = useSafeState<ContractV2[]>(
    unmountRef,
    cachedSearchContractInfo ? cachedSearchContractInfo.contracts : []
  );
  const [selectedContractIds, setSelectedContractIds] = useSafeState<ContractIdV2[]>(
    unmountRef,
    !!cachedSearchContractInfo &&
      cachedSearchContractInfo.contracts.length !== cachedSearchContractInfo.selectedContractIds.length
      ? cachedSearchContractInfo.selectedContractIds
      : []
  );
  const [totalRecordCount, setTotalRecordCount] = useSafeState<Count>(
    unmountRef,
    cachedSearchContractInfo.totalRecordCount || ZERO
  );
  const [sortKey, setSortKey] = useSafeState<ContractSortColumn>(
    unmountRef,
    cachedSearchContractInfo ? cachedSearchContractInfo.sortKey : ContractSortColumn.CONTRACT_NO
  );
  const [sort, setSort] = useSafeState<Sort>(
    unmountRef,
    cachedSearchContractInfo ? cachedSearchContractInfo.sort : Sort.DESC
  );

  const rows = useMemo<ContractRow[]>(() => {
    return contracts.map(contract => {
      const startDate =
        `${format(new Date(contract.startDate), 'yyyy/MM/dd')} ～ ` +
        (contract.endDate ? format(new Date(contract.endDate), 'yyyy/MM/dd') : '未定');

      const row = builder<ContractRow>()
        .id(contract.contractId)
        .contractNo(contract.contractNo)
        .contractPlanName(contract.contractPlan?.contractPlanName ?? EMPTY)
        .contractorName(
          contract.contractorType === ContractorType.ENTITY ? (contract.entityName ?? EMPTY) : contract.contractorName
        )
        .startDate(startDate)
        .familyName(contract.createdUser ? toFullName(contract.createdUser) : '不明')
        .updatedAt(format(new Date(contract.updatedAt!), 'yyyy/MM/dd'))
        .build();
      return row;
    });
  }, [contracts]);

  const initialize = useSafeCallback((): void => {
    setOffset(ZERO);
    setSelectedContractIds([]);
  }, [setOffset, setSelectedContractIds]);

  const searchContracts = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const request = builder<SearchContractsV2ForAdminRequest>()
      .baseId(base.baseId)
      .word(searchingWord)
      .limit(LIMIT)
      .offset(offset)
      .sortCol(sortKey)
      .sort(sort)
      .build();
    const response = await commonRequest<SearchContractsV2ForAdminRequest, SearchContractsV2ForAdminResponse>(
      SEARCH_CONTRACTS_V2_FOR_ADMIN,
      request
    );
    setContracts(response.contracts);
    setTotalRecordCount(response.count);
    setIsLoaderShown(false);
  }, [base, commonRequest, offset, searchingWord, setContracts, setIsLoaderShown, setTotalRecordCount, sort, sortKey]);

  useEffect(() => {
    searchContracts();
  }, [searchContracts]);

  const forwardToContractDetail = useSafeCallback(
    (contractId: ContractIdV2, isMoreHorizMenu?: boolean): void => {
      const contractInfo = builder<CachedSearchContractInfo>()
        .contracts(contracts)
        .selectedContractIds(isMoreHorizMenu ? selectedContractIds : contracts.map(c => c.contractId))
        .offset(offset)
        .searchingWord(searchingWord)
        .sortKey(sortKey)
        .sort(sort)
        .totalRecordCount(isMoreHorizMenu ? selectedContractIds.length : totalRecordCount)
        .build();
      saveCachedSearchContractInfo(contractInfo);
      openPath(embedIdInPath(Path.CONTRACT_DETAILS_V2_DETAIL, PATH_IDS, [base.baseCode, contractId]));
    },
    [
      base,
      contracts,
      offset,
      openPath,
      saveCachedSearchContractInfo,
      searchingWord,
      selectedContractIds,
      sortKey,
      sort,
      totalRecordCount
    ]
  );

  const forwardToRegisterContract = useSafeCallback((): void => {
    const contractInfo = builder<CachedSearchContractInfo>()
      .contracts(contracts)
      .selectedContractIds(selectedContractIds)
      .offset(offset)
      .searchingWord(searchingWord)
      .sortKey(sortKey)
      .sort(sort)
      .totalRecordCount(totalRecordCount)
      .build();
    saveCachedSearchContractInfo(contractInfo);
    openBasePath(Path.REGISTER_CONTRACT_V2);
  }, [
    contracts,
    offset,
    openBasePath,
    saveCachedSearchContractInfo,
    searchingWord,
    selectedContractIds,
    sortKey,
    sort,
    totalRecordCount
  ]);

  const handleActionSelected = useSafeCallback(
    (selectedAction: ContractAction): void => {
      setSelectedAction(selectedAction);

      switch (selectedAction) {
        case ContractAction.OPEN_SELECTED:
          if (!hasLength(selectedContractIds)) return;
          forwardToContractDetail(selectedContractIds[ZERO], true);
          break;
        default:
          break;
      }
    },
    [setSelectedAction, selectedContractIds, forwardToContractDetail]
  );

  const handleSearchingWordChanged = useSafeCallback(
    (word: Word): void => {
      initialize();
      setSearchingWord(word);
    },
    [initialize, setSearchingWord]
  );

  const handleClickRow = useSafeCallback(
    (index: Index): void => {
      const contractId: ContractId = contracts[index].contractId;
      openPathInNewTab(embedIdInPath(Path.CONTRACT_DETAILS_V2_DETAIL, PATH_IDS, [base.baseCode, contractId]));
    },
    [contracts, base, openPathInNewTab]
  );

  const handleSortColClicked = useSafeCallback(
    (sortKey: ContractSortColumn, sort: Sort): void => {
      initialize();
      setSortKey(sortKey);
      setSort(sort);
    },
    [initialize, setSortKey, setSort]
  );

  return (
    <Component style={styleForFullExpansion} className='contract-list-screen'>
      <Container>
        <Content>
          <PageHeaderV2
            title='契約'
            rightComponent={
              <ButtonV2 type='primary' label='新規作成' startIcon={<Add />} onClick={forwardToRegisterContract} />
            }
          />

          <SearchListV2
            name='契約'
            title={`${totalRecordCount}件の契約`}
            colWidth={COLUMN_WIDTH}
            header={HEADER}
            rows={rows}
            sortKey={sortKey}
            sortCol={SORT_COLUMNS}
            paginateProps={{ offset, limit: LIMIT, totalCount: totalRecordCount, setOffset }}
            isLoaderShown={isLoaderShown}
            selectedIds={selectedContractIds}
            searchFieldProps={{
              placeholder: '契約番号・契約者・作成者',
              searchingWord,
              onChange: handleSearchingWordChanged
            }}
            tableRightComponent={
              <SelectBoxV2
                labels={CONTRACT_ACTION_LABEL}
                options={CONTRACT_ACTION_OPTIONS}
                value={selectedAction}
                onChange={handleActionSelected}
              />
            }
            onClickRow={handleClickRow}
            onClickSortCol={handleSortColClicked}
            setSelectedIds={setSelectedContractIds}
          />
        </Content>
      </Container>
    </Component>
  );
});

ContractListScreen.displayName = 'ContractListScreen';
export default ContractListScreen;

const Container = styled.div`
  width: 100%;
  height: calc(100% - ${PAGINATE_HEIGHT}px);
  display: flex;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;
