import {
  BackButtonV2,
  ButtonV2,
  CardV2,
  CardWithLabelV2,
  Component,
  LabelV2,
  PageHeaderV2,
  StatusV2,
  Tag,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  FETCH_WISH_FOR_ADMIN,
  FetchWishForAdminRequest,
  FetchWishForAdminResponse,
  User,
  WISH_ID,
  Wish,
  WishId,
  WishTag,
  toFullName
} from '@atomica.co/irori';
import { Label } from '@atomica.co/types';
import { EMPTY, Language, builder, embedIdInPath, hasLength, toDateTimeStr } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../router/Routes';
import { WISH_SCOPE_TEXT, WISH_STATUS_TEXT, WISH_TYPE_TEXT } from '../../texts/wish-text';
import { toStatus } from '../../utils/wish-util';

const WISH_DETAILS_CONTENT_MAX_WIDTH = 1280;
const WISH_DETAILS_CONTENT_MIN_WIDTH = 756;

const constructUserDescription = (user: User | undefined): Label => {
  if (!user) return EMPTY;
  return `${user.companyName}${user.companyName && user.email && '・'}${user.email}`;
};

interface P {
  base: BaseDto;
}

const WishDetailsScreen: React.FC<P> = React.memo(props => {
  const { base } = props;
  const { params, openBasePath, openPath } = usePath();
  const { commonRequest } = useCommonRequest();
  const unmountRef = useUnmountRef();

  const [selectedWish, setSelectedWish] = useSafeState<Wish>(unmountRef);
  const selectedWishId = useMemo<WishId>(() => params[WISH_ID], [params]);
  const [wishTags, setWishTags] = useSafeState<WishTag[]>(unmountRef, []);

  const fromUser = useMemo<User | undefined>(() => {
    if (!selectedWish?.fromUser) return;
    return selectedWish.fromUser;
  }, [selectedWish]);

  // const deleteWish = useSafeCallback((): void => {
  //   // TODO
  //   // return () => {
  //   // }
  // }, []);

  // const subMenus = useMemo<MoreHorizMenu[]>(() => {
  //   return [{ label: '削除', onClick: deleteWish }];
  // }, [deleteWish]);

  const handleEditClicked = useSafeCallback(() => {
    openPath(embedIdInPath(Path.EDIT_WISH, PATH_IDS, [base.baseCode, selectedWishId]));
  }, [base, openPath, selectedWishId]);

  const openWishListScreen = useSafeCallback((): void => {
    openBasePath(Path.WISH_LIST);
  }, [openBasePath]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchWishForAdminRequest>().baseId(base.baseId).wishId(selectedWishId).build();
    // await getBsWish().fetchWishForAdmin(base.baseId, selectedWishId);
    const { wish } = await commonRequest<FetchWishForAdminRequest, FetchWishForAdminResponse>(
      FETCH_WISH_FOR_ADMIN,
      request
    );
    if (wish) {
      setSelectedWish(wish);
      const tags =
        wish.userWishTags && hasLength(wish.userWishTags)
          ? wish.userWishTags?.map(tag => tag.wishTag!).filter(tag => tag)
          : [];
      setWishTags(tags);
    }
  }, [base.baseId, setSelectedWish, selectedWishId, setWishTags]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleUserClicked = useSafeCallback((): void => {
    if (!fromUser) return;
    window.open(embedIdInPath(Path.USER_OVERVIEW, PATH_IDS, [base.baseCode, fromUser.userId]), '_blank');
  }, [base, fromUser]);

  return (
    <Component className='wish-details-screen' loading={false}>
      <Container>
        {selectedWish && (
          <>
            <BackButtonV2 label='戻る' onClick={openWishListScreen} />
            <PageHeaderV2
              title='Wish詳細'
              rightComponent={
                <ComponentWrapper>
                  <ButtonV2 type='primary' label='編集' onClick={handleEditClicked} />
                  {/* <MoreHorizMenuButton menuButtons={subMenus} /> */}
                </ComponentWrapper>
              }
            />
            <Contents>
              <Content>
                <UserWrapper>
                  <LabelV2 text='ユーザー' />
                  <ClickableWrapper onClick={handleUserClicked}>
                    <CardV2
                      src={fromUser?.photoURL}
                      text={`${toFullName(fromUser)}`}
                      subText={constructUserDescription(fromUser)}
                    />
                  </ClickableWrapper>
                </UserWrapper>

                <NonClickableWrapper>
                  <CardWithLabelV2 label='タイトル' text={selectedWish.content} />
                </NonClickableWrapper>

                <NonClickableWrapper>
                  <CardWithLabelV2 label='内容' text={selectedWish.remarks} />
                </NonClickableWrapper>

                <NonClickableWrapper>
                  <CardWithLabelV2 label='タイプ' text={WISH_TYPE_TEXT[selectedWish.type]} />
                </NonClickableWrapper>

                <Wrapper>
                  <LabelV2 text='タグ' />
                  {hasLength(wishTags) && (
                    <ChipWrapper>
                      {wishTags.map((tag, index) => (
                        <Tag key={`tag-${index}`} status='inactive' label={tag.name} />
                      ))}
                    </ChipWrapper>
                  )}
                  {!hasLength(wishTags) && <Text>未登録</Text>}
                </Wrapper>
              </Content>
              <Content small>
                <Wrapper>
                  <LabelV2 text='ステータス' />
                  <StatusV2 status={toStatus(selectedWish.status)} label={WISH_STATUS_TEXT[selectedWish.status]} />
                </Wrapper>

                <CardWithLabelV2 label='公開範囲' text={WISH_SCOPE_TEXT[selectedWish.scope]} />

                <CardWithLabelV2
                  label='担当者'
                  src={selectedWish.toUser!.photoURL}
                  text={toFullName(selectedWish.toUser)}
                />

                <CardWithLabelV2 label='登録日' text={toDateTimeStr(selectedWish.createdAt!, Language.JAPANESE)} />
              </Content>
            </Contents>
          </>
        )}
      </Container>
    </Component>
  );
});

WishDetailsScreen.displayName = 'WishDetailsScreen';
export default WishDetailsScreen;

const Container = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  width: 100%;
  max-width: ${WISH_DETAILS_CONTENT_MAX_WIDTH}px;
  min-width: ${WISH_DETAILS_CONTENT_MIN_WIDTH}px;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  margin: 0 auto;
`;

const ComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const Contents = styled.div`
  display: flex;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;

const Content = styled.div<{ small?: boolean }>`
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  background-color: ${themeV2.mixins.v2.color.background.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  flex: ${({ small }) => (small ? '1' : '2')};
  height: 100%;
`;

const UserWrapper = styled.div``;

const ClickableWrapper = styled.div`
  cursor: pointer;
`;

const NonClickableWrapper = styled.div`
  cursor: auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const ChipWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const Text = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.black};
`;
