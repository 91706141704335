import {
  CircularLoader,
  customMedia,
  themeV2,
  themeV3,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { Space, SpaceId, SpaceReservationType, SpaceUsagesForTime } from '@atomica.co/irori';
import { Count, Hour, Time, TimeZone } from '@atomica.co/types';
import { builder, hasLength, isGreaterThanZero, toBeginningOfDay, ZERO } from '@atomica.co/utils';
// https://github.com/fullcalendar/fullcalendar-vue/issues/152#issue-918047023
// https://github.com/fullcalendar/fullcalendar/issues/6371#issuecomment-1060708159
// @fullcalendar/react/dist/vdomをfullcalendar系importの最上位に置く
import '@fullcalendar/react/dist/vdom';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar, { CalendarApi, DateSelectArg, EventClickArg } from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { LICENSE_KEY } from '../../../constants/calendar-const';
import { convertToTimelineResources, SpaceResourceRow, TimelineEventRow } from '../../../converters/space-converter';
import '../../../styles/bootstrap-journal.min.css';
import { toZonedTimeStr } from '../../../utils/date-util';
import { getReservationStartUnit } from '../../../utils/space-reservation-util';

interface P {
  isSearchingReservation: boolean;
  spaceUsages: SpaceUsagesForTime | undefined;
  spaces: Space[];
  selectedSpaceIds: SpaceId[];
  selectedDate: Date;
  timezone: TimeZone;
  setSelectedDate: (selectedDate: Date) => void;
  handleEventClicked: (arg: EventClickArg) => void;
  handleSelected: (arg: DateSelectArg) => void;
}

const ReserveTime: React.FC<P> = React.memo(props => {
  const {
    isSearchingReservation,
    selectedDate,
    selectedSpaceIds,
    spaces,
    spaceUsages,
    timezone,
    setSelectedDate,
    handleEventClicked,
    handleSelected
  } = props;

  const unmountRef = useUnmountRef();
  const [calendarApi, setCalendarApi] = useSafeState<CalendarApi | null>(unmountRef);

  const timelineRef = useRef<FullCalendar>(null);
  const resources = useMemo<SpaceResourceRow[]>(
    () => convertToTimelineResources(spaces, selectedSpaceIds, selectedDate, timezone),
    [timezone, selectedSpaceIds, spaces, selectedDate]
  );

  const events = useMemo<TimelineEventRow[]>(() => {
    const eventRows: TimelineEventRow[] = [];
    if (!spaceUsages || !hasLength(Object.keys(spaceUsages))) return eventRows;
    selectedSpaceIds.forEach(key => {
      if (!spaceUsages[key]) return;
      spaceUsages[key].forEach(usage => {
        eventRows.push(
          builder<TimelineEventRow>()
            .resourceId(usage.spaceId)
            .id(usage.spaceReservationId)
            .title(usage.isParticipationEnabled ? '自分の予定' : '予約できません')
            .color(
              usage.isParticipationEnabled
                ? themeV3.mixins.v3.color.container.primary.default
                : themeV3.mixins.v3.color.container.neutral.high
            )
            .start(usage.start)
            .end(usage.end)
            .build()
        );
      });
    });
    return eventRows;
  }, [selectedSpaceIds, spaceUsages]);

  const isAllDay = useMemo<boolean>(() => {
    return spaces.some(space => space.reservationType === SpaceReservationType.TIME && space.isReservableMultipleDays);
  }, [spaces]);

  const setCalendarDate = useSafeCallback(
    (days: Count) => {
      if (!calendarApi) return;
      setSelectedDate(toBeginningOfDay(new Date(selectedDate.setDate(selectedDate.getDate() + days)))!);
      isGreaterThanZero(days) ? calendarApi.next() : calendarApi.prev();
    },
    [calendarApi, selectedDate, setSelectedDate]
  );

  useEffect(() => {
    setCalendarApi(calendarApi => {
      if (!calendarApi) return timelineRef.current && timelineRef.current.getApi();
      if (!selectedDate) return calendarApi;
      calendarApi.gotoDate(selectedDate);
      return calendarApi;
    });
  }, [setCalendarApi, selectedDate]);

  const getRoundUpHour = (time: Time): Hour => {
    const [hour, min] = time.split(':').map(token => Number(token));
    if (min === ZERO) return hour;
    return hour + 1;
  };

  const findMinAndMaxHours = useSafeCallback(
    (spaces: Space[], selectedSpaceIds: SpaceId[], timezone: TimeZone) => {
      let minHour = 23;
      let maxHour = 8;

      const filteredSpaces = spaces.filter(space => selectedSpaceIds.includes(space.spaceId));

      filteredSpaces.forEach(space => {
        space.availabilities?.forEach(availability => {
          const startTimeJST = toZonedTimeStr(availability.startAt, timezone);
          const endTimeJST = toZonedTimeStr(availability.endAt, timezone);
          const startHour = Number(startTimeJST.split(':')[0]);
          const endHour = getRoundUpHour(endTimeJST);

          if (startHour < minHour) {
            minHour = startHour;
          }
          if (endHour > maxHour) {
            maxHour = endHour;
          }
          if (endHour === 0) {
            maxHour = 24;
          }
        });
      });

      return { minHour, maxHour };
    },
    [timezone]
  );

  const slotTimes = useMemo(() => {
    if (!spaces || !hasLength(spaces)) return { minHour: 8, maxHour: 23 };
    const { minHour, maxHour } = findMinAndMaxHours(spaces, selectedSpaceIds, timezone);
    return {
      minHour: minHour === 24 ? 0 : minHour,
      maxHour
    };
  }, [spaces, selectedSpaceIds, timezone, findMinAndMaxHours]);

  const slotMinTime = { hour: isAllDay ? 0 : slotTimes.minHour, minute: 0 };
  const slotMaxTime = { hour: isAllDay ? 24 : slotTimes.maxHour, minute: 0 };

  return (
    <Container>
      <Content>
        <ItemNameWrapper>
          <ItemName>時間の選択（3/3）</ItemName>
          <Description>予約したい時間を選択（長押し）してください。</Description>
        </ItemNameWrapper>
        <CalendarWrapper overlay={isSearchingReservation}>
          {isSearchingReservation && (
            <CircularLoaderWrapper>
              <CircularLoader />
            </CircularLoaderWrapper>
          )}
          <FullCalendar
            headerToolbar={{
              left: 'customprev customnext',
              center: 'title',
              right: 'customtoday'
            }}
            customButtons={{
              customprev: {
                text: '<',
                click: () => {
                  setCalendarDate(-1);
                }
              },
              customnext: {
                text: '>',
                click: () => {
                  setCalendarDate(1);
                }
              },
              customtoday: {
                text: '今日',
                click: () => {
                  const today = new Date();
                  today.setHours(0, 0, 0, 0);
                  setSelectedDate(today);
                }
              }
            }}
            locale='ja'
            timeZone='local'
            initialView='resourceTimeGridDay'
            themeSystem='bootstrap'
            contentHeight='auto'
            resourceOrder='order'
            selectConstraint='businessHours'
            resourceAreaHeaderContent='会議室'
            selectable
            nowIndicator
            resourcesInitiallyExpanded
            allDaySlot={false}
            selectOverlap={false}
            dayMinWidth={120}
            longPressDelay={0}
            eventLongPressDelay={200}
            selectLongPressDelay={200}
            slotMinTime={slotMinTime}
            slotMaxTime={slotMaxTime}
            slotDuration={{ minutes: getReservationStartUnit(spaces) }}
            slotLabelFormat={{ hour: 'numeric', minute: '2-digit' }}
            ref={timelineRef}
            events={events}
            plugins={[bootstrapPlugin, interactionPlugin, resourceTimeGridPlugin, scrollGridPlugin]}
            resources={resources}
            schedulerLicenseKey={LICENSE_KEY}
            select={handleSelected}
            eventClick={(arg: EventClickArg) => handleEventClicked(arg)}
          />
        </CalendarWrapper>
      </Content>
    </Container>
  );
});

ReserveTime.displayName = 'ReserveTime';
export default ReserveTime;

const Container = styled.div`
  width: 100%;
  height: auto;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 8px;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
  padding: ${themeV2.mixins.v2.spacing * 3}px;

  ${customMedia.lessThan('small')`
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  `}
`;

const ItemNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const ItemName = styled.div`
  ${themeV2.mixins.v2.typography.title.large};

  ${customMedia.lessThan('small')`
    ${themeV2.mixins.v2.typography.title.medium};
  `}
  ${customMedia.lessThan('tiny')`
    ${themeV2.mixins.v2.typography.title.small};
  `}
`;

const Description = styled.div`
  ${themeV2.mixins.v2.typography.body.large};

  ${customMedia.lessThan('small')`
    ${themeV2.mixins.v2.typography.body.medium};
  `}
  ${customMedia.lessThan('tiny')`
    ${themeV2.mixins.v2.typography.body.small};
  `}
`;

const CalendarWrapper = styled.div<{ overlay: boolean }>`
  height: 100%;
  position: relative;
  ::after {
    content: '';
    ${({ overlay }) =>
      overlay &&
      `
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.15);
    z-index: 99;
    `}
  }
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  border: 1px solid ${themeV2.mixins.v2.color.border.gray};
  border-top: 4px solid ${themeV2.mixins.v2.color.background.yellow};

  .fc-daygrid-day-events {
    min-height: 5em !important;
  }
  .fc-datagrid-body {
    width: auto !important;
    min-width: 100%;
  }

  ${customMedia.lessThan('small')`
    padding: ${themeV2.mixins.v2.spacing}px;
    .btn {
      padding: 4px 8px;
      font-size: 20px;
    }
    .fc-toolbar-title {
      font-size: 20px;
    }
    .fc-daygrid-body-natural .fc-daygrid-day-events {
      margin-bottom: 0;
    }
    .fc-daygrid-day-events {
      min-height: 1em !important;
    }
  `}
`;
const CircularLoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
