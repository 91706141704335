/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */
import type {
  DeleteIntroducingOtherForAdmin200,
  FetchTodayUsersForAdmin200,
  FetchUserForAdmin200,
  FetchUsersByDatesForAdmin200,
  FetchUsersByDatesForAdminParams,
  SaveIntroducingOtherForAdmin200,
  SaveIntroducingOtherForAdminBody,
  SavePersonInChargeForAdmin200,
  SavePersonInChargeForAdminBody,
  SaveUserIdentificationForAdmin200,
  SaveUserIdentificationForAdminBody,
  SaveUserPointsForAdmin200,
  SaveUserPointsForAdminBody,
  SaveUserSkillTagsByNameForAdmin200,
  SaveUserSkillTagsByNameForAdminBody,
  SaveUserWishTagsForAdmin200,
  SaveUserWishTagsForAdminBody,
  SaveWishUserForAdmin200,
  SaveWishUserForAdminBody,
  SearchIntroducingOthersForAdmin200,
  SearchIntroducingOthersForAdminParams,
  SearchSkillTagsForAdmin200,
  SearchSkillTagsForAdminParams,
  SearchUserPointLogsForAdmin200,
  SearchUserPointLogsForAdminParams,
  SearchUsersForAdmin200,
  SearchUsersForAdminParams,
  SearchUsersForWishForAdmin200,
  SearchUsersForWishForAdminParams
} from '../../model'
import { privateAxiosInstance } from '../../../axios/custom-instance';
import type { BodyType } from '../../../axios/custom-instance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getBsUser = () => {
/**
 * 本日の利用者情報を取得する
 * @summary 本日の利用者情報を取得する
 */
const fetchTodayUsersForAdmin = (
    baseId: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchTodayUsersForAdmin200>(
      {url: `/admin/base/${baseId}/user/today`, method: 'GET'
    },
      options);
    }
  /**
 * ユーザー情報を検索する
 * @summary ユーザー情報を検索する
 */
const searchUsersForWishForAdmin = (
    baseId: string,
    params: SearchUsersForWishForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SearchUsersForWishForAdmin200>(
      {url: `/admin/base/${baseId}/user/search`, method: 'GET',
        params
    },
      options);
    }
  /**
 * ユーザー情報を検索する
 * @summary ユーザー情報を検索する
 */
const searchUsersForAdmin = (
    baseId: string,
    params: SearchUsersForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SearchUsersForAdmin200>(
      {url: `/admin/base/${baseId}/user`, method: 'GET',
        params
    },
      options);
    }
  /**
 * ユーザー情報を取得する
 * @summary ユーザー情報を取得する
 */
const fetchUserForAdmin = (
    baseId: string,
    userId: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchUserForAdmin200>(
      {url: `/admin/base/${baseId}/user/${userId}`, method: 'GET'
    },
      options);
    }
  /**
 * 他己紹介情報を検索する
 * @summary 他己紹介情報を検索する
 */
const searchIntroducingOthersForAdmin = (
    baseId: string,
    params: SearchIntroducingOthersForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SearchIntroducingOthersForAdmin200>(
      {url: `/admin/base/${baseId}/introducing-other`, method: 'GET',
        params
    },
      options);
    }
  /**
 * 他己紹介情報を保存する
 * @summary 他己紹介情報を保存する
 */
const saveIntroducingOtherForAdmin = (
    baseId: string,
    saveIntroducingOtherForAdminBody: BodyType<SaveIntroducingOtherForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SaveIntroducingOtherForAdmin200>(
      {url: `/admin/base/${baseId}/introducing-other`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: saveIntroducingOtherForAdminBody
    },
      options);
    }
  /**
 * Wishに紐づくタグ情報を保存する
 * @summary Wishに紐づくタグ情報を保存する
 */
const saveUserWishTagsForAdmin = (
    baseId: string,
    saveUserWishTagsForAdminBody: BodyType<SaveUserWishTagsForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SaveUserWishTagsForAdmin200>(
      {url: `/admin/base/${baseId}/user-wish-tag`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: saveUserWishTagsForAdminBody
    },
      options);
    }
  /**
 * Wishからユーザーを登録する
 * @summary Wishからユーザーを登録する
 */
const saveWishUserForAdmin = (
    baseId: string,
    saveWishUserForAdminBody: BodyType<SaveWishUserForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SaveWishUserForAdmin200>(
      {url: `/admin/base/${baseId}/user/wish-user`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: saveWishUserForAdminBody
    },
      options);
    }
  /**
 * 指定した期間内に利用開始したユーザー情報を取得する
 * @summary 指定した期間内に利用開始したユーザー情報を取得する
 */
const fetchUsersByDatesForAdmin = (
    baseId: string,
    params: FetchUsersByDatesForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<FetchUsersByDatesForAdmin200>(
      {url: `/admin/base/${baseId}/user/date`, method: 'GET',
        params
    },
      options);
    }
  /**
 * ユーザー権限情報を保存する
 * @summary ユーザー権限情報を保存する
 */
const savePersonInChargeForAdmin = (
    baseId: string,
    savePersonInChargeForAdminBody: BodyType<SavePersonInChargeForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SavePersonInChargeForAdmin200>(
      {url: `/admin/base/${baseId}/person-in-charge`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: savePersonInChargeForAdminBody
    },
      options);
    }
  /**
 * 他己紹介情報を削除する
 * @summary 他己紹介情報を削除する
 */
const deleteIntroducingOtherForAdmin = (
    baseId: string,
    introducingOtherId: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<DeleteIntroducingOtherForAdmin200>(
      {url: `/admin/base/${baseId}/introducing-other/${introducingOtherId}`, method: 'DELETE'
    },
      options);
    }
  /**
 * 本人確認情報を保存する
 * @summary 本人確認情報を保存する
 */
const saveUserIdentificationForAdmin = (
    baseId: string,
    saveUserIdentificationForAdminBody: BodyType<SaveUserIdentificationForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SaveUserIdentificationForAdmin200>(
      {url: `/admin/base/${baseId}/user-identification`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: saveUserIdentificationForAdminBody
    },
      options);
    }
  /**
 * ユーザーの保持するポイントを追加する
 * @summary ユーザーの保持するポイントを追加する
 */
const saveUserPointsForAdmin = (
    baseId: string,
    saveUserPointsForAdminBody: BodyType<SaveUserPointsForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SaveUserPointsForAdmin200>(
      {url: `/admin/base/${baseId}/user-point`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: saveUserPointsForAdminBody
    },
      options);
    }
  /**
 * ユーザーの得意なことタグ情報を保存する
 * @summary ユーザーの得意なことタグ情報を保存する
 */
const saveUserSkillTagsByNameForAdmin = (
    baseId: string,
    saveUserSkillTagsByNameForAdminBody: BodyType<SaveUserSkillTagsByNameForAdminBody>,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SaveUserSkillTagsByNameForAdmin200>(
      {url: `/admin/base/${baseId}/user-skill-tag`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: saveUserSkillTagsByNameForAdminBody
    },
      options);
    }
  /**
 * 拠点情報に紐づく得意なことタグ情報を検索する
 * @summary 拠点情報に紐づく得意なことタグ情報を検索する
 */
const searchSkillTagsForAdmin = (
    baseId: string,
    params: SearchSkillTagsForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SearchSkillTagsForAdmin200>(
      {url: `/admin/base/${baseId}/skill-tag`, method: 'GET',
        params
    },
      options);
    }
  /**
 * ユーザーのポイント利用履歴を検索する
 * @summary ユーザーのポイント利用履歴を検索する
 */
const searchUserPointLogsForAdmin = (
    baseId: string,
    params: SearchUserPointLogsForAdminParams,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SearchUserPointLogsForAdmin200>(
      {url: `/admin/base/${baseId}/user-point-log`, method: 'GET',
        params
    },
      options);
    }
  return {fetchTodayUsersForAdmin,searchUsersForWishForAdmin,searchUsersForAdmin,fetchUserForAdmin,searchIntroducingOthersForAdmin,saveIntroducingOtherForAdmin,saveUserWishTagsForAdmin,saveWishUserForAdmin,fetchUsersByDatesForAdmin,savePersonInChargeForAdmin,deleteIntroducingOtherForAdmin,saveUserIdentificationForAdmin,saveUserPointsForAdmin,saveUserSkillTagsByNameForAdmin,searchSkillTagsForAdmin,searchUserPointLogsForAdmin}};
export type FetchTodayUsersForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsUser>['fetchTodayUsersForAdmin']>>>
export type SearchUsersForWishForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsUser>['searchUsersForWishForAdmin']>>>
export type SearchUsersForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsUser>['searchUsersForAdmin']>>>
export type FetchUserForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsUser>['fetchUserForAdmin']>>>
export type SearchIntroducingOthersForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsUser>['searchIntroducingOthersForAdmin']>>>
export type SaveIntroducingOtherForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsUser>['saveIntroducingOtherForAdmin']>>>
export type SaveUserWishTagsForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsUser>['saveUserWishTagsForAdmin']>>>
export type SaveWishUserForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsUser>['saveWishUserForAdmin']>>>
export type FetchUsersByDatesForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsUser>['fetchUsersByDatesForAdmin']>>>
export type SavePersonInChargeForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsUser>['savePersonInChargeForAdmin']>>>
export type DeleteIntroducingOtherForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsUser>['deleteIntroducingOtherForAdmin']>>>
export type SaveUserIdentificationForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsUser>['saveUserIdentificationForAdmin']>>>
export type SaveUserPointsForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsUser>['saveUserPointsForAdmin']>>>
export type SaveUserSkillTagsByNameForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsUser>['saveUserSkillTagsByNameForAdmin']>>>
export type SearchSkillTagsForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsUser>['searchSkillTagsForAdmin']>>>
export type SearchUserPointLogsForAdminResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsUser>['searchUserPointLogsForAdmin']>>>
