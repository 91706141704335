import {
  Breadcrumb,
  BreadcrumbTrailV2,
  ButtonV2,
  Component,
  MoreHorizMenu,
  MoreHorizMenuButton,
  Pagenate,
  ScreenLoaderV2,
  TabComponent,
  TabsV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  CONTRACT_ID_V2,
  ContractIdV2,
  ContractLogV2,
  ContractV2,
  CreditCard,
  DELETE_CONTRACT_V2_FOR_ADMIN,
  DeleteContractUserV2ForAdminResponse,
  DeleteContractV2ForAdminRequest,
  FETCH_CONTRACT_V2_FOR_ADMIN,
  FETCH_CREDIT_CARD_FOR_ADMIN,
  FetchContractV2ForAdminRequest,
  FetchContractV2ForAdminResponse,
  FetchCreditCardForAdminRequest,
  FetchCreditCardForAdminResponse,
  PaymentMethod,
  SAVE_CONTRACT_LOG_V2_FOR_ADMIN,
  SaveContractLogV2ForAdminRequest,
  SaveContractLogV2ForAdminResponse,
  User,
  isBaseFunctionToggleEnabled
} from '@atomica.co/irori';
import { Count, Index } from '@atomica.co/types';
import { ONE, SLASH, ZERO, builder, embedIdInPath, hasLength, uuid } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { CONTRACT_DETAIL_PATHS } from '../../constants/contract-v2-const';
import { ContractDetailPathEnum, ContractDetailTabNameEnum } from '../../enums/contract-v2-enum';
import useCachedSearchContractInfo, { CachedSearchContractInfo } from '../../redux/hooks/useCachedContractList';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../router/Routes';
import { CONTRACT_DELETED } from '../../texts/contract-v2-text';
import ContractBillings from './contract-billings/ContractBillings';
import ContractDetails from './contract-details/ContractDetails';
import ContractLogs from './contract-logs/ContractLogs';
import ContractUsers from './contract-users/ContractUsers';

const LIMIT = 1;

const CONTRACT_DETAILS_HEADER_HEIGHT = 100;
const CONTRACT_DETAILS_CONSOLE_FOOTER_HEIGHT = 72;
const CONTRACT_DETAILS_CONTENT_MAX_WIDTH = 1280;
const CONTRACT_DETAILS_CONTENT_MIN_WIDTH = 756;

interface P {
  isDrawerOpen: boolean;
  base: BaseDto;
  user: User;
}

const ContractDetailsScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { path, params, openPath, openBasePathWithQueryParams } = usePath();
  const { cachedSearchContractInfo, saveCachedSearchContractInfo, clearCachedSearchContractInfo } =
    useCachedSearchContractInfo();
  const contractList = useRef<CachedSearchContractInfo>(cachedSearchContractInfo);
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();

  const initialTabIdx = useMemo((): Index => {
    const lastOfPath = path?.split(SLASH).pop();
    return Object.values(ContractDetailPathEnum).indexOf(lastOfPath as ContractDetailPathEnum) || ZERO;
  }, [path]);
  const [displayIdx, setDisplayIdx] = useSafeState<Index>(
    unmountRef,
    contractList.current.selectedContractIds.indexOf(params[CONTRACT_ID_V2])
  );
  const [selectedTabIdx, setSelectedTabIdx] = useSafeState<Index>(unmountRef, initialTabIdx);
  const [contract, setContract] = useSafeState<ContractV2>(unmountRef);
  const [isLoaderShown, setIsLoaderShown] = useSafeState(unmountRef, false);
  const [creditCard, setCreditCard] = useSafeState<CreditCard | undefined>(unmountRef);

  const loaded = useMemo<boolean>(() => !!contract, [contract]);
  const contractId = useMemo<ContractIdV2>(() => params[CONTRACT_ID_V2], [params]);

  const count = useMemo<Count>(() => {
    if (hasLength(contractList.current.selectedContractIds)) return contractList.current.selectedContractIds.length;
    return !contractList.current.totalRecordCount ? 1 : contractList.current.totalRecordCount;
  }, []);

  const tabComponents = useMemo<TabComponent[]>(
    () => [
      {
        label: ContractDetailTabNameEnum.DETAIL,
        component: <ContractDetails base={base} contract={contract} creditCard={creditCard} />
      },
      { label: ContractDetailTabNameEnum.BILLING, component: <ContractBillings base={base} contract={contract} /> },
      { label: ContractDetailTabNameEnum.MEMBER, component: <ContractUsers base={base} contract={contract} /> },
      { label: ContractDetailTabNameEnum.LOGS, component: <ContractLogs base={base} contract={contract} /> }
    ],
    [base, contract, creditCard]
  );

  const detailTabIdx = useMemo<Index>(
    () => tabComponents.findIndex(({ label }) => label === ContractDetailTabNameEnum.DETAIL),
    [tabComponents]
  );

  const billingTabIdx = useMemo<Index>(
    () => tabComponents.findIndex(({ label }) => label === ContractDetailTabNameEnum.BILLING),
    [tabComponents]
  );

  const isPayWithPayment = useMemo(() => {
    return isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_STRIPE);
  }, [base]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchContractV2ForAdminRequest>().baseId(base.baseId).contractId(contractId).build();
    const response = await commonRequest<FetchContractV2ForAdminRequest, FetchContractV2ForAdminResponse>(
      FETCH_CONTRACT_V2_FOR_ADMIN,
      request
    );
    setContract(response.contract);
    // NOTE: version2との互換性のため、一旦このようにしていますが、後で修正する必要があります。
    if (isPayWithPayment && response.contract?.paymentMethod === PaymentMethod.CREDIT_CARD) {
      const fetchCreditCardRequest = builder<FetchCreditCardForAdminRequest>()
        .baseId(base.baseId)
        .contractId(contractId)
        .build();
      const res = await commonRequest<FetchCreditCardForAdminRequest, FetchCreditCardForAdminResponse>(
        FETCH_CREDIT_CARD_FOR_ADMIN,
        fetchCreditCardRequest
      );
      setCreditCard(res?.creditCard);
    }
  }, [base, commonRequest, contractId, setContract, isPayWithPayment]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const deleteContract = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const request = builder<DeleteContractV2ForAdminRequest>()
      .baseId(base.baseId)
      .contractId(contract!.contractId)
      .build();
    await commonRequest<DeleteContractV2ForAdminRequest, DeleteContractUserV2ForAdminResponse>(
      DELETE_CONTRACT_V2_FOR_ADMIN,
      request
    );

    const contractLog = builder<ContractLogV2>()
      .contractLogId(uuid())
      .contract(contract)
      .operation(CONTRACT_DELETED)
      .createdUser(user)
      .build();
    const contractLogToSave = builder<SaveContractLogV2ForAdminRequest>()
      .baseId(base.baseId)
      .contractLogs([contractLog])
      .build();
    await commonRequest<SaveContractLogV2ForAdminRequest, SaveContractLogV2ForAdminResponse>(
      SAVE_CONTRACT_LOG_V2_FOR_ADMIN,
      contractLogToSave
    );

    clearCachedSearchContractInfo();
    openPath(embedIdInPath(Path.CONTRACT_LIST_V2, PATH_IDS, [base.baseCode]));
    setIsLoaderShown(false);
  }, [user, contract, base.baseCode, base.baseId, clearCachedSearchContractInfo, openPath, setIsLoaderShown]);

  const subMenus = useMemo<MoreHorizMenu[]>(() => {
    return [{ label: '削除', onClick: deleteContract }];
  }, [deleteContract]);

  const openPathForContractDetail = useSafeCallback(
    (path: Path, contractId: ContractIdV2) => {
      return openPath(embedIdInPath(path, PATH_IDS, [base.baseCode, contractId]));
    },
    [base, openPath]
  );

  const selectPath = useSafeCallback(
    (selectedTabIdx: Index, selectedContractId?: ContractIdV2) => {
      const contractIdForPath = selectedContractId ? selectedContractId : contractId;
      openPathForContractDetail(CONTRACT_DETAIL_PATHS[selectedTabIdx], contractIdForPath);
    },
    [contractId, openPathForContractDetail]
  );

  const backPage = useSafeCallback((): void => {
    setDisplayIdx(displayIdx => {
      const nextDisplayIdx = displayIdx - LIMIT;
      selectPath(selectedTabIdx, contractList.current.selectedContractIds[displayIdx - LIMIT]);
      return nextDisplayIdx;
    });
  }, [setDisplayIdx, selectPath, selectedTabIdx]);

  const forwardPage = useSafeCallback((): void => {
    setDisplayIdx(displayIdx => {
      const nextDisplayIdx = displayIdx + LIMIT;
      selectPath(selectedTabIdx, contractList.current.selectedContractIds[nextDisplayIdx]);
      return nextDisplayIdx;
    });
  }, [setDisplayIdx, selectPath, selectedTabIdx]);

  const backToContractList = useSafeCallback((): void => {
    saveCachedSearchContractInfo(contractList.current);
  }, [contractList, saveCachedSearchContractInfo]);

  const breadcrumbs: Breadcrumb[] = useMemo(
    (): Breadcrumb[] => [
      {
        label: '契約一覧へ',
        path: embedIdInPath(Path.CONTRACT_LIST_V2, PATH_IDS, [base.baseCode]),
        handlePathChanged: backToContractList
      }
    ],
    [base, backToContractList]
  );

  const forwardToEditerContract = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.EDIT_CONTRACT_V2, PATH_IDS, [base.baseCode, contractId]));
  }, [base, contractId, openPath]);

  const forwardToCreateBilling = useSafeCallback((): void => {
    openBasePathWithQueryParams(Path.REGISTER_BILLING_V2, { contractId: contractId });
  }, [base, contractId, openPath]);

  useEffect(() => {
    selectPath(selectedTabIdx);
  }, [selectedTabIdx, selectPath]);

  useEffect(() => {
    return () => {
      clearCachedSearchContractInfo();
    };
  }, [clearCachedSearchContractInfo]);

  return (
    <Component className='contract-details-screen' loading={!loaded} style={styleForComponent}>
      <Container>
        {loaded && (
          <Content>
            <HeaderWrapper>
              <Header>
                <BreadcrumbTrailV2 breadcrumbs={breadcrumbs} />
                <Wrapper>
                  <Title>{contract?.contractNo} の詳細</Title>
                  {detailTabIdx === selectedTabIdx && (
                    <FunctionWrapper>
                      <ButtonV2 type='primary' label='編集' onClick={forwardToEditerContract} />
                      <MoreHorizMenuButton menuButtons={subMenus} />
                    </FunctionWrapper>
                  )}
                  {billingTabIdx === selectedTabIdx && (
                    <FunctionWrapper>
                      <ButtonV2 type='primary' label='新規作成' onClick={forwardToCreateBilling} />
                    </FunctionWrapper>
                  )}
                </Wrapper>
              </Header>
            </HeaderWrapper>

            <Body>
              <TabsV2 selectedTabIdx={selectedTabIdx} tabComponents={tabComponents} onChange={setSelectedTabIdx} />
            </Body>
          </Content>
        )}

        <Footer>
          <Pagenate
            offset={displayIdx < 0 ? 0 : displayIdx}
            limit={LIMIT}
            count={count}
            shape='rect'
            clickable={count !== ONE}
            onClickBack={backPage}
            onClickForward={forwardPage}
          />
        </Footer>
      </Container>
      <ScreenLoaderV2 loading={isLoaderShown} />
    </Component>
  );
});

ContractDetailsScreen.displayName = 'ContractDetailsScreen';
export default ContractDetailsScreen;

const styleForComponent: CSSProperties = {
  height: '100%'
};

const Container = styled.div`
  width: 100%;
  height: calc(100% - ${CONTRACT_DETAILS_CONSOLE_FOOTER_HEIGHT}px);
  display: flex;
  flex-direction: column;
`;
const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px ${themeV2.mixins.v2.spacing * 3}px;
`;
const HeaderWrapper = styled.div`
  width: 100%;
  height: ${CONTRACT_DETAILS_HEADER_HEIGHT}px;
  max-width: ${CONTRACT_DETAILS_CONTENT_MAX_WIDTH}px;
  min-width: ${CONTRACT_DETAILS_CONTENT_MIN_WIDTH}px;
  margin: 0 auto;
  position: relative;
`;
const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
  padding: ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 3}px 0;
  width: 100%;
  max-width: ${CONTRACT_DETAILS_CONTENT_MAX_WIDTH}px;
  min-width: ${CONTRACT_DETAILS_CONTENT_MIN_WIDTH}px;
  z-index: 10;
`;
const Body = styled.div`
  width: 100%;
  flex: 1;
  max-width: ${CONTRACT_DETAILS_CONTENT_MAX_WIDTH}px;
  min-width: ${CONTRACT_DETAILS_CONTENT_MIN_WIDTH}px;
  margin: 0 auto;
  padding: 0 ${themeV2.mixins.v2.spacing * 3}px 0;
  height: calc(100% - ${CONTRACT_DETAILS_HEADER_HEIGHT + CONTRACT_DETAILS_CONSOLE_FOOTER_HEIGHT}px);
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FunctionWrapper = styled.div`
  display: flex;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  position: relative;
`;

const Title = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.xLarge};
  min-height: 36px;
`;
const Footer = styled.div`
  position: fixed;
  right: 0;
  transition: ${themeV2.transitions.create(['width', 'right'], {
    easing: themeV2.transitions.easing.easeOut,
    duration: themeV2.transitions.duration.enteringScreen
  })};
  width: 100%;
  bottom: 0;
`;
