import {
  ButtonV2,
  ColWidth,
  Header,
  MoreHorizMenu,
  MoreHorizMenuButton,
  MoreHorizMenuForTable,
  SearchListV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseId,
  DELETE_EVENT_SCHEDULE_PARTICIPANT_V2_FOR_ADMIN,
  DeleteEventScheduleParticipantV2ForAdminRequest,
  DeleteEventScheduleParticipantV2ForAdminResponse,
  EventScheduleParticipantId,
  EventScheduleParticipantV2,
  EventScheduleV2,
  SEARCH_EVENT_SCHEDULE_PARTICIPANTS_V2_FOR_ADMIN,
  SearchEventScheduleParticipantsV2ForAdminRequest,
  SearchEventScheduleParticipantsV2ForAdminResponse,
  toFullName
} from '@atomica.co/irori';
import { Code, Count, Email, Id, Index, Label, Name, Offset, Phone, UserId, Word } from '@atomica.co/types';
import { EMPTY, ONE, ZERO, builder, embedIdInPath, hasLength, isGreaterThanZero } from '@atomica.co/utils';
import React, { useEffect, useMemo, useRef } from 'react';
import { CSVDownload } from 'react-csv';
import styled from 'styled-components';
import DefaultUserIcon from '../../../../assets/default_user_icon.png';
import { ParticipantCSV, convertToParticipantV2CSV } from '../../../../converters/export-converter';
import { SearchOption } from '../../../../enums/event-v2-enum';
import { CSVTemplate } from '../../../../models/common-model';
import useCommonRequest from '../../../../redux/hooks/useCommonRequest';
import usePath from '../../../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../../../router/Routes';
import { EVENT_HEADERS } from '../../../../texts/event-text';
import AssignGroupModal from '../../modal/AssignGroupModal';
import RegisterGroupModal from '../../modal/RegisterGroupModal';

const COLUMN_WIDTH: ColWidth = { icon: 56, name: 'auto', phone: 'auto', email: 'auto', regular: 180, group: 138 };

const HEADER: Header = {
  icon: { label: EMPTY },
  name: { label: '氏名' },
  phone: { label: '電話番号' },
  email: { label: 'メールアドレス' },
  regular: { label: '定期参加' },
  group: { label: 'グループ' }
};

interface ParticipantRow {
  id: Id;
  icon: JSX.Element;
  name: Name;
  phone: Phone;
  email: Email;
  group: JSX.Element;
  regular: Label;
}

interface P {
  baseCode: Code;
  baseId: BaseId;
  eventSchedule: EventScheduleV2;
  regularUserIds: UserId[];
}

const photoComponent = (participant: EventScheduleParticipantV2): JSX.Element => {
  return (
    <IconWrapper>
      <Icon src={participant.user?.photoURL || DefaultUserIcon} />
    </IconWrapper>
  );
};

const ParticipantList: React.FC<P> = React.memo(props => {
  const { baseCode, baseId, eventSchedule, regularUserIds = [] } = props;
  const { openPath } = usePath();
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [isRegisterGroupModalOpen, setIsRegisterGroupModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [participants, setParticipants] = useSafeState<EventScheduleParticipantV2[]>(unmountRef, []);
  const [rows, setRows] = useSafeState<ParticipantRow[]>(unmountRef, []);
  const [selectedParticipantIds, setSelectedParticipantIds] = useSafeState<EventScheduleParticipantId[]>(
    unmountRef,
    []
  );
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, EMPTY);
  const [offset, setOffset] = useSafeState<Offset>(unmountRef, SearchOption.OFFSET);
  const [totalCount, setTotalCount] = useSafeState<Count>(unmountRef, ZERO);
  const [isLoaderShown, setIsLoaderShown] = useSafeState<boolean>(unmountRef, true);
  const [savedGroupName, setSavedGroupName] = useSafeState<Name>(unmountRef, EMPTY);

  const [csvHeaders, setCsvHeaders] = useSafeState<CSVTemplate[]>(unmountRef, []);
  const [csvContent, setCsvContent] = useSafeState<ParticipantCSV[]>(unmountRef, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const csvInstance = useRef<any>();

  const deleteParticipant = useSafeCallback(
    async (eventScheduleParticipantId: EventScheduleParticipantId, initialize: () => Promise<void>): Promise<void> => {
      setIsLoaderShown(true);

      const request = builder<DeleteEventScheduleParticipantV2ForAdminRequest>()
        .baseId(baseId)
        .eventScheduleParticipantId(eventScheduleParticipantId)
        .build();
      const response = await commonRequest<
        DeleteEventScheduleParticipantV2ForAdminRequest,
        DeleteEventScheduleParticipantV2ForAdminResponse
      >(DELETE_EVENT_SCHEDULE_PARTICIPANT_V2_FOR_ADMIN, request);

      if (!response.eventScheduleParticipantId) {
        setIsLoaderShown(false);
        return;
      }

      await initialize();
      setIsLoaderShown(false);
    },
    [baseId, setIsLoaderShown]
  );

  const initialize = useSafeCallback(async (): Promise<void> => {
    setIsLoaderShown(true);
    const participantRequest = builder<SearchEventScheduleParticipantsV2ForAdminRequest>()
      .baseId(baseId)
      .eventScheduleIdV2(eventSchedule.eventScheduleId)
      .limit(SearchOption.LIMIT)
      .offset(offset)
      .word(searchingWord)
      .build();

    const participantResponse = await commonRequest<
      SearchEventScheduleParticipantsV2ForAdminRequest,
      SearchEventScheduleParticipantsV2ForAdminResponse
    >(SEARCH_EVENT_SCHEDULE_PARTICIPANTS_V2_FOR_ADMIN, participantRequest);

    const { eventScheduleParticipantsV2, totalCount } = participantResponse;

    if (!eventScheduleParticipantsV2) return;

    const rows = eventScheduleParticipantsV2.map(participant =>
      builder<ParticipantRow>()
        .id(participant.eventScheduleParticipantId)
        .icon(photoComponent(participant))
        .name(toFullName(participant.user))
        .phone(participant.user?.phone || EMPTY)
        .email(participant.particiantEmail ?? EMPTY)
        .regular(hasLength(regularUserIds) && regularUserIds.includes(participant.user!.userId) ? '定期参加者' : EMPTY)
        .group(
          participant.eventGroup ? <span>{participant.eventGroup.name}</span> : <NoGroupLabel>未設定</NoGroupLabel>
        )
        .build()
    );

    if (!hasLength(rows) && isGreaterThanZero(offset)) setOffset(offset - ONE);

    setParticipants(eventScheduleParticipantsV2);
    setRows(rows);
    setTotalCount(totalCount);
    setIsLoaderShown(false);
  }, [
    baseId,
    commonRequest,
    eventSchedule,
    offset,
    regularUserIds,
    searchingWord,
    setParticipants,
    setOffset,
    setRows,
    setTotalCount,
    setIsLoaderShown
  ]);

  const handleSearchingWordChanged = useSafeCallback(
    (word: Word): void => {
      setSearchingWord(word);
    },
    [setSearchingWord]
  );

  const handleParticipantsGroupModalClosed = useSafeCallback(
    (isUpdated: boolean): void => {
      setIsModalOpen(false);
      if (isUpdated) initialize();
    },
    [initialize, setIsModalOpen]
  );

  const handleSelectGroup = useSafeCallback((): void => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const exportCSV = useSafeCallback(
    (participants: EventScheduleParticipantV2[]): void => {
      setCsvHeaders(EVENT_HEADERS);
      setCsvContent(convertToParticipantV2CSV(eventSchedule, participants));
    },
    [eventSchedule, setCsvHeaders, setCsvContent]
  );

  useEffect(() => {
    initialize();
  }, [initialize]);

  const headerSubMenus = useMemo<MoreHorizMenu[]>(
    () => [
      {
        label: 'CSVダウンロード',
        onClick: () => exportCSV(participants)
      }
      // {
      //   label: 'スプレッドシートを開く',
      //   onClick: noop
      // }
    ],
    [participants, exportCSV]
  );

  const handleCancelMenu = useSafeCallback(
    async (rowIndex: Index): Promise<void> => {
      const participant = rows[rowIndex];
      await deleteParticipant(participant.id, initialize);
    },
    [deleteParticipant, initialize, rows]
  );

  const moreHorizMenusForTable = useMemo<MoreHorizMenuForTable[]>(
    () => [
      {
        color: themeV2.mixins.v2.color.font.gray,
        label: '参加者を削除する',
        onClick: rowIndex => handleCancelMenu(rowIndex)
      }
    ],
    [handleCancelMenu]
  );

  const openUserOverviewScreen = useSafeCallback(
    (index: Index): void => {
      if (!baseCode) return;
      const user = participants[index].user;
      openPath(embedIdInPath(Path.USER_OVERVIEW, PATH_IDS, [baseCode, user!.userId]));
    },
    [baseCode, openPath, participants]
  );

  return (
    <Container>
      {eventSchedule && (
        <Content>
          <AssignGroupModal
            baseId={baseId}
            isOpen={isModalOpen}
            eventScheduleV2={eventSchedule}
            eventScheduleParticipants={selectedParticipantIds.map(
              participantId =>
                participants.find(participant => participant.eventScheduleParticipantId === participantId)!
            )}
            savedGroupName={savedGroupName}
            onChange={setSavedGroupName}
            onClose={isUpdated => handleParticipantsGroupModalClosed(isUpdated)}
            openRegisterGroupModal={() => setIsRegisterGroupModalOpen(true)}
          />
          <RegisterGroupModal
            baseId={baseId}
            isOpen={isRegisterGroupModalOpen}
            eventScheduleV2={eventSchedule}
            mode='register'
            onChange={setSavedGroupName}
            onClose={isUpdated => setIsRegisterGroupModalOpen(isUpdated)}
          />
          <SearchListV2
            name='参加者'
            title={`${totalCount}人の参加者`}
            colWidth={COLUMN_WIDTH}
            header={HEADER}
            rows={rows}
            offset={offset}
            limit={SearchOption.LIMIT}
            totalCount={totalCount}
            placeholder='氏名・メールアドレスで検索'
            searchingWord={searchingWord}
            isLoaderShown={isLoaderShown}
            moreHorizMenusForTable={moreHorizMenusForTable}
            selectedIds={selectedParticipantIds}
            headerRightComponent={<MoreHorizMenuButton type='default' menuButtons={headerSubMenus} />}
            tableRightComponent={<ButtonV2 type='primary' label='グループを指定' onClick={handleSelectGroup} />}
            setOffset={setOffset}
            setSelectedIds={setSelectedParticipantIds}
            onChange={handleSearchingWordChanged}
            onClickRow={index => openUserOverviewScreen(index)}
          />
          {hasLength(csvContent) && <CSVDownload ref={csvInstance} headers={csvHeaders} data={csvContent} />}
        </Content>
      )}
    </Container>
  );
});

ParticipantList.displayName = 'ParticipantList';
export default ParticipantList;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${themeV2.mixins.v2.spacing * 2}px 0 ${themeV2.mixins.v2.spacing * 5}px 0;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;

const NoGroupLabel = styled.span`
  color: ${themeV2.mixins.v2.color.font.lightGray};
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
`;
