import {
  ButtonV2,
  Component,
  InputWithLabelV2,
  PageHeaderV2,
  ScreenLoaderV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  REGISTER_USER_WISH,
  RegisterUserWishRequest,
  RegisterUserWishResponse,
  UPDATE_USER_WISH,
  UpdateUserWishRequest,
  UpdateUserWishResponse,
  User
} from '@atomica.co/irori';
import { Text } from '@atomica.co/types';
import { builder, embedIdInPath, EMPTY } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { MOBILE_MIN_WIDTH } from '../../../constants/common-const';
import { createCustomization } from '../../../customization/customizationFactory';
import { useSnackbarV2 } from '../../../provider/SnackbarProviderV2';
import useCommonRequest from '../../../redux/hooks/useCommonRequest';
import usePath from '../../../redux/hooks/usePath';
import useUserWish from '../../../redux/hooks/useUserWish';
import { Path, PATH_IDS } from '../../../router/Routes';
import { RegisterAccountWishForm } from '../../../validate/accountScreen/accountUserWish/schema';
import { validateRegisterAccountWish } from '../../../validate/accountScreen/accountUserWish/validate';

interface P {
  base: BaseDto;
  user: User;
}

const RegisterAccountWish: React.FC<P> = React.memo(props => {
  const { user, base } = props;
  const { params, openBasePath, goBack, openPath } = usePath();
  const unmountRef = useUnmountRef();
  const { commonRequest } = useCommonRequest();
  const { openSnackbar } = useSnackbarV2();
  const { loadUserWish } = useUserWish();

  const customization = createCustomization(base.baseCode);
  const replacedWishWord = useMemo(() => {
    return customization.replaceWithMapping('Wish');
  }, [customization]);

  const [isLoading, setIsLoading] = useSafeState<boolean>(unmountRef, false);
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [content, setContent] = useSafeState<Text>(unmountRef, EMPTY);
  const [errorMessageForContent, setErrorMessageForContent] = useSafeState<Text>(unmountRef);

  const isEdit = useMemo<boolean>(() => !!params['userWishId'], [params]);

  const openAccountWishListScreen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.ACCOUNT_WISH_LIST, PATH_IDS, [base.baseCode, user.userId]));
  }, [openBasePath, user.userId, openPath]);

  const validateUserWish = useSafeCallback((): boolean => {
    setErrorMessageForContent(EMPTY);
    const inputedUserWish = builder<RegisterAccountWishForm>().content(content).build();
    const errorMessage = validateRegisterAccountWish(inputedUserWish);
    setErrorMessageForContent(errorMessage);
    if (errorMessage) {
      openSnackbar('入力値に誤りがあります', 'warning', 4000);
    }
    return !errorMessage;
  }, [content, openSnackbar, setErrorMessageForContent]);

  const registerUserWish = useSafeCallback(async (): Promise<void> => {
    setSaving(true);
    const request = builder<RegisterUserWishRequest>().userId(user.userId).baseId(base.baseId).content(content).build();
    const response = await commonRequest<RegisterUserWishRequest, RegisterUserWishResponse>(
      REGISTER_USER_WISH,
      request
    );
    setSaving(false);

    if (!response.result) {
      openSnackbar(`${replacedWishWord}の登録に失敗しました 時間をおいてもう一度お試しください`, 'error', 4000);
      return;
    }
    openSnackbar(`${replacedWishWord}を登録しました！`, 'success', 4000);
  }, [commonRequest, openSnackbar, setSaving, user, base, content]);

  const updateUserWish = useSafeCallback(async (): Promise<void> => {
    setSaving(true);
    const request = builder<UpdateUserWishRequest>()
      .baseId(base.baseId)
      .userWishId(params['userWishId'])
      .content(content)
      .build();
    const response = await commonRequest<UpdateUserWishRequest, UpdateUserWishResponse>(UPDATE_USER_WISH, request);
    setSaving(false);

    if (!response.result) {
      openSnackbar(`${replacedWishWord}の更新に失敗しました 時間をおいてもう一度お試しください`, 'error', 4000);
      return;
    }
    openSnackbar(`${replacedWishWord}を更新しました！`, 'success', 4000);
  }, [base.baseId, setSaving, params, content, commonRequest, openSnackbar]);

  const handleSaveButtonClicked = useSafeCallback(async (): Promise<void> => {
    if (saving || !validateUserWish()) return;
    if (isEdit) {
      await updateUserWish();
    } else {
      await registerUserWish();
    }
    openAccountWishListScreen();
  }, [isEdit, openAccountWishListScreen, registerUserWish, saving, updateUserWish, validateUserWish]);

  const handleContentInputed = useSafeCallback(
    async (content: Text): Promise<void> => {
      setErrorMessageForContent(EMPTY);
      setContent(content);
    },
    [setContent, setErrorMessageForContent]
  );

  const initialize = useSafeCallback(async (): Promise<void> => {
    setIsLoading(true);
    const userWishes = await loadUserWish(user.userId, base.baseId);
    const userWish = userWishes.find(userWish => userWish.userWishId === params['userWishId']);
    setContent(userWish?.content ?? EMPTY);
    setIsLoading(false);
  }, [base.baseId, loadUserWish, params, setContent, setIsLoading, user.userId]);

  useEffect(() => {
    if (!isEdit) return;
    initialize();
  }, [initialize, isEdit]);

  return (
    <Component
      style={{ width: '100%', height: '100%' }}
      className='register-account-wish'
      title={`${replacedWishWord}の新規登録`}
      loading={isLoading}
    >
      <Container data-testid='register-account-wish'>
        <Content>
          <PageHeaderV2 title={`${replacedWishWord}を` + (isEdit ? '編集' : '新規作成') + '(β版)'} />
          <InputSection data-testid='wish-content'>
            <InputWithLabelV2
              text='内容'
              remarks='全角140文字以内'
              multiline
              required
              value={content}
              maxLength={140}
              onChange={handleContentInputed}
              errorMessage={errorMessageForContent}
              name='wish-content'
            />
          </InputSection>
        </Content>
        <Footer>
          <StyledFooterButton
            type={'primary'}
            label={`${replacedWishWord}を保存`}
            onClick={handleSaveButtonClicked}
            disabled={saving}
          />
          <StyledFooterButton label='キャンセル' onClick={goBack} disabled={saving} />
        </Footer>
        <ScreenLoaderV2 loading={saving} />
      </Container>
    </Component>
  );
});
RegisterAccountWish.displayName = 'RegisterAccountWish';
export default RegisterAccountWish;

const Container = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
`;
const Content = styled.div`
  width: 100%;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: 640px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;
const InputSection = styled.section`
  width: 100%;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  background-color: ${themeV2.mixins.v2.color.background.white};
  border-radius: 12px;
`;
const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  margin-top: auto;
  background: ${themeV2.mixins.v2.color.background.white};
`;

const StyledFooterButton = styled(ButtonV2).attrs(() => ({ size: 'large', isFullWidth: true }))`
  ${media.greaterThan('small')`
      min-width:220px !important;
      width: max-content !important;
  `};
`;
