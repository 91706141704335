import {
  BackButtonV2,
  ButtonV2,
  Component,
  Components,
  IconButtonV2,
  ImageUploadV2,
  InputWithLabelV2,
  LabelV2,
  PageHeaderV2,
  RadioBoxV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  AuthorityDefCategory,
  BaseAuthority,
  BaseDto,
  BaseFunctionToggleCode,
  EVENT_ID_V2,
  EventIdV2,
  EventMembership,
  EventV2,
  FETCH_EVENT_V2_FOR_ADMIN,
  FetchEventV2ForAdminRequest,
  FetchEventV2ForAdminResponse,
  ParticipatingMembershipTypeEnum,
  SAVE_EVENT_V2_FOR_ADMIN,
  SaveEventV2ForAdminRequest,
  SaveEventV2ForAdminResponse,
  User,
  isBaseFunctionToggleEnabled
} from '@atomica.co/irori';
import { Index, Name, Text } from '@atomica.co/types';
import { EMPTY, builder, embedIdInPath, hasLength, uuid } from '@atomica.co/utils';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import ListV2, { ListRow } from '../../components/list/List';
import { ImageCategory } from '../../enums/common-enum';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import { PATH_IDS, Path } from '../../router/Routes';
import { ImageService } from '../../services/image-service';
import { EVENT_MEMBERSHIP_TYPE_LABELS } from '../../texts/event-text';
import MembershipModal from './modal/MembershipModal';

interface P {
  isDrawerOpen: boolean;
  base: BaseDto;
  user: User;
  onChange(path: Path): void;
}

const RegisterEventV2Screen: React.FC<P> = React.memo(props => {
  const { base, onChange } = props;
  const { path, params, openPath } = usePath();
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [event, setEvent] = useSafeState<EventV2>(unmountRef);
  const [nameToSave, setNameToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [descriptionToSave, setDescriptionToSave] = useSafeState<Text>(unmountRef, EMPTY);
  const [photo, setPhoto] = useSafeState<File>(unmountRef);
  const [participatingMembershipTypeToSave, setParticipatingMembershipTypeToSave] =
    useSafeState<ParticipatingMembershipTypeEnum>(unmountRef, ParticipatingMembershipTypeEnum.ALL);
  const [isOpenMembershipModal, setIsOpenMembershipModal] = useSafeState<boolean>(unmountRef, false);
  const [membershipsToSave, setMembershipsToSave] = useSafeState<BaseAuthority[]>(unmountRef, []);

  const eventId = useMemo<EventIdV2>(() => {
    return path === Path.EDIT_EVENT_V2 ? params[EVENT_ID_V2] : uuid();
  }, [path, params]);

  const baseMemberships = useMemo<BaseAuthority[]>(
    () =>
      base.authorities?.filter(auth => auth.authorityDef?.category?.categoryCode === AuthorityDefCategory.RANK) || [],
    [base]
  );

  const onChangeFile = useSafeCallback(
    async (file: File): Promise<boolean> => {
      setPhoto(file);
      return true;
    },
    [setPhoto]
  );

  const loadEvent = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchEventV2ForAdminRequest>().baseId(base.baseId).eventIdV2(eventId).build();
    const { eventV2 } = await commonRequest<FetchEventV2ForAdminRequest, FetchEventV2ForAdminResponse>(
      FETCH_EVENT_V2_FOR_ADMIN,
      request
    );
    if (!eventV2) return;

    const image = await ImageService.urlToFile(eventV2.photoURL);
    setEvent(eventV2);
    if (image) setPhoto(image);
  }, [base, commonRequest, eventId, setEvent, setPhoto]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    await loadEvent();
  }, [loadEvent]);

  useEffect(() => {
    if (!event) return;
    setNameToSave(event.name);
    setDescriptionToSave(event.description);
    setParticipatingMembershipTypeToSave(event.participatingMembershipType);
    if (!event.eventMemberships) return;
    setMembershipsToSave(event.eventMemberships.map(membership => membership.authority!));
  }, [event, setDescriptionToSave, setMembershipsToSave, setNameToSave, setParticipatingMembershipTypeToSave]);

  const handleButtonClicked = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.EVENT_V2_LIST, PATH_IDS, [base.baseCode]));
  }, [base, openPath]);

  const disabledSaveButton = useMemo<boolean>(() => {
    return (
      !nameToSave ||
      (participatingMembershipTypeToSave === ParticipatingMembershipTypeEnum.LIMITED && !hasLength(membershipsToSave))
    );
  }, [participatingMembershipTypeToSave, membershipsToSave, nameToSave]);

  const openEventV2DetailsScreen = useSafeCallback((): void => {
    onChange(embedIdInPath(Path.EVENT_V2_DETAILS, PATH_IDS, [base.baseCode, eventId]));
  }, [onChange, base, eventId]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const saveNewEvent = useSafeCallback(async (): Promise<void> => {
    setSaving(true);

    const photoUrlToSave = await ImageService.uploadImageToFirebase(photo, ImageCategory.EVENT, eventId);

    const eventMembershipsToSave = membershipsToSave.map(membership =>
      builder<EventMembership>().eventMembershipId(uuid()).authority(membership).build()
    );

    const eventToSave = builder<EventV2>()
      .eventId(eventId)
      .name(nameToSave)
      .description(descriptionToSave)
      .photoURL(photoUrlToSave)
      .participatingMembershipType(participatingMembershipTypeToSave)
      .eventMemberships(eventMembershipsToSave)
      .base(base)
      .build();

    const request = builder<SaveEventV2ForAdminRequest>().baseId(base.baseId).eventV2(eventToSave).build();
    await commonRequest<SaveEventV2ForAdminRequest, SaveEventV2ForAdminResponse>(SAVE_EVENT_V2_FOR_ADMIN, request);

    openEventV2DetailsScreen();
  }, [
    base,
    commonRequest,
    descriptionToSave,
    eventId,
    membershipsToSave,
    nameToSave,
    participatingMembershipTypeToSave,
    photo,
    setSaving,
    openEventV2DetailsScreen
  ]);

  const deleteEventMembership = useSafeCallback(
    async (indexToDelete: Index): Promise<void> => {
      const newMemberships = membershipsToSave.filter(
        (membership: BaseAuthority, index: Index) => index !== indexToDelete
      );
      setMembershipsToSave(newMemberships);
    },
    [membershipsToSave, setMembershipsToSave]
  );

  const SelectMembershipComponents = useMemo<Components>(
    () => ({
      [ParticipatingMembershipTypeEnum.LIMITED]: (
        <ButtonV2 size='small' type='secondary' label='会員種別を選択' onClick={() => setIsOpenMembershipModal(true)} />
      )
    }),
    [setIsOpenMembershipModal]
  );

  return (
    <Component loading={saving} style={{ width: '100%', height: '100%' }} className='register-event-v2-screen'>
      <Container>
        <Content>
          <BackButtonV2 label='戻る' onClick={handleButtonClicked} />
          <HeaderWrapper>
            <PageHeaderV2 title={path === Path.EDIT_EVENT_V2 ? 'イベントの編集' : 'イベントの新規作成'} />
          </HeaderWrapper>

          <InputCard>
            <FullInputWrapper>
              <InputWithLabelV2 required text='イベント名' value={nameToSave} onChange={setNameToSave} />
            </FullInputWrapper>

            <FullInputWrapper>
              <InputWithLabelV2
                multiline
                text='イベントの概要'
                placeholder='イベントの概要を入力'
                value={descriptionToSave}
                onChange={setDescriptionToSave}
              />
            </FullInputWrapper>

            <FullInputWrapper>
              <ImageUploadV2
                textOnly
                header='カバー写真'
                remarks='1500px以上の横幅の画像を推奨'
                height={92}
                file={photo}
                onChange={onChangeFile}
              />
            </FullInputWrapper>

            {isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USER_RANK) && (
              <FullInputWrapper>
                <LabelV2 required text='参加可能な会員種別' />
                <RadioBoxWrapper>
                  <RadioBoxV2
                    direction='column'
                    options={Object.values(ParticipatingMembershipTypeEnum)}
                    labels={EVENT_MEMBERSHIP_TYPE_LABELS}
                    onChange={setParticipatingMembershipTypeToSave}
                    rightComponents={SelectMembershipComponents}
                    value={participatingMembershipTypeToSave}
                  />
                </RadioBoxWrapper>
              </FullInputWrapper>
            )}
            {participatingMembershipTypeToSave === ParticipatingMembershipTypeEnum.LIMITED &&
              hasLength(membershipsToSave) && (
                <ListV2
                  subTitle='会員種別'
                  rows={membershipsToSave.map((membership, index) =>
                    builder<ListRow>()
                      .text(membership.authorityName)
                      .secondaryAction(
                        <IconButtonV2 icon={<ClearRoundedIcon />} onClick={() => deleteEventMembership(index)} />
                      )
                      .build()
                  )}
                />
              )}
          </InputCard>

          <ButtonWrapper>
            <ButtonV2 size='medium' type='secondary' label='キャンセル' onClick={handleButtonClicked} />
            <ButtonV2
              disabled={disabledSaveButton}
              size='medium'
              type='primary'
              label={path === Path.EDIT_EVENT_V2 ? '保存' : '作成'}
              onClick={saveNewEvent}
            />
          </ButtonWrapper>
        </Content>
        <MembershipModal
          isOpen={isOpenMembershipModal}
          baseMemberships={baseMemberships}
          membershipsToSave={membershipsToSave}
          onClick={setMembershipsToSave}
          onClose={() => {
            setIsOpenMembershipModal(false);
          }}
        />
      </Container>
    </Component>
  );
});

RegisterEventV2Screen.displayName = 'RegisterEventV2Screen';
export default RegisterEventV2Screen;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-bottom: ${themeV2.mixins.v2.spacing * 8}px;
`;

const Content = styled.div`
  width: 100%;
  max-width: 768px;
  height: auto;
`;

const HeaderWrapper = styled.div`
  margin-bottom: ${themeV2.mixins.v2.spacing * 2}px;
`;

const InputCard = styled.div`
  ${themeV2.mixins.v2.typography.label.medium};
  background: ${themeV2.mixins.v2.color.background.offWhite};
  border-radius: 8px;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing}px;
`;

const FullInputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 2}px;
`;

const RadioBoxWrapper = styled.div`
  margin-top: ${themeV2.mixins.v2.spacing}px;
  display: flex;
  align-items: flex-end;
`;

const ButtonWrapper = styled.div`
  background: ${themeV2.mixins.v2.color.background.white};
  box-shadow: ${themeV2.mixins.v2.shadow.elevation3};
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing / 2}px ${themeV2.mixins.v2.spacing}px
    ${themeV2.mixins.v2.spacing / 2}px;
  gap: 10px;
  z-index: 1000;
`;
