import {
  ColgroupV2,
  IconButtonV2,
  LabelV2,
  SelectBoxV2,
  TableV2,
  TbodyV2,
  TdV2,
  TextFieldV2,
  TheadV2,
  ThV2,
  TrV2,
  useSafeCallback
} from '@atomica.co/components';
import {
  ContractDetailId,
  ContractQuantityInputType,
  PriceUtils,
  RecurrencePattern,
  UnitId,
  UnitV2
} from '@atomica.co/irori';
import { Count, Index, Key, Label, Name, Option, Price, Quantity } from '@atomica.co/types';
import { EMPTY, hasLength } from '@atomica.co/utils';
import { Clear, Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { DetailForContractRegisterScreen } from '../../../../converters/contract-v2-converter';
import { DisplayTaxes, toTaxLabels, toTaxOptions } from '../../../../converters/tax-converter';
import { toUnitLabels, toUnitOptions } from '../../../../converters/unit-converter';
import { ContractDetailInputKeyEnum } from '../../../../enums/contract-v2-enum';
import { Labels } from '../../../../models/common-model';
import { isNumberOfUsers } from '../../../../utils/contract-util';
import { isBasicCharge } from '../../../../utils/item-util';
import { getTaxKey, reverseTaxKey } from '../../../../utils/tax-util';

interface P {
  contractDetails: DetailForContractRegisterScreen[];
  deleteDetailRow: (recurrencePattern: RecurrencePattern, contractDetailId: ContractDetailId) => void;
  displayTaxes: DisplayTaxes | undefined;
  handleDetailInput: (
    recurrencePattern: RecurrencePattern,
    contractDetailId: ContractDetailId,
    key: ContractDetailInputKeyEnum,
    val: string | number | boolean
  ) => void;
  label: Label;
  recurrencePattern: RecurrencePattern;
  units: UnitV2[];
  usersCount: Count;
  setUsersCount: (value: Count) => void;
}

export const ContractDetailsInput: React.FC<P> = React.memo(props => {
  const {
    contractDetails,
    deleteDetailRow,
    displayTaxes,
    handleDetailInput,
    label,
    recurrencePattern,
    setUsersCount,
    units,
    usersCount
  } = props;

  const taxOptions = useMemo<Option[]>(() => toTaxOptions(displayTaxes), [displayTaxes]);
  const taxLabels = useMemo<Labels>(() => toTaxLabels(displayTaxes), [displayTaxes]);
  const unitOptions = useMemo<Option[]>(() => toUnitOptions(units), [units]);
  const unitLabels = useMemo<Labels>(() => toUnitLabels(units), [units]);

  const handleItemNameChanged = useSafeCallback(
    (detail: DetailForContractRegisterScreen, itemName: Name): void => {
      handleDetailInput(recurrencePattern, detail.contractDetailId, ContractDetailInputKeyEnum.ITEM_NAME, itemName);
    },
    [handleDetailInput, recurrencePattern]
  );

  const handleUnitPriceChanged = useSafeCallback(
    (detail: DetailForContractRegisterScreen, unitPrice: Price): void => {
      handleDetailInput(recurrencePattern, detail.contractDetailId, ContractDetailInputKeyEnum.UNIT_PRICE, unitPrice);
    },
    [handleDetailInput, recurrencePattern]
  );

  const handleQuantityChanged = useSafeCallback(
    (detail: DetailForContractRegisterScreen, quantity: Quantity): void => {
      detail.quantityInputType === ContractQuantityInputType.NUMBER_OF_USERS && setUsersCount(quantity);
      handleDetailInput(recurrencePattern, detail.contractDetailId, ContractDetailInputKeyEnum.QUANTITY, quantity);
    },
    [setUsersCount, handleDetailInput, recurrencePattern]
  );

  const handleUnitChanged = useSafeCallback(
    (detail: DetailForContractRegisterScreen, unitId: UnitId): void => {
      handleDetailInput(recurrencePattern, detail.contractDetailId, ContractDetailInputKeyEnum.UNIT_ID, unitId);
    },
    [handleDetailInput, recurrencePattern]
  );

  const handleTaxChanged = useSafeCallback(
    (detail: DetailForContractRegisterScreen, key: Key): void => {
      const { taxId, taxDiv, taxRate } = reverseTaxKey(key);
      if (!taxId || !taxDiv || taxRate == null) return;
      handleDetailInput(recurrencePattern, detail.contractDetailId, ContractDetailInputKeyEnum.TAX_ID, taxId);
      handleDetailInput(recurrencePattern, detail.contractDetailId, ContractDetailInputKeyEnum.TAX_DIV, taxDiv);
      handleDetailInput(recurrencePattern, detail.contractDetailId, ContractDetailInputKeyEnum.TAX_RATE, taxRate);
    },
    [handleDetailInput, recurrencePattern]
  );

  const handleDisabledChanged = useSafeCallback(
    (detail: DetailForContractRegisterScreen, disabled: boolean): void => {
      handleDetailInput(recurrencePattern, detail.contractDetailId, ContractDetailInputKeyEnum.DISABLED, !disabled);
    },
    [handleDetailInput, recurrencePattern]
  );

  return (
    <Container>
      <LabelV2 text={label} />
      {hasLength(contractDetails) && (
        <TableV2 shape='circle' showBorder={true}>
          <ColgroupV2 />
          <ColgroupV2 width={112} />
          <ColgroupV2 width={100} />
          <ColgroupV2 width={100} />
          <ColgroupV2 width={136} />
          <ColgroupV2 width={80} />
          <ColgroupV2 width={56} />
          <TheadV2>
            <TrV2>
              <ThV2 horizonPadding={8}>品目</ThV2>
              <ThV2 horizonPadding={8}>単価（円）</ThV2>
              <ThV2 horizonPadding={8}>数量・単位</ThV2>
              <ThV2 horizonPadding={8}>{EMPTY}</ThV2>
              <ThV2 horizonPadding={8}>課税</ThV2>
              <ThV2 horizonPadding={8}>金額（円）</ThV2>
              <ThV2 horizonPadding={8}>{EMPTY}</ThV2>
            </TrV2>
          </TheadV2>
          <TbodyV2>
            {contractDetails.map((detail: DetailForContractRegisterScreen, rowIdx: Index) => (
              <TrV2 key={`monthly-tr${rowIdx}`} showOverlay={detail.disabled}>
                <TdV2 horizonPadding={8}>
                  <TextFieldV2
                    align='left'
                    onChange={value => handleItemNameChanged(detail, value)}
                    readonly={detail.disabled}
                    value={detail.itemName}
                  />
                </TdV2>
                <TdV2 horizonPadding={8}>
                  <TextFieldV2
                    align='right'
                    inputType='price'
                    disabled={detail.disabled}
                    onChange={value => handleUnitPriceChanged(detail, value)}
                    value={detail.unitPrice}
                  />
                </TdV2>
                <TdV2 horizonPadding={8}>
                  <TextFieldV2
                    align='right'
                    inputType='quantity'
                    value={isNumberOfUsers(detail) ? usersCount : detail.quantity}
                    onChange={value => handleQuantityChanged(detail, value)}
                    disabled={detail.disabled}
                    readonly={detail.quantityInputType === ContractQuantityInputType.FIXED}
                  />
                </TdV2>
                <TdV2 horizonPadding={8}>
                  <SelectBoxV2
                    labels={unitLabels}
                    options={unitOptions}
                    value={detail.unitId}
                    onChange={value => handleUnitChanged(detail, value)}
                    disabled={detail.disabled}
                    readonly={!detail.isDeletable || isBasicCharge(detail.itemCode)}
                  ></SelectBoxV2>
                </TdV2>
                <TdV2 horizonPadding={8}>
                  <SelectBoxV2
                    labels={taxLabels}
                    options={taxOptions}
                    value={getTaxKey(detail.taxId, detail.taxDiv, detail.taxRate)}
                    onChange={value => handleTaxChanged(detail, value)}
                    disabled={detail.disabled}
                    readonly={!detail.isDeletable}
                  ></SelectBoxV2>
                </TdV2>
                <TdV2 horizonPadding={8} align='right'>
                  {detail.unitPrice && detail.quantity
                    ? PriceUtils.applyAmountPrice(detail.unitPrice, detail.quantity).toLocaleString()
                    : '-'}
                </TdV2>
                <TdV2 horizonPadding={0} align='center'>
                  {!isBasicCharge(detail.itemCode) && (
                    <>
                      {!detail.isDeletable && (
                        <IconButtonV2
                          size='medium'
                          icon={detail.disabled ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
                          onClick={() => handleDisabledChanged(detail, detail.disabled)}
                        />
                      )}

                      {detail.isDeletable && (
                        <IconButtonV2
                          size='medium'
                          icon={<Clear />}
                          onClick={() => deleteDetailRow(recurrencePattern, detail.contractDetailId)}
                        />
                      )}
                    </>
                  )}
                </TdV2>
              </TrV2>
            ))}
          </TbodyV2>
        </TableV2>
      )}
    </Container>
  );
});

ContractDetailsInput.displayName = 'ContractDetailsInput';
const Container = styled.div``;
