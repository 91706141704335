import { themeV2, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  BaseDto,
  LineMessage,
  SAVE_LINE_MESSAGE_FOR_ADMIN,
  SEARCH_LINE_MESSAGES_FOR_ADMIN,
  SaveLineMessageForAdminRequest,
  SaveLineMessageForAdminResponse,
  SearchLineMessagesForAdminRequest,
  SearchLineMessagesForAdminResponse,
  User
} from '@atomica.co/irori';
import { Index, Message, Offset, ProviderId, USER_ID, UserId } from '@atomica.co/types';
import { EMPTY, ZERO, builder, hasLength, toTimeDiffLabel } from '@atomica.co/utils';
import Send from '@material-ui/icons/Send';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { toLineMessage } from '../../../../converters/line-message-converter';
import useCommonRequest from '../../../../redux/hooks/useCommonRequest';
import usePath from '../../../../redux/hooks/usePath';
import { toFullName } from '../../../../utils/user-util';

const LIMIT = 10;

const OPTIONS: IntersectionObserverInit = {
  root: null,
  rootMargin: '300px 0px 0px 0px'
};

interface P {
  base: BaseDto;
  selectedUser: User;
  signInUser: User;
}

const MessageSection: React.FC<P> = React.memo(props => {
  const { base, selectedUser, signInUser } = props;
  const { params } = usePath();
  const { commonRequest } = useCommonRequest();

  const loaded = useRef<boolean>(false);
  const hasMore = useRef<boolean>(true);
  const offset = useRef<Offset>(ZERO);
  const topRef = useRef<HTMLDivElement>(null);

  const unmountRef = useUnmountRef();
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [lineMessage, setLineMessage] = useSafeState<Message>(unmountRef, EMPTY);
  const [lineMessages, setLineMessages] = useSafeState<LineMessage[]>(unmountRef, []);

  const selectedUserId = useMemo<UserId>(() => params[USER_ID], [params]);

  const isLineUser = useMemo<boolean>(
    () =>
      hasLength(selectedUser.mappings) &&
      !!selectedUser.mappings?.find(mapping => mapping.providerId === ProviderId.LINE),
    [selectedUser]
  );

  const searchLineMessages = useSafeCallback(async (): Promise<void> => {
    if (!hasMore.current) return;

    const request = builder<SearchLineMessagesForAdminRequest>()
      .baseId(base.baseId)
      .limit(LIMIT)
      .offset(offset.current)
      .userId(selectedUserId)
      .build();
    const response = await commonRequest<SearchLineMessagesForAdminRequest, SearchLineMessagesForAdminResponse>(
      SEARCH_LINE_MESSAGES_FOR_ADMIN,
      request
    );
    const { lineMessages, totalCount } = response;

    offset.current += LIMIT;
    hasMore.current = totalCount <= LIMIT;

    setLineMessages(prevMessage => [...prevMessage, ...lineMessages.reverse()]);
  }, [base, commonRequest, selectedUserId, setLineMessages]);

  const onScroll = useSafeCallback(
    async (entries: IntersectionObserverEntry[]): Promise<void> => {
      for (const entry of entries) {
        if (!entry.isIntersecting) return;
        await searchLineMessages();
      }
    },
    [searchLineMessages]
  );

  useEffect(() => {
    if (loaded.current) return;
    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => onScroll(entries), OPTIONS);
    topRef.current && observer.observe(topRef.current);
    loaded.current = true;
    return () => observer.disconnect();
  }, [loaded, onScroll]);

  const handleLineMessageChanged = useSafeCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setLineMessage(e.target.value);
      return () => setLineMessage(EMPTY);
    },
    [setLineMessage]
  );

  const saveAndInitLineMessage = useSafeCallback(async (): Promise<void> => {
    setSaving(true);

    const messageToSave = toLineMessage(lineMessage, signInUser, selectedUser);
    const request = builder<SaveLineMessageForAdminRequest>().lineMessage(messageToSave).baseId(base.baseId).build();
    const response = await commonRequest<SaveLineMessageForAdminRequest, SaveLineMessageForAdminResponse>(
      SAVE_LINE_MESSAGE_FOR_ADMIN,
      request
    );

    if (response.lineMessageId) {
      setLineMessage(EMPTY);
      setLineMessages(messages => [...messages, messageToSave]);
    }

    setSaving(false);
  }, [base, lineMessage, setSaving, signInUser, selectedUser, setLineMessage, setLineMessages]);

  useEffect(() => {
    const container = document.getElementById('messages-container')!;
    const bottom = document.getElementById('messages-bottom')!;
    if (!bottom) return;
    const position = bottom.getBoundingClientRect();
    container.scrollTo(0, position.bottom + 95);
  }, [lineMessages]);

  return (
    <MessageOuterContainer id='messages-container'>
      {!isLineUser && <Overlay>メッセージはLINEユーザーのみ送ることができます</Overlay>}
      <OuterHolder>
        <Heading>メッセージ</Heading>

        <Top ref={topRef} />

        {lineMessages.map((row: LineMessage, index: Index) => (
          <MessageContainer key={index}>
            <StyledMessage>{row.message}</StyledMessage>
            <SenderHolder>
              <RowLowerText>{`${toFullName(row.fromUser!)}が送信`}</RowLowerText>
              <RowLowerText>{toTimeDiffLabel(row.createdAt)}</RowLowerText>
            </SenderHolder>
          </MessageContainer>
        ))}

        <div id='messages-bottom' />

        {isLineUser && !hasLength(lineMessages) && (
          <NoMessageArea>
            <NoMessage>まだメッセージを送っていません</NoMessage>
          </NoMessageArea>
        )}
      </OuterHolder>
      <SendMessageContainer>
        <TextArea
          placeholder={isLineUser ? 'メッセージを入力してください\n※メッセージは送信のみ可能で受信はできません' : EMPTY}
          value={lineMessage}
          onChange={handleLineMessageChanged}
        />
        <SendButtonHolder>
          <SendButton disabled={!lineMessage || saving} onClick={saveAndInitLineMessage}>
            <Send color='inherit' />
          </SendButton>
        </SendButtonHolder>
      </SendMessageContainer>
    </MessageOuterContainer>
  );
});

MessageSection.displayName = 'MessageSection';
export default MessageSection;

const Heading = styled.p`
  ${themeV2.mixins.v2.typography.title.xLarge};
  margin: 0px;
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
  padding: ${themeV2.mixins.v2.spacing * 2}px 0;
  top: 0px;
  position: ${(props: { sticky?: string }) => (props.sticky ? 'sticky' : 'unset')};
`;

const RowLowerText = styled.p`
  margin: 0px;
  color: ${themeV2.mixins.v2.color.font.gray};
  ${themeV2.mixins.v2.typography.body.small};
`;

const NoMessageArea = styled.div`
  width: 100%;
  height: 100%;
  min-height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${themeV2.mixins.v2.spacing * 3}px;
`;

const NoMessage = styled.div`
  color: ${themeV2.mixins.v2.color.font.gray};
  ${themeV2.mixins.v2.typography.body.medium};
`;

const MessageOuterContainer = styled.div`
  background-color: ${themeV2.mixins.v2.color.font.white};
  border-radius: 8px;
  max-height: 680px;
  overflow-y: scroll;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  flex: 1;
  ::-webkit-scrollbar {
    display: none;
  }
  position: relative;
`;

const MessageContainer = styled.div`
  border-radius: 8px;
  background-color: ${themeV2.mixins.v2.color.background.offWhite};
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  margin: ${themeV2.mixins.v2.spacing}px 0px;
`;

const StyledMessage = styled.p`
  color: ${themeV2.mixins.v2.color.font.black};
  ${themeV2.mixins.v2.typography.body.medium};
  padding-top: ${themeV2.mixins.v2.spacing}px;
  margin: 0px;
  white-space: break-spaces;
`;

const SenderHolder = styled.div`
  margin-top: ${themeV2.mixins.v2.spacing}px;
`;

const OuterHolder = styled.div`
  padding-left: ${themeV2.mixins.v2.spacing * 3}px;
  padding-right: ${themeV2.mixins.v2.spacing * 3}px;
`;

const SendMessageContainer = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  border-top: 1px solid ${themeV2.mixins.v2.color.border.gray};
  margin-top: ${themeV2.mixins.v2.spacing * 2}px;
  background: ${themeV2.mixins.v2.color.background.white};
  position: sticky;
  bottom: 0px;
  z-index: 99;
`;

const TextArea = styled.textarea.attrs({
  placeholderTextColor: themeV2.mixins.v2.color.font.lightGray
})`
  ${themeV2.mixins.v2.typography.body.medium};
  resize: none;
  outline: none;
  border: none;
  width: 100%;
  height: 76px;
  color: ${themeV2.mixins.v2.color.font.black};
  background-color: ${themeV2.mixins.v2.color.background.offWhite};
  padding: ${themeV2.mixins.v2.spacing * 2}px;
`;

const SendButtonHolder = styled.div`
  margin-top: ${themeV2.mixins.v2.spacing * 2}px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SendButton = styled.button`
  outline: none;
  border: none;
  background-color: ${(props: { disabled?: boolean }) =>
    props.disabled ? themeV2.mixins.v2.color.border.gray : themeV2.mixins.v2.color.border.pink};
  border-radius: 8px;
  padding: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 2}px;
  color: ${themeV2.mixins.v2.color.font.white};
`;

const Top = styled.div``;

const Overlay = styled.div`
  ${themeV2.mixins.v2.typography.label.medium};
  color: ${themeV2.mixins.v2.color.font.black};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(66, 66, 66, 0.5);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
`;
