import {
  ButtonV2,
  CardDeckRef,
  Component,
  Tips,
  customMedia,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  AccessControl,
  BaseDto,
  BaseFunctionToggleCode,
  CREATE_QRCODES_FOR_EACH_SPACE_RESERVATION,
  CREATE_QRCODE_FOR_ALL_SPACES,
  CreateQrCodeForAllSpacesRequest,
  CreateQrCodeForAllSpacesResponse,
  CreateQrCodesForEachSpaceReservationRequest,
  CreateQrCodesForEachSpaceReservationResponse,
  EligibleUser,
  FETCH_ACCESS_CONTROLS,
  FETCH_CONTRACT_V2_BY_USER,
  FETCH_SPACE_RESERVATIONS_BY_USER,
  FetchAccessControlsRequest,
  FetchAccessControlsResponse,
  FetchContractV2ByUserRequest,
  FetchContractV2ByUserResponse,
  FetchSpaceReservationsByUserRequest,
  FetchSpaceReservationsByUserResponse,
  QrCodeData,
  SpaceReservation,
  User,
  isBaseFunctionToggleEnabled
} from '@atomica.co/irori';
import { Width } from '@atomica.co/types';
import { builder, embedIdInPath, hasLength, toEndOfDay } from '@atomica.co/utils';
import React, { CSSProperties, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH } from '../../../constants/common-const';
import { constructCards } from '../../../converters/account-converter';
import { isInLineClient } from '../../../line';
import { SpaceReservationCard } from '../../../models/account-model';
import usePath from '../../../redux/hooks/usePath';
import CommonRequest from '../../../requests/common-request';
import { PATH_IDS, Path } from '../../../router/Routes';
import ReservationCards from './reservation-card/ReservationCards';

const TIPS_MESSAGE =
  '本日の予定はありません。\n設備の予約をするか\n設備の予約の招待を受け取ると\n本日の予定が表示されます。';

interface P extends RouteComponentProps {
  base: BaseDto;
  user: User;
}

const AccountHome: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { openPath } = usePath();
  const ref = useRef<CardDeckRef>(null);
  const unmountRef = useUnmountRef();
  /** @deprecated */
  const [loadedCards, setLoadedCards] = useSafeState<boolean>(unmountRef, false);
  /** @deprecated */
  const [cards, setCards] = useSafeState<SpaceReservationCard[]>(unmountRef, []);
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [accessControls, setAccessControls] = useSafeState<AccessControl[]>(unmountRef, []);

  /** @deprecated */
  const fetchSpaceReservations = useSafeCallback(async (): Promise<SpaceReservation[]> => {
    if (isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_ACCESS_CONTROL)) return [];
    if (!user || !user.userId) return [];
    const fromDate = new Date();
    const toDate = toEndOfDay(new Date())!;
    const request = builder<FetchSpaceReservationsByUserRequest>()
      .baseId(base.baseId)
      .userIds([user.userId])
      .fromDate(fromDate)
      .toDate(toDate)
      .build();
    const { spaceReservations } = await CommonRequest.call<
      FetchSpaceReservationsByUserRequest,
      FetchSpaceReservationsByUserResponse
    >(FETCH_SPACE_RESERVATIONS_BY_USER, request);
    return spaceReservations;
  }, [base.baseId, user]);

  /** @deprecated */
  const isEligibleUser = useSafeCallback(async (): Promise<boolean> => {
    if (!base || !user) return false;
    const { eligibleUser } = base;

    switch (eligibleUser) {
      case EligibleUser.ALL:
        return true;
      case EligibleUser.CONTRACTED: {
        const request = builder<FetchContractV2ByUserRequest>().baseId(base.baseId).userId(user.userId).build();
        const response = await CommonRequest.call<FetchContractV2ByUserRequest, FetchContractV2ByUserResponse>(
          FETCH_CONTRACT_V2_BY_USER,
          request
        );
        return !!response.contract;
      }
      default:
        return false;
    }
  }, [base, user]);

  /** @deprecated */
  const fetchQrCodeForAllSpaces = useSafeCallback(async (): Promise<CreateQrCodeForAllSpacesResponse | undefined> => {
    const isEligible = await isEligibleUser();
    if (!isEligible) return;

    const request = builder<CreateQrCodeForAllSpacesRequest>().base(base).user(user).build();
    const response = await CommonRequest.call<CreateQrCodeForAllSpacesRequest, CreateQrCodeForAllSpacesResponse>(
      CREATE_QRCODE_FOR_ALL_SPACES,
      request
    );
    return response;
  }, [isEligibleUser, base, user]);

  // /** @deprecated */
  // const fetchQrCodeForContractedSpaces = useSafeCallback(async (): Promise<Code | Buffer | undefined> => {
  //   if (!useFaceAccess) return;
  //   const request = builder<CreateQrCodeForContractedSpacesRequest>().base(base).user(user).build();
  //   const response = await QrCodeRequest.createQrCodeForContractedSpaces(request);
  //   return response.qrcode;
  // }, [useFaceAccess, base, user]);

  /** @deprecated */
  const fetchQrCodesForEachSpaceReservation = useSafeCallback(
    async (reservations: SpaceReservation[]): Promise<QrCodeData[]> => {
      if (isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_ACCESS_CONTROL)) return [];
      if (!hasLength(reservations)) return [];
      const request = builder<CreateQrCodesForEachSpaceReservationRequest>()
        .user(user)
        .spaceReservations(reservations)
        .build();

      const { qrcodes } = await CommonRequest.call<
        CreateQrCodesForEachSpaceReservationRequest,
        CreateQrCodesForEachSpaceReservationResponse
      >(CREATE_QRCODES_FOR_EACH_SPACE_RESERVATION, request);
      return qrcodes;
    },
    [user]
  );

  /** @deprecated */
  const createCards = useSafeCallback(
    async (reservations: SpaceReservation[]): Promise<SpaceReservationCard[]> => {
      if (!base.spaceManagement) return [];

      const [qrCodeForAllSpaces, qrCodesForEachSpaceReservation] = await Promise.all([
        fetchQrCodeForAllSpaces(),
        fetchQrCodesForEachSpaceReservation(reservations)
      ]);

      return constructCards(base, user, qrCodeForAllSpaces, qrCodesForEachSpaceReservation, reservations);
    },
    [base, user, fetchQrCodeForAllSpaces, fetchQrCodesForEachSpaceReservation]
  );

  /** @deprecated */
  const initialize = useSafeCallback(async (): Promise<void> => {
    const reservations = await fetchSpaceReservations();
    const cards = await createCards(reservations);
    setCards(cards);
    setLoadedCards(true);
  }, [fetchSpaceReservations, createCards, setCards, setLoadedCards]);

  /** @deprecated */
  useEffect(() => {
    initialize();
  }, [initialize]);

  const fetchAccessControls = useSafeCallback(async (): Promise<AccessControl[]> => {
    if (!isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_ACCESS_CONTROL)) return [];
    if (!base || !user) return [];
    const request = builder<FetchAccessControlsRequest>().baseId(base.baseId).userId(user.userId).build();
    const response = await CommonRequest.call<FetchAccessControlsRequest, FetchAccessControlsResponse>(
      FETCH_ACCESS_CONTROLS,
      request
    );
    return response.controls;
  }, [base, user]);

  const initializeV2 = useSafeCallback(async (): Promise<void> => {
    const accessControls = await fetchAccessControls();
    setAccessControls(accessControls);
    setLoaded(true);
  }, [fetchAccessControls, setAccessControls, setLoaded]);

  useEffect(() => {
    initializeV2();
  }, [initializeV2]);

  const openAccountMySchedulesScreen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.ACCOUNT_MY_SCHEDULES, PATH_IDS, [base.baseCode]));
  }, [base, openPath]);

  return (
    <Component loading={!loaded || !loadedCards} style={styleForComponent} className='account-home' title='ホーム'>
      <Container data-testid='account-home'>
        <Content>
          {!hasLength(cards) && !hasLength(accessControls) && (
            <>
              <NoReservationWrapper>
                <Tips message={TIPS_MESSAGE} />
              </NoReservationWrapper>
              <CenteredWrapper>
                <StyledButton
                  width={80}
                  size='large'
                  type='secondary'
                  label='すべての予定を確認'
                  onClick={openAccountMySchedulesScreen}
                />
              </CenteredWrapper>
            </>
          )}
          {(hasLength(cards) || hasLength(accessControls)) && (
            <>
              <LabelWrapper>
                <Label>本日の予定</Label>
                <StyledButton
                  width={40}
                  size='small'
                  type='tertiary'
                  label='すべての予定を確認'
                  onClick={openAccountMySchedulesScreen}
                />
              </LabelWrapper>
              <ReservationsWrapper>
                <ReservationCards ref={ref} base={base} user={user} cards={cards} accessControls={accessControls} />
              </ReservationsWrapper>
            </>
          )}
        </Content>
      </Container>
    </Component>
  );
});

AccountHome.displayName = 'AccountHome';
export default AccountHome;

const styleForComponent: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden'
};

const Container = styled.div`
  width: 100%;
  height: ${isInLineClient() ? '100%' : 'calc(100% - 100px)'};
  max-width: 1124px;
  padding: ${themeV2.mixins.v2.spacing * 2}px 0 ${themeV2.mixins.v2.spacing}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  ${customMedia.lessThan('tiny')`
    padding: ${themeV2.mixins.v2.spacing}px 0 ;
  `};
`;

const Content = styled.div`
  width: 100%;
  max-width: ${MOBILE_MAX_WIDTH - themeV2.mixins.v2.spacing * 4}px;
  min-width: ${MOBILE_MIN_WIDTH - themeV2.mixins.v2.spacing * 4}px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  ${customMedia.lessThan('tiny')`
    gap: ${themeV2.mixins.v2.spacing}px;
  `};
`;

const LabelWrapper = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
`;

const Label = styled.div`
  ${themeV2.mixins.v2.typography.title.medium}
`;

const ReservationsWrapper = styled.div`
  width: 100%;
  flex: 1;
  margin: 0 auto;
  top: 120px;
  left: 0;
`;

const NoReservationWrapper = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenteredWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const StyledButton = styled(ButtonV2)<{ width: Width }>`
  && {
    width: ${({ width }) => `${width}%`};
    white-space: nowrap;
  }
`;
