import {
  CardV2,
  InformationPanel,
  InputWithLabelV2,
  LabelV2,
  MasterSearchAction,
  MasterSearchOption,
  MasterSearchV2,
  RadioBoxV2,
  SelectBoxV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  User,
  UserType,
  Wish,
  WishScope,
  WishStatus,
  WishTag,
  WishType,
  WithBaseId,
  toFullName
} from '@atomica.co/irori';
import { Name, Remarks, Text } from '@atomica.co/types';
import { EMPTY, builder, embedIdInPath, hasLength } from '@atomica.co/utils';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import WishTags from '../../../components/tags/WishTags';
import { MAX_WISH_CONTENT_LENGTH, MAX_WISH_TITLE_LENGTH } from '../../../constants/wish-const';
import {
  toMasterSearchOptionFromUser,
  toMasterSearchOptionFromUserWithAuthAndCompany
} from '../../../converters/user-converter';
import { PATH_IDS, Path } from '../../../router/Routes';
import { WISH_SCOPE_TEXT, WISH_STATUS_TEXT, WISH_TYPE_TEXT } from '../../../texts/wish-text';
import useSearchedUsers from '../../../redux/hooks/useSearchedUsers';
import { SearchUsersForWishForAdminParams } from '../../../__generated/model';

const LIMIT = 10;

interface P {
  base: BaseDto;
  initWish?: Wish;
  fromUser: User | null;
  toUser: User | null;
  content: Text;
  remarks: Remarks;
  wishTags: WishTag[];
  status: WishStatus;
  scope: WishScope;
  type: WishType;
  setFromUser?: (user: User | null) => void;
  setToUser: (user: User | null) => void;
  setContent: (content: Text) => void;
  setRemarks: (remark: Remarks) => void;
  setWishTags: React.Dispatch<React.SetStateAction<WishTag[]>>;
  setStatus: (status: WishStatus) => void;
  setScope: (scope: WishScope) => void;
  setType: (type: WishType) => void;
  addNewUserAction?: (name: Name) => void;
}

interface ClickableCardV2Props extends React.ComponentProps<typeof CardV2> {
  onClick?: () => void;
}

const ClickableCardV2: React.FC<ClickableCardV2Props> = ({ onClick, ...props }) => (
  <StyledClickableCard onClick={onClick}>
    <CardV2 {...props} />
  </StyledClickableCard>
);

const userCard = (base: BaseDto, user: User, handleClearUser: () => void, isClearable?: boolean): JSX.Element => (
  <ClickableCardV2
    styles={{ background: themeV2.mixins.v2.color.background.white }}
    src={user.photoURL}
    text={`${toFullName(user)}`}
    subText={`${user.companyName}
  ${user.companyName && user.email && '・'}
  ${user.email}`}
    component={
      isClearable && (
        <CloseIcon
          onClick={e => {
            e.stopPropagation();
            handleClearUser();
          }}
          style={{ cursor: 'pointer' }}
        />
      )
    }
    onClick={async () => {
      await new Promise(resolve => setTimeout(resolve, 0));
      window.open(embedIdInPath(Path.USER_OVERVIEW, PATH_IDS, [base.baseCode, user.userId]), '_blank');
    }}
  />
);

const InputWish: React.FC<P> = React.memo(props => {
  const {
    base,
    initWish,
    fromUser,
    toUser,
    content,
    remarks,
    wishTags,
    status,
    scope,
    type,
    setFromUser,
    setToUser,
    setContent,
    setRemarks,
    setWishTags,
    setStatus,
    setScope,
    setType,
    addNewUserAction
  } = props;

  const { searchedUsers: users, search: searchUsers } = useSearchedUsers();

  const { searchedUsers: toUserList, search: searchToUsers } = useSearchedUsers();

  const unmountRef = useUnmountRef();
  const [createUserActions, setCreateUserActions] = useSafeState<MasterSearchAction[]>(unmountRef, []);
  const [partialName, setPartialName] = useSafeState<Name>(unmountRef, EMPTY);

  useEffect(() => {
    if (!initWish) return;
    setContent(initWish.content);
    setType(initWish.type);
    setRemarks(initWish.remarks);
    setScope(initWish.scope);
    if (setFromUser) setFromUser(initWish.fromUser!);
    setToUser(initWish.toUser!);
    const wishTags = hasLength(initWish.userWishTags) ? initWish.userWishTags!.map(tag => tag.wishTag!) : [];
    setWishTags(wishTags);
  }, [initWish, setContent, setType, setRemarks, setScope, setWishTags, setFromUser, setToUser]);

  const handleClearUser = useSafeCallback((): void => {
    if (!setFromUser) return;
    setFromUser(null);
  }, [setFromUser]);

  const handleClearToUser = useSafeCallback((): void => {
    setToUser(null);
  }, [setToUser]);

  const handleActionClick = useSafeCallback((): void => {
    if (!addNewUserAction) return;
    addNewUserAction(partialName);
  }, [addNewUserAction, partialName]);

  const handleSearchUser = useSafeCallback(
    async (name: Name): Promise<void> => {
      const trimedName = name.trim();
      setPartialName(trimedName);
      if (!trimedName || !addNewUserAction) {
        setCreateUserActions([]);
      } else {
        setCreateUserActions([
          {
            label: `+ 「${trimedName}」を新規作成`,
            labelType: 'notify',
            onClick: handleActionClick
          }
        ]);
      }
      const request = builder<WithBaseId<SearchUsersForWishForAdminParams>>()
        .baseId(base.baseId)
        .limit(LIMIT)
        .offset(0)
        .userWord(name)
        .build();

      await searchUsers(request);
    },
    [setPartialName, addNewUserAction, base.baseId, searchUsers, setCreateUserActions, handleActionClick]
  );

  const handleSearchToUser = useSafeCallback(
    async (name: Name): Promise<void> => {
      const request = builder<WithBaseId<SearchUsersForWishForAdminParams>>()
        .baseId(base.baseId)
        .limit(LIMIT)
        .offset(0)
        .userWord(name)
        .userTypes([UserType.ADMIN])
        .build();

      await searchToUsers(request);
    },
    [base, searchToUsers]
  );

  const handleSelectUser = useSafeCallback(
    (option: MasterSearchOption): void => {
      if (!setFromUser) return;
      const findUser = users.find(user => user.userId === option.value);
      if (findUser) {
        setFromUser(findUser);
      }
    },
    [setFromUser, users]
  );

  const handleSelectToUser = useSafeCallback(
    (option: MasterSearchOption): void => {
      const findUser = toUserList.find(user => user.userId === option.value);
      if (findUser) setToUser(findUser);
    },
    [setToUser, toUserList]
  );

  return (
    <ParentContainer>
      <PanelWrapper>
        <InformationPanel isBold status='default' title='入力の際のご注意'>
          入力された内容はSlackに投稿されるため、Wishの[タイトル]、[内容]には個人情報（氏名、住所、電話番号など）を入力しないようお願いいたします。
        </InformationPanel>
      </PanelWrapper>

      <Container>
        <Content>
          <div>
            <LabelV2 required text='ユーザー' />

            {fromUser && userCard(base, fromUser!, handleClearUser, !!setFromUser)}

            {!fromUser && (
              <MasterSearchV2
                placeholder='ユーザーの氏名・メールアドレス・電話番号で検索'
                selectedWord={initWish?.fromUserName}
                options={toMasterSearchOptionFromUserWithAuthAndCompany(users)}
                actions={createUserActions}
                handleOptionCleared={handleClearUser}
                handleOptionSelected={handleSelectUser}
                onChange={handleSearchUser}
              />
            )}
          </div>

          <InputWithLabelV2
            required
            text='タイトル'
            placeholder='Wishを入力'
            maxLength={MAX_WISH_TITLE_LENGTH}
            value={content}
            onChange={setContent}
          />

          <InputWithLabelV2
            required
            multiline
            text='内容'
            maxLength={MAX_WISH_CONTENT_LENGTH}
            value={remarks}
            onChange={setRemarks}
          />

          <div>
            <LabelV2 text='タイプ' />
            <RadioBoxV2 labels={WISH_TYPE_TEXT} options={Object.values(WishType)} value={type} onChange={setType} />
          </div>

          <div>
            <LabelV2 text='タグ' />
            <WishTags base={base} editable addNewTags wishTags={wishTags} setWishTags={setWishTags} />
          </div>
        </Content>
        <Content small>
          <div>
            <LabelV2 text='ステータス' />
            <SelectBoxV2
              options={Object.values(WishStatus)}
              labels={WISH_STATUS_TEXT}
              onChange={setStatus}
              value={status}
            />
          </div>

          <div>
            <LabelV2 text='公開範囲' />
            <SelectBoxV2
              options={Object.keys(WISH_SCOPE_TEXT)}
              labels={WISH_SCOPE_TEXT}
              onChange={setScope}
              value={scope}
            />
          </div>
          <div>
            <LabelV2 required text='担当者' />
            <div>
              {toUser && userCard(base, toUser, handleClearToUser, true)}

              {!toUser && (
                // FIXME
                <MasterSearchV2
                  placeholder=''
                  options={toMasterSearchOptionFromUser(toUserList)}
                  handleOptionCleared={handleClearToUser}
                  handleOptionSelected={handleSelectToUser}
                  onChange={handleSearchToUser}
                />
              )}
            </div>
          </div>
        </Content>
      </Container>
    </ParentContainer>
  );
});

InputWish.displayName = 'InputWish';
export default InputWish;

const PanelWrapper = styled.div`
  border: 0.5px solid ${themeV2.mixins.v2.color.border.gray};
  border-radius: 8px;
`;

const Container = styled.div`
  display: flex;
  gap: 24px;
`;

const ParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 2;
`;

const Content = styled.div<{ small?: boolean }>`
  padding: ${themeV2.mixins.v2.spacing * 3}px;
  background-color: ${themeV2.mixins.v2.color.background.offWhite};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: ${({ small }) => (small ? 1 : 2)};
  height: 100%;
`;

const StyledClickableCard = styled.div`
  cursor: pointer;
  transition:
    transform 0.1s ease-in-out,
    box-shadow 0.1s ease-in-out,
    background-color 0.1s ease-in-out;

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  }
`;
