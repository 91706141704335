import { BillingDetailV2, BillingLogV2, BillingV2, TaxDiv, TaxV2, UnitV2, User } from '@atomica.co/irori';
import { Key, Name, Operation, Text } from '@atomica.co/types';
import { builder, EMPTY, hasLength, isNumber, ONE, uuid, ZERO } from '@atomica.co/utils';
import { format, isDate, isEqual } from 'date-fns';
import {
  BILLING_CREATED,
  BILLING_DELETED,
  BILLING_DETAIL_ADDED,
  BILLING_DETAIL_DELETED,
  BILLING_MODIFIED_DEFAULT,
  BILLING_MODIFIED_DETAIL
} from '../texts/billing-v2-text';
import { PAYMENT_METHOD_LABELS } from '../texts/contract-v2-text';

enum LogTypeEnum {
  DEFAULT = 'default',
  DETAIL = 'detail'
}

type ConfigCompareColumn = {
  columnName: Name | Name[];
  columnLabel: Name;
  logType: LogTypeEnum;
  displayItemName?: Name;
  getLabel?: (key: Key) => Text;
};

const configCompareBilling: ConfigCompareColumn[] = [
  { columnName: 'billingDate', columnLabel: '請求日', logType: LogTypeEnum.DETAIL },
  { columnName: 'billingName', columnLabel: '件名', logType: LogTypeEnum.DETAIL },
  { columnName: 'billingStatus', columnLabel: 'ステータス', logType: LogTypeEnum.DETAIL },
  {
    columnName: 'contractorInfo',
    columnLabel: '契約先種別',
    logType: LogTypeEnum.DEFAULT
  },
  { columnName: 'contractorName', columnLabel: '契約者名', logType: LogTypeEnum.DETAIL },
  { columnName: 'paymentDueDate', columnLabel: '支払い期日', logType: LogTypeEnum.DETAIL },
  { columnName: 'paymentCount', columnLabel: '支払回数', logType: LogTypeEnum.DETAIL },
  {
    columnName: 'paymentMethod',
    columnLabel: '支払方法',
    logType: LogTypeEnum.DETAIL,
    getLabel: key => PAYMENT_METHOD_LABELS[key]
  },
  { columnName: 'remarks', columnLabel: '備考', logType: LogTypeEnum.DEFAULT }
];

const configCompareBillingDetail = (units: UnitV2[], taxes: TaxV2[]): ConfigCompareColumn[] => [
  {
    columnName: 'isDisabled',
    columnLabel: '有効／無効',
    logType: LogTypeEnum.DETAIL,
    getLabel: key => (key ? '無効' : '有効'),
    displayItemName: 'itemName'
  },
  { columnName: 'itemName', columnLabel: '項目名', logType: LogTypeEnum.DETAIL, displayItemName: 'itemName' },
  {
    columnName: 'taxDiv',
    columnLabel: '税種別',
    logType: LogTypeEnum.DETAIL,
    getLabel: key => TaxDiv[key.toUpperCase()],
    displayItemName: 'itemName'
  },
  {
    columnName: ['tax', 'taxId'],
    columnLabel: '税率',
    logType: LogTypeEnum.DETAIL,
    displayItemName: 'itemName',
    getLabel: key => taxes.filter(tax => tax.taxId === key)[ZERO].taxName
  },
  {
    columnName: ['unit', 'unitId'],
    columnLabel: '単位',
    logType: LogTypeEnum.DETAIL,
    displayItemName: 'itemName',
    getLabel: key => units.filter(unit => unit.unitId === key)[ZERO].unitName
  },
  { columnName: 'unitQuantity', columnLabel: '数量', logType: LogTypeEnum.DETAIL, displayItemName: 'itemName' },
  { columnName: 'unitPrice', columnLabel: '単価', logType: LogTypeEnum.DETAIL, displayItemName: 'itemName' }
];

type BillingV2Dtos = BillingV2 | BillingDetailV2;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toRegisterText = (value: any, getLabel: ((key: Key) => Text) | undefined, isDateColumn: boolean): Text => {
  if (value === null || value === undefined) return value;

  if (getLabel) {
    return getLabel(value);
  }
  if (isDateColumn) {
    return format(value, 'yyyy-MM-dd');
  }
  return value.toString();
};

const createBillingLogs = (billing: BillingV2, operation: Operation, user: User): BillingLogV2 => {
  return builder<BillingLogV2>().billingLogId(uuid()).billing(billing).operation(operation).createdUser(user).build();
};

const getValue = (dto: BillingV2Dtos, columnName: Name | Name[]) =>
  typeof columnName === 'string'
    ? dto[columnName]
    : dto[columnName[ZERO]]
    ? dto[columnName[ZERO]][columnName[ONE]]
    : EMPTY;

const compare = (
  billing: BillingV2,
  prev: BillingV2Dtos,
  curr: BillingV2Dtos,
  configs: ConfigCompareColumn[],
  user: User
): BillingLogV2[] => {
  if (!prev || !curr) return [];

  return configs.reduce((billingLogs: BillingLogV2[], config: ConfigCompareColumn): BillingLogV2[] => {
    const { columnName, columnLabel, displayItemName, logType, getLabel } = config;
    const prevValue = getValue(prev, columnName);
    const currValue = getValue(curr, columnName);
    const isNumberColumn = isNumber(prevValue) || isNumber(currValue);
    const isDateColumn = isDate(prevValue) || isDate(currValue);
    const isDifferent = isDateColumn
      ? !isEqual(prevValue, currValue)
      : isNumberColumn
      ? parseFloat(prevValue) !== parseFloat(currValue)
      : prevValue !== currValue;
    if (isDifferent) {
      const prevText = toRegisterText(prevValue, getLabel, isDateColumn);
      const currText = toRegisterText(currValue, getLabel, isDateColumn);
      const itemName = displayItemName ? curr[displayItemName] : EMPTY;
      billingLogs.push(
        createBillingLogs(
          billing,
          logType === LogTypeEnum.DETAIL
            ? BILLING_MODIFIED_DETAIL(columnLabel, prevText, currText, itemName)
            : BILLING_MODIFIED_DEFAULT(columnLabel, itemName),
          user
        )
      );
    }
    return billingLogs;
  }, []);
};

export const createBillingLogsFromBilling = (
  prevBillings: BillingV2 | undefined,
  currBillings: BillingV2 | undefined,
  user: User | undefined
): BillingLogV2[] => {
  if (!user) return [];
  if (!prevBillings && !currBillings) return [];

  // 新規契約登録
  if (!prevBillings && currBillings) {
    return [createBillingLogs(currBillings, BILLING_CREATED, user)];
  }

  // 契約削除
  if (prevBillings && !currBillings) {
    return [createBillingLogs(prevBillings, BILLING_DELETED(prevBillings.billingId), user)];
  }

  // 差分取得
  return compare(
    currBillings as BillingV2,
    prevBillings as BillingV2Dtos,
    currBillings as BillingV2Dtos,
    configCompareBilling,
    user
  );
};

export const createBillingLogsFromBillingDetail = (
  billing: BillingV2,
  prevBillingDetails: BillingDetailV2[],
  currBillingDetails: BillingDetailV2[],
  user: User | undefined,
  units: UnitV2[],
  taxes: TaxV2[]
): BillingLogV2[] => {
  if (!user) return [];
  if (!hasLength(prevBillingDetails) && !hasLength(currBillingDetails)) return [];

  // 追加された明細のログ
  const addedBillingLogs = currBillingDetails.reduce(
    (logs: BillingLogV2[], currDetail: BillingDetailV2): BillingLogV2[] => {
      if (!prevBillingDetails.some(prevDetail => prevDetail.billingDetailId === currDetail.billingDetailId)) {
        logs.push(createBillingLogs(billing, BILLING_DETAIL_ADDED(currDetail.itemName), user));
      }
      return logs;
    },
    []
  );

  // 削除された明細のログ
  const deletedBillingLogs = prevBillingDetails.reduce(
    (logs: BillingLogV2[], prevDetail: BillingDetailV2): BillingLogV2[] => {
      if (!currBillingDetails.some(currDetail => currDetail.billingDetailId === prevDetail.billingDetailId)) {
        logs.push(createBillingLogs(billing, BILLING_DETAIL_DELETED(prevDetail.itemName), user));
      }
      return logs;
    },
    []
  );

  // 共通明細のログ
  const comparedBillingLogs = currBillingDetails.reduce(
    (logs: BillingLogV2[], currBillingDetail: BillingDetailV2): BillingLogV2[] => {
      const prevBillingDetail = prevBillingDetails.find(
        prevDetail => prevDetail.billingDetailId === currBillingDetail.billingDetailId
      );
      if (prevBillingDetail) {
        const comparedBillingLogs = compare(
          billing,
          prevBillingDetail,
          currBillingDetail,
          configCompareBillingDetail(units, taxes),
          user
        );
        return logs.concat(comparedBillingLogs);
      }
      return logs;
    },
    []
  );
  return addedBillingLogs.concat(deletedBillingLogs).concat(comparedBillingLogs);
};
