import {
  BackButtonV2,
  ButtonV2,
  ScreenLoaderV2,
  StepNavigationV2,
  themeV2,
  themeV3,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { QUESTIONNAIRE_ID, Questionnaire, QuestionnaireId, QuestionnaireStatus, User } from '@atomica.co/irori';
import { Index, Message, URL } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import { isAfter } from 'date-fns';
import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import Screen from '../../components/screen/Screen';
import { MOBILE_MAX_WIDTH } from '../../constants/common-const';
import { QuestionnaireAnswerStepEnum } from '../../enums/questionnaire-enum';
import { useSnackbarV2 } from '../../provider/SnackbarProviderV2';
import useCachedURL from '../../redux/hooks/useCachedURL';
import usePath from '../../redux/hooks/usePath';
import useQuestionnaire from '../../redux/hooks/useQuestionnaire';
import { Path } from '../../router/Routes';
import { ImageService } from '../../services/image-service';
import { QUESTIONNAIRE_ANSWER_STEP_LABEL } from '../../texts/questionnaire-text';
import RegisteredScreen from '../registered/RegisteredScreen';
import QuestionnaireForm from './form/QuestionnaireForm';

interface P {
  user: User;
}

const QuestionnaireAnswerScreen: React.FC<P> = React.memo(props => {
  const { user } = props;

  const { params, openBasePath } = usePath();
  const { hasCachedURL, openCachedURL, clearCachedURL } = useCachedURL();
  const { isLoading, fetchQuestionnaire, validateAnswer, saveAnswer, clearCachedAnswer } = useQuestionnaire();
  const { openSnackbar } = useSnackbarV2();

  const unmountRef = useUnmountRef();
  const [currentStepIndex, setCurrentStepIndex] = useSafeState<Index>(unmountRef, QuestionnaireAnswerStepEnum.ANSWER);
  const [thumbnail, setThumbnail] = useSafeState<File>(unmountRef);
  const [questionnaire, setQuestionnaire] = useSafeState<Questionnaire>(unmountRef);
  const [isInitialized, setIsInitialized] = useSafeState<boolean>(unmountRef, false);
  const [isCompleted, setIsCompleted] = useSafeState<boolean>(unmountRef, false);

  const topRef = useRef<HTMLDivElement>(null);

  const questionnaireId = useMemo<QuestionnaireId>(() => params[QUESTIONNAIRE_ID], [params]);

  const isConfirmation = useMemo<boolean>(
    () => currentStepIndex === QuestionnaireAnswerStepEnum.CONFIRMATION,
    [currentStepIndex]
  );

  const isAnswered = useMemo<boolean>(
    () => questionnaire?.questionnaireAnswers?.some(answer => answer.answeredUser?.userId === user.userId) ?? false,
    [questionnaire, user]
  );

  const getQuestionnaireThumbnail = useSafeCallback(
    async (thumbnailURL: URL): Promise<void> => {
      const downloadFile = await ImageService.urlToFile(thumbnailURL);
      downloadFile && setThumbnail(downloadFile);
    },
    [setThumbnail]
  );

  const initialize = useSafeCallback(async (): Promise<void> => {
    const questionnaire = await fetchQuestionnaire(questionnaireId, user.userId);
    if (questionnaire) setQuestionnaire(questionnaire);
    if (questionnaire && questionnaire.thumbnailURL) getQuestionnaireThumbnail(questionnaire.thumbnailURL);
    if (questionnaire) setIsInitialized(true);
  }, [fetchQuestionnaire, getQuestionnaireThumbnail, setIsInitialized, setQuestionnaire, user]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const isLinkInvalid = useMemo<boolean>(
    () => isInitialized && (!questionnaire || questionnaire.status === QuestionnaireStatus.PRIVATE),
    [isInitialized, questionnaire]
  );

  const isQuestionnaireExpired = useMemo<boolean>(() => {
    if (!questionnaire) return false;
    return !isAnswered && !!questionnaire.answerDueAt && isAfter(new Date(), questionnaire.answerDueAt);
  }, [isAnswered, questionnaire]);

  const errorMessage = useMemo<Message>(() => {
    if (isLinkInvalid) return 'このリンクは無効です';
    if (isQuestionnaireExpired) return 'アンケートの回答期限が過ぎているため、表示できません';
    return EMPTY;
  }, [isLinkInvalid, isQuestionnaireExpired]);

  const goBack = useSafeCallback((): void => {
    if (hasCachedURL()) {
      openCachedURL();
      clearCachedURL();
    } else {
      openBasePath(Path.ACCOUNT_HOME);
    }
  }, [clearCachedURL, hasCachedURL, openCachedURL, openBasePath]);

  const handlePrimaryButtonClicked = useSafeCallback(async (): Promise<void> => {
    if (isConfirmation) {
      await saveAnswer();

      setIsCompleted(true);
    } else {
      if (!validateAnswer()) return;
      setCurrentStepIndex(QuestionnaireAnswerStepEnum.CONFIRMATION);
    }
  }, [isConfirmation, openSnackbar, saveAnswer, setCurrentStepIndex, setIsCompleted, validateAnswer]);

  useEffect(() => {
    if (!isAnswered) return;
    setCurrentStepIndex(QuestionnaireAnswerStepEnum.CONFIRMATION);
  }, [isAnswered, setCurrentStepIndex]);

  useEffect(() => {
    clearCachedAnswer();
  }, [clearCachedAnswer]);

  useLayoutEffect(() => {
    if (!topRef || !isConfirmation || isAnswered) return;
    topRef.current?.scrollIntoView();
  }, [isAnswered, isConfirmation]);

  return isCompleted ? (
    <RegisteredScreen title='回答が完了しました' text={questionnaire?.thanksMessage ?? EMPTY}>
      <LargeButton type='tertiary' label='ホーム画面に戻る' onClick={() => openBasePath(Path.ACCOUNT_HOME)} />
    </RegisteredScreen>
  ) : (
    <Screen
      style={{ minHeight: '100%' }}
      loading={!isInitialized}
      loadingType='circular'
      errorMsg={errorMessage}
      className='questionnaire-answer-screen'
      title={isConfirmation ? '回答内容の確認' : 'アンケート回答フォーム'}
    >
      <Container ref={topRef}>
        <BackButtonWrapper>
          <BackButtonV2 hasBackground label='戻る' onClick={goBack} />
        </BackButtonWrapper>
        <Content>
          {!isAnswered && (
            <StepNavigationWrapper>
              <StepNavigationV2
                texts={Object.values(QUESTIONNAIRE_ANSWER_STEP_LABEL)}
                currentStepIndex={currentStepIndex}
              />
            </StepNavigationWrapper>
          )}
          {questionnaire && (
            <QuestionnaireForm
              questionnaire={questionnaire}
              thumbnail={thumbnail}
              readOnly={isConfirmation}
              isAnswered={isAnswered}
            />
          )}
        </Content>
        {!isAnswered && (
          <Footer>
            <ButtonWrapper>
              {isConfirmation && (
                <StyledButton
                  type='secondary'
                  label='回答に戻る'
                  onClick={() => setCurrentStepIndex(QuestionnaireAnswerStepEnum.ANSWER)}
                />
              )}
              <StyledButton
                type='primary'
                label={isConfirmation ? '完了' : '確認'}
                onClick={handlePrimaryButtonClicked}
              />
            </ButtonWrapper>
            <StyledButton label='回答をキャンセル' onClick={goBack} />
          </Footer>
        )}
        <ScreenLoaderV2 loading={isLoading} />
      </Container>
    </Screen>
  );
});

QuestionnaireAnswerScreen.displayName = 'QuestionnaireAnswerScreen';
export default QuestionnaireAnswerScreen;

const Container = styled.div`
  width: 100%;
`;

const BackButtonWrapper = styled.div`
  ${media.lessThan('small')`
    display: none;
  `};
`;
const Content = styled.div`
  max-width: 640px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  margin: 0 auto;
`;

const StepNavigationWrapper = styled.div`
  width: 240px;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing}px;
  background: ${themeV3.mixins.v3.color.container.neutral.white};
  padding: ${themeV2.mixins.v2.spacing * 2}px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const StyledButton = styled(ButtonV2).attrs(() => ({ size: 'large', width: 200 }))``;

const LargeButton = styled(ButtonV2).attrs(() => ({ size: 'large', isFullWidth: true }))`
  && {
    max-width: ${MOBILE_MAX_WIDTH}px;
  }
`;
